import { useFineTuningContext } from '../../FineTuningContext';
export type FineTuningAfterResetPageHandler = {
    onDone: () => void;
};
const useFineTuningAfterResetPageHandler =
    (): FineTuningAfterResetPageHandler => {
        const { handleStartFineTuning } = useFineTuningContext();
        const onDone = () => {
            handleStartFineTuning();
        };
        return { onDone };
    };

export default useFineTuningAfterResetPageHandler;
