import React, { useContext, useState } from 'react';
import SpeechComfortTestDetailPage from './Detail/SpeechComfortTestDetailPage';
import { GainUIMapping } from '../../../Models/HearingProfile/GainUIMapping';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Redux/Reducers/RootReducer';
import IDeviceState from '../../../Redux/Models/Device/IDeviceState';
import {
    ISpeechComfortTestContext,
    ISpeechComfortTestInfo,
    SpeechComfortTestContext,
} from './SpeechComfortTestContext';
import { SpeechComfortTestState } from './SpeechComfortTestModels';
import { MainViewContext } from '../../MainComponents/MainView/MainViewContext';
import {
    MainViewWorkflow,
    TransitionAction,
} from '../../MainComponents/MainView/MainViewModel';
import RedoLoudnessStartPage from './StartRedo/RedoLoudnessStartPage';
import AudioRoutingPage from './AudioRouting/AudioRoutingPage';

function RedoLoudness() {
    const deviceState = useSelector(
        (state: RootState) => state.device
    ) as IDeviceState;

    const viewContext = useContext(MainViewContext);
    const [info, setInfo] = useState<ISpeechComfortTestInfo>({
        masterGain: new GainUIMapping([]),
        deviceState: deviceState,
        speechComfortTestState:
            viewContext.workflowType == MainViewWorkflow.AppFineTuning
                ? SpeechComfortTestState.AudioRouting
                : SpeechComfortTestState.Start,
        latestLeftValue: 0,
        latestRightValue: 0,
        loading: false,
    });

    const exitSpeechComfort = async () => {
        viewContext.doTransition(TransitionAction.RedoLoudnessContinue);
    };

    const initialValue: ISpeechComfortTestContext = {
        info: info,
        setSpeechComfortTestInfo: setInfo,
        exitSpeechComfort: exitSpeechComfort,
    };

    const renderSwitch = (state: SpeechComfortTestState) => {
        switch (state) {
            case SpeechComfortTestState.AudioRouting: {
                return <AudioRoutingPage />;
            }
            case SpeechComfortTestState.Start: {
                return <RedoLoudnessStartPage />;
            }
            case SpeechComfortTestState.Detail: {
                return <SpeechComfortTestDetailPage />;
            }
            default: {
                return <></>;
            }
        }
    };

    return (
        <SpeechComfortTestContext.Provider value={initialValue}>
            {renderSwitch(info.speechComfortTestState)}
        </SpeechComfortTestContext.Provider>
    );
}

export default withTracking(RedoLoudness);
