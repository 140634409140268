import {
    deviceModel,
    deviceModelId,
    platformType,
} from '../Models/SupportedDeviceModel';

const BluetoothDevices: string[] = [
    deviceModelId.VibeGo,
    deviceModelId.VibeComplete,
];

const BluetoothDeviceModels: deviceModel[] = [
    deviceModel.VibeGo,
    deviceModel.VibeComplete,
];

export function isBluetoothDevice(modelId: string): boolean {
    return BluetoothDevices.includes(modelId);
}

export function isBluetoothDeviceByModel(model: deviceModel): boolean {
    return BluetoothDeviceModels.includes(model);
}

// if platform is D11, originalId is encoded with receiver size
export function mapEncodedModelIdToDefault(
    originalId: string,
    platformId: string
): string {
    if (platformId != platformType.D11) return originalId;
    const receiverSizes = [0, 1, 2, 3, 4];
    const receiverBitSize = 3;
    const receiverBitShift = 25;
    const receiverOffset = 1;
    const defaultId = Number(deviceModelId.VibeGo);
    for (const receiverSize in receiverSizes) {
        const dwBitsToSet =
            (((1 << receiverBitSize) - 1) &
                (Number(receiverSize) + receiverOffset)) <<
            receiverBitShift;
        const dwBitsToKeep =
            defaultId &
            ~(
                (((1 << receiverBitSize) - 1) << receiverBitShift) &
                0x0000ffffffffffff
            );

        if (Number(originalId) == (dwBitsToSet | dwBitsToKeep))
            return deviceModelId.VibeGo;
    }
    return originalId;
}
