import { useFineTuningContext } from '../FineTuningContext';

export type FineTuningInfoPageHandler = {
    goStart: () => void;
};

const useFineTuningInfoPageHandler = (): FineTuningInfoPageHandler => {
    const { send } = useFineTuningContext();
    const goStart = () => {
        send('goToStart');
    };

    return {
        goStart,
    };
};

export default useFineTuningInfoPageHandler;
