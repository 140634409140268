import React from 'react';
import useRedoEasyFitOneSideSetUpResource from './RedoEasyFitOneSideSetUpResourceHook';
import useRedoEasyFitOneSideSetUpHandler from './RedoEasyFitOneSideSetUpHandlerHook';
import OneSideSetUp from '../../../../Pages/MainComponents/OneSideSetUp/OneSideSetUp';

export default function RedoEasyFitOneSideSetUp(): React.ReactElement {
    return (
        <OneSideSetUp
            useHandler={useRedoEasyFitOneSideSetUpHandler}
            useResource={useRedoEasyFitOneSideSetUpResource}
        />
    );
}
