import React from 'react';
import { HeaderActionType } from './HeaderActionType';
import CustomIconButton from '../Button/CustomIconButton';
import FlexContainer from '../Container/FlexContainer';
import CustomImg from '../CustomImage/CustomImg';
import MenuDialog from '../Dialog/Menu/MenuDialog';
import CustomTypography, {
    TypographyType,
} from '../Typography/CustomTypography';
import useHeaderHandler from './HeaderHandlerHook';
import './HeaderStyle.scss';
import { HeaderResource } from '../../Utils/PageUtils';

export interface IHeaderProps {
    headerContent: HeaderResource;
    disabled?: boolean;
    onClick?: () => void | Promise<void>;
}

function Header({
    headerContent,
    disabled = false,
    onClick,
}: IHeaderProps): JSX.Element {
    const { showMenu, handleCloseMenu, handleOnClick } = useHeaderHandler(
        onClick,
        headerContent.type
    );

    return (
        <FlexContainer flexRow className={'headerArea'}>
            {showMenu && (
                <MenuDialog displayed={showMenu} onClose={handleCloseMenu} />
            )}
            {headerContent.iconImgSrc && (
                <CustomImg
                    imgType="icon"
                    src={headerContent.iconImgSrc}
                    alt={`icon-${headerContent.type}`}
                    style={{ margin: 'auto auto auto 0' }}
                />
            )}
            <CustomTypography
                id={headerContent.id}
                hidden={!headerContent.text || headerContent.text.trim() === ''}
                type={TypographyType.comfortaaHeading}
                text={`${
                    headerContent.prefix
                        ? `\`teal{${headerContent.prefix}}\``
                        : ''
                } ${headerContent.text}`}
                textAlign="left"
            />
            {headerContent.type &&
                headerContent.type !== HeaderActionType.NoAction && (
                    <CustomIconButton
                        imgSrc={`/resources/header/${headerContent.type.toString()}.svg`}
                        disabled={disabled}
                        className={disabled ? 'disabled' : ''}
                        onClick={handleOnClick}
                        id={`btn-${headerContent.type.toString()}`}
                    />
                )}
        </FlexContainer>
    );
}

export default Header;
