import { MobileStepper } from '@material-ui/core';
import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { joinMultipleStyle } from '../../Utils/ScssUtils';
import CustomIconButton from '../Button/CustomIconButton';
import FlexContainer from '../Container/FlexContainer';
import CarouselFooter, { CarouselFooterContent } from './CarouselFooter';
import useCarouselHook from './CarouselHandlerHook';
import './CarouselStyle.scss';

export interface ICarouselProps {
    step: number;
    setStep: (newStep: number) => void;
    buttonsDisabled?: boolean;
    carouselContents: JSX.Element[];
    instructionContents?: CarouselFooterContent[];
    useHandler?: Function;
    carouselContainerClassName?: string;
}

function Carousel({
    step,
    setStep,
    buttonsDisabled = false,
    carouselContents,
    instructionContents = [],
    carouselContainerClassName,
    useHandler = useCarouselHook,
}: ICarouselProps): React.ReactElement {
    const { handleForwardAction, handleBackwardAction, handleOnChangeIndex } =
        useHandler(carouselContents.length, step, setStep);
    return (
        <FlexContainer
            className={joinMultipleStyle(
                'carouselContainer',
                carouselContainerClassName
            )}
        >
            <FlexContainer flexRow className={'carouselDisplay'}>
                <CustomIconButton
                    id="btn-back"
                    iconSize="iconLarge"
                    disableFocusRipple={true}
                    disableRipple={true}
                    disableTouchRipple={true}
                    onClick={handleBackwardAction}
                    className={'arrowButton'}
                    disabled={step === 0 || buttonsDisabled}
                    imgSrc={
                        step === 0 || buttonsDisabled
                            ? `/resources/shared/left-arrow_disabled.svg`
                            : `/resources/shared/left-arrow.svg`
                    }
                />
                <SwipeableViews
                    axis="x"
                    index={step}
                    onChangeIndex={handleOnChangeIndex}
                    autoPlay={false}
                    enableMouseEvents
                    disabled={buttonsDisabled}
                    id="img-carousel"
                    slideStyle={{ overflow: 'hidden' }}
                >
                    {carouselContents.map((content: JSX.Element, index) => {
                        return (
                            <FlexContainer
                                key={`img-container-${index}`}
                                className={'carouselContentContainer'}
                                id={`carousel-card-${index}`}
                            >
                                {content}
                            </FlexContainer>
                        );
                    })}
                </SwipeableViews>
                <CustomIconButton
                    id="btn-next"
                    className={'arrowButton'}
                    iconSize="iconLarge"
                    disableFocusRipple={true}
                    disableRipple={true}
                    disableTouchRipple={true}
                    onClick={handleForwardAction}
                    disabled={
                        step === carouselContents.length - 1 || buttonsDisabled
                    }
                    imgSrc={
                        step === carouselContents.length - 1 || buttonsDisabled
                            ? `/resources/shared/right-arrow_disabled.svg`
                            : `/resources/shared/right-arrow.svg`
                    }
                />
            </FlexContainer>
            <MobileStepper
                steps={carouselContents.length}
                className={'stepperDots'}
                position="static"
                variant="dots"
                activeStep={step}
                nextButton={<div />}
                backButton={<div />}
            />
            {instructionContents.length > 0 && (
                <CarouselFooter step={step} contents={instructionContents} />
            )}
        </FlexContainer>
    );
}

export default Carousel;
