import axios from 'axios';

export const preloadImages = async (): Promise<void> => {
    let imageList: string[] = [];
    const cdnEndpoint = process.env.REACT_APP_CDN_ENDPOINT as string;
    axios
        .get(`${cdnEndpoint}resources/imageList.json`)
        .then((imageListJson) => {
            imageList = imageListJson.data['imagePaths'];
            imageList.forEach((url) => {
                const img = new Image();
                img.src = url.replaceAll('\\', '/');
            });
        });
};
