import React from 'react';
import CustomizedCircularProgressPercent from '../../../../../SharedComponents/Spinner/CustomizedCircularProgressPercent';
import CustomTypography, {
    TypographyType,
} from '../../../../../SharedComponents/Typography/CustomTypography';
import { useFineTuningSolutionContext } from '../../FineTuningSolutionContext';
import useFineTuningApplyingPageResource from './FineTuningApplyingPageResource';
import '../../FineTuningSolutionPage.scss';
import FlexContainer from '../../../../../SharedComponents/Container/FlexContainer';

export interface IFineTuningApplyingPage {
    solutionNumber: number;
}

export default function FineTuningApplyingPage(
    props: IFineTuningApplyingPage
): React.ReactElement {
    const { solutionText, progress } = useFineTuningApplyingPageResource(props);
    const { percentage } = useFineTuningSolutionContext();
    return (
        <FlexContainer className={'solutionCard'}>
            <CustomTypography
                id={solutionText.id}
                text={solutionText.text}
                type={TypographyType.notoSansSubHeading}
            />
            <CustomizedCircularProgressPercent
                id={progress.id}
                loadingPercentage={percentage}
                spinnerContainerClassName={'spinnerContainerApplying'}
                loadingText={progress.text}
            />
        </FlexContainer>
    );
}
