import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import AuthService from '../../../Services/Authentication/AuthService';
import { addResourceBundle } from '../../../Services/LocalizationService';
import LocalStorageService from '../../../Services/LocalStorageService';
import TelemetryService from '../../../Services/Monitoring/TelemetryService';
import { getCustomConsentVersion } from '../../../Utils/ConsentVersionUtils';
import { VoidReturn } from '../../../Utils/PageUtils';
import { MainViewContext } from '../MainView/MainViewContext';
import { TransitionAction } from '../MainView/MainViewModel';

export type ConsentPageHandler = {
    showLegalInfo: boolean;
    showStartButton: boolean;
    handleOperationDataCheckboxChange: (
        event: React.ChangeEvent<HTMLInputElement>
    ) => VoidReturn;
    onContinue: () => VoidReturn;
    openHyperLinkPrivacy: () => VoidReturn;
    dialogClose: () => VoidReturn;
};

function useConsentPageHandler(
    setLoading: React.Dispatch<boolean>
): ConsentPageHandler {
    const history = useHistory();
    const [showLegalInfo, setShowLegalInfo] = useState(false);
    const [showStartButton, setShowStartButton] = React.useState(false);
    const viewContext = useContext(MainViewContext);

    const loading = useCallback(async () => {
        setLoading(true);
        if (getCustomConsentVersion()) {
            await addResourceBundle(
                `Legal/${LocalStorageService.serviceInstance.getSelectedCountry()}/`,
                'consent'
            );
        }
        const patientId = LocalStorageService.serviceInstance.getPatientId();
        if (patientId === '') {
            const refreshCookies = async () => {
                await AuthService.adB2cInstance.refreshCookies();
            };
            refreshCookies();
        }
        setLoading(false);
    }, [setLoading]);

    useEffect(() => {
        loading();
    }, [history, loading]);

    const handleOperationDataCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setShowStartButton(event.target.checked);
    };

    const onContinue = async () => {
        setShowStartButton(false);
        LocalStorageService.serviceInstance.setOperationConsentState(true);

        TelemetryService.serviceInstance.TrackWithIdentity(
            'EasyFit-FeatureUsage',
            {
                Feature: 'OperationConsented',
            }
        );
        viewContext.doTransition(TransitionAction.ConsentContinue);
    };

    const openHyperLinkPrivacy = () => {
        setShowLegalInfo(true);
    };

    const dialogClose = () => {
        setShowLegalInfo(false);
    };

    return {
        showLegalInfo,
        showStartButton,
        handleOperationDataCheckboxChange,
        onContinue,
        openHyperLinkPrivacy,
        dialogClose,
    };
}

export default useConsentPageHandler;
