import React from 'react';
import { ButtonType } from '../../../../../SharedComponents/Button/ButtonType';
import CustomButton from '../../../../../SharedComponents/Button/CustomButton';
import FlexContainer from '../../../../../SharedComponents/Container/FlexContainer';
import CustomTypography, {
    TypographyType,
} from '../../../../../SharedComponents/Typography/CustomTypography';
import useFTFooterAppliedSuccessPageResource from './FTFooterAppliedSuccessPageResource';
import '../../FineTuningSolutionPage.scss';
import useFTFooterAppliedSuccessPageHandler from './FTFooterAppliedSuccessHandler';

export default function FTFooterAppliedSuccessPage(): JSX.Element {
    const { goHome } = useFTFooterAppliedSuccessPageHandler();
    const { successText, button } = useFTFooterAppliedSuccessPageResource();
    return (
        <FlexContainer>
            <CustomTypography
                id={successText.id}
                text={successText.text}
                type={TypographyType.notoSansBody}
                className={'appliedSuccessfullyFooterText'}
            />
            <CustomButton
                id={button.id}
                buttonType={ButtonType.Primary}
                onClick={goHome}
            >
                {button.text}
            </CustomButton>
        </FlexContainer>
    );
}
