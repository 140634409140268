import { useContext } from 'react';
import { AnyEventObject, assign, createMachine } from 'xstate';
import { MainViewContext } from '../MainComponents/MainView/MainViewContext';
import { MainViewWorkflow } from '../MainComponents/MainView/MainViewModel';
import { PairingStateContext, pairingStateMachine } from './PairingModels';
import usePairingStateMachineHandler from './PairingStateMachineHandler';

export default function PairingStateMachine(): pairingStateMachine {
    const { exitWorkflowHandler, updatePairingContext, switchSide } =
        usePairingStateMachineHandler();
    const { workflowType } = useContext(MainViewContext);
    const arcPairingMachine = {
        initial: 'PreparePairing',
        states: {
            PreparePairing: {
                on: {
                    completePrepare: 'PairOneSide',
                },
            },
            PairOneSide: {
                on: {
                    completeOneSide: 'Confirmation',
                },
            },
            Confirmation: {
                on: {
                    confimred: 'Success',
                    rejected: 'TroubleShooting',
                },
            },
            TroubleShooting: {
                on: {
                    troubleshot: 'PreparePairing',
                },
            },
            Success: {
                on: {
                    successed: [
                        {
                            target: 'PairOneSide',
                            cond: (context: PairingStateContext) =>
                                Boolean(switchSide(context)),
                            actions: updatePairingContext,
                        },
                        {
                            actions: exitWorkflowHandler,
                        },
                    ],
                },
            },
        },
    };

    const stateMachine = createMachine(
        {
            id: 'pairing-state-machine',
            initial: 'TechnicalSetup',
            context: {
                activeSide: 'left',
                left: false,
                right: false,
            } as PairingStateContext,
            states: {
                TechnicalSetup: {
                    on: {
                        completeTechnicalSetup: [
                            {
                                target: 'BLEPairing',
                                cond: 'isVibeAppWorkflow',
                            },
                            {
                                target: 'arcPairing',
                            },
                        ],
                    },
                },
                arcPairing: {
                    ...arcPairingMachine,
                },
                BLEPairing: {
                    on: {
                        completeBLEParing: {
                            actions: exitWorkflowHandler,
                        },
                        switchSide: {
                            actions: assign(
                                (
                                    context: PairingStateContext,
                                    event: AnyEventObject
                                ) => {
                                    return {
                                        activeSide: event.data,
                                    };
                                }
                            ),
                        },
                    },
                },
            },
        },
        {
            guards: {
                isVibeAppWorkflow: () => workflowType !== MainViewWorkflow.Web,
            },
        }
    );
    return stateMachine;
}
