import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IHlaaState from '../../../Redux/Models/HLAA/IHlaaState';
import { updateHlaa } from '../../../Redux/Reducers/HlaaSlice';
import { RootState } from '../../../Redux/Reducers/RootReducer';
import AuthService from '../../../Services/Authentication/AuthService';
import { getCurrentLocale } from '../../../Services/LocalizationService';
import LocalStorageService from '../../../Services/LocalStorageService';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import { TelemetryContext } from '../../../Services/Monitoring/TelemetryProvider';
import PatientManagementService from '../../../Services/PatientManagementService';
import FineTuningWorkflowError from '../../../SharedComponents/Dialog/FineTuningWorkflowError/FineTuningWorkflowError';
import GlobalDialog from '../../../SharedComponents/Dialog/GlobalDialog/UseGlobalDialog';
import useLoading from '../../../SharedComponents/Spinner/PageLoadSpinner';
import FineTuningPage from '../../FineTuning/FineTuningPage';
import AudiogramScoringCheckPage from '../../HearingProfile/AudiogramScoringCheckPage';
import CompleteRecommendationPage from '../../HearingProfile/CompleteRecommendation/CompleteRecommendationPage';
import SpeechComfortTestPage from '../../HearingProfile/SpeechComfortTest/SpeechComfortTestPage';
import RedoLoudnessPage from '../../HearingProfile/SpeechComfortTest/RedoLoudnessPage';
import HearingAssessmentPage from '../../HLAA/HearingAssessmentPage';
import PairingPage from '../../Pairing/PairingPage';
import ConsentPage from '../Consent/ConsentPage';
import ConsentAnalyticPage from '../ConsentAnalytic/ConsentAnalyticPage';
import ContraindicationPage from '../Contraindication/ContraindicationPage';
import CountrySelectionPage from '../CountrySelection/CountrySelectionPage';
import HIAssemblyPage from '../HIAssembly/HIAssemblyPage';
import LandingPage from '../Landing/LandingPage';
import SmartRemotePage from '../SmartRemote/SmartRemotePage';
import SplashScreen from '../SplashScreen/SplashScreenPage';
import UserJourneyPage from '../UserJourney/UserJourneyPage';
import WelcomePage from '../Welcome/WelcomePage';
import { IMainViewContext, MainViewContext } from './MainViewContext';
import * as deviceInfo from 'react-device-detect';
import {
    DialogState,
    IMainViewProps,
    MainViewWorkflow,
    PageState,
    TransitionAction,
    ViewState,
} from './MainViewModel';
import { getDebugRoute, getPageStateParam } from './TransitionHandlerUtils';
import DeviceCompatibilityCheckDialog from '../../../SharedComponents/Dialog/DeviceCompatibilityCheck/DeviceCompatibilityCheckDialog';
import AppLandingPage from '../Landing/AppLanding/AppLandingPage';
import { switchWebAppVibeApp } from '../../../Utils/PageUtils';
import RedoEasyFitPage from '../../../SharedComponents/Dialog/RedoEasyFit/RedoEasyFitPage';
import RedoHLAAPage from '../../../SharedComponents/Dialog/RedoHLAA/RedoHLAAPage';
import SleeveSelection from '../../../SharedComponents/Dialog/SleeveSelection/SleeveSelection';
import useConsentAnalyticHandler from '../ConsentAnalytic/ConsentAnalyticHandlerHook';
import DeleteAccount from '../../../SharedComponents/Dialog/DeleteAccount/DeleteAccount';

function MainView({ workflowType, useTransitionHandler }: IMainViewProps) {
    const [loadingSpinner] = useLoading();
    const dispatch = useDispatch();
    const hlaaState = useSelector(
        (state: RootState) => state.hlaa
    ) as IHlaaState;
    const isComponentMounted = useRef(true);
    const telemetryClient = useContext(TelemetryContext);
    const [currentView, setView] = useState({
        dialog: DialogState.None,
        page: PageState.Loading,
    } as ViewState);

    const transitionHandler = useTransitionHandler();

    const doTransition = async (action: TransitionAction) => {
        const patient = await PatientManagementService.getPatient();

        const nextView = transitionHandler.doTransition(
            currentView,
            action,
            patient
        );
        setView(nextView);
    };

    const viewContext: IMainViewContext = {
        pageState: currentView.page,
        dialogState: currentView.dialog,
        workflowType: workflowType,
        doTransition: doTransition,
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentView]);

    useEffect(() => {
        const logDeviceDetails = () => {
            telemetryClient.trackTrace(
                `Patient ID: ${LocalStorageService.serviceInstance.getPatientId()}, Device Details: ${JSON.stringify(
                    deviceInfo.deviceDetect()
                )}, Screen Resolution: ${
                    window.screen.width * window.devicePixelRatio
                } x ${window.screen.height * window.devicePixelRatio}`
            );
        };

        const determineInitialState = async () => {
            const instance = await AuthService.getInstance();
            await instance.handleLoginRedirect();

            if (instance.getIsAuthenticated()) {
                const supportedHis =
                    await PatientManagementService.getSupporedHis();
                LocalStorageService.serviceInstance.setSupportedHis(
                    supportedHis
                );
                const patientId =
                    LocalStorageService.serviceInstance.getPatientId();
                telemetryClient.tracePatientId(patientId);

                logDeviceDetails();
                dispatch(
                    updateHlaa({
                        ...hlaaState,
                        hlaa0ToneFirstRunState: {
                            left: true,
                            right: true,
                        },
                    })
                );

                const patient = await PatientManagementService.getPatient();
                const userSelectedLocale = getCurrentLocale();

                if (
                    patient &&
                    patient.preferredLanguage !== userSelectedLocale
                ) {
                    await PatientManagementService.updateLanguage(
                        userSelectedLocale
                    );
                }

                const debugRoute = getDebugRoute();

                const initialPage = getPageStateParam();

                const nextView = await transitionHandler.goToInitialState(
                    patient,
                    debugRoute,
                    initialPage
                );
                setView(nextView);
            } else {
                // special case for /delete-account, redirect to IDS Login as landing
                if (workflowType === MainViewWorkflow.DeleteAccount) {
                    const instance = await AuthService.getInstance();
                    await instance.login(true);
                } else {
                    setView({
                        dialog: DialogState.None,
                        page: PageState.SplashScreen,
                    } as ViewState);
                }
            }
        };

        if (isComponentMounted.current) {
            (async function init() {
                await determineInitialState();
            })();
        }
        return () => {
            isComponentMounted.current = false;
        };
    }, [dispatch, hlaaState, telemetryClient, transitionHandler, workflowType]);

    const renderView = (view: ViewState) => {
        switch (view.page) {
            case PageState.Loading:
                return loadingSpinner;
            case PageState.SplashScreen:
                return <SplashScreen />;
            case PageState.NewWelcome:
                return <WelcomePage />;
            case PageState.RestartWelcome:
                return <WelcomePage />;
            case PageState.CountrySelection:
                return <CountrySelectionPage />;
            case PageState.Consent:
                return <ConsentPage />;
            case PageState.ConsentAnalytic:
                return (
                    <ConsentAnalyticPage
                        useHandler={useConsentAnalyticHandler}
                    />
                );
            case PageState.HiAssemble:
                return <HIAssemblyPage />;
            case PageState.Contraindication:
                return <ContraindicationPage />;
            case PageState.Journey:
                return <UserJourneyPage />;
            case PageState.Pairing:
                return <PairingPage />;
            case PageState.RePairing:
                return <PairingPage />;
            case PageState.SleeveSelection:
                return <SleeveSelection />;
            case PageState.Assessment:
                return <HearingAssessmentPage />;
            case PageState.RedoHLAA:
                return <RedoHLAAPage />;
            case PageState.InitialSettings:
                return <AudiogramScoringCheckPage />;
            case PageState.CompleteRecommendation:
                return <CompleteRecommendationPage />;
            case PageState.SpeechComfort:
                return <SpeechComfortTestPage />;
            case PageState.RedoLoudness:
                return <RedoLoudnessPage />;
            case PageState.Landing:
                return switchWebAppVibeApp(
                    workflowType,
                    <LandingPage />,
                    <AppLandingPage />
                );
            case PageState.FineTuning:
                return <FineTuningPage />;
            case PageState.SmartRemote:
                return <SmartRemotePage />;
            case PageState.RedoAssessmentOption:
                return <RedoEasyFitPage />;
            case PageState.DeleteAccount:
                return <DeleteAccount />;
            case PageState.FineTuningWorkflowError:
                return <FineTuningWorkflowError />;
            case PageState.DeviceIncompatibleError:
                return (
                    <DeviceCompatibilityCheckDialog
                        displayed={true}
                        // to be removed in task #45821
                        onClose={() => {
                            console.log(
                                'Placeholder to pass eslint. This needs to be removed'
                            );
                        }}
                    />
                );
            default:
                return (
                    <div>
                        <p>Error: 404</p>
                    </div>
                );
        }
    };

    const renderDialogView = (dialog: DialogState) => {
        if (dialog != DialogState.None) {
            return <GlobalDialog />;
        }
    };

    return (
        <MainViewContext.Provider value={viewContext}>
            {renderView(currentView)}
            {renderDialogView(currentView.dialog)}
        </MainViewContext.Provider>
    );
}

export default withTracking(MainView);
