import React from 'react';
import BodyContainer from '../../SharedComponents/Container/BodyContainer';
import CustomImg from '../../SharedComponents/CustomImage/CustomImg';
import CustomTypography, {
    TypographyType,
} from '../../SharedComponents/Typography/CustomTypography';
import { ImageResource, TextResource } from '../../Utils/PageUtils';
import OneSidePlayBubble from './OneSide/OneSidePlayBubble';

export interface HLAASharedViewResource {
    title: TextResource;
    img: ImageResource;
    instruction: TextResource;
    withBubble?: boolean;
}

export function HLAASharedView({
    title,
    img,
    instruction,
    withBubble,
}: HLAASharedViewResource): React.ReactElement {
    return (
        <BodyContainer flexStart>
            <CustomTypography
                id={title.id}
                text={title.text}
                type={TypographyType.notoSansBodyBig}
                className={'beepConfirmationTitle'}
            />
            {withBubble ? (
                <OneSidePlayBubble img={img} />
            ) : (
                <CustomImg
                    id={img.id}
                    src={img.src}
                    alt={img.alt}
                    imgType="medium"
                />
            )}
            <CustomTypography
                type={TypographyType.notoSansBodyBig}
                noMargin
                id={instruction.id}
                text={instruction.text}
                className={'hlaaPlayButtonInstruction'}
            />
        </BodyContainer>
    );
}
