export enum MenuDialogType {
    HearingAids = 'hearingAids',
    Instructions = 'instructions',
    LegalInformation = 'legalInformation',
    Settings = 'settings',
    RedoEasyFit = 'redoEasyFit',
    RedoPairing = 'redoPairing',
    RedoLoudness = 'redoLoudness',
    RedoHLAA = 'redoHLAA',
    CookiePolicy = 'cookiePolicy',
    LegalInfo = 'legalInfo',
    TermAndConditions = 'termAndConditions',
    PrivacyPolicy = 'privacyPolicy',
    AppInfo = 'appInfo',
    LanguageSelection = 'languageSelection',
    AccountSettings = 'accountSettings',
    RevokeAnalyticConsent = 'revokeAnalyticConsent',
    RevokeOperationConsent = 'revokeOperationConsent',
    CountrySelection = 'countrySelection',
}
