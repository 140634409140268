import { useContext, useState } from 'react';
import { VoidReturn } from '../../../Utils/PageUtils';
import { MainViewContext } from '../MainView/MainViewContext';
import { TransitionAction } from '../MainView/MainViewModel';

type LandingHandler = {
    displayDownloadAppDialog: boolean;
    closeDownloadAppDialog: () => VoidReturn;
    openDowndloadAppDialog: () => VoidReturn;
    goToFineTuning: () => VoidReturn;
};

function useLandingHandler(): LandingHandler {
    const viewContext = useContext(MainViewContext);
    const [displayDownloadAppDialog, setDisplayDownloadAppDialog] =
        useState(false);
    const closeDownloadAppDialog = () => {
        setDisplayDownloadAppDialog(false);
    };
    const openDowndloadAppDialog = () => {
        setDisplayDownloadAppDialog(true);
    };
    const goToFineTuning = () => {
        viewContext.doTransition(TransitionAction.GoFineTuning);
    };

    return {
        displayDownloadAppDialog,
        closeDownloadAppDialog,
        openDowndloadAppDialog,
        goToFineTuning,
    };
}

export default useLandingHandler;
