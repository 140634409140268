import React, { useState } from 'react';
import { ButtonType } from '../../../SharedComponents/Button/ButtonType';
import CustomButton from '../../../SharedComponents/Button/CustomButton';
import Carousel from '../../../SharedComponents/Carousel/Carousel';
import AppContainer from '../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../SharedComponents/Container/BodyContainer';
import CustomImg from '../../../SharedComponents/CustomImage/CustomImg';
import Header from '../../../SharedComponents/Header/HeaderRefactor';
import useTechnicalSetupHandler from './TechnicalSetupHandlerHook';
import useTechnicalSetupResource from './TechnicalSetupResourceHook';
import './TechnicalSetupStyle.scss';

export default function TechnicalSetup(): JSX.Element {
    const [step, setStep] = useState(0);

    const { handleStartPairingAction } = useTechnicalSetupHandler();

    const { header, imgSrcList, instructionList, button } =
        useTechnicalSetupResource();

    const carouselContents = imgSrcList.map((imgSrc, index) => {
        return (
            <CustomImg
                key={`carousel-img-${index}`}
                style={{ height: '21vh', maxHeight: '21vh' }}
                src={imgSrc}
                alt={`img-carousel-${step}`}
            />
        );
    });

    return (
        <AppContainer>
            <Header headerContent={header} />
            <BodyContainer flexStart noRowMargin>
                <Carousel
                    carouselContents={carouselContents}
                    instructionContents={instructionList}
                    step={step}
                    setStep={(newStep: number) => setStep(newStep)}
                    carouselContainerClassName={
                        'carouselContainerTechnicalSetup'
                    }
                />
            </BodyContainer>
            {step === carouselContents.length - 1 ? (
                <CustomButton
                    id={button.id}
                    buttonType={ButtonType.Primary}
                    onClick={handleStartPairingAction}
                >
                    {button.label}
                </CustomButton>
            ) : (
                <></>
            )}
        </AppContainer>
    );
}
