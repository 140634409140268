import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerServiceContactService } from '../../../Services/Configuration/CustomerServiceContactService';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import {
    ButtonResource,
    HeaderResource,
    ImageResource,
    TextResource,
} from '../../../Utils/PageUtils';
import { PairingContext } from '../PairingContext';

export type PairingTroubleShootingResource = {
    header: HeaderResource;
    title: TextResource;
    instruction: TextResource;
    instructionImage: ImageResource;
    button: ButtonResource;
    help: TextResource;
};

export default function usePairingTroubleShootingResource(): PairingTroubleShootingResource {
    const { t } = useTranslation();
    const {
        state: { context },
    } = useContext(PairingContext);

    const header = {
        text: t(
            `vibeAir:VibeAir_Device:Pairing_Feature:Troubleshooting:Header`
        ),
        type: HeaderActionType.Menu,
        id: 'header-pairing-troubleshooting',
    };
    const title = {
        id: 'txt-title',
        text: t(
            `vibeAir:VibeAir_Device:Pairing_Feature:Troubleshooting:TitleText`
        ),
    };
    const instruction = {
        id: 'txt-instruction',
        text:
            context.activeSide === 'left'
                ? t(
                      `vibeAir:VibeAir_Device:Pairing_Feature:Troubleshooting:Text1`
                  )
                : t(
                      `vibeAir:VibeAir_Device:Pairing_Feature:Troubleshooting:Text2`
                  ),
    };
    const instructionImage = {
        id: 'img-instruction',
        src: `/resources/pairing/${context.activeSide}-troubleshoot.svg`,
    };
    const button = {
        id: `btn-next`,
        label: t('common:Common_Feature:TryAgain'),
    };
    const help = {
        id: 'link-customer-support',
        text: `${t(
            'vibeAir:VibeAir_Device:Pairing_Feature:Troubleshooting:Text3'
        )}\n${CustomerServiceContactService.getGlobalWebSupportAddress()}`,
    };

    return {
        header,
        title,
        instruction,
        instructionImage,
        button,
        help,
    };
}
