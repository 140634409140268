import { useEffect, useRef, useState } from 'react';
import AuthService from '../../../Services/Authentication/AuthService';
import { EnvironmentInfoService } from '../../../Services/EnvironmentInfoService';
import useLoading from '../../../SharedComponents/Spinner/PageLoadSpinner';
import { VoidReturn } from '../../../Utils/PageUtils';

export type SplashScreenHandler = {
    showLanguageSelection: boolean;
    showLegalInfo: boolean;
    gotoLoginPage: () => VoidReturn;
    closeLanguageSelection: () => VoidReturn;
    openLanguageSelection: () => VoidReturn;
    closeCookiePolicy: () => VoidReturn;
    openCookiePolicy: () => VoidReturn;
    spinner: JSX.Element;
    loading: boolean;
};

function useSplashScreenHandler(): SplashScreenHandler {
    const [showLanguageSelection, setShowLanguageSelection] = useState(false);
    const [showLegalInfo, setShowLegalInfo] = useState(false);

    const [spinner, setLoading, loading] = useLoading();
    const isComponentMounted = useRef(true);

    useEffect(() => {
        const initialization = async () => {
            await EnvironmentInfoService.backEndVersion();
            if (isComponentMounted.current) setLoading(false);
        };
        (async () => {
            if (isComponentMounted.current) setLoading(true);
            await initialization();
        })();
        return () => {
            isComponentMounted.current = false;
        };
    }, [setLoading]);

    const gotoLoginPage = async () => {
        setLoading(true);
        const instance = await AuthService.getInstance();
        await instance.login();
    };

    const closeLanguageSelection = () => {
        setShowLanguageSelection(false);
    };

    const openLanguageSelection = () => {
        setShowLanguageSelection(true);
    };

    const closeCookiePolicy = () => {
        setShowLegalInfo(false);
    };

    const openCookiePolicy = () => {
        setShowLegalInfo(true);
    };

    return {
        showLanguageSelection,
        showLegalInfo,
        gotoLoginPage,
        closeLanguageSelection,
        openLanguageSelection,
        closeCookiePolicy,
        openCookiePolicy,
        spinner,
        loading,
    };
}

export default useSplashScreenHandler;
