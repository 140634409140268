import React from 'react';
import FlexContainer from '../../../../../SharedComponents/Container/FlexContainer';
import CustomImg from '../../../../../SharedComponents/CustomImage/CustomImg';
import CustomTypography, {
    TypographyType,
} from '../../../../../SharedComponents/Typography/CustomTypography';
import useFineTuningSolutionPageHandler from '../../FineTuningSolutionPageHandlerHook';
import useFineTuningCustomerServicePageResource from './FineTuningCustomerServicePageResource';
import '../../FineTuningSolutionPage.scss';

export default function FineTuningCustomerServicePage(): React.ReactElement {
    const { makeCallText, image, customerServiceGlobalLink } =
        useFineTuningCustomerServicePageResource();
    const { isContentEnabled, handleCustomerService } =
        useFineTuningSolutionPageHandler();

    return (
        <FlexContainer
            className={
                isContentEnabled() ? 'solutionCard' : 'solutionCard disabled'
            }
        >
            <CustomTypography
                id={makeCallText.id}
                text={makeCallText.text}
                type={TypographyType.notoSansSubHeading}
            />

            <CustomImg
                id={image.id}
                src={image.src}
                alt={image.alt}
                className={'imgSettings'}
            />
            <CustomTypography
                id={customerServiceGlobalLink.id}
                type={TypographyType.notoSansBody}
                text={customerServiceGlobalLink.text}
                className={'finetuning-customer-service-text'}
                onClick={async () => await handleCustomerService()}
            />
        </FlexContainer>
    );
}
