import React, { useState } from 'react';
import useRedoHLAATechnicalSetupHandler from './RedoHLAATechnicalSetupHandler';
import useTechnicalSetupResource from '../../../../Pages/Pairing/TechnicalSetup/TechnicalSetupResourceHook';
import '../../../../Pages/Pairing/TechnicalSetup/TechnicalSetupStyle.scss';
import CustomImg from '../../../CustomImage/CustomImg';
import AppContainer from '../../../Container/AppContainer';
import Header from '../../../Header/HeaderRefactor';
import BodyContainer from '../../../Container/BodyContainer';
import Carousel from '../../../Carousel/Carousel';
import CustomButton from '../../../Button/CustomButton';
import { ButtonType } from '../../../Button/ButtonType';

export default function RedoHLAATechnicalSetup(): JSX.Element {
    const [step, setStep] = useState(0);

    const { onContinue } = useRedoHLAATechnicalSetupHandler();

    const { header, imgSrcList, instructionList, button } =
        useTechnicalSetupResource();

    const carouselContents = imgSrcList.map((imgSrc, index) => {
        return (
            <CustomImg
                key={`carousel-img-${index}`}
                style={{ height: '21vh', maxHeight: '21vh' }}
                src={imgSrc}
                alt={`img-carousel-${step}`}
            />
        );
    });

    return (
        <AppContainer>
            <Header headerContent={header} />
            <BodyContainer flexStart noRowMargin>
                <Carousel
                    carouselContents={carouselContents}
                    instructionContents={instructionList}
                    step={step}
                    setStep={(newStep: number) => setStep(newStep)}
                    carouselContainerClassName={
                        'carouselContainerTechnicalSetup'
                    }
                />
            </BodyContainer>
            {step === carouselContents.length - 1 ? (
                <CustomButton
                    id={button.id}
                    buttonType={ButtonType.Primary}
                    onClick={onContinue}
                >
                    {button.label}
                </CustomButton>
            ) : (
                <></>
            )}
        </AppContainer>
    );
}
