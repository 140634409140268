import { useContext } from 'react';
import PatientManagementService from '../../../../Services/PatientManagementService';
import { WarningDialogHandler } from '../../../../SharedComponents/Dialog/Warning/WarningDialog';
import { VoidReturn } from '../../../../Utils/PageUtils';
import { InitialSettingContext } from '../InitialSettingContext';
import useInitialSettingApplyHandler from '../SideApplication/InitialSettingApplyHandlerHook';

export type LeftRightDifferenceHandler = {
    onExit: () => VoidReturn;
    onContinue: () => VoidReturn;
    getLoading: () => boolean | undefined;
};

export default function useLeftRightDifferenceHandler(): WarningDialogHandler {
    const { exitInitialSetting } = useContext(InitialSettingContext);

    const { onClickApply } = useInitialSettingApplyHandler();

    async function onExit() {
        await PatientManagementService.resetFactory();
        exitInitialSetting('mismatch');
    }

    async function onContinue() {
        onClickApply();
    }

    return {
        secondaryAction: onExit,
        primaryAction: onContinue,
    };
}
