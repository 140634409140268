import React from 'react';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import { ButtonType } from '../../../SharedComponents/Button/ButtonType';
import CustomButton from '../../../SharedComponents/Button/CustomButton';
import CustomCheckBox from '../../../SharedComponents/Checkbox/CustomCheckBox';
import AppContainer from '../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../SharedComponents/Container/BodyContainer';
import Header from '../../../SharedComponents/Header/HeaderRefactor';
import BulletList from '../../../SharedComponents/List/BulletList';
import CustomTypography, {
    TypographyType,
} from '../../../SharedComponents/Typography/CustomTypography';
import useContraindicationHandler from './ContraindicationHandlerHook';
import './ContraindicationPageStyle.scss';
import useContraindicationResource from './ContraindicationResourceHook';

const ContraindicationPage = () => {
    const { agreeOnTerms, handleCheckboxChange, onContinue } =
        useContraindicationHandler();

    const {
        header,
        instruction,
        terms,
        termAgreementInfo,
        termAgreementCheckBox,
        continueButton,
    } = useContraindicationResource();

    return (
        <>
            <AppContainer>
                <Header headerContent={header} />
                <BodyContainer>
                    <CustomTypography
                        textAlign="left"
                        type={TypographyType.notoSansBody}
                        text={instruction.text}
                        id={instruction.id}
                    />

                    <BulletList
                        bulletItems={terms.text.split('|')}
                        className={'contraindication-page-term'}
                    />

                    <CustomTypography
                        textAlign="left"
                        type={TypographyType.notoSansBody}
                        text={termAgreementInfo.text}
                        id={termAgreementInfo.id}
                    />

                    <CustomCheckBox
                        onChange={handleCheckboxChange}
                        displayText={termAgreementCheckBox.label}
                        id={termAgreementCheckBox.id}
                        style={{ paddingLeft: 'unset' }}
                        checked={agreeOnTerms}
                    />
                </BodyContainer>
                <CustomButton
                    buttonType={ButtonType.Primary}
                    id={continueButton.id}
                    onClick={onContinue}
                    disabled={!agreeOnTerms}
                >
                    {continueButton.label}
                </CustomButton>
            </AppContainer>
        </>
    );
};

export default withTracking(ContraindicationPage);
