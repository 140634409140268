import { useContext } from 'react';
import { useTranslation } from 'react-i18next/';
import { getUserDeviceModel } from '../../../Services/ResourceService';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import { switchBasedOnDevice } from '../../../Utils/PageUtils';
import { BeepConfirmationResource } from '../../MainComponents/BeepConfirmation/BeepConfirmation';
import { HLAAContext } from '../HLAAContext';

export const useHLAAConfirmationResource = (): BeepConfirmationResource => {
    const { info } = useContext(HLAAContext);
    const { t } = useTranslation();

    const side =
        info.current.activeSide.toLowerCase() === 'left' ? 'Left' : 'Right';
    return {
        header: {
            id: 'hlaa-header',
            text: t('common:Common_Feature:HearingProfileCheck'),
            prefix: `2.`,
            type: HeaderActionType.Menu,
        },
        title: {
            id: 'hlaa-title',
            text: t(`pairing:Pairing_Feature:${side}Title`),
        },
        instruction: {
            id: 'text-instruction',
            text: t(`common:Common_Feature:ConfirmationBeepMessage`),
        },
        instructionImage: {
            id: 'img-instruction',
            src: switchBasedOnDevice(getUserDeviceModel(), {
                VibeAir: `/resources/shared/beep-confirmation_${info.current.activeSide.toLowerCase()}_vibeAir.svg`,
                VibeGo: `/resources/shared/beep-confirmation_${info.current.activeSide.toLowerCase()}_vibeGo.svg`,
                VibeComplete: `/resources/shared/beep-confirmation_${info.current.activeSide.toLowerCase()}_vibeComplete.svg`,
            }),
        },
        buttons: {
            primary: {
                id: 'btn-confirm-yes',
                label: t(`common:Common_Feature:Yes`),
            },
            secondary: {
                id: 'btn-confirm-no',
                label: t(`common:Common_Feature:No`),
            },
        },
    };
};
