import React from 'react';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import { IDialogContentProps } from '../DialogUtils';
import WarningDialog from '../Warning/WarningDialog';
import useRedoPairingDialogHandler from './RedoPairingDialogHandlerHook';
import useRedoPairingDialogResource from './RedoPairingDialogResourceHook';

function RedoPairingDialog(props: IDialogContentProps) {
    const { onClose } = props;

    const useHandler = () => {
        return useRedoPairingDialogHandler({
            onClose,
        });
    };

    return (
        <WarningDialog
            onClose={onClose}
            useHandler={useHandler}
            useResource={useRedoPairingDialogResource}
        />
    );
}

export default withTracking(RedoPairingDialog);
