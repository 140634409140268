import { useTranslation } from 'react-i18next';
import { WarningDialogResource } from '../Warning/WarningDialog';
import { HeaderActionType } from '../../Header/HeaderActionType';
import globalDialogResource from './GlobalDialogResource.json';

function useGlobalDialogResource(): WarningDialogResource {
    const { t } = useTranslation();

    const {
        headerContent,
        imgContent,
        warningTitleContent,
        warningMessageContent,
        warningInfoContent,
    } = globalDialogResource;

    const header = {
        id: headerContent.id,
        text: t(headerContent.translationKey),
        type: HeaderActionType.Menu,
    };

    const image = imgContent;

    const warningTitle = {
        id: warningTitleContent.id,
        text: t(warningTitleContent.translationKey),
    };

    const warningMessage = {
        id: warningMessageContent.id,
        text: t(warningMessageContent.translationKey),
    };

    const warningInfo = {
        id: warningInfoContent.id,
        text: '',
    };

    const buttonLabels = {
        primaryLabel: {
            id: 'btn-confirm-yes',
            label: t('pairing:Pairing_Feature:Warning_Error:NextButton'),
        },
    };

    return {
        header,
        image,
        warningTitle,
        warningMessage,
        warningInfo,
        buttonLabels,
    };
}

export default useGlobalDialogResource;
