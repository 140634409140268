import { List, ListItem, ListItemProps } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import React from 'react';
import CustomTypography, {
    TypographyType,
} from '../Typography/CustomTypography';
import './ListStyle.scss';

export interface IBulletListProps extends Omit<ListItemProps, 'key' | 'dense'> {
    bulletItems: string[];
    listId?: string;
}

function BulletList({
    listId,
    bulletItems,
    button,
    ...others
}: IBulletListProps): JSX.Element {
    return (
        <List id={listId}>
            {bulletItems.map((textItem: string, index: number) => {
                return (
                    <ListItem
                        dense={false}
                        key={`bullet-list-item-${index}`}
                        {...others}
                    >
                        <FiberManualRecordIcon className={'bulletPoint'} />
                        <CustomTypography
                            textAlign="left"
                            type={TypographyType.notoSansBody}
                            text={textItem.trim()}
                        />
                    </ListItem>
                );
            })}
        </List>
    );
}

export default BulletList;
