import { useTranslation } from 'react-i18next';

export type FineTuningAfterResetPageResource = {
    headerText: string;
    tickMarkImgSrc: string;
    afterResetText: string;
    buttonText: string;
};

const useFineTuningAfterResetPageResource =
    (): FineTuningAfterResetPageResource => {
        const { t } = useTranslation();

        const headerText = t('fineTuning:FineTuning_Feature:Reset:Header');

        const tickMarkImgSrc = '/resources/shared/successful_tick-mark.svg';

        const afterResetText = t('fineTuning:FineTuning_Feature:Reset:Text2');

        const buttonText = t('common:Common_Feature:Done');

        return {
            headerText,
            tickMarkImgSrc,
            afterResetText,
            buttonText,
        };
    };

export default useFineTuningAfterResetPageResource;
