import IDeviceState from '../Models/Device/IDeviceState';
import IHlaaState from '../Models/HLAA/IHlaaState';
import IEasyFitReduxState from '../Models/IEasyFitReduxState';
import ILeaveAppState from '../Models/LeaveApp/ILeaveAppState';
import INotificationState from '../Models/Notification/INotificationState';
import { NotificationBarType } from '../Models/Notification/NotificationBarType';
import { NotificationCloseHandlerType } from '../Models/Notification/NotificationCloseHandlerType';
import { DeviceType } from '../Models/Setup/DeviceType';
import ISetupState from '../Models/Setup/ISetupState';
import { LanguageType } from '../Models/Setup/LanguageType';
import { MarketType } from '../Models/Setup/MarketType';
import { SupportedPairingType } from '../Models/Setup/SupportedPairingType';

const initialSetupState: ISetupState = {
    marketType: MarketType.Singapore,
    languageType: LanguageType.English,
    deviceType: DeviceType.VibeAir,
    supportedPairingType: SupportedPairingType.Binaural,
    userExistsType: true,
};

const initialNotificationState: INotificationState = {
    notificationBarState: {
        content: '',
        type: NotificationBarType.Success,
        isDisplayed: false,
    },
    alertMessage: {
        title: undefined,
        message: undefined,
        detailMessage: undefined,
        userSelections: [
            { content: 'close', action: NotificationCloseHandlerType.Init },
        ],
        isDisplayed: false,
    },
    maintenanceMessage: {
        isDisplayed: false,
        maintenancePeriod: undefined,
    },
};

const initialDeviceState: IDeviceState = {
    masterGainState: [
        // gain values are inverted - mininum is 15, max is 0
        {
            side: 'left',
            masterGainId: 0,
            masterGainUiMinId: -8,
            masterGainUiMaxId: 7,
        },
        {
            side: 'right',
            masterGainId: 0,
            masterGainUiMinId: -8,
            masterGainUiMaxId: 7,
        },
    ],
};

const initialHlaaState: IHlaaState = {
    hlaa0ToneFirstRunState: {
        left: true,
        right: true,
    },
};

const initialLeaveAppState: ILeaveAppState = {
    leaveAppUrl: '',
};

export const initialState: IEasyFitReduxState = {
    setupState: initialSetupState,
    notificationState: initialNotificationState,
    deviceState: initialDeviceState,
    hlaaState: initialHlaaState,
    leaveAppState: initialLeaveAppState,
};
