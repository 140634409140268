import React from 'react';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import TroubleShooting from '../../MainComponents/TroubleShooting/TroubleShooting';
import useHearingAssessmentTroubleShootingHandler from './HearingAssessmentTroubleShootingHandlerHook';
import useHearingAssessmentTroubleShootingResource from './HearingAssessmentTroubleShootingResourceHook';

function HearingAssessmentTroubleShooting() {
    return (
        <TroubleShooting
            useHandler={useHearingAssessmentTroubleShootingHandler}
            useResource={useHearingAssessmentTroubleShootingResource}
        />
    );
}

export default withTracking(HearingAssessmentTroubleShooting);
