import { deviceModel } from '../Models/SupportedDeviceModel';
import { MainViewWorkflow } from '../Pages/MainComponents/MainView/MainViewModel';
import { CustomImgType } from '../SharedComponents/CustomImage/CustomImg';
import { HeaderActionType } from '../SharedComponents/Header/HeaderActionType';
import { MenuItem } from '../SharedComponents/List/MenuList';
import { SubMenuItem } from '../SharedComponents/List/SubMenuList';

export interface IPageProps {
    useResource?: Function;
    useHandler?: Function;
    useRender?: Function;
}

export type HeaderResource = {
    id?: string;
    text?: string;
    type?: HeaderActionType;
    prefix?: string;
    iconImgSrc?: string;
};

export type linkCallBacksResource = {
    [key: string]: () => VoidReturn;
};

export type SubMenuListResource = {
    header: HeaderResource;
    menuItems: SubMenuItem[];
};

export type MenuListResource = {
    header: HeaderResource;
    menuItems: MenuItem[];
};

export type ImageResource = {
    id?: string;
    src: string;
    alt?: string;
    size?: CustomImgType;
};

export type TextResource = {
    id?: string;
    text: string;
};

export type ButtonResource = {
    label: string;
    id?: string;
};

export type VoidReturn = void | Promise<void>;

//Function to switch for Vibe Air and Vibe Go
//Only use if page has small changes. If there are too many differences,
//it is advice to write a new page/new resource handler
export function switchResource(
    workflow: MainViewWorkflow
): 'webApp' | 'vibeApp' {
    return workflow === MainViewWorkflow.Web ? 'webApp' : 'vibeApp';
}

/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export function switchWebAppVibeApp(
    workflow: MainViewWorkflow,
    webApp: any,
    vibeApp: any
): any {
    return workflow === MainViewWorkflow.Web ? webApp : vibeApp;
}

export function switchBasedOnDevice(
    device: deviceModel,
    contentToSwitch: { [key: string]: any }
): any {
    return contentToSwitch[device];
}
/* eslint-enable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
