import { WarningDialogHandler } from '../../Warning/WarningDialog';
import { useContext } from 'react';
import {
    AnalyticConsentDialogContext,
    AnalyticConsentDialogState,
} from '../AnalyticConsentDialogContext';

function useAnalyticConsentAlertDialogHandler(): WarningDialogHandler {
    const { setAnalyticConsentDialogState } = useContext(
        AnalyticConsentDialogContext
    );

    return {
        primaryAction: async () => {
            setAnalyticConsentDialogState(
                AnalyticConsentDialogState.ForceUpversioAnalyticConsent
            );
        },
    };
}

export default useAnalyticConsentAlertDialogHandler;
