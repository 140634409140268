import { useTranslation } from 'react-i18next';
import { deviceModel } from '../../../Models/SupportedDeviceModel';
import { getUserDeviceModel } from '../../../Services/ResourceService';
import {
    CarouselFooterContent,
    InstructionType,
} from '../../../SharedComponents/Carousel/CarouselFooter';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import { HeaderResource, switchBasedOnDevice } from '../../../Utils/PageUtils';

type TechnicalSetupResource = {
    header: HeaderResource;
    imgSrcList: string[];
    instructionList: CarouselFooterContent[];
    button: {
        id: string;
        label: string;
    };
};

function useTechnicalSetupResource(): TechnicalSetupResource {
    const { t } = useTranslation();

    const imgSrcList: string[] = [];
    const instructionList: CarouselFooterContent[] = [];
    const lggKey = switchBasedOnDevice(getUserDeviceModel(), {
        VibeAir: 'pairing:Pairing_Feature:InstructionForPairingSetup:Text',
        VibeGo: 'vibeGo:VibeGo_Device:Pairing_Feature:TechnicalSetup:Text',
        VibeComplete:
            'vibeComplete:VibeComplete_Device:Pairing_Feature:TechnicalSetup:Text',
    });

    //vibe air header prefix config

    const header = {
        id: 'header-technical-setup',
        text: t(`pairing:Pairing_Feature:InstructionForPairingSetup:Header`),
        type: HeaderActionType.Menu,
    };

    for (let index = 1; index <= 3; index++) {
        imgSrcList.push(
            `/resources/pairing/technicalSetup/${
                getUserDeviceModel() === deviceModel.VibeAir
                    ? 'nonBluetooth'
                    : 'bluetooth'
            }/technical-setup-${index}.svg`
        );
        instructionList.push({
            instructionContent: t(`${lggKey}${index}`),
            instructionType: InstructionType.BulletList,
        });
    }

    const button = {
        id: 'btn-start',
        label: t('common:Common_Feature:Continue'),
    };

    return {
        header,
        imgSrcList,
        instructionList,
        button,
    };
}

export default useTechnicalSetupResource;
