import { useTranslation } from 'react-i18next';
import { HeaderActionType } from '../../../../SharedComponents/Header/HeaderActionType';
import {
    ButtonResource,
    HeaderResource,
    ImageResource,
    TextResource,
} from '../../../../Utils/PageUtils';

export type AppLandingPageResource = {
    header: HeaderResource;
    illustrationImage: ImageResource;
    subtitle: TextResource;
    buttons: {
        continue: ButtonResource;
        return: ButtonResource;
    };
};

export default function useAppLandingPageResource(): AppLandingPageResource {
    const { t } = useTranslation();
    const header = {
        id: 'header-welcome',
        type: HeaderActionType.Menu,
        text: t('vibeApp:VibeApp_Workflow:FineTuning_Feature:Welcome:Header'),
    };

    const illustrationImage = {
        id: 'img-easyfitillustration',
        src: '/resources/shared/easyfit-illustration.svg',
        alt: t('welcome:header'),
    };

    const subtitle = {
        id: 'txt-subtitle',
        text: t('vibeApp:VibeApp_Workflow:FineTuning_Feature:Welcome:Text'),
    };

    const continueButton = {
        id: 'btn-continue',
        label: t('common:Common_Feature:FineTuning'),
    };

    const returnButton = {
        id: 'btn-return',
        label: t(
            'vibeApp:VibeApp_Workflow:FineTuning_Feature:Welcome:BackButton'
        ),
    };

    const buttons = {
        continue: continueButton,
        return: returnButton,
    };

    return {
        header,
        illustrationImage,
        subtitle,
        buttons,
    };
}
