import React from 'react';
import BodyContainer from '../../../SharedComponents/Container/BodyContainer';
import CustomImg from '../../../SharedComponents/CustomImage/CustomImg';
import Header from '../../../SharedComponents/Header/HeaderRefactor';
import CustomTypography, {
    TypographyType,
} from '../../../SharedComponents/Typography/CustomTypography';
import {
    HeaderResource,
    ImageResource,
    TextResource,
} from '../../../Utils/PageUtils';
import './WelcomeStyle.scss';

export type WelcomeViewResource = {
    header: HeaderResource;
    illustrationImage: ImageResource;
    title?: TextResource;
    subtitle: TextResource;
};

export function WelcomeView({
    header,
    illustrationImage,
    title,
    subtitle,
}: WelcomeViewResource): React.ReactElement {
    return (
        <>
            <Header headerContent={header} />
            <BodyContainer>
                <CustomImg
                    imgType="medium"
                    id={illustrationImage.id}
                    src={illustrationImage.src}
                    alt={illustrationImage.alt}
                />
                {title && (
                    <CustomTypography
                        textAlign="center"
                        type={TypographyType.comfortaaHeading}
                        id={title.id}
                        text={title.text}
                        className={'welcome-title'}
                    />
                )}
                <CustomTypography
                    textAlign="center"
                    type={TypographyType.notoSansBody}
                    id={subtitle.id}
                    text={subtitle.text}
                />
            </BodyContainer>
        </>
    );
}
