import React from 'react';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import Header from '../../../SharedComponents/Header/HeaderRefactor';
import AppContainer from '../../../SharedComponents/Container/AppContainer';
import useSmartRemotePageHandler from './SmartRemotePageHandlerHook';
import useSmartRemotePageResource from './SmartRemotePageResourceHook';
import CustomTypography, {
    TypographyType,
} from '../../../SharedComponents/Typography/CustomTypography';
import './SmartRemotePageStyle.scss';
import CustomSlider from '../../../SharedComponents/Slider/CustomSlider';

function SmartRemotePage() {
    const {
        loading,
        error,
        sliderState,
        onChangeCommittedSlider,
        onChangeSlider,
        handleSliderStart,
    } = useSmartRemotePageHandler();

    const { header, slider, infoText, thumbComponent } =
        useSmartRemotePageResource();

    return (
        <AppContainer>
            <Header headerContent={header} disabled={loading} />
            <CustomSlider
                id={slider.id}
                handleSliderChange={onChangeSlider}
                handleSliderChangeCommitted={onChangeCommittedSlider}
                sliderState={sliderState}
                onTouchStart={handleSliderStart}
                thumbImgSrc={thumbComponent.src}
            />

            <CustomTypography
                id={slider.label.id}
                type={TypographyType.notoSansBody}
                text={slider.label.text}
                className={'smartRemoteLabelText'}
            />
            {error !== '' && (
                <CustomTypography
                    id={infoText.id}
                    type={TypographyType.notoSansBody}
                    className={'smartRemoteInfoArea'}
                    text={error}
                />
            )}
        </AppContainer>
    );
}

export default withTracking(SmartRemotePage);
