import axios from 'axios';
import { MaintenanceInfo } from '../../Models/MaintenanceInfo';
import { ServiceConfigFactory } from '../ServiceConfigFactory';

export default class MaintenanceService {
    public static async GetMaintenancePageResponse(): Promise<MaintenanceInfo> {
        const res = await axios.get<MaintenanceInfo>(
            process.env.REACT_APP_EASYFIT_API_URL + `/health`,
            ServiceConfigFactory.CreateAxiosConfigWithoutAuth()
        );

        if (
            res.status < 300 &&
            res.headers['content-type'] &&
            res.headers['content-type'] === 'application/json'
        ) {
            return new MaintenanceInfo(
                res.data.IsAppInMaintenanceMode,
                res.data.MaintenancePeriodEndTime
            );
        }
        return new MaintenanceInfo(false, 0);
    }
}
