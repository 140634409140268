import { useTranslation } from 'react-i18next';
import { SupportedCountries } from '../../../Models/SupportedCountries';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import {
    ListItemType,
    SubMenuItem,
} from '../../../SharedComponents/List/SubMenuList';
import {
    ButtonResource,
    HeaderResource,
    SubMenuListResource,
    TextResource,
    VoidReturn,
} from '../../../Utils/PageUtils';

type CountrySelectionResource = {
    header: HeaderResource;
    instructions: TextResource;
    continueButton: ButtonResource;
    countrySelectionButton: ButtonResource;
    countrySelectionDialog: SubMenuListResource;
};

function useCountrySelectionResource(
    selectedCountry: string,
    onSelectCountry: (country: string) => VoidReturn
): CountrySelectionResource {
    const { t } = useTranslation();

    const header = {
        type: HeaderActionType.Menu,
        iconImgSrc: '/resources/shared/vibe-logo.svg',
        id: 'header-countrySelection',
    };

    const instructions = {
        id: 'txt-country-selection',
        text: t('countrySelection:CountrySelection_Feature:Text'),
    };

    const continueButton = {
        id: 'btn-continue',
        label: t('common:Common_Feature:Next'),
    };
    const mapCountryToLabel = {
        [SupportedCountries.JP]:
            'countrySelection:CountrySelection_Feature:CountrySelectionDialog:Items:JP',
        [SupportedCountries.SG]:
            'countrySelection:CountrySelection_Feature:CountrySelectionDialog:Items:SG',
        [SupportedCountries.KR]:
            'countrySelection:CountrySelection_Feature:CountrySelectionDialog:Items:KR',
        [SupportedCountries.CH]:
            'countrySelection:CountrySelection_Feature:CountrySelectionDialog:Items:CH',
        [SupportedCountries.AU]:
            'countrySelection:CountrySelection_Feature:CountrySelectionDialog:Items:AU',
        default: `common:Common_Feature:Select`,
    };

    const countrySelectionButton = {
        id: 'btn-country-selection',
        label: t(
            mapCountryToLabel[
                selectedCountry
                    ? (selectedCountry as SupportedCountries)
                    : 'default'
            ]
        ),
    };

    const countrySelectionDialog: SubMenuListResource = {
        header: {
            text: t(
                'countrySelection:CountrySelection_Feature:CountrySelectionDialog:Header'
            ),
            type: HeaderActionType.Close,
            id: 'header-countrySelection-dialog',
        },
        menuItems: Object.keys(SupportedCountries).map((country) => {
            return {
                id: `${country}`,
                displayText: t(
                    `countrySelection:CountrySelection_Feature:CountrySelectionDialog:Items:${country}`
                ),
                onClickHandler: (item: ListItemType) => {
                    onSelectCountry(item.id);
                },
            } as SubMenuItem;
        }),
    };

    return {
        header,
        instructions,
        continueButton,
        countrySelectionButton,
        countrySelectionDialog,
    };
}

export default useCountrySelectionResource;
