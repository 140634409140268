import { useContext } from 'react';
import { BestCluster } from '../../../../Models/HearingProfile/BestCluster';
import TelemetryService from '../../../../Services/Monitoring/TelemetryService';
import { BeepConfirmationHandle } from '../../../MainComponents/BeepConfirmation/BeepConfirmation';
import { InitialSettingContext, Side } from '../InitialSettingContext';
import { InitialSettingApplicationState } from '../InitialSettingModels';
import useInitialSettingSideHandler from '../Side/InitialSettingSideHandlerHook';

export default function useInitialSettingConfirmationHandler(): BeepConfirmationHandle {
    const { info, setInitialSettingInfo } = useContext(InitialSettingContext);
    const { initializeClusterSetting, programInitialSetting } =
        useInitialSettingSideHandler();

    const getOtherSide = (side: string): string => {
        const otherSideState = info.sideStates.find((item) => {
            return item.side !== side;
        });
        if (otherSideState !== undefined) {
            return otherSideState.side;
        }
        return '';
    };

    const handleConfirmYesAction = async () => {
        const currentSide = info.activeSide;
        const otherSideState = info.sideStates.find((item) => {
            return item.side !== currentSide;
        });

        TelemetryService.serviceInstance.TrackWithIdentity(
            'EasyFit-CompletedWorkflow',
            {
                Workflow:
                    currentSide.toLowerCase() === 'left'
                        ? 'InitialSettingsLeft'
                        : 'InitialSettingsRight',
            }
        );

        const otherSide = getOtherSide(currentSide) as Side;

        if (otherSideState?.isDone !== true) {
            const currentStates = info.sideStates.map(
                (x: {
                    side: Side;
                    isDone: boolean;
                    bestCluster: BestCluster;
                }) => ({
                    side: x.side,
                    isDone: x.side === currentSide ? true : x.isDone,
                    bestCluster: x.bestCluster,
                })
            );
            setInitialSettingInfo({
                ...info,
                initialSettingStep: InitialSettingApplicationState.Application,
                activeSide: otherSide,
                completedSide: currentSide,
                sideStates: currentStates,
                loading: false,
            });
        } else {
            setInitialSettingInfo({
                ...info,
                initialSettingStep: InitialSettingApplicationState.Application,
                completedSide: currentSide,
                isOneSideDone: true,
                loading: false,
            });
        }
    };

    const handleConfirmNoAction = async () => {
        setInitialSettingInfo({
            ...info,
            initialSettingStep: InitialSettingApplicationState.Writing,
            loading: true,
        });
        if (!info.initialized) {
            await initializeClusterSetting();
        } else {
            await programInitialSetting();
        }
    };

    return {
        primaryAction: handleConfirmYesAction,
        secondaryAction: handleConfirmNoAction,
    };
}
