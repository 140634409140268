import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import {
    ButtonResource,
    HeaderResource,
    ImageResource,
    TextResource,
} from '../../../Utils/PageUtils';
import { PairingContext } from '../PairingContext';

export type PairingPrepareResource = {
    header: HeaderResource;
    instructionImage: ImageResource;
    instruction: TextResource;
    prepareCompleteButton: ButtonResource;
};

export default function usePairingPrepareResource(): PairingPrepareResource {
    const { t } = useTranslation();
    const {
        state: { context },
    } = useContext(PairingContext);

    const header = {
        id: 'header-pairing-prepare',
        text: t(
            `vibeAir:VibeAir_Device:Pairing_Feature:Prepare:${
                context.activeSide === 'left' ? 'Left' : 'Right'
            }:Header`
        ),
        prefix: `1.`,
        type: HeaderActionType.Menu,
    };

    const instructionImage = {
        id: 'img-instruction',
        src: `/resources/pairing/repair-${context.activeSide}.svg`,
        alt: 'open-battery-door',
    };
    const instruction = {
        id: 'txt-instruction',
        text: t(
            `vibeAir:VibeAir_Device:Pairing_Feature:Prepare:${
                context.activeSide === 'left' ? 'Text1' : 'Text2'
            }`
        ),
    };
    const prepareCompleteButton = {
        label: t(`common:Common_Feature:Continue`),
        id: 'btn-next',
    };

    return {
        header,
        instructionImage,
        instruction,
        prepareCompleteButton,
    };
}
