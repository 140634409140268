import React from 'react';
import FlexContainer from '../../../../../SharedComponents/Container/FlexContainer';
import CustomizedCircularProgressPercent from '../../../../../SharedComponents/Spinner/CustomizedCircularProgressPercent';
import CustomTypography, {
    TypographyType,
} from '../../../../../SharedComponents/Typography/CustomTypography';
import { useFineTuningSolutionContext } from '../../FineTuningSolutionContext';
import useFineTuningWaitingPageResource from './FineTuningWaitingPageResource';
import './FineTuningWaitingPageStyle.scss';

export default function FineTuningWaitingPage(): React.ReactElement {
    const { progress, loadingText } = useFineTuningWaitingPageResource();
    const { percentage } = useFineTuningSolutionContext();
    return (
        <FlexContainer flexGrow>
            <CustomizedCircularProgressPercent
                id={progress.id}
                loadingPercentage={Math.round(percentage)}
                loadingText={progress.text}
            />
            <CustomTypography
                id={loadingText.id}
                noMargin
                text={loadingText.text}
                type={TypographyType.notoSansHeading}
                className="finetuning-waiting-loading-text"
            />
        </FlexContainer>
    );
}
