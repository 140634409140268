import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SubMenuListResource } from '../../../../../Utils/PageUtils';
import { HeaderActionType } from '../../../../Header/HeaderActionType';
import { MenuContext } from '../../MenuContext';
import { MenuDialogType } from '../../MenuDialogType';
import { createSubMenuItem } from '../../MenuUtils';
import useLegalInfoMenuHandler from './LegalInfoMenuHandlerHook';

export default function useLegalInformationMenuResource(): SubMenuListResource {
    const { t } = useTranslation();
    const { pushMenu } = useContext(MenuContext);
    const { openLink } = useLegalInfoMenuHandler();
    const legalInformationMenuLists = [
        createSubMenuItem(
            'item-cookiePolicy',
            t('common:Common_Feature:CookiePolicy'),
            openLink
        ),
        createSubMenuItem(
            'item-legalinfo',
            t('menu:Menu_Feature:SubMenu:LegalInformation:Text1'),
            () => pushMenu(MenuDialogType.LegalInfo)
        ),
        createSubMenuItem(
            'item-termAndConditions',
            t('common:Common_Feature:TermsAndConditions'),
            openLink
        ),
        createSubMenuItem(
            'item-privacyPolicy',
            t('common:Common_Feature:PrivacyPolicy'),
            openLink
        ),
        createSubMenuItem(
            'item-appInfo',
            t('menu:Menu_Feature:SubMenu:LegalInformation:Text2'),
            () => pushMenu(MenuDialogType.AppInfo)
        ),
    ];
    return {
        header: {
            id: 'header-legalInfo-menu',
            text: t('menu:Menu_Feature:Main:Text4'),
            type: HeaderActionType.Close,
        },
        menuItems: legalInformationMenuLists,
    } as SubMenuListResource;
}
