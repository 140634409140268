import { useTranslation } from 'react-i18next';
import {
    ButtonResource,
    HeaderResource,
    ImageResource,
    TextResource,
} from '../../../Utils/PageUtils';

export type AccountDeletedResource = {
    header: HeaderResource;
    infoImage: ImageResource;
    info: TextResource;
    button: ButtonResource;
};

export default function useAccountDeletedResource(): AccountDeletedResource {
    const { t } = useTranslation();

    const header = {
        id: 'header-account-deleted',
        text: t('common:Common_Feature:DeleteAccount'),
    };

    const infoImage = {
        id: 'img-bin',
        src: `/resources/consent/deleted.svg`,
        alt: t(`consent:bin}`),
    };

    const info = {
        id: 'txt-accountDeletionNotification',
        text: t('consent:Consent_Feature:Text7'),
    };

    const button = {
        id: 'btn-logout',
        label: t('common:Common_Feature:Exit'),
    };

    return {
        header,
        infoImage,
        info,
        button,
    };
}
