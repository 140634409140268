import { useTranslation } from 'react-i18next';
import { EnvironmentInfoService } from '../../../Services/EnvironmentInfoService';
import {
    HeaderResource,
    ImageResource,
    TextResource,
} from '../../../Utils/PageUtils';
import { HeaderActionType } from '../../Header/HeaderActionType';
import { LegalInfoType } from './LegalDialogModel';

export type LegalResource = {
    header: HeaderResource;
    legalContent: TextResource;
    ceMark: ImageResource;
};

function useLegalResource(type: LegalInfoType): LegalResource {
    const { t } = useTranslation();

    let headerText = '';

    switch (type) {
        case LegalInfoType.CookiePolicy:
            headerText = 'common:Common_Feature:CookiePolicy';
            break;
        case LegalInfoType.LegalInfo:
            headerText = 'legal:Legal_Feature:LegalInfo:Header';
            break;
        case LegalInfoType.TermAndConditions:
            headerText = 'common:Common_Feature:TermsAndConditions';
            break;
        case LegalInfoType.PrivacyPolicy:
            headerText = 'common:Common_Feature:PrivacyPolicy';
            break;
        case LegalInfoType.AppInfo:
            headerText = 'legal:Legal_Feature:AppInfo:Header';
            break;
    }

    const header = {
        id: 'header-legal-dialog',
        text: t(`${headerText}`),
        type: HeaderActionType.Return,
    };

    const legalContent = {
        id: 'txt-legal',
        text: t(`legal:Legal_Feature:${type}:Text`, {
            Version: EnvironmentInfoService.frontEndVersion(),
            UDI_PI: EnvironmentInfoService.frontEndVersion()
                .substr(1)
                .replace(/\./g, 'A'),
        }),
    };

    const ceMark = {
        id: 'img-ce-mark',
        src: '/resources/shared/CE-logo.svg',
        alt: 'CE-mark',
    };

    return {
        header,
        legalContent,
        ceMark,
    };
}

export default useLegalResource;
