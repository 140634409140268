import { useContext, useState } from 'react';
import PatientManagementService from '../../../Services/PatientManagementService';
import { VoidReturn } from '../../../Utils/PageUtils';
import { MainViewContext } from '../../MainComponents/MainView/MainViewContext';
import { TransitionAction } from '../../MainComponents/MainView/MainViewModel';

export type SevereHearingLossHandler = {
    onGoBack: () => VoidReturn;
    getLoading: () => boolean | undefined;
};

export default function useSevereHearingLossHandler(): SevereHearingLossHandler {
    const [loading, setLoading] = useState(false);
    const viewContext = useContext(MainViewContext);

    function getLoading(): boolean | undefined {
        return loading;
    }

    async function onGoBack() {
        setLoading(true);
        PatientManagementService.resetFactory();
        viewContext.doTransition(TransitionAction.InitialSettingsSevereLoss);
    }

    return {
        onGoBack,
        getLoading,
    };
}
