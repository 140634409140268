import { VoidReturn } from '../../../Utils/PageUtils';
import { HearingAssessmentState, HLAAContext } from '../HLAAContext';
import { useContext } from 'react';
import AudioService from '../../../Services/AudioService';
import { ProgressBarCalculateCurrentProgress } from '../../../Utils/ProgressBarService';
import { HLAAState } from '../../../Models/HLAA/HLAAState';

export type HearingAssessmentStartHandler = {
    startHLAA: () => VoidReturn;
    getTopProgress: () => number;
    error: string;
};

export default function useHearingAssessmentStartHandler(): HearingAssessmentStartHandler {
    const { info, setHearingAssessmentState } = useContext(HLAAContext);
    const error = '';

    const getTopProgress = (): number => {
        // HLAA start page should start progress from 0%
        info.current.totalPageCount = 0;
        return ProgressBarCalculateCurrentProgress(
            info.current.totalPageCount,
            info.current.totalPagesForProgress
        );
    };

    const startHLAA = async () => {
        // HLAA start page should start progress from left side
        info.current.totalPageCount = 0;
        info.current.activeSide = 'left';
        info.current.left = {
            hlaaDone: false,
        };
        info.current.right = {
            hlaaDone: false,
        };
        info.current.hlaaState = new HLAAState('', '', -1, -1);
        info.current.startKeepAlive = false;
        info.current.repeatDeviceSetup = false;
        info.current.hlaaTestId = '';

        await AudioService.serviceInstance.initAudioContext();

        info.current.freqStartTime = new Date().getTime();
        info.current.startKeepAlive = false;
        setHearingAssessmentState(HearingAssessmentState.SetUp);
    };

    return {
        startHLAA,
        getTopProgress,
        error,
    };
}
