import { useContext } from 'react';
import { MainViewContext } from '../../../../../Pages/MainComponents/MainView/MainViewContext';
import { TransitionAction } from '../../../../../Pages/MainComponents/MainView/MainViewModel';
import TelemetryService from '../../../../../Services/Monitoring/TelemetryService';
import { VoidReturn } from '../../../../../Utils/PageUtils';
import { MenuContext } from '../../MenuContext';
import { MenuDialogType } from '../../MenuDialogType';
import { itemHandler } from '../../MenuUtils';

export type MainMenuHandler = {
    fineTuning: itemHandler;
    hearingAids: itemHandler;
    instructions: itemHandler;
    legalInformation: itemHandler;
    settings: itemHandler;
};

export default function useMainMenuHandler(
    onClose?: () => VoidReturn
): MainMenuHandler {
    const { pushMenu: renderNextMenu } = useContext(MenuContext);
    const viewContext = useContext(MainViewContext); // todo: move somewhere else?
    return {
        fineTuning: () => {
            if (onClose) onClose();
            viewContext.doTransition(TransitionAction.GoFineTuning);
        },
        hearingAids: () => renderNextMenu(MenuDialogType.HearingAids),
        instructions: async () => {
            TelemetryService.serviceInstance.TrackWithIdentity(
                'EasyFit-MenuUsage',
                {
                    MenuType: MenuDialogType.Instructions.toString(),
                }
            );
            renderNextMenu(MenuDialogType.Instructions);
        },
        legalInformation: () => renderNextMenu(MenuDialogType.LegalInformation),
        settings: () => renderNextMenu(MenuDialogType.Settings),
    };
}
