import { DeviceRepairingState } from '../../../Models/DeviceRepairingState';
import { Patient } from '../../../Models/Patient/Patient';
import { SupportedCountries } from '../../../Models/SupportedCountries';
import CountryConsentConfigService from '../../../Services/Configuration/CountryConsentConfigService';
import LocalStorageService from '../../../Services/LocalStorageService';
import LoggingService from '../../../Services/LoggingService';
import CompletedWorkflowService from '../../../Utils/CompletedWorkflow';
import { getActiveConsentVersion } from '../../../Utils/ConsentVersionUtils';
import { setCouplingIfRequired } from '../../../Utils/CouplingUtils';
import {
    DialogState,
    ITransitionHandler,
    PageState,
    TransitionAction,
    ViewState,
} from './MainViewModel';
import {
    isValidAirDevicePairingAddresses,
    handleActionError,
    validatePreviousState,
    checkAndGetNotCompletedWorkflowNextPage,
} from './TransitionHandlerUtils';

function useWebTransitionHandler(): ITransitionHandler {
    const getDialogOnPatientState = (
        patient?: Patient,
        action?: TransitionAction
    ): DialogState => {
        let dialog = DialogState.None;
        if (patient !== undefined) {
            if (patient.deleteOperationDataInProcess) {
                dialog = DialogState.AccountDeleted;
            } else if (
                patient.devicePairingRequired !== DeviceRepairingState.NoAction
            ) {
                dialog = DialogState.PairingConnectionLost;
            } else if (checkDeviceInfo(patient)) {
                dialog = DialogState.DeviceInfoCheck;
            } else if (
                patient.privacyNotesVersion !== getActiveConsentVersion()
            ) {
                dialog = DialogState.UpversionAnalyticConsent;
            }
        } else {
            if (action === TransitionAction.DeleteAccount) {
                dialog = DialogState.AccountDeleted;
            }
        }

        return dialog;
    };

    const checkDeviceInfo = (patient: Patient) => {
        const leftDevice = patient.devices[0].modelId;
        const rightDevice = patient.devices[1].modelId;
        return leftDevice === rightDevice && rightDevice !== '6144007';
    };

    const doTransition = (
        currentView: ViewState,
        action: TransitionAction,
        patient: Patient | undefined
    ): ViewState => {
        const currentPage = currentView.page;
        let nextPage = PageState.None;
        let nextDialog = currentView.dialog;
        // TODO: error handling when patient is undefined
        nextDialog = getDialogOnPatientState(patient, action);
        switch (action) {
            case TransitionAction.CountrySelectionContinue:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.CountrySelection
                );
                nextPage = PageState.NewWelcome;
                break;
            case TransitionAction.WelcomeContinue:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.NewWelcome,
                    PageState.RestartWelcome
                );
                switch (currentPage) {
                    case PageState.NewWelcome: {
                        const country =
                            LocalStorageService.serviceInstance.getSelectedCountry();
                        const enumValue =
                            SupportedCountries[
                                country as keyof typeof SupportedCountries
                            ];
                        const countryConsentConfig =
                            CountryConsentConfigService.getCountryConfig(
                                enumValue
                            );
                        const isOperationDataConsentRequired =
                            countryConsentConfig.operationalDataConsent;
                        const isOperationDataConsented =
                            LocalStorageService.serviceInstance.getOperationConsentState();
                        const analyticConsented =
                            LocalStorageService.serviceInstance.getDataAnalyticConsentState();

                        if (isOperationDataConsentRequired) {
                            if (isOperationDataConsented) {
                                if (!analyticConsented)
                                    nextPage = PageState.ConsentAnalytic;
                                else {
                                    nextPage = PageState.Contraindication;
                                }
                            } else {
                                nextPage = PageState.Consent;
                            }
                        } else {
                            if (
                                !countryConsentConfig.analyticalDataConsent ||
                                analyticConsented
                            ) {
                                nextPage = PageState.Contraindication;
                            } else {
                                nextPage = PageState.ConsentAnalytic;
                            }
                        }
                        break;
                    }
                    case PageState.RestartWelcome:
                        nextPage = PageState.Contraindication;
                        break;
                    default:
                        // Do nothing. Error logging handled by validatePreviousState
                        break;
                }
                break;
            case TransitionAction.JourneyResume: {
                validatePreviousState(currentPage, action, PageState.Journey);
                const resumeState =
                    LocalStorageService.serviceInstance.getCompletedWorkflow();
                nextPage =
                    CompletedWorkflowService.getNextMandatoryWorkflow(
                        resumeState
                    );
                break;
            }
            case TransitionAction.HiAssembleContinue:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.HiAssemble
                );
                nextPage = PageState.Journey;
                break;
            case TransitionAction.ConsentContinue:
                validatePreviousState(currentPage, action, PageState.Consent);
                nextPage = PageState.ConsentAnalytic;
                break;
            case TransitionAction.AnalyticsConsentContinue:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.ConsentAnalytic
                );
                nextPage = PageState.Contraindication;
                break;
            case TransitionAction.AnalyticsConsentDialogContinue: {
                const resumeState =
                    LocalStorageService.serviceInstance.getCompletedWorkflow();
                nextPage = checkAndGetNotCompletedWorkflowNextPage(
                    resumeState,
                    PageState.Landing
                );
                nextDialog = DialogState.None;
                break;
            }
            case TransitionAction.ContraindicationContinue:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.Contraindication
                );
                nextPage = PageState.HiAssemble;
                break;
            case TransitionAction.PairingContinue:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.Pairing,
                    PageState.RePairing
                );
                setCouplingIfRequired();
                switch (currentPage) {
                    case PageState.Pairing:
                        nextPage = PageState.Assessment;
                        break;
                    case PageState.RePairing: {
                        const workflow =
                            LocalStorageService.serviceInstance.getCompletedWorkflow();
                        if (
                            CompletedWorkflowService.isCompletedMandatoryWorkflow(
                                workflow
                            )
                        ) {
                            nextPage = PageState.Landing;
                        } else {
                            nextPage = PageState.Journey;
                        }
                        break;
                    }
                    default:
                        // Do nothing. Error logging handled by validatePreviousState
                        break;
                }
                break;
            case TransitionAction.AssessmentContinue:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.Assessment
                );
                nextPage = PageState.InitialSettings;
                break;
            case TransitionAction.AssessmentZeroTone:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.Assessment
                );
                nextPage = PageState.Pairing;
                break;
            case TransitionAction.InitialSettingsContinue:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.InitialSettings
                );
                nextPage = PageState.SpeechComfort;
                break;
            case TransitionAction.InitialSettingsSevereLoss:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.InitialSettings
                );
                nextPage = PageState.RestartWelcome;
                break;
            case TransitionAction.InitialSettingsMismatchedLoss:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.InitialSettings
                );
                nextPage = PageState.RestartWelcome;
                break;
            case TransitionAction.SpeechComfortContinue:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.SpeechComfort
                );
                nextPage = PageState.CompleteRecommendation;
                break;
            case TransitionAction.RedoLoudnessContinue:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.RedoLoudness
                );
                nextPage = PageState.Landing;
                break;
            case TransitionAction.CompleteRecommendationContinue:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.CompleteRecommendation
                );
                nextPage = PageState.Landing;
                // Note: For FDA study, nextPage should be SmartRemote and not Landing
                // nextPage = PageState.SmartRemote;
                break;
            case TransitionAction.FineTuningComplete:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.FineTuning
                );
                nextPage = PageState.Landing;
                break;
            case TransitionAction.GoFineTuning:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.CompleteRecommendation,
                    PageState.Landing
                );
                nextPage = PageState.FineTuning;
                break;
            case TransitionAction.GoRedoLoudness:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.CompleteRecommendation,
                    PageState.Landing,
                    PageState.RedoLoudness
                );
                nextPage = PageState.RedoLoudness;
                break;
            case TransitionAction.DoRepeatPairing:
                // validatePreviousState(currentPage, action, PageState.Landing);
                // This ensures pairing connection loss dialog is closed & that pairing connect loss dialog is not shown on repeat-pairing menu item
                nextDialog = DialogState.None;
                nextPage = PageState.RePairing;
                break;
            case TransitionAction.DoRedoEasyFit:
                validatePreviousState(currentPage, action, PageState.Landing);
                nextPage = PageState.Contraindication;
                break;
            case TransitionAction.DoRedoHLAA: {
                const devicePairingState =
                    LocalStorageService.serviceInstance.getDevicePairingRequired();
                nextDialog = DialogState.None;
                if (
                    devicePairingState ===
                    DeviceRepairingState.NoAction.toString()
                ) {
                    nextPage = PageState.Assessment;
                } else {
                    nextPage = PageState.RePairing;
                }
                break;
            }
            case TransitionAction.DeleteAccount:
                nextDialog = DialogState.AccountDeleted;
                nextPage = PageState.None;
                break;
            case TransitionAction.ResetUserDeviceProfileAndWorkflow:
                //validatePreviousState(currentPage, action, PageState.None);
                nextDialog = DialogState.None;
                nextPage = PageState.Contraindication;
                break;
            default:
                nextPage = handleActionError(currentPage, action);
                break;
        }

        LoggingService.info({
            componentName: 'WebTransitionHandlerHook.doTransition',
            args: [
                `Transitioning from ${PageState[currentPage]} (action: ${TransitionAction[action]}) to ${PageState[nextPage]} with dialog ${DialogState[nextDialog]}`,
            ],
        });
        return { dialog: nextDialog, page: nextPage } as ViewState;
    };

    const goToInitialState = async (
        patient: Patient | undefined,
        debugRoute?: PageState,
        initial?: PageState
    ): Promise<ViewState> => {
        let nextPage = PageState.None;
        let nextDialog = DialogState.None;
        // TODO: read patient from local store.

        if (debugRoute) {
            nextPage = debugRoute;
            if (patient) nextDialog = getDialogOnPatientState(patient);
        } else {
            if (!patient) {
                // No patient record found
                nextPage =
                    LocalStorageService.serviceInstance.getSelectedCountry()
                        ? PageState.NewWelcome
                        : PageState.CountrySelection;
            } else {
                if (!isValidAirDevicePairingAddresses(patient)) {
                    return {
                        dialog: DialogState.PairingConnectionLost,
                        page: PageState.RePairing,
                    } as ViewState;
                }

                setCouplingIfRequired();
                nextDialog = getDialogOnPatientState(patient);

                const completedWorkflows = patient.easyFitWorkflows;

                if (initial) {
                    nextPage = checkAndGetNotCompletedWorkflowNextPage(
                        completedWorkflows,
                        initial
                    );
                } else {
                    nextPage = checkAndGetNotCompletedWorkflowNextPage(
                        completedWorkflows,
                        PageState.Landing
                    );
                }
            }
        }

        LoggingService.info({
            componentName: 'WebTransitionHandlerHook.goToInitialState',
            args: [
                `Initial state: ${PageState[nextPage]} with dialog ${DialogState[nextDialog]}`,
            ],
        });
        //nextDialog
        return {
            dialog: nextDialog,
            page: nextPage,
        } as ViewState;
    };

    return {
        doTransition,
        goToInitialState,
    };
}

export default useWebTransitionHandler;
