import React from 'react';
import { ButtonType } from '../../Button/ButtonType';
import CustomButton from '../../Button/CustomButton';
import AppContainer from '../../Container/AppContainer';
import BodyContainer from '../../Container/BodyContainer';
import CustomImg from '../../CustomImage/CustomImg';
import Header from '../../Header/HeaderRefactor';
import CustomTypography, {
    TypographyType,
} from '../../Typography/CustomTypography';
import CustomDialog from '../CustomDialog';
import { IDialogProps } from '../DialogUtils';
import useAccountDeletedHandler from './AccountDeletedHandlerHook';
import useAccountDeletedResource from './AccountDeletedResourceHook';
import './AccountDeletedStyle.scss';

export default function AccountDeleted({
    displayed,
    onClose,
}: IDialogProps): React.ReactElement {
    const { logout } = useAccountDeletedHandler();

    const { header, infoImage, info, button } = useAccountDeletedResource();

    return (
        <CustomDialog open={displayed} onClose={onClose}>
            <AppContainer>
                <Header headerContent={header} />
                <BodyContainer flexStart>
                    <CustomImg
                        id={infoImage.id}
                        imgType={'small'}
                        src={infoImage.src}
                        alt={infoImage.alt}
                    />
                    <CustomTypography
                        id={info.id}
                        type={TypographyType.notoSansBody}
                        text={info.text}
                        className={'accountDeleteInfoText'}
                        noMargin
                    />
                </BodyContainer>
                <CustomButton
                    id={button.id}
                    buttonType={ButtonType.PrimaryLoading}
                    onClick={logout}
                >
                    {button.label}
                </CustomButton>
            </AppContainer>
        </CustomDialog>
    );
}
