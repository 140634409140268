import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { getUserDeviceModel } from '../../../Services/ResourceService';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import {
    HeaderResource,
    ImageResource,
    switchBasedOnDevice,
    TextResource,
} from '../../../Utils/PageUtils';
import { HLAAContext } from '../HLAAContext';

type HearingAssessmentOneSideResource = {
    header: HeaderResource;
    headImage: ImageResource;
    sideTypography: TextResource;
    errorTypography: TextResource;
    confirmTypography: TextResource;
    circularProgress: TextResource;
    leftComponent: {
        id: string;
    };

    rightComponent: {
        id: string;
    };
};

function useHearingAssessmentOneSideResource(
    side: string
): HearingAssessmentOneSideResource {
    const { t } = useTranslation();
    const {
        info: {
            current: { activeSide },
        },
    } = useContext(HLAAContext);

    const sideText = side.toLowerCase() === 'left' ? 'Left' : 'Right';

    const header = {
        id: 'header-hearinginstrument-one-side-page',
        text: t('common:Common_Feature:HearingProfileCheck'),
        type: HeaderActionType.Menu,
        prefix: '2.',
    };

    const sideTypography = {
        id: 'txt-content1',
        text: t(`pairing:Pairing_Feature:${sideText}Title`),
    };

    const errorTypography = {
        id: 'txt-error',
        text: '',
    };

    const confirmTypography = {
        id: 'txt-confirm',
        text: t('hlaa:Hlaa_Feature:OneSide:Text1'),
    };

    const circularProgress = {
        id: 'circular-progress',
        text: t('hlaa:Hlaa_Feature:OneSide:Text2'),
    };

    const leftComponent = {
        id: 'hlaa-one-side-left',
    };

    const rightComponent = {
        id: 'hlaa-one-side-right',
    };

    const headImage = {
        id: `img-${activeSide}`,
        src: switchBasedOnDevice(getUserDeviceModel(), {
            VibeAir: `/resources/shared/evaluation_${activeSide.toLowerCase()}_vibeAir.svg`,
            VibeGo: `/resources/shared/evaluation_${activeSide.toLowerCase()}_vibeGo.svg`,
            VibeComplete: `/resources/shared/evaluation_${activeSide.toLowerCase()}_vibeComplete.svg`,
        }),
        alt: `${sideText}`,
    };

    return {
        header,
        headImage,
        sideTypography,
        errorTypography,
        confirmTypography,
        circularProgress,
        leftComponent,
        rightComponent,
    };
}

export default useHearingAssessmentOneSideResource;
