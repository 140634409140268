import React from 'react';
import { GainUIMapping } from '../../../Models/HearingProfile/GainUIMapping';
import IDeviceState from '../../../Redux/Models/Device/IDeviceState';
import { SpeechComfortTestState } from './SpeechComfortTestModels';

export interface ISpeechComfortTestInfo {
    masterGain: GainUIMapping;
    deviceState: IDeviceState;
    speechComfortTestState: SpeechComfortTestState;
    latestLeftValue: number;
    latestRightValue: number;
    loading: boolean;
}

export interface ISpeechComfortTestContext {
    info: ISpeechComfortTestInfo;
    setSpeechComfortTestInfo: (value: ISpeechComfortTestInfo) => void;
    exitSpeechComfort: () => void;
}

export const SpeechComfortTestContext = React.createContext(
    {} as ISpeechComfortTestContext
);
