import React, { useContext } from 'react';
import Header from '../../../../SharedComponents/Header/HeaderRefactor';
import { InitialSettingContext } from '../InitialSettingContext';
import AppContainer from '../../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../../SharedComponents/Container/BodyContainer';
import CustomTypography, {
    TypographyType,
} from '../../../../SharedComponents/Typography/CustomTypography';
import { ButtonType } from '../../../../SharedComponents/Button/ButtonType';
import CustomButton from '../../../../SharedComponents/Button/CustomButton';
import useInitialSettingApplyHandler from './InitialSettingApplyHandlerHook';
import CustomImg from '../../../../SharedComponents/CustomImage/CustomImg';
import './InitialSettingApplicationSide.scss';
import useInitialSettingApplicationSideResource from './InitialSettingApplicationSideResourceHook';

export default function InitialSettingApplicationSide(): JSX.Element {
    const { info } = useContext(InitialSettingContext);

    const { onClickApply } = useInitialSettingApplyHandler();

    const {
        header,
        instructionApplication,
        instructionImageApplication,
        button,
    } = useInitialSettingApplicationSideResource();

    return (
        <AppContainer>
            <Header headerContent={header} disabled={info.loading} />
            <BodyContainer>
                <CustomImg
                    imgType={'iconLarge'}
                    id={instructionImageApplication.id}
                    src={instructionImageApplication.src}
                    className={'tickImageInitialSettingApplicationSide'}
                />
                <CustomTypography
                    type={TypographyType.notoSansBodyBig}
                    id={instructionApplication.id}
                    text={instructionApplication.text}
                    className={
                        'instructionApplicationInitialSettingApplicationSide'
                    }
                />
            </BodyContainer>
            <CustomButton
                buttonType={ButtonType.PrimaryLoading}
                id={button.id}
                onClick={async () => {
                    await onClickApply();
                }}
            >
                {button.label}
            </CustomButton>
        </AppContainer>
    );
}
