import React from 'react';
import FlexContainer from '../../../../../SharedComponents/Container/FlexContainer';
import CustomTypography, {
    TypographyType,
} from '../../../../../SharedComponents/Typography/CustomTypography';

import useChangeSleeveRecommendPageResource from './ChangeSleeveRecommendPageResourceHook';
import useChangeSleeveRecommendPageHandler from './ChangeSleeveRecommendPageHandlerHook';
import '../../FineTuningSolutionPage.scss';
import PairButton from '../../../../../SharedComponents/Button/PairButton';

export default function ChangeSleeveRecommendPage(): React.ReactElement {
    const { instruction, yesButton, noButton } =
        useChangeSleeveRecommendPageResource();
    const { onContinue, onCancel } = useChangeSleeveRecommendPageHandler();

    return (
        <FlexContainer>
            <CustomTypography
                id={instruction.id}
                text={instruction.text}
                type={TypographyType.notoSansBody}
                style={{ margin: '12vh 4vh 2vh 4vh' }}
            />

            <PairButton
                primaryButton={{
                    id: yesButton.id,
                    onClick: onContinue,
                    children: yesButton.label,
                }}
                secondaryButton={{
                    id: noButton.id,
                    onClick: onCancel,
                    children: noButton.label,
                }}
            />
        </FlexContainer>
    );
}
