import { ButtonProps } from '@material-ui/core';
import React from 'react';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import { ButtonType } from '../../../SharedComponents/Button/ButtonType';
import CustomButton from '../../../SharedComponents/Button/CustomButton';
import PairButton from '../../../SharedComponents/Button/PairButton';
import AppContainer from '../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../SharedComponents/Container/BodyContainer';
import CustomDialog from '../../../SharedComponents/Dialog/CustomDialog';
import WarningDialog from '../../../SharedComponents/Dialog/Warning/WarningDialog';
import Header from '../../../SharedComponents/Header/HeaderRefactor';
import { StepContentType } from '../../../SharedComponents/Stepper/StepperModel';
import VerticalStepper from '../../../SharedComponents/Stepper/VerticalStepper';
import useUserJourneyHandler from './UserJourneyPageHandlerHook';
import useUserJourneyResource from './UserJourneyPageResourceHook';

function UserJourneyPage() {
    const {
        useWarningDialogHandler,
        activeStep,
        warningDisplayed,
        showStartOver,
        onResume,
        onClose,
    } = useUserJourneyHandler();

    const { useWarningDialogResource, header, stepContents, buttonLabels } =
        useUserJourneyResource(activeStep);

    const primButtonConfig: ButtonProps = {
        id: buttonLabels.primary.id,
        children: buttonLabels.primary.label,
        onClick: onResume,
    };

    return (
        <AppContainer>
            <CustomDialog fullScreen open={warningDisplayed}>
                <WarningDialog
                    onClose={onClose}
                    useHandler={useWarningDialogHandler}
                    useResource={useWarningDialogResource}
                />
            </CustomDialog>
            <Header headerContent={header} />
            <BodyContainer flexStart>
                <VerticalStepper
                    activeStep={activeStep}
                    stepContentType={StepContentType.IconStepper}
                    stepContents={stepContents}
                />
            </BodyContainer>
            {buttonLabels.secondary ? (
                <PairButton
                    withLoading
                    secondaryButton={{
                        id: buttonLabels.secondary.id,
                        onClick: showStartOver,
                        children: buttonLabels.secondary.label,
                    }}
                    primaryButton={primButtonConfig}
                />
            ) : (
                <CustomButton
                    buttonType={ButtonType.Primary}
                    {...primButtonConfig}
                />
            )}
        </AppContainer>
    );
}

export default withTracking(UserJourneyPage);
