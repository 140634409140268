import {
    Table,
    TableBody,
    TableCell,
    TableCellProps,
    TableRow,
} from '@material-ui/core';
import React from 'react';
import CustomIconButton from '../Button/CustomIconButton';
import FlexContainer from '../Container/FlexContainer';
import CustomTypography, {
    TypographyType,
} from '../Typography/CustomTypography';
import './CustomTable.scss';
import { TableCellType } from './TableCellType';

export interface CustomTableCellProps extends TableCellProps {
    className?: string;
    cellType: TableCellType;
    text?: string;
    imgSrc?: string;
    buttonConfig?: {
        disabled: boolean;
        onClick: () => void;
    };
}

export type TableRowConfig = {
    cellsInRow: CustomTableCellProps[];
};

export interface ICustomTableProps {
    tableBodyConfig: TableRowConfig[];
    className?: string;
    id?: string;
}

function CustomTable(props: ICustomTableProps): JSX.Element {
    return (
        <FlexContainer className={`customTableContainer ${props.className}`}>
            <Table id={props.id ? props.id : ''}>
                <TableBody>
                    {props.tableBodyConfig.map((row, index) => (
                        <TableRow key={`body-row-${index}`}>
                            {row.cellsInRow.map((cell, index) => (
                                <TableCell
                                    key={`body-row-cell-${index}`}
                                    className={`noBottomBorder
                                        ${
                                            cell.className
                                                ? cell.className
                                                : 'customTableCell'
                                        }`}
                                >
                                    {cell.cellType === TableCellType.Text ? (
                                        <CustomTypography
                                            text={cell.text ? cell.text : ''}
                                            type={TypographyType.notoSansBody}
                                            textAlign={'left'}
                                        />
                                    ) : (
                                        <CustomIconButton
                                            imgSrc={
                                                cell.imgSrc ? cell.imgSrc : ''
                                            }
                                            iconSize={'iconSmall'}
                                            className={'customTableImg'}
                                            {...cell.buttonConfig}
                                        />
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </FlexContainer>
    );
}

export default CustomTable;
