import { useTranslation } from 'react-i18next';
import { WarningDialogResource } from '../../../SharedComponents/Dialog/Warning/WarningDialog';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import {
    ButtonResource,
    HeaderResource,
    switchBasedOnDevice,
} from '../../../Utils/PageUtils';
import './UserJourneyStyle.scss';
import userJourneyPageResource from './UserJourneyPageResource.json';
import { getUserDeviceModel } from '../../../Services/ResourceService';
import { VerticalStepperContent } from '../../../SharedComponents/Stepper/StepperModel';
import { deviceModel } from '../../../Models/SupportedDeviceModel';

export type UserJourneyResource = {
    useWarningDialogResource: () => WarningDialogResource;
    header: HeaderResource;
    stepContents: VerticalStepperContent[];
    buttonLabels: {
        primary: ButtonResource;
        secondary?: ButtonResource;
    };
};

function useUserJourneyResource(activeStep: number): UserJourneyResource {
    const { t } = useTranslation();
    const steps = [
        switchBasedOnDevice(getUserDeviceModel(), {
            VibeAir: 'Pairing',
            VibeGo: 'InsertInstrucions',
            VibeComplete: 'InsertInstrucions',
        }),
        'Evaluation',
        'PersonalizedSettings',
        'MyComfort',
    ] as string[];

    const { startOver, pageHeader, buttons } = userJourneyPageResource;

    const useWarningDialogResource = () => {
        const {
            headerContent,
            imgContent,
            warningTitleContent,
            warningMessageContent,
            warningInfoContent,
            warningButtons,
        } = startOver;

        const header = {
            id: headerContent.id,
            text: t(headerContent.translationKey),
            type: HeaderActionType.Close,
        };

        const image = imgContent;

        const warningTitle = {
            id: warningTitleContent.id,
            text: t(warningTitleContent.translationKey),
        };

        const warningMessage = {
            id: warningMessageContent.id,
            text: t(warningMessageContent.translationKey),
        };

        const warningInfo = {
            id: warningInfoContent.id,
            text: t(warningInfoContent.translationKey),
        };

        const buttonLabels = {
            primaryLabel: {
                id: warningButtons.primary.id,
                label: t(warningButtons.primary.label),
            },
            secondaryLabel: {
                id: warningButtons.secondary.id,
                label: t(warningButtons.secondary.label),
            },
        };

        return {
            header,
            image,
            warningTitle,
            warningMessage,
            warningInfo,
            buttonLabels,
        };
    };

    const header = {
        id: pageHeader.id,
        text: t(pageHeader.translationKey),
        type: HeaderActionType.Menu,
    };

    const stepContents = steps.map((step, index) => {
        return {
            index: index,
            stepIcon: `/resources/userJourney/${step}.svg`,
            stepIconCompleted: `/resources/userJourney/${step}_completed.svg`,
            stepLabel: step,
            stepLabelContent:
                step == 'InsertInstrucions' &&
                getUserDeviceModel() == deviceModel.VibeGo
                    ? t(
                          `vibeGo:VibeGo_Device:UserJourney_Feature:${step}:TitleText`
                      )
                    : t(`userJourney:UserJourney_Feature:${step}:TitleText`),
            stepTextContent: t(`userJourney:UserJourney_Feature:${step}:Text`),
            className: 'userJourneyStepText',
        };
    });

    const buttonLabels = {
        primary: {
            id: activeStep > 0 ? buttons.primary.id : buttons.initial.id,
            label: t(
                activeStep > 0 ? buttons.primary.label : buttons.initial.label
            ),
        },
        secondary:
            activeStep > 0
                ? {
                      id: buttons.secondary.id,
                      label: t(buttons.secondary.label),
                  }
                : undefined,
    };

    return {
        useWarningDialogResource,
        header,
        stepContents,
        buttonLabels,
    };
}

export default useUserJourneyResource;
