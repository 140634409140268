import { ImageResource } from '../../../../../Utils/PageUtils';
import { t } from '../../../../../Services/LocalizationService';
import { IFineTuningBeforeApplyPage } from './FineTuningBeforeApplyPage';

type FineTuningBeforeApplyPageResource = {
    solutionText: {
        id: string;
        text: string;
    };

    image: ImageResource;

    button: {
        id: string;
        text: String;
    };
};

export default function useFineTuningBeforeApplyPageResource(
    props: IFineTuningBeforeApplyPage
): FineTuningBeforeApplyPageResource {
    const solutionText = {
        id: 'txt-solution',
        text: `${t('fineTuning:FineTuning_Feature:Solution:Text12')} 0${
            props.solutionNumber
        }`,
    };

    const image = {
        id: 'img-settings',
        src: `/resources/shared/wait-asset.svg`,
        alt: 'settings',
    };

    const button = {
        id: `btn-apply-solution-${props.solutionNumber}`,
        text: `${t('fineTuning:FineTuning_Feature:Solution:Text11')} 0${
            props.solutionNumber
        }`,
    };

    return {
        solutionText,
        image,
        button,
    };
}
