import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import { OneSideSetUpResource } from '../../MainComponents/OneSideSetUp/OneSideSetUp';
import { HLAAContext } from '../HLAAContext';

export default function useHearingAssessmentOneSideSetUpResource(): OneSideSetUpResource {
    const { t } = useTranslation();
    const { info } = useContext(HLAAContext);

    const header = {
        id: 'header-hlaa-oneSide-setup',
        text: t('common:Common_Feature:HearingProfileCheck'),
        prefix: '2.',
        type: HeaderActionType.Menu,
    };

    const image = {
        id: `img-${info.current.activeSide.toLowerCase()}`,
        src: `/resources/shared/wait-asset.svg`,
        alt: `${info.current.activeSide.toLowerCase()}`,
    };

    const progress = {
        id: 'progress-loading',
    };
    const infoText = {
        id: 'txt-info',
        text: t('hlaa:Hlaa_Feature:Setup:Text'),
    };

    return {
        header,
        image,
        progress,
        infoText,
    };
}
