import { useTranslation } from 'react-i18next';
import { CustomerServiceContactService } from '../../../Services/Configuration/CustomerServiceContactService';
import { getUserDeviceModel } from '../../../Services/ResourceService';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import {
    ButtonResource,
    HeaderResource,
    ImageResource,
    switchBasedOnDevice,
    TextResource,
} from '../../../Utils/PageUtils';

export type SevereHearingLossPageResource = {
    header: HeaderResource;
    instructionImage: ImageResource;
    customerServiceImage: ImageResource;
    instructionHeader: TextResource;
    instruction1: TextResource;
    instruction2: TextResource;
    continueButton: ButtonResource;
    supportLink: TextResource;
};

export default function useSevereHearingLossPageResource(): SevereHearingLossPageResource {
    const { t } = useTranslation();

    const header = {
        id: 'servere-hearingloss-header',
        text: t(
            `initialSettings:InitialSettings_Feature:SevereHearingLoss:Header`
        ),
        type: HeaderActionType.Menu,
    };

    const instructionImage = {
        id: 'img-severe-hearing-loss',
        src: switchBasedOnDevice(getUserDeviceModel(), {
            VibeAir: `/resources/hlaa/severe-hearing-loss-vibeAir.svg`,
            VibeGo: `/resources/hlaa/severe-hearing-loss-vibeGo.svg`,
            VibeComplete: `/resources/hlaa/severe-hearing-loss-vibeComplete.svg`,
        }),
        alt: 'initialSettings:severeHearingLoss:header',
    };
    const customerServiceImage = {
        id: 'img-customer-service-phone',
        src: '/resources/shared/customer-care-phone.svg',
        alt: 'img-customer-service-phone',
    };
    const instructionHeader = {
        id: 'severeHearingLoss-customerServiceHeader',
        text: t(
            `initialSettings:InitialSettings_Feature:SevereHearingLoss:Text3`
        ),
    };
    const instruction1 = {
        id: 'severeHearingLoss-instruction1',
        text: t(
            `initialSettings:InitialSettings_Feature:SevereHearingLoss:Text1`
        ),
    };
    const instruction2 = {
        id: 'severeHearingLoss-instruction2',
        text: t(
            `initialSettings:InitialSettings_Feature:SevereHearingLoss:Text2`
        ),
    };
    const continueButton = {
        label: t(`common:Common_Feature:Exit`),
        id: 'btn-exit',
    };

    const supportLink = {
        text: CustomerServiceContactService.getGlobalWebSupportAddress(),
        id: 'link-customer-service',
    };

    return {
        header,
        instructionImage,
        customerServiceImage,
        instructionHeader,
        instruction1,
        instruction2,
        continueButton,
        supportLink,
    };
}
