import { useEffect, useRef } from 'react';

export default function Polling(
    callback: Function,
    delay: number | null
): void {
    const savedCallback = useRef<Function>(() => void 0);

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}
