import React from 'react';

export enum AnalyticConsentDialogState {
    Start,
    ForceUpversioAnalyticConsent,
}

export interface IAnalyticConsentDialogContext {
    analyticConsentDialogState: AnalyticConsentDialogState;
    setAnalyticConsentDialogState: React.Dispatch<AnalyticConsentDialogState>;
}

export const AnalyticConsentDialogContext = React.createContext(
    {} as IAnalyticConsentDialogContext
);
