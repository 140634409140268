import { ButtonProps } from '@material-ui/core';
import React from 'react';
import ActionContainer from '../Container/ActionContainer';
import ButtonContainer from '../Container/ButtonContainer';
import ButtonStickyContainer from '../Container/ButtonStickyContainer';
import { createButton } from './ButtonFactory';
import { ButtonType } from './ButtonType';

export interface IButtonProps extends ButtonProps {
    buttonType: ButtonType;
    sticky?: boolean;
    withDefaultContainer?: boolean;
}

function CustomButton({
    buttonType,
    sticky = true,
    withDefaultContainer = true,
    ...others
}: IButtonProps): React.ReactElement {
    const renderButton = () => {
        return createButton(buttonType, others);
    };
    if (withDefaultContainer)
        if (sticky)
            return (
                <ButtonStickyContainer>{renderButton()}</ButtonStickyContainer>
            );
        else
            return (
                <ActionContainer sticky={false}>
                    <ButtonContainer>{renderButton()}</ButtonContainer>
                </ActionContainer>
            );
    else return renderButton();
}

export default CustomButton;
