import { DeviceState } from '../../../../Models/FineTuning/DeviceState';
import { FineTuningDeviceStates } from '../../../../Models/FineTuning/FineTuningDeviceStates';
import ArcToneService from '../../../../Services/ArcToneService';
import AudioService from '../../../../Services/AudioService';
import DeviceManagementService from '../../../../Services/DeviceManagementService';
import FineTuningService from '../../../../Services/FineTuningService';
import LocalStorageService from '../../../../Services/LocalStorageService';
import LoggingService from '../../../../Services/LoggingService';
import TelemetryService from '../../../../Services/Monitoring/TelemetryService';
import PatientManagementService from '../../../../Services/PatientManagementService';
import { useFineTuningContext } from '../../FineTuningContext';

export type FineTuningDuringResetPageHandler = {
    resetFineTuning: () => Promise<void>;
};

const useFineTuningDuringResetPageHandler = (
    setPercentage: React.Dispatch<React.SetStateAction<number>>
): FineTuningDuringResetPageHandler => {
    const { send, info } = useFineTuningContext();

    const sleep = (milliseconds: number) => {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
    };

    const getMasterGainAudio = async (
        brandId: number,
        pairingAddress: number,
        side: string,
        masterGain: number
    ) => {
        await AudioService.serviceInstance.prepAudio(
            ArcToneService.getMasterGain(brandId, pairingAddress, masterGain),
            `${side.toUpperCase()} side masterGainId ${masterGain}`
        );
    };

    const updateFineTuning = async (
        deviceId: string,
        brandId: number,
        pairingAddress: number,
        side: string,
        masterGain: number,
        band1EqualizerGain: number,
        band2EqualizerGain: number,
        band3EqualizerGain: number,
        band4EqualizerGain: number
    ) => {
        await getMasterGainAudio(brandId, pairingAddress, side, masterGain);
        setPercentage((prev) => prev + 25);
        await AudioService.serviceInstance.prepAudio(
            ArcToneService.getBandEqualizerGain(
                brandId,
                pairingAddress,
                band1EqualizerGain,
                band2EqualizerGain,
                band3EqualizerGain,
                band4EqualizerGain
            ),
            `${side} side bandEqualizerGain ${band1EqualizerGain} ${band2EqualizerGain} ${band3EqualizerGain} ${band4EqualizerGain}`
        );

        setPercentage((prev) => prev + 25);

        await DeviceManagementService.fineTuningApplied(
            2,
            LocalStorageService.serviceInstance.getPatientId(),
            deviceId,
            masterGain,
            band1EqualizerGain,
            band2EqualizerGain,
            band3EqualizerGain,
            band4EqualizerGain
        );

        info.current.deviceStateFinal[deviceId] = {
            easyfitMasterGain: masterGain,
            band1EqualizerGain: band1EqualizerGain,
            band2EqualizerGain: band2EqualizerGain,
            band3EqualizerGain: band3EqualizerGain,
            band4EqualizerGain: band4EqualizerGain,
        };
    };

    const onReset = async () => {
        TelemetryService.serviceInstance.TrackWithIdentity(
            'EasyFit-FeatureUsage',
            { Feature: 'FineTuningRevertInitialSettings' }
        );
        setPercentage(0);
        LoggingService.log({
            componentName: onReset.name,
            args: [
                'Reset master gain and band equalizer to settings after recommendation engine.',
            ],
        });
        const deviceState: { [deviceId: string]: DeviceState } = {};
        const leftDeviceId =
            LocalStorageService.serviceInstance.getDeviceId('left');
        const rightDeviceId =
            LocalStorageService.serviceInstance.getDeviceId('right');

        const leftBrandId =
            LocalStorageService.serviceInstance.getDeviceBrandId('left');
        const leftPairingAddress =
            LocalStorageService.serviceInstance.getPairingAddress('left');
        const rightBrandId =
            LocalStorageService.serviceInstance.getDeviceBrandId('right');
        const rightPairingAddress =
            LocalStorageService.serviceInstance.getPairingAddress('right');

        deviceState[leftDeviceId] = {
            easyfitMasterGain: info.current.defaultMasterGain.left,
            band1EqualizerGain: 0,
            band2EqualizerGain: 0,
            band3EqualizerGain: 0,
            band4EqualizerGain: 0,
        };
        deviceState[rightDeviceId] = {
            easyfitMasterGain: info.current.defaultMasterGain.right,
            band1EqualizerGain: 0,
            band2EqualizerGain: 0,
            band3EqualizerGain: 0,
            band4EqualizerGain: 0,
        };
        await AudioService.serviceInstance.initAudioContext();

        await updateFineTuning(
            leftDeviceId,
            leftBrandId,
            leftPairingAddress,
            'left',
            deviceState[leftDeviceId].easyfitMasterGain,
            deviceState[leftDeviceId].band1EqualizerGain,
            deviceState[leftDeviceId].band2EqualizerGain,
            deviceState[leftDeviceId].band3EqualizerGain,
            deviceState[leftDeviceId].band4EqualizerGain
        );

        await updateFineTuning(
            rightDeviceId,
            rightBrandId,
            rightPairingAddress,
            'right',
            deviceState[rightDeviceId].easyfitMasterGain,
            deviceState[rightDeviceId].band1EqualizerGain,
            deviceState[rightDeviceId].band2EqualizerGain,
            deviceState[rightDeviceId].band3EqualizerGain,
            deviceState[rightDeviceId].band4EqualizerGain
        );
        setPercentage(100);
        await sleep(1000);
        await FineTuningService.fineTuningReset(
            LocalStorageService.serviceInstance.getActiveHearingProfileId(),
            true,
            new FineTuningDeviceStates(deviceState)
        );

        const leftDevice = info.current.deviceStateFinal[leftDeviceId];
        const rightDevice = info.current.deviceStateFinal[rightDeviceId];

        TelemetryService.serviceInstance.TrackWithIdentity(
            `EasyFit-FineTuned-MasterGain`,
            {
                Left: leftDevice.easyfitMasterGain.toString(),
            }
        );

        TelemetryService.serviceInstance.TrackWithIdentity(
            'EasyFit-FineTuned-MasterGain',
            {
                Right: rightDevice.easyfitMasterGain.toString(),
            }
        );

        TelemetryService.serviceInstance.TrackWithIdentity(
            'EasyFit-Left-FineTuned-BandEqualizer',
            {
                Band1: leftDevice.band1EqualizerGain.toString(),
                Band2: leftDevice.band2EqualizerGain.toString(),
                Band3: leftDevice.band3EqualizerGain.toString(),
                Band4: leftDevice.band4EqualizerGain.toString(),
            }
        );

        TelemetryService.serviceInstance.TrackWithIdentity(
            'EasyFit-Right-FineTuned-BandEqualizer',
            {
                Band1: rightDevice.band1EqualizerGain.toString(),
                Band2: rightDevice.band2EqualizerGain.toString(),
                Band3: rightDevice.band3EqualizerGain.toString(),
                Band4: rightDevice.band4EqualizerGain.toString(),
            }
        );
        send('finishReset');
        const workflow = await PatientManagementService.getCompletedWorkflow();
        LocalStorageService.serviceInstance.setCompletedWorkflow(
            Number(workflow)
        );
    };

    return {
        resetFineTuning: onReset,
    };
};

export default useFineTuningDuringResetPageHandler;
