import axios, { AxiosResponse } from 'axios';
import CountryConsentConfigService from './Configuration/CountryConsentConfigService';
import { CustomerServiceContactService } from './Configuration/CustomerServiceContactService';
import LoggingService from './LoggingService';

export const getNonMedicalResource = async (
    componentName: string,
    filePath: string
): Promise<void | AxiosResponse<unknown>> => {
    const resource = await axios
        .get(`${process.env.REACT_APP_NON_MEDICAL_STORAGE_URL}${filePath}`)
        .catch((error) => {
            LoggingService.error({
                componentName: `${componentName}`,
                args: [
                    `Failed to fetch Non medical content file from ${filePath}. Err: ${error}`,
                ],
            });
        });

    return resource;
};

export const loadNonMedicalResources = async (): Promise<void> => {
    await CountryConsentConfigService.loadCountryConfig();
    await CustomerServiceContactService.loadCustomerServiceCountryData();
};
