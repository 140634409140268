import React from 'react';
import FlexContainer from '../Container/FlexContainer';
import BulletList from '../List/BulletList';
import CustomTypography, {
    TypographyType,
} from '../Typography/CustomTypography';

export interface ICarouselFooterProps {
    step: number;
    contents: CarouselFooterContent[];
}

export type CarouselFooterContent = {
    instructionType?: InstructionType;
    instructionContent: string;
};

export enum InstructionType {
    BulletList,
}

function CarouselFooter(props: ICarouselFooterProps): JSX.Element {
    return (
        <FlexContainer>
            {props.contents[props.step].instructionType ===
            InstructionType.BulletList ? (
                <BulletList
                    listId={'carousel-instruction'}
                    bulletItems={props.contents[props.step].instructionContent
                        .split('\n')
                        .filter(Boolean)}
                />
            ) : (
                <CustomTypography
                    id={'carousel-instruction'}
                    type={TypographyType.notoSansBody}
                    text={props.contents[props.step].instructionContent}
                    className={'instructionText'}
                />
            )}
        </FlexContainer>
    );
}

export default CarouselFooter;
