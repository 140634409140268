import { Patient } from '../../../Models/Patient/Patient';
import LocalStorageService from '../../../Services/LocalStorageService';
import LoggingService from '../../../Services/LoggingService';
import { checkPairingStatus } from '../../../Services/ResourceService';
import CompletedWorkflowService from '../../../Utils/CompletedWorkflow';
import { getActiveConsentVersion } from '../../../Utils/ConsentVersionUtils';
import { setCouplingIfRequired } from '../../../Utils/CouplingUtils';
import { goToUrl } from '../../../Utils/NavigationUtils';
import {
    DialogState,
    ITransitionHandler,
    PageState,
    TransitionAction,
    ViewState,
} from './MainViewModel';
import {
    checkAndGetNotCompletedWorkflowNextPage,
    handleActionError,
    supportedPageStateQueryParamsFineTuning,
    validatePreviousState,
} from './TransitionHandlerUtils';

function useAppFineTuningTransitionHandler(): ITransitionHandler {
    const appExit = (status: string) => {
        const magicUrl = `appexit:finetuning/${status}`;
        goToUrl(magicUrl);
    };

    const getDialogOnPatientState = (patient: Patient): DialogState => {
        let dialog = DialogState.None;
        if (patient.deleteOperationDataInProcess) {
            dialog = DialogState.AccountDeleted;
        } else if (patient.privacyNotesVersion !== getActiveConsentVersion()) {
            dialog = DialogState.UpversionAnalyticConsent;
        }

        return dialog;
    };

    const doTransition = (
        currentView: ViewState,
        action: TransitionAction,
        patient: Patient | undefined
    ): ViewState => {
        const currentPage = currentView.page;
        let nextPage = PageState.None;
        let nextDialog = currentView.dialog;
        // TODO: error handling when patient is undefined
        if (patient) {
            nextDialog = getDialogOnPatientState(patient);
            if (patient) nextDialog = getDialogOnPatientState(patient);
        }

        switch (action) {
            case TransitionAction.AnalyticsConsentDialogContinue: {
                const resumeState =
                    LocalStorageService.serviceInstance.getCompletedWorkflow();
                nextPage = checkAndGetNotCompletedWorkflowNextPage(
                    resumeState,
                    PageState.Landing,
                    PageState.FineTuningWorkflowError
                );
                nextDialog = DialogState.None;
                break;
            }
            case TransitionAction.DeleteAccount:
                LocalStorageService.serviceInstance.clear();
                appExit('fail/no-account');
                nextPage = currentPage;
                break;
            case TransitionAction.FineTuningComplete:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.FineTuning
                );
                nextPage = PageState.Landing;
                break;
            case TransitionAction.FineTuningWorkflowError:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.FineTuningWorkflowError
                );
                appExit('fail/generic-error');
                nextPage = currentPage;
                break;
            case TransitionAction.GoFineTuning:
                validatePreviousState(currentPage, action, PageState.Landing);
                nextPage = PageState.FineTuning;
                break;
            case TransitionAction.ExitInAppBrowser:
                validatePreviousState(currentPage, action, PageState.Landing);
                appExit('success/complete');
                nextPage = currentPage;
                break;
            case TransitionAction.RedoLoudnessContinue:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.RedoLoudness
                );
                appExit('success/complete-redo-loudness');
                nextPage = currentPage;
                break;
            case TransitionAction.ChangeSleeveCustomerServiceContinue:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.FineTuning
                );
                appExit('success/complete');
                nextPage = currentPage;
                break;
            case TransitionAction.GoSplashScreen:
                nextDialog = DialogState.None;
                nextPage = PageState.SplashScreen;
                break;
            case TransitionAction.DoLeaveApp:
                nextDialog = DialogState.None;
                nextPage =
                    currentPage === PageState.FineTuning
                        ? PageState.Landing
                        : PageState.RedoLoudness;
                window.location.reload();
                break;
            case TransitionAction.DoLeaveAppDialogOpen:
                nextDialog = DialogState.LeaveApp;
                nextPage = currentPage;
                break;
            case TransitionAction.DoLeaveAppDialogClose:
                nextDialog = DialogState.None;
                nextPage = currentPage;
                break;
            // eslint-disable-next-line no-fallthrough
            case TransitionAction.CountrySelectionContinue:
            case TransitionAction.WelcomeContinue:
            case TransitionAction.JourneyResume:
            case TransitionAction.HiAssembleContinue:
            case TransitionAction.ConsentContinue:
            case TransitionAction.AnalyticsConsentContinue:
            case TransitionAction.ContraindicationContinue:
            case TransitionAction.PairingContinue:
            case TransitionAction.AssessmentContinue:
            case TransitionAction.AssessmentZeroTone:
            case TransitionAction.InitialSettingsContinue:
            case TransitionAction.InitialSettingsSevereLoss:
            case TransitionAction.InitialSettingsMismatchedLoss:
            case TransitionAction.SpeechComfortContinue:
            case TransitionAction.CompleteRecommendationContinue:
            case TransitionAction.GoRedoLoudness:
            case TransitionAction.DoRedoHLAA:
            case TransitionAction.DoRepeatPairing: // fall through
            default:
                nextPage = handleActionError(currentPage, action);
                break;
        }

        LoggingService.info({
            componentName: 'AppFineTuningTransitionHandlerHook.doTransition',
            args: [
                `Transitioning from ${PageState[currentView.page]} (action: ${
                    TransitionAction[action]
                }) to ${PageState[nextPage]} with dialog ${
                    DialogState[nextDialog]
                }`,
            ],
        });
        return { dialog: nextDialog, page: nextPage } as ViewState;
    };

    const goToInitialState = async (
        patient: Patient | undefined,
        debugRoute?: PageState,
        initial?: PageState
    ): Promise<ViewState> => {
        let nextPage = PageState.None;
        let nextDialog = DialogState.None;

        if (debugRoute) {
            nextPage = debugRoute;
        } else {
            if (!patient) {
                // No patient record found
                nextPage = PageState.FineTuningWorkflowError;
            } else {
                setCouplingIfRequired();
                nextDialog = getDialogOnPatientState(patient);

                if (
                    initial &&
                    supportedPageStateQueryParamsFineTuning.includes(initial)
                ) {
                    nextPage = checkAndGetNotCompletedWorkflowNextPage(
                        patient.easyFitWorkflows,
                        initial,
                        PageState.FineTuningWorkflowError
                    );

                    if (!checkPairingStatus()) {
                        nextPage = PageState.FineTuningWorkflowError;
                    }
                } else {
                    if (
                        CompletedWorkflowService.isCompletedMandatoryWorkflow(
                            patient.easyFitWorkflows
                        )
                    ) {
                        nextPage = PageState.Landing;
                    } else {
                        nextPage = PageState.FineTuningWorkflowError;
                    }
                }
            }
        }

        LoggingService.info({
            componentName:
                'AppFineTuningTransitionHandlerHook.goToInitialState',
            args: [
                `Initial state: ${PageState[nextPage]} with dialog ${DialogState[nextDialog]}`,
            ],
        });
        return { dialog: nextDialog, page: nextPage } as ViewState;
    };

    return {
        doTransition,
        goToInitialState,
    };
}

export default useAppFineTuningTransitionHandler;
