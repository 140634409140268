import { useState } from 'react';
import { HeaderActionType } from './HeaderActionType';

export type HeaderHandler = {
    showMenu: boolean;
    handleCloseMenu: () => void;
    handleOnClick: () => void;
};

function useHeaderHandler(
    onClick: (() => void | Promise<void>) | undefined,
    actionType?: HeaderActionType
): HeaderHandler {
    const [showMenu, setShowMenu] = useState(false);

    function handleCloseMenu() {
        setShowMenu(false);
    }

    function handleOnClick() {
        if (onClick) onClick();
        else if (actionType === HeaderActionType.Menu) setShowMenu(true);
    }

    return {
        showMenu,
        handleCloseMenu,
        handleOnClick,
    };
}

export default useHeaderHandler;
