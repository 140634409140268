import { useTranslation } from 'react-i18next';
import { HeaderActionType } from '../../../../SharedComponents/Header/HeaderActionType';
import {
    ButtonResource,
    HeaderResource,
    ImageResource,
    TextResource,
} from '../../../../Utils/PageUtils';

type AudioRoutingPageResource = {
    header: HeaderResource;
    image: ImageResource;
    instructionText: TextResource;
    button: ButtonResource;
};

export default function useAudioRoutingPageResource(): AudioRoutingPageResource {
    const { t } = useTranslation();

    const header = {
        id: 'header-audio-routing',
        text: t(`pairing:Pairing_Feature:InstructionForPairingSetup:Header`),
        type: HeaderActionType.NoAction,
    };

    const image = {
        id: 'img-audio-routing',
        src: `/resources/pairing/technicalSetup/bluetooth/technical-setup-2.svg`,
        alt: '',
    };

    const instructionText = {
        id: 'instruction-audio-routing',
        text: t('vibeGo:VibeGo_Device:Pairing_Feature:TechnicalSetup:Text2'),
    };

    const button = {
        id: 'btn-audio-routing',
        label: t('common:Common_Feature:Continue'),
    };

    return {
        header,
        image,
        instructionText,
        button,
    };
}
