import { createSlider, IVolumeSlider } from './SliderFactory';

export type SliderStateType = {
    initialPosition: number;
    posMin: number;
    posMax: number;
};

export enum SlideType {
    sideLeft,
    sideRight,
}

export const defaultSliderState: SliderStateType = {
    initialPosition: 0,
    posMin: -8,
    posMax: 7,
};

export interface ICustomVolumeSlider extends IVolumeSlider {
    type?: SlideType;
}

function CustomSlider({ type, ...props }: ICustomVolumeSlider): JSX.Element {
    return createSlider(props, type);
}

export default CustomSlider;
