import React, { useEffect, useRef, useState } from 'react';
import CustomIconButton from '../../../SharedComponents/Button/CustomIconButton';
import AppContainer from '../../../SharedComponents/Container/AppContainer';
import ButtonStickyContainerDoubleGradient from '../../../SharedComponents/Container/ButtonStickyContainerDoubleGradient';
import FlexContainer from '../../../SharedComponents/Container/FlexContainer';
import Header from '../../../SharedComponents/Header/HeaderRefactor';
import CustomLink from '../../../SharedComponents/Link/CustomLink';
import TopProgressBar from '../../../SharedComponents/TopProgressBar/TopProgressBar';
import '../HearingAssessmentStyle.scss';
import { HLAASharedView } from '../HLAASharedView';
import useHearingAssessmentConfirmationHandler from './HearingAssessmentConfirmationHandler';
import useHearingAssementConfirmationResource from './HearingAssessmentConfirmationResource';
import './HearingAssessmentConfirmationStyle.scss';
import ButtonContainer from '../../../SharedComponents/Container/ButtonContainer';

export default function HearingAssessmentConfirmation(): React.ReactElement {
    const {
        handleNextState,
        replay,
        getTopProgress,
        buttonDisplay,
        setButtonVisibility,
    } = useHearingAssessmentConfirmationHandler();
    const {
        header,
        sideText,
        evaluationImage,
        iconButton,
        choiceImage,
        confirmInstruction,
        replayInstruction,
    } = useHearingAssementConfirmationResource();
    const [loading, setLoading] = useState(false);

    const choices = [0, 1, 2, 3];

    const onUserChoosing = async (numberOfTonesHeard: number) => {
        await handleNextState(numberOfTonesHeard);
    };
    const isComponentMounted = useRef(true);

    useEffect(() => {
        const doAction = async () => {
            if (isComponentMounted.current) {
                setTimeout(function () {
                    setButtonVisibility(true);
                }, 250);
                setLoading(false);
            }
        };
        (async () => {
            if (isComponentMounted.current) setLoading(true);
            await doAction();
        })();
        return () => {
            isComponentMounted.current = false;
        };
    }, [setButtonVisibility]);

    return (
        <AppContainer>
            <TopProgressBar progress={getTopProgress()} />
            <Header headerContent={header} />
            <HLAASharedView
                img={evaluationImage}
                title={sideText}
                instruction={confirmInstruction}
            />
            <ButtonStickyContainerDoubleGradient
                className={'actionStickyContainerStyle'}
            >
                {buttonDisplay && (
                    <FlexContainer flexRow>
                        {choices.map((choice) => (
                            <CustomIconButton
                                iconSize="iconMediumLarge"
                                className={loading ? 'disabled' : ''}
                                id={`${iconButton.id}${choice}`}
                                imgSrc={`${choiceImage.src}${choice}.svg`}
                                key={choice}
                                onClick={async () => {
                                    setLoading(true);
                                    await onUserChoosing(choice);
                                }}
                                style={{ padding: '0 5px' }}
                                disabled={loading}
                            />
                        ))}
                    </FlexContainer>
                )}
                <ButtonContainer>
                    <CustomLink
                        id={replayInstruction.id}
                        onClick={() => replay()}
                    >
                        {replayInstruction.text}
                    </CustomLink>
                </ButtonContainer>
            </ButtonStickyContainerDoubleGradient>
        </AppContainer>
    );
}
