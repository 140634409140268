import { useTranslation } from 'react-i18next';
import { locales } from '../../../Services/LocalizationService';
import { HeaderResource, VoidReturn } from '../../../Utils/PageUtils';
import { HeaderActionType } from '../../Header/HeaderActionType';
import { ListItemType, SubMenuItem } from '../../List/SubMenuList';

export type LanguageSelectionResource = {
    header: HeaderResource;
    menuItems: SubMenuItem[];
};

function useLanguageSelectionResource(
    onClickItemHandle: (item: ListItemType) => VoidReturn
): LanguageSelectionResource {
    const { t } = useTranslation();

    const header = {
        id: 'header-language-selection',
        text: t('languageSelection:LanguageSelection_Feature:Header'),
        type: HeaderActionType.Close,
    };

    const menuItems: SubMenuItem[] = locales.map((locale) => {
        return {
            id: locale.code,
            displayText: t(
                `languageSelection:LanguageSelection_Feature:Selection:${locale.code}`
            ),
            onClickHandler: onClickItemHandle,
        };
    });

    return {
        header,
        menuItems,
    };
}

export default useLanguageSelectionResource;
