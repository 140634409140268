import React from 'react';
import BeepConfirmation from '../../../../Pages/MainComponents/BeepConfirmation/BeepConfirmation';
import TopProgressBar from '../../../TopProgressBar/TopProgressBar';
import { IDialogContentProps } from '../../DialogUtils';
import useRedoHLAABeepConfirmationHandler from './RedoHLAABeepConfirmationHandler';
import { useRedoHLAAConfirmationResource } from './RedoHLAABeepConfirmationResource';

export default function RedoHLAABeepConfirmation({
    onClose,
}: IDialogContentProps): React.ReactElement {
    const {
        onBeepConfirmationYesAction,
        onBeepConfirmationNoAction,
        getTopProgress,
    } = useRedoHLAABeepConfirmationHandler(onClose);
    const useRender = () => {
        return {
            renderTopProgressBar: (): JSX.Element => {
                return <TopProgressBar progress={getTopProgress()} />;
            },
        };
    };

    return (
        <BeepConfirmation
            useHandler={() => {
                return {
                    primaryAction: onBeepConfirmationYesAction,
                    secondaryAction: onBeepConfirmationNoAction,
                };
            }}
            useResource={useRedoHLAAConfirmationResource}
            useRender={useRender}
        />
    );
}
