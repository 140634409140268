import { switchWebAppVibeApp, VoidReturn } from '../../../Utils/PageUtils';
import { ProgressBarCalculateCurrentProgress } from '../../../Utils/ProgressBarService';
import { HearingAssessmentState, HLAAContext } from '../HLAAContext';
import { useContext } from 'react';
import TelemetryService from '../../../Services/Monitoring/TelemetryService';
import AudioService from '../../../Services/AudioService';
import { MainViewContext } from '../../MainComponents/MainView/MainViewContext';

type HearingAssessmentBeepConfirmationHandler = {
    onBeepConfirmationYesAction: () => VoidReturn;
    onBeepConfirmationNoAction: () => VoidReturn;
    getTopProgress: () => number;
};

export default function useHearingAssessmentBeepConfirmationHandler(): HearingAssessmentBeepConfirmationHandler {
    const { info, setHearingAssessmentState } = useContext(HLAAContext);
    const { workflowType } = useContext(MainViewContext);
    const getTopProgress = (): number => {
        return ProgressBarCalculateCurrentProgress(
            info.current.totalPageCount,
            info.current.totalPagesForProgress
        );
    };

    const onBeepConfirmationYesAction = () => {
        info.current.startKeepAlive = true;
        setHearingAssessmentState(HearingAssessmentState.AssessmentPlay);
    };

    const onBeepConfirmationNoActionWebApp = async () => {
        TelemetryService.serviceInstance.TrackWithIdentity(
            'EasyFit-UserIssue',
            {
                Issue: 'HLAASetupConfirmationBeepNotHeard',
            }
        );
        onStartHLAA();
    };

    const onBeepConfirmationNoActionVibeApp = () => {
        TelemetryService.serviceInstance.TrackWithIdentity(
            'EasyFit-UserIssue',
            {
                Issue: 'HLAASetupConfirmationBeepNotHeard',
            }
        );
        setHearingAssessmentState(HearingAssessmentState.TroubleShooting);
    };

    const onStartHLAA = async () => {
        await AudioService.serviceInstance.initAudioContext();

        info.current.startKeepAlive = false;
        info.current.repeatDeviceSetup = true;
        info.current.freqStartTime = new Date().getTime();

        setHearingAssessmentState(HearingAssessmentState.SetUp);
    };

    const onBeepConfirmationNoAction = switchWebAppVibeApp(
        workflowType,
        onBeepConfirmationNoActionWebApp,
        onBeepConfirmationNoActionVibeApp
    );

    return {
        onBeepConfirmationYesAction,
        onBeepConfirmationNoAction,
        getTopProgress,
    };
}
