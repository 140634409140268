import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BestCluster } from '../../../../Models/HearingProfile/BestCluster';
import { BestClusterAppliedSettings } from '../../../../Models/HearingProfile/BestClusterAppliedSettings';
import IDeviceState from '../../../../Redux/Models/Device/IDeviceState';
import { updateDevice } from '../../../../Redux/Reducers/DeviceSlice';
import { RootState } from '../../../../Redux/Reducers/RootReducer';
import DeviceManagementService from '../../../../Services/DeviceManagementService';
import LocalStorageService from '../../../../Services/LocalStorageService';
import PatientManagementService from '../../../../Services/PatientManagementService';
import { Workflow } from '../../../../Utils/CompletedWorkflow';
import { VoidReturn } from '../../../../Utils/PageUtils';
import { ProgressBarCalculateCurrentProgress } from '../../../../Utils/ProgressBarService';
import { InitialSettingContext } from '../InitialSettingContext';
import { InitialSettingApplicationState } from '../InitialSettingModels';
import useInitialSettingSideHandler from '../Side/InitialSettingSideHandlerHook';

export type ApplyInitialSettingHandle = {
    onClickApply: () => VoidReturn;
    getTopProgress: () => number;
};

export default function useInitialSettingApplyHandler(): ApplyInitialSettingHandle {
    const { info, setInitialSettingInfo, exitInitialSetting } = useContext(
        InitialSettingContext
    );
    const dispatch = useDispatch();
    const deviceState = useSelector(
        (state: RootState) => state.device
    ) as IDeviceState;

    const { initializeClusterSetting, programInitialSetting } =
        useInitialSettingSideHandler();

    const getTopProgress = (): number => {
        return ProgressBarCalculateCurrentProgress(
            info.totalPageCount,
            info.totalPagesForProgress
        );
    };

    const storeMasterGainData = async (
        sideStates: {
            side: string;
            isDone: boolean;
            bestCluster: BestCluster;
        }[]
    ) => {
        const masterGainState = Object.entries(sideStates).map((x) => {
            return {
                side: x[1].side,
                masterGainId: x[1].bestCluster.masterGainId,
                masterGainUiMinId: x[1].bestCluster.masterGainUiMinId,
                masterGainUiMaxId: x[1].bestCluster.masterGainUiMaxId,
            };
        });
        dispatch(
            updateDevice({
                ...deviceState,
                masterGainState: masterGainState,
            })
        );
    };

    const onClickApply = async () => {
        const currentSide = info.activeSide;
        const currentSideState = info.sideStates.find((item) => {
            return item.side === currentSide;
        });

        if (currentSideState?.isDone === true) {
            const initialSettings: {
                [deviceId: string]: BestCluster;
            } = Object.assign(
                {},
                ...info.sideStates.map(
                    (curr: {
                        side: string;
                        isDone: boolean;
                        bestCluster: BestCluster;
                    }) => ({
                        [LocalStorageService.serviceInstance.getDeviceId(
                            curr.side
                        )]: curr.bestCluster,
                    })
                )
            );
            const patientInitialSettings: BestClusterAppliedSettings = {
                patientId: LocalStorageService.serviceInstance.getPatientId(),
                deviceInitialSettings: initialSettings,
            };
            await DeviceManagementService.confirmBestClustersAreApplied(
                patientInitialSettings
            );

            storeMasterGainData(info.sideStates);
            await PatientManagementService.endWorkflow(Workflow.Recommendation);
            exitInitialSetting('continue');
            return;
        }

        setInitialSettingInfo({
            ...info,
            initialSettingStep: InitialSettingApplicationState.Writing,
            loading: true,
        });

        if (!info.initialized) {
            await initializeClusterSetting();
        } else {
            await programInitialSetting();
        }
    };

    return {
        onClickApply: onClickApply,
        getTopProgress: getTopProgress,
    };
}
