import { goToUrl } from '../../../Utils/NavigationUtils';
import { VoidReturn } from '../../../Utils/PageUtils';
import { WarningDialogHandler } from '../Warning/WarningDialog';

export interface IDeviceCompatibilityCheckDialogProps {
    onClose: () => VoidReturn;
}

function useDeviceCompatibilityCheckDialogHandler(
    props: IDeviceCompatibilityCheckDialogProps
): WarningDialogHandler {
    const { onClose } = props;

    return {
        primaryAction: () => {
            onClose();
            goToUrl('appexit:fitting/fail/incompatible');
        },
    };
}

export default useDeviceCompatibilityCheckDialogHandler;
