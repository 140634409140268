import { useTranslation } from 'react-i18next';
import { HeaderActionType } from '../../Header/HeaderActionType';
import maintenanceDialogResource from './MaintenanceDialogResource.json';
import { CustomImgType } from '../../CustomImage/CustomImg';
import {
    HeaderResource,
    ImageResource,
    TextResource,
} from '../../../Utils/PageUtils';

export interface IMaintenanceDialogResource {
    maintenancePeriod: number;
}

export type MaintenanceDialogResource = {
    header: HeaderResource;
    image: ImageResource;
    dialogTitle: TextResource;
    dialogMessage: TextResource;
    dialogHour: TextResource;
    dialogMinute: TextResource;
    dialogSecond: TextResource;
};

function useMaintenanceDialogResource(): MaintenanceDialogResource {
    const { t } = useTranslation();

    const {
        headerContent,
        imgContent,
        dialogTitleContent,
        dialogMessageContent,
        dialogHourContent,
        dialogMinuteContent,
        dialogSecondContent,
    } = maintenanceDialogResource;

    const header = {
        id: headerContent.id,
        type: HeaderActionType.NoAction,
        iconImgSrc: headerContent.iconImgSrc,
    };

    const image = {
        id: imgContent.id,
        src: imgContent.src,
        alt: imgContent.alt,
        size: imgContent.size as CustomImgType,
    };

    const dialogTitle = {
        id: dialogTitleContent.id,
        text: t(
            `maintenance:Maintenance_Feature${dialogTitleContent.translationKey}`
        ),
    };

    const dialogMessage = {
        id: dialogMessageContent.id,
        text: t(
            `maintenance:Maintenance_Feature${dialogMessageContent.translationKey}`
        ),
    };

    const dialogHour = {
        id: dialogHourContent.id,
        text: t(
            `maintenance:Maintenance_Feature${dialogHourContent.translationKey}`
        ),
    };

    const dialogMinute = {
        id: dialogMinuteContent.id,
        text: t(
            `maintenance:Maintenance_Feature${dialogMinuteContent.translationKey}`
        ),
    };

    const dialogSecond = {
        id: dialogSecondContent.id,
        text: t(
            `maintenance:Maintenance_Feature${dialogSecondContent.translationKey}`
        ),
    };

    return {
        header,
        image,
        dialogTitle,
        dialogMessage,
        dialogHour,
        dialogMinute,
        dialogSecond,
    };
}

export default useMaintenanceDialogResource;
