import React, { useContext } from 'react';
import { MainViewContext } from '../../../../Pages/MainComponents/MainView/MainViewContext';
import { withTracking } from '../../../../Services/Monitoring/ApplicationInsights';
import { switchWebAppVibeApp } from '../../../../Utils/PageUtils';
import { IDialogContentProps } from '../../DialogUtils';
import useRedoEasyFitDialogResource from './RedoEasyFitDialogResourceHook';
import WarningDialog from '../../Warning/WarningDialog';
import useRedoEasyFitDialogHandler from './RedoEasyFitDialogHandlerHook';
import useRedoEasyFitDialogVibeGoHandler from './RedoEasyfitDialogVibeGoHandlerHook';

function RedoEasyFitDialog({ onClose }: IDialogContentProps) {
    const { workflowType } = useContext(MainViewContext);
    const useHandler = () =>
        switchWebAppVibeApp(
            workflowType,
            useRedoEasyFitDialogHandler(onClose),
            useRedoEasyFitDialogVibeGoHandler()
        );

    return (
        <WarningDialog
            onClose={onClose}
            useHandler={useHandler}
            useResource={useRedoEasyFitDialogResource}
        />
    );
}

export default withTracking(RedoEasyFitDialog);
