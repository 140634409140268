import { FineTuningSolution } from '../../../../../Models/FineTuning/FineTuningSolution';
import { FineTuningSolutionState } from '../../../../../Models/FineTuning/FineTuningSolutionState';
import { SolutionValueChanged } from '../../../../../Models/FineTuning/SolutionValueChanged';
import FineTuningService from '../../../../../Services/FineTuningService';
import LocalStorageService from '../../../../../Services/LocalStorageService';
import LoggingService from '../../../../../Services/LoggingService';
import { useFineTuningContext } from '../../../FineTuningContext';
import { useFineTuningSolutionContext } from '../../FineTuningSolutionContext';
import useFineTuningSolutionPageHandler from '../../FineTuningSolutionPageHandlerHook';
import { isChangeSleeveSolution } from '../../../../../Utils/ChangeSleeveUtils';

export type FineTuningBeforeApplyPageHandler = {
    handleApply: (
        solution: FineTuningSolution,
        solutionNumber: number
    ) => Promise<void>;
};

const useFineTuningBeforeApplyPageHandler =
    (): FineTuningBeforeApplyPageHandler => {
        const { setFineTuningSolutionState, setPercentage } =
            useFineTuningSolutionContext();
        const { info } = useFineTuningContext();
        const { updateMasterGainAndBandEqualizer } =
            useFineTuningSolutionPageHandler();

        const handleApply = async (
            solution: FineTuningSolution,
            solutionNumber: number
        ) => {
            LoggingService.info({
                componentName: handleApply.name,
                args: [`Apply solution 0${solutionNumber}`],
            });
            if (isChangeSleeveSolution(solution.name)) {
                setFineTuningSolutionState(
                    FineTuningSolutionState.ChangeSleeveRecommend
                );
            } else {
                setPercentage(0);
                setFineTuningSolutionState(FineTuningSolutionState.Applying);
                await updateMasterGainAndBandEqualizer(
                    SolutionValueChanged.Both,
                    solution.deviceState
                );
                await FineTuningService.fineTuningApplied(
                    LocalStorageService.serviceInstance.getActiveHearingProfileId(),
                    info.current.selected.category,
                    info.current.selected.problem,
                    solutionNumber,
                    solution
                );
                setFineTuningSolutionState(FineTuningSolutionState.AfterApply);
            }
        };

        return { handleApply };
    };

export default useFineTuningBeforeApplyPageHandler;
