import React from 'react';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import { ButtonType } from '../../../SharedComponents/Button/ButtonType';
import CustomButton from '../../../SharedComponents/Button/CustomButton';
import AppContainer from '../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../SharedComponents/Container/BodyContainer';
import CustomImg from '../../../SharedComponents/CustomImage/CustomImg';
import Header from '../../../SharedComponents/Header/HeaderRefactor';
import CustomTypography, {
    TypographyType,
} from '../../../SharedComponents/Typography/CustomTypography';
import './SevereHearingLossPage.scss';
import useSevereHearingLossHandler from './SevereHearingLossPageHandlerHook';
import useSevereHearingLossPageResource from './SevereHearingLossPageResourceHook';

const SevereHearingLossPage = () => {
    const {
        header,
        instructionImage,
        customerServiceImage,
        instructionHeader,
        instruction1,
        instruction2,
        continueButton,
        supportLink,
    } = useSevereHearingLossPageResource();

    const { onGoBack } = useSevereHearingLossHandler();

    return (
        <AppContainer>
            <Header headerContent={header} />
            <BodyContainer>
                <CustomTypography
                    type={TypographyType.notoSansBody}
                    text={instruction1.text}
                    id={instruction1.id}
                    className={'severeHLinstruction'}
                />
                <CustomImg
                    id={instructionImage.id}
                    imgType={'small'}
                    src={instructionImage.src}
                    alt={instructionImage.alt}
                />
                <CustomTypography
                    type={TypographyType.notoSansBody}
                    text={instruction2.text}
                    id={instruction2.id}
                    className={'severeHLinstruction'}
                />
                <div
                    className={'solutionCardSevereHearing'}
                    id="customer-service-card"
                >
                    <CustomTypography
                        type={TypographyType.notoSansBody}
                        text={instructionHeader.text}
                        id={instructionHeader.id}
                        className={'solutionCardTitle'}
                    />
                    <CustomImg
                        id={customerServiceImage.id}
                        imgType={'icon'}
                        src={customerServiceImage.src}
                        alt={customerServiceImage.alt}
                        className={'customerServiceImageSevereHearing'}
                    />
                    <CustomTypography
                        id={supportLink.id}
                        type={TypographyType.notoSansBody}
                        text={supportLink.text}
                        className={'solutionCardLink'}
                    />
                </div>
            </BodyContainer>
            <CustomButton
                id={continueButton.id}
                buttonType={ButtonType.Primary}
                onClick={async () => {
                    await onGoBack();
                }}
            >
                {continueButton.label}
            </CustomButton>
        </AppContainer>
    );
};

export default withTracking(SevereHearingLossPage);
