import React from 'react';
import TroubleShooting from '../../../../Pages/MainComponents/TroubleShooting/TroubleShooting';
import { withTracking } from '../../../../Services/Monitoring/ApplicationInsights';
import useRedoEasyFitTroubleShootingHandler from './RedoEasyFitTroubleShootingHandlerHook';
import useRedoEasyFitTroubleShootingResource from './RedoEasyFitTroubleShootingResourceHook';

function RedoEasyFitTroubleShooting() {
    return (
        <TroubleShooting
            useHandler={useRedoEasyFitTroubleShootingHandler}
            useResource={useRedoEasyFitTroubleShootingResource}
        />
    );
}

export default withTracking(RedoEasyFitTroubleShooting);
