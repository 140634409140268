import React, { useEffect, useRef } from 'react';
import useSpeechComfortTestStartPageHandler from '../Start/SpeechComfortTestStartPageHandlerHook';

export default function RedoLoudnessStartPage(): React.ReactElement {
    const { goToDetailPage } = useSpeechComfortTestStartPageHandler();
    const isComponentMounted = useRef(true);
    useEffect(() => {
        (async () => {
            if (isComponentMounted.current) {
                await goToDetailPage();
            }
        })();
        return () => {
            isComponentMounted.current = false;
        };
    }, [goToDetailPage]);
    return <></>;
}
