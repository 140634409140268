import { useContext, useEffect, useRef, useState } from 'react';
import {
    HearingAssessmentState,
    HLAAContext,
} from '../../../Pages/HLAA/HLAAContext';
import { MainViewContext } from '../../../Pages/MainComponents/MainView/MainViewContext';
import { TransitionAction } from '../../../Pages/MainComponents/MainView/MainViewModel';
import LocalStorageService from '../../../Services/LocalStorageService';
import PatientManagementService from '../../../Services/PatientManagementService';
import { VoidReturn } from '../../../Utils/PageUtils';
import { updateCoupling } from '../../../Utils/CouplingUtils';
import { sleeveCoupling } from '../../../Models/SupportedDeviceModel';
import { getCouplingOrDefault } from '../../../Utils/CouplingUtils';
import { Patient } from '../../../Models/Patient/Patient';
import LoggingService from '../../../Services/LoggingService';
import TelemetryService from '../../../Services/Monitoring/TelemetryService';
import { CustomerServiceContactService } from '../../../Services/Configuration/CustomerServiceContactService';

export type SleeveSelectionHandler = {
    selectedSleeve: number;
    onContinue: () => VoidReturn;
    onSleeveChangedToClosed: () => VoidReturn;
    onSleeveChangedToVented: () => VoidReturn;
    openHyperLink: () => VoidReturn;
};

export default function useSleeveSelectionHandler(
    onClose?: () => VoidReturn
): SleeveSelectionHandler {
    const { doTransition } = useContext(MainViewContext);
    const { setHearingAssessmentState } = useContext(HLAAContext);
    const [selectedSleeve, setSelectedSleeve] = useState<number>(-1);
    const isComponentMounted = useRef(true);

    useEffect(() => {
        (() => {
            if (isComponentMounted.current) {
                setSelectedSleeve(getCouplingOrDefault('left'));
            }
        })();
        return () => {
            isComponentMounted.current = false;
        };
    }, []);

    const openHyperLink = () => {
        TelemetryService.serviceInstance.TrackWithIdentity(
            'EasyFit-FeatureUsage',
            {
                Feature: 'ChangeSleeveSupportHelp',
            }
        );
        window.location.replace(
            CustomerServiceContactService.getCountrySpecificVibeHearingWebSupportAddress(
                false
            )
        );
    };

    const onContinue = async () => {
        if (selectedSleeve != getCouplingOrDefault('left')) {
            LoggingService.info({
                componentName: onContinue.name,
                args: [`Coupling id changed to ${selectedSleeve}.`],
            });
            updateCoupling(selectedSleeve);
            const patient =
                (await PatientManagementService.getPatient()) as Patient;
            await LocalStorageService.serviceInstance.setPatient(patient);
        }

        if (setHearingAssessmentState) {
            setHearingAssessmentState(HearingAssessmentState.Start);
        }
        doTransition(TransitionAction.GoToAssessment);
        if (onClose) onClose();
    };

    const onSleeveChangedToClosed = () => {
        setSelectedSleeve(sleeveCoupling.Closed);
    };

    const onSleeveChangedToVented = () => {
        setSelectedSleeve(sleeveCoupling.Vented);
    };

    return {
        selectedSleeve,
        onContinue,
        onSleeveChangedToClosed,
        onSleeveChangedToVented,
        openHyperLink,
    };
}
