import { useContext } from 'react';
import { VoidReturn } from '../../../../Utils/PageUtils';
import { RedoHLAAContext, RedoHLAAState } from '../RedoHLAAContext';

export type TechnicalSetupHandler = {
    onContinue: () => VoidReturn;
};

function useRedoHLAATechnicalSetupHandler(): TechnicalSetupHandler {
    const { setRedoHLAAState } = useContext(RedoHLAAContext);

    const onContinue = () => {
        setRedoHLAAState(RedoHLAAState.OneSideSetUp);
    };

    return {
        onContinue,
    };
}

export default useRedoHLAATechnicalSetupHandler;
