import LocalStorageService from '../Services/LocalStorageService';
import PatientManagementService from '../Services/PatientManagementService';

function getDeviceInfo() {
    const leftDeviceModelId =
        LocalStorageService.serviceInstance.getDeviceModelId('left');
    const leftDeviceBrandId =
        LocalStorageService.serviceInstance.getDeviceBrandId('left');
    const leftDevicePairingId =
        LocalStorageService.serviceInstance.getPairingAddress('left');

    const rightDeviceModelId =
        LocalStorageService.serviceInstance.getDeviceModelId('right');
    const rightDeviceBrandId =
        LocalStorageService.serviceInstance.getDeviceBrandId('right');
    const rightDevicePairingId =
        LocalStorageService.serviceInstance.getPairingAddress('right');

    return {
        leftDeviceModelId,
        leftDeviceBrandId,
        leftDevicePairingId,
        rightDeviceModelId,
        rightDeviceBrandId,
        rightDevicePairingId,
    };
}

export function getCouplingOrDefault(
    side: string,
    modelIdParam?: string
): number {
    const couplingId =
        LocalStorageService.serviceInstance.getDeviceCouplingId(side);
    const modelId =
        modelIdParam ??
        LocalStorageService.serviceInstance.getDeviceModelId(side);
    const supportedHis = LocalStorageService.serviceInstance.getSupportedHis();
    if (supportedHis !== undefined) {
        if (
            supportedHis.some(
                (e) =>
                    e.couplingId === couplingId &&
                    e.hiUri === modelId.toString()
            )
        ) {
            return couplingId;
        } else {
            return (
                supportedHis.find(
                    (hi) => hi.hiUri === modelId.toString() && hi.defaultConfig
                )?.couplingId ?? -1
            );
        }
    } else return -1;
}

export async function setCouplingIfRequired(): Promise<void> {
    await PatientManagementService.getPatient();

    const devices = [
        {
            side: 'Left',
            brandId: getDeviceInfo().leftDeviceBrandId,
            modelId: getDeviceInfo().leftDeviceModelId as unknown as string,
            pairingAddress: getDeviceInfo().leftDevicePairingId,
            couplingId: getCouplingOrDefault(
                'left',
                getDeviceInfo().leftDeviceModelId as unknown as string
            ),
        },
        {
            side: 'Right',
            brandId: getDeviceInfo().rightDeviceBrandId,
            modelId: getDeviceInfo().rightDeviceModelId as unknown as string,
            pairingAddress: getDeviceInfo().rightDevicePairingId,
            couplingId: getCouplingOrDefault(
                'right',
                getDeviceInfo().rightDeviceModelId as unknown as string
            ),
        },
    ];
    await PatientManagementService.SetDevices(devices);
}
export async function updateCoupling(coupling: number): Promise<void> {
    const devices = [
        {
            side: 'Left',
            brandId: getDeviceInfo().leftDeviceBrandId,
            modelId: getDeviceInfo().leftDeviceModelId as unknown as string,
            pairingAddress: getDeviceInfo().leftDevicePairingId,
            couplingId: coupling,
        },
        {
            side: 'Right',
            brandId: getDeviceInfo().rightDeviceBrandId,
            modelId: getDeviceInfo().rightDeviceModelId as unknown as string,
            pairingAddress: getDeviceInfo().rightDevicePairingId,
            couplingId: coupling,
        },
    ];
    await PatientManagementService.SetDevices(devices);
}
