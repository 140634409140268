import React from 'react';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import { getUserDeviceModel } from '../../../Services/ResourceService';
import { ButtonType } from '../../../SharedComponents/Button/ButtonType';
import CustomButton from '../../../SharedComponents/Button/CustomButton';
import AppContainer from '../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../SharedComponents/Container/BodyContainer';
import CustomImg from '../../../SharedComponents/CustomImage/CustomImg';
import Header from '../../../SharedComponents/Header/HeaderRefactor';
import CustomTypography, {
    TypographyType,
} from '../../../SharedComponents/Typography/CustomTypography';
import VideoPlayer from '../../../SharedComponents/VideoPlayer/VideoPlayer';
import { switchBasedOnDevice } from '../../../Utils/PageUtils';
import useHIAssemblyPageHandler from './HIAssemblyPageHandlerHook';
import useHIAssemblyPageResource from './HIAssemblyPageResourceHook';
import './HIAssemblyPageStyle.scss';

export interface IHIAssemblyPageProps {
    workflow: string;
}

function HIAssemblyPage() {
    const { onContinueAction, logVideoLoadingError } =
        useHIAssemblyPageHandler();

    const {
        header,
        title,
        instructionsText,
        video,
        image,
        continueText,
        continueButton,
    } = useHIAssemblyPageResource();

    return (
        <AppContainer>
            <Header headerContent={header} />
            <BodyContainer>
                <CustomTypography
                    id={title.id}
                    type={TypographyType.notoSansSubHeading}
                    text={title.text}
                    className={'hiAssemblyTitle'}
                />
                {switchBasedOnDevice(getUserDeviceModel(), {
                    VibeAir: <></>,
                    VibeGo: (
                        <CustomImg
                            {...image}
                            imgType="small"
                            style={{ margin: '4vh 0' }}
                        />
                    ),
                    VibeComplete: (
                        <CustomImg
                            {...image}
                            imgType="small"
                            style={{ margin: '4vh 0' }}
                        />
                    ),
                })}
                <CustomTypography
                    id={instructionsText.id}
                    type={TypographyType.notoSansBody}
                    text={instructionsText.text}
                    className={'hiAssemblyInstructions'}
                />
                {switchBasedOnDevice(getUserDeviceModel(), {
                    VibeAir: (
                        <VideoPlayer
                            id={video.id}
                            url={video.url}
                            videoType={video.videoType}
                            previewImageSrc={video.previewImageSrc}
                            onError={() =>
                                logVideoLoadingError(HIAssemblyPage.name)
                            }
                        />
                    ),
                    VibeGo: <></>,
                    VibeComplete: <></>,
                })}
                <CustomTypography
                    id={continueText.id}
                    type={TypographyType.notoSansBody}
                    text={continueText.text}
                    textAlign="bottom"
                />
            </BodyContainer>
            <CustomButton
                id={continueButton.id}
                buttonType={ButtonType.PrimaryLoading}
                onClick={async () => {
                    await onContinueAction();
                }}
            >
                {continueButton.label}
            </CustomButton>
        </AppContainer>
    );
}

export default withTracking(HIAssemblyPage);
