import React from 'react';
import { ButtonType } from '../../../SharedComponents/Button/ButtonType';
import CustomButton from '../../../SharedComponents/Button/CustomButton';
import AppContainer from '../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../SharedComponents/Container/BodyContainer';
import FlexContainer from '../../../SharedComponents/Container/FlexContainer';
import CustomDialog from '../../../SharedComponents/Dialog/CustomDialog';
import SubMenuDialog from '../../../SharedComponents/Dialog/Menu/SubMenuDialog';
import Header from '../../../SharedComponents/Header/HeaderRefactor';
import CustomTypography, {
    TypographyType,
} from '../../../SharedComponents/Typography/CustomTypography';
import useCountrySelectionHandler from './CountrySelectionHandlerHook';
import './CountrySelectionPageStyle.scss';
import useCountrySelectionResource from './CountrySelectionResourceHook';

export default function CountrySelectionPage(): React.ReactElement {
    const {
        selectedCountry,
        dialogDisplayState,
        onClickContinueButton,
        onCloseDialog,
        onOpenDialog,
        onSelectCountry,
        getSelectedItems,
    } = useCountrySelectionHandler();

    const {
        header,
        instructions,
        continueButton,
        countrySelectionButton,
        countrySelectionDialog,
    } = useCountrySelectionResource(selectedCountry, onSelectCountry);

    return (
        <AppContainer>
            <CustomDialog fullScreen open={dialogDisplayState}>
                <SubMenuDialog
                    onClose={onCloseDialog}
                    useResource={() => countrySelectionDialog}
                    useHandler={() => ({ getSelectedItems })}
                />
            </CustomDialog>
            <Header headerContent={header} />
            <BodyContainer flexStart>
                <CustomTypography
                    textAlign="left"
                    type={TypographyType.notoSansBodyBig}
                    text={instructions.text}
                    id={instructions.id}
                    className={'country-selection-page-instruction'}
                />
                <FlexContainer>
                    <CustomButton
                        buttonType={ButtonType.SubMenuButton}
                        id={countrySelectionButton.id}
                        onClick={onOpenDialog}
                        withDefaultContainer={false}
                    >
                        {countrySelectionButton.label}
                    </CustomButton>
                </FlexContainer>
            </BodyContainer>
            <CustomButton
                id={continueButton.id}
                buttonType={ButtonType.Primary}
                onClick={onClickContinueButton}
                disabled={selectedCountry === ''}
            >
                {continueButton.label}
            </CustomButton>
        </AppContainer>
    );
}
