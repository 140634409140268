import { useContext } from 'react';
import { useTranslation } from 'react-i18next/';
import { BeepConfirmationResource } from '../../../../Pages/MainComponents/BeepConfirmation/BeepConfirmation';
import { getUserDeviceModel } from '../../../../Services/ResourceService';
import { switchBasedOnDevice } from '../../../../Utils/PageUtils';
import { HeaderActionType } from '../../../Header/HeaderActionType';
import { RedoEasyFitContext } from '../RedoEasyFitContext';

export const useRedoEasyFitConfirmationResource =
    (): BeepConfirmationResource => {
        const { info } = useContext(RedoEasyFitContext);
        const { t } = useTranslation();

        const side =
            info.current.activeSide.toLowerCase() === 'left' ? 'Left' : 'Right';
        return {
            header: {
                id: 'redo-easyFit-header',
                text: t('menu:Menu_Feature:Main:Text2'),
                type: HeaderActionType.Menu,
            },
            title: {
                id: 'redo-easyFit-title',
                text: t(`pairing:Pairing_Feature:${side}Title`),
            },
            instruction: {
                id: 'text-instruction',
                text: t(`common:Common_Feature:ConfirmationBeepMessage`),
            },
            instructionImage: {
                id: 'img-instruction',
                src: switchBasedOnDevice(getUserDeviceModel(), {
                    VibeGo: `/resources/shared/beep-confirmation_${info.current.activeSide.toLowerCase()}_vibeGo.svg`,
                    VibeComplete: `/resources/shared/beep-confirmation_${info.current.activeSide.toLowerCase()}_vibeComplete.svg`,
                }),
            },
            buttons: {
                primary: {
                    id: 'btn-confirm-yes',
                    label: t(`common:Common_Feature:Yes`),
                },
                secondary: {
                    id: 'btn-confirm-no',
                    label: t(`common:Common_Feature:No`),
                },
            },
        };
    };
