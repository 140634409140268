import { DeviceState } from './DeviceState';

export class StartFineTuning {
    public patientId: string;
    public modelId: number;
    public hearingProfileId: string;
    public deviceState: { [deviceId: string]: DeviceState };
    public couplingId: number;

    constructor(
        patientId: string,
        hearingProfileId: string,
        modelId: number,
        deviceState: { [deviceId: string]: DeviceState },
        couplingId: number
    ) {
        this.patientId = patientId;
        this.hearingProfileId = hearingProfileId;
        this.modelId = modelId;
        this.deviceState = deviceState;
        this.couplingId = couplingId;
    }
}
