/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { MasterGain } from './MasterGain';

/**
 * Backend returns absolute values for min and max.
 * Which means e.g. max = 7, min = -1
 * We invert it to be user friendly:
 *
 *  {
 *      {4: 7},
 *      {3: 6},
 *      {2: 5},
 *      {1: 4},
 *      {0: 3},
 *      {-1: 2},
 *      {-2: 1},
 *      {-3: 0},
 *      {-4: -1},
 * }
 */

export class GainUIMapping {
    public display: {
        [side: string]: {
            masterGain: number;
            startingPosition: number;
            min: number;
            max: number;
        };
    };

    public uiToValueMap: Map<string, Map<number, number>>;
    public valueToUiMap: Map<string, Map<number, number>>;

    constructor(masterGains: MasterGain[]) {
        this.uiToValueMap = new Map<string, Map<number, number>>();
        this.valueToUiMap = new Map<string, Map<number, number>>();
        this.initializeMaps(masterGains);
        this.display = this.getUIStepForDisplay(masterGains);
    }

    private initializeMaps(masterGains: MasterGain[]) {
        masterGains.forEach((x) => {
            this.uiToValueMap.set(
                x.side,
                this.createMapForUIStepToBackendStep(
                    x.masterGainId,
                    x.masterGainUiMinId,
                    x.masterGainUiMaxId
                )
            );
            this.valueToUiMap.set(
                x.side,
                this.createMapForBackendStepToUIStep(
                    x.masterGainId,
                    x.masterGainUiMinId,
                    x.masterGainUiMaxId
                )
            );
        });
    }

    private getUIStepForDisplay(masterGains: MasterGain[]) {
        return Object.assign(
            {},
            ...masterGains.map((x) => ({
                [x.side]: {
                    masterGain: this.valueToUiMap
                        .get(x.side)!
                        .get(x.masterGainId),
                    startingPosition: this.valueToUiMap
                        .get(x.side)!
                        .get(x.masterGainId),
                    min: this.valueToUiMap
                        .get(x.side)!
                        .get(x.masterGainUiMinId),
                    max: this.valueToUiMap
                        .get(x.side)!
                        .get(x.masterGainUiMaxId),
                },
            }))
        );
    }

    private createMapForUIStepToBackendStep(
        absGain: number,
        absMin: number,
        absMax: number
    ) {
        const map = new Map<number, number>();

        const startingIndex = absMax - absGain; // base point being absGain
        const endingIndex = absMin - absGain;

        for (
            let val = absMax, index = startingIndex;
            index >= endingIndex;
            val--, index--
        ) {
            map.set(index, val);
        }

        return map;
    }

    /**
     * Reverse the above to make our life easier when fetching..
     */
    private createMapForBackendStepToUIStep(
        absGain: number,
        absMin: number,
        absMax: number
    ) {
        const map = new Map<number, number>();

        const startingIndex = absMax - absGain; // base point being absGain
        const endingIndex = absMin - absGain;

        for (
            let val = absMax, index = startingIndex;
            index >= endingIndex;
            val--, index--
        ) {
            map.set(val, index);
        }

        return map;
    }
}
