import { useContext, useState } from 'react';
import ArcToneService from '../../../Services/ArcToneService';
import AudioService from '../../../Services/AudioService';
import LocalStorageService from '../../../Services/LocalStorageService';
import { VoidReturn } from '../../../Utils/PageUtils';
import { ProgressBarCalculateCurrentProgress } from '../../../Utils/ProgressBarService';
import { RunningState, useRunning } from '../../../Utils/UseRunning';
import { HearingAssessmentState, HLAAContext } from '../HLAAContext';

export type HearingAssessmentOneSideDoneHandler = {
    onDoneAssessment: () => VoidReturn;
    onSwitchHLAASide: () => VoidReturn;
    onCompletedBothSideHLAA: () => VoidReturn;
    getTopProgress: () => number;
    error: string;
    loading: boolean | undefined;
    progressSetUp: number;
};

export default function useHearingAssessmentOneSideDoneHandler(): HearingAssessmentOneSideDoneHandler {
    const { info, setHearingAssessmentState, exitHearingAssessment } =
        useContext(HLAAContext);
    const [_Ignore, error, setRunning] = useRunning();
    const [loading, setLoading] = useState(true);
    const [progressSetUp, setProgressSetUp] = useState(0);

    const getTopProgress = (): number => {
        return ProgressBarCalculateCurrentProgress(
            info.current.totalPageCount,
            info.current.totalPagesForProgress
        );
    };

    const onSwitchHLAASide = () => {
        setRunning(RunningState.Reset);

        const activeSide =
            info.current.activeSide === 'left' ? 'right' : 'left'; //getOtherSide(assessmentState.activeSide);

        info.current.activeSide = activeSide;
        info.current.startKeepAlive = false;
        setHearingAssessmentState(HearingAssessmentState.SetUp);
    };

    const onCompletedBothSideHLAA = () => {
        exitHearingAssessment();
    };

    const exitHLAA = async (brandId: number, pairingAddress: number) => {
        await AudioService.serviceInstance.prepAudio(
            ArcToneService.getExitAssessment(brandId, pairingAddress),
            'ExitAssessment'
        );
    };

    const onDoneAssessment = async () => {
        try {
            setProgressSetUp(15);
            setLoading(true);
            setRunning(RunningState.Running);
            const brandId =
                LocalStorageService.serviceInstance.getDeviceBrandId(
                    info.current.activeSide
                );
            const pairingAddressActive =
                LocalStorageService.serviceInstance.getPairingAddress(
                    info.current.activeSide
                );
            const pairingAddressInactive =
                LocalStorageService.serviceInstance.getPairingAddress(
                    info.current.activeSide === 'left' ? 'right' : 'left'
                );
            setProgressSetUp(30);
            await exitHLAA(brandId, pairingAddressActive);
            setProgressSetUp(80);
            await AudioService.serviceInstance.prepAudio(
                ArcToneService.unmuteDevice(brandId, pairingAddressInactive),
                'UnmuteDevice'
            );
            setProgressSetUp(100);
            setRunning(RunningState.Completed);
            setLoading(false);
            setProgressSetUp(0);
        } catch (error) {
            setRunning(
                RunningState.Error,
                `Error when exit assessment for ${info.current.activeSide} side: ${error}.`
            );
            throw error;
        }
    };

    return {
        onDoneAssessment,
        onSwitchHLAASide,
        onCompletedBothSideHLAA,
        getTopProgress,
        error,
        loading,
        progressSetUp,
    };
}
