import { t } from '../../../../../Services/LocalizationService';
import { SubMenuListResource } from '../../../../../Utils/PageUtils';
import { HeaderActionType } from '../../../../Header/HeaderActionType';
import { createSubMenuItem } from '../../MenuUtils';
import useInstructionsMenuHandler from './InstructionsMenuHandlerHook';

export const useInstructionsMenuResource = (): SubMenuListResource => {
    const instructionsHandlers = useInstructionsMenuHandler();
    const instructionsMenuLists = [
        createSubMenuItem(
            'item-instruction-video',
            t('menu:Menu_Feature:SubMenu:Instructions:Text1'),
            instructionsHandlers.video
        ),
    ];
    return {
        header: {
            id: 'header-instructions-menu',
            text: t('menu:Menu_Feature:Main:Text3'),
            type: HeaderActionType.Close,
        },
        menuItems: instructionsMenuLists,
    };
};
