import { useContext } from 'react';
import { MainViewContext } from '../../../Pages/MainComponents/MainView/MainViewContext';
import { TransitionAction } from '../../../Pages/MainComponents/MainView/MainViewModel';
import DeviceManagementService from '../../../Services/DeviceManagementService';
import LocalStorageService from '../../../Services/LocalStorageService';
import PatientManagementService from '../../../Services/PatientManagementService';
import { Workflow } from '../../../Utils/CompletedWorkflow';
import { VoidReturn } from '../../../Utils/PageUtils';
import { WarningDialogHandler } from '../Warning/WarningDialog';
export interface IRedoPairingDialogHandlerProps {
    onClose?: () => VoidReturn;
}

function useRedoPairingDialogHandler(
    props: IRedoPairingDialogHandlerProps
): WarningDialogHandler {
    const viewContext = useContext(MainViewContext);

    const { onClose } = props;

    const repeatPairing = async () => {
        const leftDeviceId =
            LocalStorageService.serviceInstance.getDeviceId('left');
        const rightDeviceId =
            LocalStorageService.serviceInstance.getDeviceId('right');
        await DeviceManagementService.resetPairingAddress(
            leftDeviceId,
            rightDeviceId
        );
        LocalStorageService.serviceInstance.setCompletedWorkflow(
            LocalStorageService.serviceInstance.getCompletedWorkflow() &
                ~(Workflow.DevicePairingLeft | Workflow.DevicePairingRight)
        );
        await PatientManagementService.getDeviceRepairingState();
    };

    const primaryAction = async () => {
        await repeatPairing();
        LocalStorageService.serviceInstance.clearPairingKey();
        if (window.location.pathname.toLowerCase().includes('pairing')) {
            window.location.reload();
        } else {
            viewContext.doTransition(TransitionAction.DoRepeatPairing);
        }
    };

    const secondaryAction = () => {
        if (onClose) onClose();
    };

    return {
        primaryAction,
        secondaryAction,
    };
}

export default useRedoPairingDialogHandler;
