import { useTranslation } from 'react-i18next';
import { TroubleShootingResource } from '../../../../Pages/MainComponents/TroubleShooting/TroubleShooting';
import { CustomerServiceContactService } from '../../../../Services/Configuration/CustomerServiceContactService';
import { HeaderActionType } from '../../../Header/HeaderActionType';

import troubleShootingResource from './RedoHLAATroubleShootingResource.json';

function useRedoHLAATroubleShootingResource(): TroubleShootingResource {
    const { t } = useTranslation();

    const getCustomerServiceSupportLink = () => {
        return CustomerServiceContactService.getGlobalWebSupportAddress();
    };

    const {
        headerContent,
        imgContent,
        troubleShootingMessageContent,
        buttonContent,
        customerSupportMessageContent,
        customerSupportLinkContent,
    } = troubleShootingResource;

    const header = {
        id: headerContent.id,
        text: t(headerContent.translationKey),
        type: HeaderActionType.Menu,
    };

    const image = imgContent;

    const troubleShootingMessage = {
        id: troubleShootingMessageContent.id,
        text: t(troubleShootingMessageContent.translationKey),
    };

    const buttonLabel = {
        id: buttonContent.id,
        label: t(buttonContent.translationKey),
    };

    const customerSupportMessage = {
        id: customerSupportMessageContent.id,
        text: t(customerSupportMessageContent.translationKey),
    };

    const customerSupportLink = {
        id: customerSupportLinkContent.id,
        text: getCustomerServiceSupportLink(),
    };

    return {
        header,
        image,
        troubleShootingMessage,
        buttonLabel,
        customerSupportMessage,
        customerSupportLink,
    };
}

export default useRedoHLAATroubleShootingResource;
