import { t } from '../../../../../Services/LocalizationService';
import { useFineTuningSolutionContext } from '../../FineTuningSolutionContext';
type FineTuningWaitingPageResource = {
    progress: {
        id: string;
        text: string;
    };

    loadingText: {
        id: string;
        text: string;
    };
};

export default function useFineTuningWaitingPageResource(): FineTuningWaitingPageResource {
    const { percentage } = useFineTuningSolutionContext();
    const progress = {
        id: 'progress-waiting',
        text: t('fineTuning:FineTuning_Feature:Solution:Text4'),
    };

    const loadingText = {
        id: 'txt-loading',
        text: `${percentage}%`,
    };

    return {
        progress,
        loadingText,
    };
}
