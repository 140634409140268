import { Backdrop, CircularProgress } from '@material-ui/core';
import React, { useState } from 'react';
import './SpinnerStyle.scss';

function useLoading(): [JSX.Element, React.Dispatch<boolean>, boolean] {
    const [loading, setLoading] = useState(true);

    const spinner = (
        <Backdrop className={'pageLoadSpinner'} open={loading}>
            <CircularProgress color="secondary" />
        </Backdrop>
    );

    return [spinner, setLoading, loading];
}

export default useLoading;
