import axios from 'axios';
import { ServiceConfigFactory } from './ServiceConfigFactory';
import { StartFineTuning } from '../Models/FineTuning/StartFineTuning';
import { DeviceState } from '../Models/FineTuning/DeviceState';
import { SupportedCategory } from '../Models/FineTuning/SupportedCategory';
import { FineTuningSolution } from '../Models/FineTuning/FineTuningSolution';
import { FineTuningDeviceStates } from '../Models/FineTuning/FineTuningDeviceStates';
import AuthService from './Authentication/AuthService';

export default class FineTuningService {
    public static async startFineTuning(
        patientId: string,
        hearingProfileId: string,
        modelId: number,
        deviceState: { [deviceId: string]: DeviceState },
        couplingId: number
    ): Promise<void> {
        const startFineTuningDto = new StartFineTuning(
            patientId,
            hearingProfileId,
            modelId,
            deviceState,
            couplingId
        );
        const res = await axios.post(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/HearingProfile/StartFineTuning`,
            startFineTuningDto,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );

        if (res.status < 300) {
            return;
        }
        return Promise.reject('Fail to start fine tuning ' + res.statusText);
    }

    public static async getCategoryAndAttributeOption(
        patientId: string,
        hearingProfileId: string
    ): Promise<SupportedCategory[]> {
        const res = await axios.get<SupportedCategory[]>(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/HearingProfile/GetCategoryAndAttributeOption?hearingProfileId=${hearingProfileId}`,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );

        if (res.status < 300) {
            return res.data;
        }

        return Promise.reject(
            'Fail to get category and attribute option ' + res.statusText
        );
    }

    public static async getFineTuningSolution(
        hearingProfileId: string,
        modelId: number,
        category: string,
        attribute: string,
        solutionCounter: number,
        couplingId: number
    ): Promise<FineTuningSolution | undefined> {
        const res = await axios.get<FineTuningSolution>(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/HearingProfile/GetFineTuningSolution?hearingProfileId=${hearingProfileId}&modelId=${modelId}&category=${category}&attribute=${attribute}&solutionCounter=${solutionCounter}&couplingId=${couplingId}`,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );

        if (res.status === 200) {
            if (res.data) {
                return res.data;
            } else {
                return undefined;
            }
        }

        return Promise.reject(
            'Fail to get fine tuning solution ' + res.statusText
        );
    }

    public static async fineTuningApplied(
        hearingProfileId: string,
        category: string,
        attribute: string,
        solutionCounter: number,
        solution: FineTuningSolution
    ): Promise<void> {
        const res = await axios.post(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/HearingProfile/FineTuningApplied?hearingProfileId=${hearingProfileId}&categoryId=${category}&attributeId=${attribute}&solutionCounter=${solutionCounter}`,
            solution,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );

        if (res.status < 300) {
            return;
        }
        return Promise.reject('Fail to apply fine tuning ' + res.statusText);
    }

    public static async fineTuningConfirmed(
        hearingProfileId: string
    ): Promise<void> {
        const res = await axios.post(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/HearingProfile/FineTuningConfirmed?hearingProfileId=${hearingProfileId}`,
            null,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );

        if (res.status < 300) {
            return;
        }
        return Promise.reject('Fail to confirm fine tuning ' + res.statusText);
    }

    public static async getFineTuningDeviceStates(
        hearingProfileId: string
    ): Promise<FineTuningDeviceStates> {
        const res = await axios.get<FineTuningDeviceStates>(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/HearingProfile/GetFineTuningDeviceStates?hearingProfileId=${hearingProfileId}`,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );

        if (res.status < 300) {
            return res.data;
        }
        return Promise.reject('Fail to get fine tuning ' + res.statusText);
    }

    public static async fineTuningReset(
        hearingProfileId: string,
        isGlobalReset: boolean,
        deviceState: FineTuningDeviceStates
    ): Promise<void> {
        const res = await axios.post(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/HearingProfile/FineTuningReset?hearingProfileId=${hearingProfileId}&isGlobalReset=${isGlobalReset}`,
            deviceState,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );

        if (res.status < 300) {
            return;
        }
        return Promise.reject('Fail to reset fine tuning ' + res.statusText);
    }
}
