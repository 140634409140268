import { useContext } from 'react';
import TelemetryService from '../../../Services/Monitoring/TelemetryService';
import { VoidReturn } from '../../../Utils/PageUtils';
import { PairingContext } from '../PairingContext';

export type PairingSuccessHandler = {
    handlePrimaryAction: () => VoidReturn;
};

export default function usePairingSuccessHandler(): PairingSuccessHandler {
    const {
        state: { context },
        send,
    } = useContext(PairingContext);

    const handlePrimaryAction = async () => {
        TelemetryService.serviceInstance.TrackWithIdentity(
            'EasyFit-CompletedWorkflow',
            {
                Workflow:
                    context.activeSide === 'left'
                        ? 'DevicePairingLeft'
                        : 'DevicePairingRight',
            }
        );
        send('successed');
    };
    return {
        handlePrimaryAction,
    };
}
