import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import { HeaderResource } from '../../../Utils/PageUtils';
import { HearingAssessmentState, HLAAContext } from '../HLAAContext';

type HearingAssessmentInfoPageResource = {
    header: HeaderResource;
    infoPageButton: {
        id: string;
        text: string;
    };
    instruction1: {
        id: string;
        text: string;
    };
    instruction2: {
        id: string;
        text: string;
    };
};

function useHearingAssementInfoPageResource(): HearingAssessmentInfoPageResource {
    const { t } = useTranslation();
    const { hearingAssessmentState } = useContext(HLAAContext);

    const getInstruction1 = (type: HearingAssessmentState): string => {
        switch (type) {
            case HearingAssessmentState.Reinstruct: {
                return 'hlaa:Hlaa_Feature:ReInstruct:Text';
            }
            case HearingAssessmentState.AbortMaxPresentedToneReach: {
                return 'hlaa:Hlaa_Feature:AbortMaxPresentedToneReach:Text';
            }
            case HearingAssessmentState.Constant0ToneHeard: {
                return 'hlaa:Hlaa_Feature:Text1';
            }
            default: {
                return 'hlaa:Hlaa_Feature:ReInstruct:Text';
            }
        }
    };

    const getInstruction2 = (type: HearingAssessmentState): string => {
        switch (type) {
            case HearingAssessmentState.Reinstruct: {
                return 'hlaa:Hlaa_Feature:Text2';
            }
            case HearingAssessmentState.AbortMaxPresentedToneReach: {
                return 'hlaa:Hlaa_Feature:Text2';
            }
            case HearingAssessmentState.Constant0ToneHeard: {
                return 'hlaa:Hlaa_Feature:Constant0ToneHeard:Text';
            }
            default: {
                return 'hlaa:Hlaa_Feature:Text2';
            }
        }
    };

    const getButtonText = (type: HearingAssessmentState): string => {
        switch (type) {
            case HearingAssessmentState.Reinstruct: {
                return 'common:Common_Feature:TryAgain';
            }
            case HearingAssessmentState.AbortMaxPresentedToneReach: {
                return 'common:Common_Feature:Continue';
            }
            case HearingAssessmentState.Constant0ToneHeard: {
                return 'common:Common_Feature:Continue';
            }
            default: {
                return 'common:Common_Feature:TryAgain';
            }
        }
    };

    const header = {
        id: 'hlaa-info-header',
        text: t('common:Common_Feature:Hints'),
        type: HeaderActionType.Menu,
    };

    const infoPageButton = {
        id: 'btn-info-continue',
        text: t(getButtonText(hearingAssessmentState)),
    };

    const instruction1 = {
        id: `txt-${hearingAssessmentState}-content`,
        text: t(getInstruction1(hearingAssessmentState)),
    };

    const instruction2 = {
        id: `txt-${hearingAssessmentState}-instruction2`,
        text: t(getInstruction2(hearingAssessmentState)),
    };

    return {
        header,
        infoPageButton,
        instruction1,
        instruction2,
    };
}

export default useHearingAssementInfoPageResource;
