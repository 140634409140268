import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { MainViewContext } from '../../../Pages/MainComponents/MainView/MainViewContext';
import {
    DialogState,
    TransitionAction,
} from '../../../Pages/MainComponents/MainView/MainViewModel';
import { WarningDialogHandler } from '../Warning/WarningDialog';
import { PartialDialogHandler } from '../PartialDialog/PartialDialog';
import ILeaveAppState from '../../../Redux/Models/LeaveApp/ILeaveAppState';
import { RootState } from '../../../Redux/Reducers/RootReducer';
import { goToUrl } from '../../../Utils/NavigationUtils';

export type GlobalDialogState = {
    pairingConnectionLostHandler: {
        display: boolean;
        useHandler: () => WarningDialogHandler;
    };
    accountDeletedHandler: {
        display: boolean;
        onClose: () => void;
    };
    deviceInfoCheckHandler: {
        display: boolean;
        onClose: () => void;
    };
    upversionAnalyticConsentHandler: {
        display: boolean;
        onClose: () => void;
    };
    leaveAppHandler: {
        display: boolean;
        useHandler: () => PartialDialogHandler;
    };
    updateDisplay: (dialog: DialogState) => void;
};

function useGlobalDialogHandler(): GlobalDialogState {
    const [pairingConnectionLostDisplay, setPairingConnectionLostDisplay] =
        useState(false);
    const [accountDeletedDisplay, setAccountDeletedDisplay] = useState(false);
    const [deviceInfoCheckDisplay, setDeviceInfoCheckDisplay] = useState(false);
    const [
        upversionAnalyticConsentDisplay,
        setUpversionAnalyticConsentDisplay,
    ] = useState(false);
    const [leaveAppDisplay, setLeaveAppDisplay] = useState(false);
    const [loading, setLoading] = useState(false);
    const viewContext = useContext(MainViewContext);
    const leaveAppState = useSelector(
        (state: RootState) => state.leaveApp
    ) as ILeaveAppState;

    const updateDisplay = (dialog: DialogState) => {
        switch (dialog) {
            case DialogState.AccountDeleted:
                setAccountDeletedDisplay(true);
                break;
            case DialogState.PairingConnectionLost:
                setPairingConnectionLostDisplay(true);
                break;
            case DialogState.DeviceInfoCheck:
                setDeviceInfoCheckDisplay(true);
                break;
            case DialogState.UpversionAnalyticConsent:
                setUpversionAnalyticConsentDisplay(true);
                break;
            case DialogState.LeaveApp:
                setLeaveAppDisplay(true);
                break;
            case DialogState.None:
                setAccountDeletedDisplay(false);
                setPairingConnectionLostDisplay(false);
                setLeaveAppDisplay(false);
        }
    };
    const onClosePairingConnectionLost = () => {
        setPairingConnectionLostDisplay(false);
        viewContext.doTransition(TransitionAction.DoRepeatPairing);
    };

    const pairingConnectionLostHandler = {
        display: pairingConnectionLostDisplay,
        useHandler: () => {
            return {
                primaryAction: () => {
                    setLoading(true);
                    onClosePairingConnectionLost();
                    setLoading(false);
                },
                loading,
            };
        },
    };

    const accountDeletedHandler = {
        display: accountDeletedDisplay,
        onClose: () => setAccountDeletedDisplay(false),
    };

    const deviceInfoCheckHandler = {
        display: deviceInfoCheckDisplay,
        onClose: () => setDeviceInfoCheckDisplay(false),
    };

    const upversionAnalyticConsentHandler = {
        display: upversionAnalyticConsentDisplay,
        onClose: () => setUpversionAnalyticConsentDisplay(false),
    };

    const leaveAppHandler = {
        display: leaveAppDisplay,
        useHandler: (): PartialDialogHandler => {
            return {
                primaryAction: async () => {
                    setLeaveAppDisplay(false);
                    await goToUrl(leaveAppState.leaveAppUrl, true);
                    await viewContext.doTransition(TransitionAction.DoLeaveApp);
                },
                secondaryAction: () => {
                    setLeaveAppDisplay(false);
                    viewContext.doTransition(
                        TransitionAction.DoLeaveAppDialogClose
                    );
                },
            };
        },
    };

    return {
        pairingConnectionLostHandler,
        accountDeletedHandler,
        deviceInfoCheckHandler,
        upversionAnalyticConsentHandler,
        updateDisplay,
        leaveAppHandler,
    };
}

export default useGlobalDialogHandler;
