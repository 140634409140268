export class BestCluster {
    public clusterId: number;
    public masterGainId: number;
    public masterGainUiMinId: number;
    public masterGainUiMaxId: number;

    constructor(
        clusterId: number,
        masterGainId: number,
        masterGainUiMinId: number,
        masterGainUiMaxId: number
    ) {
        this.clusterId = clusterId;
        this.masterGainId = masterGainId;
        this.masterGainUiMinId = masterGainUiMinId;
        this.masterGainUiMaxId = masterGainUiMaxId;
    }
}
