import React from 'react';
import AccountDeleted from '../AccountDeleted/AccountDeleted';
import AnalyticConsentDialog from '../AnalyticConsent/AnalyticConsentDialog';
import CustomDialog from '../CustomDialog';
import DeviceInfoCheckDialog from '../DeviceInfoCheck/DeviceInfoCheckDialog';
import PartialDialog, {
    PartialDialogHandler,
    PartialDialogResource,
} from '../PartialDialog/PartialDialog';
import WarningDialog, {
    WarningDialogHandler,
    WarningDialogResource,
} from '../Warning/WarningDialog';

export type GlobalDialogRender = {
    renderPairingConnectionLostDialog: () => JSX.Element;
    renderAccountDeletedDialog: () => JSX.Element;
    renderDeviceInfoCheckDialog: () => JSX.Element;
    renderUpversionAnalyticConsentDialog: () => JSX.Element;
    renderLeaveAppDialog: () => JSX.Element;
};

export interface IGlobalDialogRenderProps {
    pairingConnectionLostHandler: {
        display: boolean;
        useHandler: () => WarningDialogHandler;
    };
    usePairingConnectionLostResource: () => WarningDialogResource;
    accountDeletedHandler: {
        display: boolean;
        onClose: () => void;
    };
    deviceInfoCheckHandler: {
        display: boolean;
        onClose: () => void;
    };
    upversionAnalyticConsentHandler: {
        display: boolean;
        onClose: () => void;
    };
    leaveAppHandler: {
        display: boolean;
        useHandler: () => PartialDialogHandler;
    };
    useLeaveAppDialogResource: () => PartialDialogResource;
}

function useGlobalDialogRender(
    props: IGlobalDialogRenderProps
): GlobalDialogRender {
    const renderPairingConnectionLostDialog = () => {
        const { display, useHandler } = props.pairingConnectionLostHandler;

        const useResource = props.usePairingConnectionLostResource;

        return (
            <CustomDialog fullScreen open={display}>
                <WarningDialog
                    useHandler={useHandler}
                    useResource={useResource}
                />
            </CustomDialog>
        );
    };

    const renderAccountDeletedDialog = () => {
        const { display, onClose } = props.accountDeletedHandler;
        return <AccountDeleted displayed={display} onClose={onClose} />;
    };

    const renderDeviceInfoCheckDialog = () => {
        const { display, onClose } = props.deviceInfoCheckHandler;
        return <DeviceInfoCheckDialog display={display} onClose={onClose} />;
    };

    const renderUpversionAnalyticConsentDialog = () => {
        const { display } = props.upversionAnalyticConsentHandler;
        return <AnalyticConsentDialog display={display} />;
    };

    const renderLeaveAppDialog = () => {
        return (
            <PartialDialog
                open={props.leaveAppHandler.display}
                useHandler={props.leaveAppHandler.useHandler}
                useResource={props.useLeaveAppDialogResource}
            />
        );
    };

    return {
        renderPairingConnectionLostDialog,
        renderAccountDeletedDialog,
        renderDeviceInfoCheckDialog,
        renderUpversionAnalyticConsentDialog,
        renderLeaveAppDialog,
    };
}

export default useGlobalDialogRender;
