import React from 'react';
import { LinearProgress, LinearProgressProps } from '@material-ui/core';
import './TopProgressBarStyle.scss';

interface ITopProgressBar {
    progress: number;
}

function TopProgressBar({
    progress,
    classes,
    ...others
}: Omit<LinearProgressProps, keyof ITopProgressBar> &
    ITopProgressBar): React.ReactElement {
    return (
        <div className={'topProgressArea'}>
            <LinearProgress
                variant="determinate"
                value={progress}
                classes={{
                    colorPrimary: 'colorPrimary',
                    barColorPrimary: 'barColorPrimary',
                    root: 'root',
                    bar: 'bar',
                }}
                {...others}
            />
        </div>
    );
}

export default TopProgressBar;
