import { ImageResource } from '../../../../../Utils/PageUtils';
import { t } from '../../../../../Services/LocalizationService';
import { CustomerServiceContactService } from '../../../../../Services/Configuration/CustomerServiceContactService';

type FineTuningCustomerServicePageResource = {
    makeCallText: {
        id: string;
        text: string;
    };

    image: ImageResource;

    customerServiceGlobalLink: {
        id: string;
        text: string;
    };
};

export default function useFineTuningCustomerServicePageResource(): FineTuningCustomerServicePageResource {
    const makeCallText = {
        id: 'txt-makecall',
        text: t('fineTuning:FineTuning_Feature:Solution:Text5'),
    };

    const image = {
        id: 'img-customer-service',
        src: '/resources/shared/customer-care-phone.svg',
        alt: 'customer-service',
    };

    const customerServiceGlobalLink = {
        id: 'txt-contact-customer-service',
        text: CustomerServiceContactService.getGlobalWebSupportAddress(false),
    };

    return {
        makeCallText,
        image,
        customerServiceGlobalLink,
    };
}
