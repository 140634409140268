import { useContext } from 'react';
import { VoidReturn } from '../../../../../Utils/PageUtils';
import { itemHandler } from '../../MenuUtils';
import { MainViewContext } from '../../../../../Pages/MainComponents/MainView/MainViewContext';
import { MainViewWorkflow } from '../../../../../Pages/MainComponents/MainView/MainViewModel';
import { openNewTabWithUrl } from '../../../../../Utils/NavigationUtils';
import useMenuDialogHandler from '../../MenuDialogHandler';

export type LegalInfoMenuHandler = {
    openLink: itemHandler;
};

const useLegalInfoMenuHandler = (
    onClose?: () => VoidReturn
): LegalInfoMenuHandler => {
    const viewContext = useContext(MainViewContext);
    const { onClickExternalLinkMenu } = useMenuDialogHandler();

    const legalInfoGlobalLink = 'https://www.vibe-hearing.com/privacy-policies';

    const openLink = async () => {
        if (viewContext.workflowType === MainViewWorkflow.Web) {
            openNewTabWithUrl(legalInfoGlobalLink);
        } else {
            onClickExternalLinkMenu(legalInfoGlobalLink);
        }
        if (onClose) onClose();
    };

    return {
        openLink,
    };
};

export default useLegalInfoMenuHandler;
