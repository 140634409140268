import React, { useEffect, useRef } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai, anonymousAI } from './ApplicationInsights';
import { withRouter, RouteComponentProps, RouteProps } from 'react-router-dom';
import Telemetry from './Telemetry';
import AnonymousTelemetry from './AnonymousTelemetry';

interface TelemetryProviderProps extends RouteComponentProps {
    instrumentationKey: string;
}

export const TelemetryContext = React.createContext<Telemetry>(new Telemetry());

const TelemetryProvider = (props: TelemetryProviderProps & RouteProps) => {
    const isTelemetryInitialized = useRef(false);

    useEffect(() => {
        const { history, instrumentationKey } = props;
        if (!isTelemetryInitialized.current && instrumentationKey && history) {
            const applicationInsights = ai.initialize(
                instrumentationKey,
                history
            );
            const anonymousApplicationInsights = anonymousAI.initialize(
                instrumentationKey,
                history
            );
            AnonymousTelemetry.getInstance().setApplicationInsights(
                anonymousApplicationInsights
            );
            Telemetry.getInstance().setApplicationInsights(applicationInsights);
            isTelemetryInitialized.current = true;
        }
    }, [props, isTelemetryInitialized]);

    return (
        <TelemetryContext.Provider value={Telemetry.getInstance()}>
            {props.children}
        </TelemetryContext.Provider>
    );
};

export default withRouter(withAITracking(ai.reactPlugin, TelemetryProvider));
