import React from 'react';
import PairButton from '../../../../../SharedComponents/Button/PairButton';
import FlexContainer from '../../../../../SharedComponents/Container/FlexContainer';
import CustomTypography, {
    TypographyType,
} from '../../../../../SharedComponents/Typography/CustomTypography';
import useFTFooterWarningPageHandler from './FTFooterWarningPageHandler';
import useFTFooterWarningPageResource from './FTFooterWarningPageResource';

export default function FTFooterWarningPage(): React.ReactElement {
    const { handleWarningYes, handleWarningNo } =
        useFTFooterWarningPageHandler();
    const { warningText, warningButtonTexts } =
        useFTFooterWarningPageResource();
    return (
        <FlexContainer>
            <CustomTypography
                id={warningText.id}
                text={warningText.text}
                type={TypographyType.notoSansBody}
            />
            <PairButton
                primaryButton={{
                    id: 'btn-warning-yes',
                    onClick: handleWarningYes,
                    children: warningButtonTexts.primary,
                }}
                secondaryButton={{
                    id: 'btn-warning-no',
                    onClick: handleWarningNo,
                    children: warningButtonTexts.secondary,
                }}
            />
        </FlexContainer>
    );
}
