import { useContext } from 'react';
import { MainViewContext } from '../../../../Pages/MainComponents/MainView/MainViewContext';
import { TransitionAction } from '../../../../Pages/MainComponents/MainView/MainViewModel';
import { VoidReturn } from '../../../../Utils/PageUtils';
import { ProgressBarCalculateCurrentProgress } from '../../../../Utils/ProgressBarService';
import { RedoEasyFitContext, RedoEasyFitState } from '../RedoEasyFitContext';

type RedoEasyFitBeepConfirmationHandler = {
    onBeepConfirmationYesAction: () => VoidReturn;
    onBeepConfirmationNoAction: () => VoidReturn;
    getTopProgress: () => number;
};

export default function useRedoEasyFitBeepConfirmationHandler(): RedoEasyFitBeepConfirmationHandler {
    const { doTransition } = useContext(MainViewContext);
    const { info, setRedoEasyFitState } = useContext(RedoEasyFitContext);
    const getTopProgress = (): number => {
        return ProgressBarCalculateCurrentProgress(
            info.current.totalPageCount,
            info.current.totalPagesForProgress
        );
    };

    const onBeepConfirmationYesAction = async () => {
        const activeSide =
            info.current.activeSide === 'left' ? 'right' : 'left';

        info.current.activeSide = activeSide;

        if (activeSide === 'right')
            setRedoEasyFitState(RedoEasyFitState.OneSideSetUp);
        else {
            doTransition(TransitionAction.WelcomeContinue);
        }
    };

    const onBeepConfirmationNoAction = () => {
        setRedoEasyFitState(RedoEasyFitState.TroubleShooting);
    };

    return {
        onBeepConfirmationYesAction,
        onBeepConfirmationNoAction,
        getTopProgress,
    };
}
