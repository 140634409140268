import { useTranslation } from 'react-i18next';
import { HeaderActionType } from '../../Header/HeaderActionType';
import pageNotFoundDialogResource from './PageNotFoundDialogResource.json';
import {
    HeaderResource,
    ImageResource,
    TextResource,
    ButtonResource,
} from '../../../Utils/PageUtils';

export type PageNotFoundDialogResource = {
    header: HeaderResource;
    image: ImageResource;
    dialogTitle: TextResource;
    dialogMessage: TextResource;
    backButton: ButtonResource;
};

function usePageNotFoundDialogResource(): PageNotFoundDialogResource {
    const { t } = useTranslation();

    const {
        headerContent,
        imgContent,
        dialogTitleContent,
        dialogMessageContent,
        backButtonContent,
    } = pageNotFoundDialogResource;

    const header = {
        id: headerContent.id,
        type: HeaderActionType.NoAction,
        iconImgSrc: headerContent.iconImgSrc,
    };

    const image = {
        id: imgContent.id,
        src: imgContent.src,
        alt: imgContent.alt,
    };

    const dialogTitle = {
        id: dialogTitleContent.id,
        text: t(
            `common:Common_Feature:404_Error${dialogTitleContent.translationKey}`
        ),
    };

    const dialogMessage = {
        id: dialogMessageContent.id,
        text: t(
            `common:Common_Feature:404_Error${dialogMessageContent.translationKey}`
        ),
    };

    const backButton = {
        id: backButtonContent.id,
        label: t(
            `common:Common_Feature:404_Error${backButtonContent.translationKey}`
        ),
    };

    return {
        header,
        image,
        dialogTitle,
        dialogMessage,
        backButton,
    };
}

export default usePageNotFoundDialogResource;
