import { t } from '../../../../../Services/LocalizationService';
type FTFooterAppliedSuccessPageResource = {
    successText: {
        id: string;
        text: string;
    };

    button: {
        id: string;
        text: string;
    };
};

export default function useFTFooterAppliedSuccessPageResource(): FTFooterAppliedSuccessPageResource {
    const successText = {
        id: 'txt-success',
        text: t('fineTuning:FineTuning_Feature:Solution:Text3'),
    };

    const button = {
        id: 'btn-home',
        text: t('common:Common_Feature:Done'),
    };

    return {
        successText,
        button,
    };
}
