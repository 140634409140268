import React from 'react';
import FlexContainer from '../../../../../SharedComponents/Container/FlexContainer';
import CustomTypography, {
    TypographyType,
} from '../../../../../SharedComponents/Typography/CustomTypography';
import useFTFooterApplyingPageResource from './FTFooterApplyingPageResource';
import './FTFooterApplyingPageStyle.scss';

export default function FTFooterApplyingPage(): React.ReactElement {
    const { progressText } = useFTFooterApplyingPageResource();
    return (
        <FlexContainer flexGrow>
            <CustomTypography
                id={progressText.id}
                text={progressText.text}
                type={TypographyType.notoSansHeading}
                className="finetuning-applying-progress-text"
            />
        </FlexContainer>
    );
}
