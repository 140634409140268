import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './InitialState';

const leaveAppSlice = createSlice({
    name: 'leaveAppSlice',
    initialState: initialState.leaveAppState,
    reducers: {
        updateLeaveApp: (state, action) => {
            return action.payload;
        },
    },
});

export const { updateLeaveApp } = leaveAppSlice.actions;

export const leaveAppReducer = leaveAppSlice.reducer;
