import { useContext } from 'react';
import LoggingService from '../../../Services/LoggingService';
import PatientManagementService from '../../../Services/PatientManagementService';
import { Workflow } from '../../../Utils/CompletedWorkflow';
import { VoidReturn } from '../../../Utils/PageUtils';
import { MainViewContext } from '../MainView/MainViewContext';
import { TransitionAction } from '../MainView/MainViewModel';

export type HIAssemblyPageHandler = {
    onContinueAction: () => VoidReturn;
    logVideoLoadingError: (componentName: string) => void;
};

export default function useHIAssemblyPageHandler(): HIAssemblyPageHandler {
    const viewContext = useContext(MainViewContext);

    async function onContinueAction() {
        await PatientManagementService.endWorkflow(Workflow.HIAssemble);
        viewContext.doTransition(TransitionAction.HiAssembleContinue);
    }

    function logVideoLoadingError(componentName: string) {
        LoggingService.warn({
            componentName: componentName,
            args: ['Video loading error'],
        });
    }

    return {
        onContinueAction,
        logVideoLoadingError,
    };
}
