import { ButtonProps } from '@material-ui/core';
import React from 'react';
import { ButtonType } from './ButtonType';
import PrimaryButton from './Variants/PrimaryButton';
import PrimaryButtonWithLoading from './Variants/PrimaryButtonWithLoading';
import SecondaryButton from './Variants/SecondaryButton';
import SecondaryButtonWithLoading from './Variants/SecondaryButtonWithLoading';
import SecondaryButtonWithMenuArrow from './Variants/SecondaryButtonWithMenuArrow';

export function createButton(
    type: ButtonType,
    props: ButtonProps
): JSX.Element {
    switch (type) {
        case ButtonType.Primary:
            return <PrimaryButton {...props} />;
        case ButtonType.Secondary:
            return <SecondaryButton {...props} />;
        case ButtonType.PrimaryLoading:
            return <PrimaryButtonWithLoading {...props} />;
        case ButtonType.SecondaryLoading:
            return <SecondaryButtonWithLoading {...props} />;
        case ButtonType.SubMenuButton:
            return <SecondaryButtonWithMenuArrow {...props} />;
        default:
            return <></>;
    }
}
