import { VoidReturn } from '../../../Utils/PageUtils';
import { useHistory } from 'react-router-dom';

export type PageNotFoundDialogHandler = {
    backToVibeNow: () => VoidReturn;
};

function usePageNotFoundDialogHandler(): PageNotFoundDialogHandler {
    const history = useHistory();

    const backToVibeNow = () => {
        history.push('/');
    };

    return {
        backToVibeNow,
    };
}

export default usePageNotFoundDialogHandler;
