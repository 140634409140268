import { useTranslation } from 'react-i18next';

export type FineTuningBeforeResetPageResource = {
    headerText: string;
    resetImgSrc: string;
    instruction1: string;
    instruction2: string;
    buttonYesText: string;
    buttonNoText: string;
};

const useFineTuningBeforeResetPageResource =
    (): FineTuningBeforeResetPageResource => {
        const { t } = useTranslation();
        const headerText = t('fineTuning:FineTuning_Feature:Reset:Header');

        const resetImgSrc = '/resources/fineTuning/reset-for_center-screen.svg';

        const instruction1 = t(
            'fineTuning:FineTuning_Feature:Reset:BeforeReset:TitleText'
        );

        const instruction2 = t(
            'fineTuning:FineTuning_Feature:Reset:BeforeReset:Text'
        );

        const buttonYesText = t('common:Common_Feature:Yes');

        const buttonNoText = t('common:Common_Feature:No');

        return {
            headerText,
            resetImgSrc,
            instruction1,
            instruction2,
            buttonYesText,
            buttonNoText,
        };
    };

export default useFineTuningBeforeResetPageResource;
