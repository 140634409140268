import { addResourceBundle } from './../Services/LocalizationService';
import { SupportedCountries } from '../Models/SupportedCountries';
import CountryConsentConfigService from '../Services/Configuration/CountryConsentConfigService';
import LocalStorageService from '../Services/LocalStorageService';

export const getResidingCountry = (): SupportedCountries => {
    const patientCountry =
        LocalStorageService.serviceInstance.getPatientCountry();
    const selectedCountry =
        LocalStorageService.serviceInstance.getSelectedCountry();
    return (
        patientCountry !== '' ? patientCountry : selectedCountry
    ) as SupportedCountries;
};

export function getActiveConsentVersion(): string {
    const residingCountry = getResidingCountry();
    return (
        CountryConsentConfigService.getCountryConfig(residingCountry)
            .customVersion ?? '1.0'
    );
}

export function getCurrentConsentVersion(): string {
    return LocalStorageService.serviceInstance.getConsentVersion();
}

export function getCustomConsentVersion(): string | undefined {
    const residingCountry = getResidingCountry();
    const countryConfig =
        CountryConsentConfigService.getCountryConfig(residingCountry);
    return countryConfig !== undefined
        ? countryConfig.customVersion
        : undefined;
}

export async function addCountrySpecificResourceBundles(): Promise<void> {
    if (getCustomConsentVersion()) {
        await addResourceBundle(`Legal/${getResidingCountry()}/`, 'consent');
    }
}
