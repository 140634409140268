import { useEffect } from 'react';
import { useState } from 'react';
import { LegalInfoType } from './LegalDialogModel';

export type LegalHandler = {
    dialogType: LegalInfoType;
    linkCallBacks: {
        privacyPolicyHandler: () => void;
        cookiePolicyHandler: () => void;
    };
};

function useLegalHandler(type: LegalInfoType): LegalHandler {
    const [dialogType, setDialogType] = useState(type);
    useEffect(() => {
        setDialogType(type);
    }, [type]);

    function openCookiePolicy() {
        document.getElementById('top-anchor')?.scrollIntoView();
        setDialogType(LegalInfoType.CookiePolicy);
    }

    function openPrivacyPolicy() {
        document.getElementById('top-anchor')?.scrollIntoView();
        setDialogType(LegalInfoType.PrivacyPolicy);
    }

    const linkCallBacks = {
        privacyPolicyHandler: openPrivacyPolicy,
        cookiePolicyHandler: openCookiePolicy,
    };

    return {
        dialogType,
        linkCallBacks,
    };
}

export default useLegalHandler;
