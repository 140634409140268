import React from 'react';
import PairButton from '../../../../SharedComponents/Button/PairButton';
import AppContainer from '../../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../../SharedComponents/Container/BodyContainer';
import CustomImg from '../../../../SharedComponents/CustomImage/CustomImg';
import Header from '../../../../SharedComponents/Header/HeaderRefactor';
import CustomTypography, {
    TypographyType,
} from '../../../../SharedComponents/Typography/CustomTypography';
import useFineTuningBeforeResetPageHandler from './FineTuningBeforeResetPageHandlerHook';
import useFineTuningBeforeResetPageResource from './FineTuningBeforeResetPageResourceHook';
import '../FineTuningResetPage.scss';

const FineTuningBeforeResetPage = (): JSX.Element => {
    const { startReset, goBack } = useFineTuningBeforeResetPageHandler();
    const {
        headerText,
        resetImgSrc,
        instruction1,
        instruction2,
        buttonYesText,
        buttonNoText,
    } = useFineTuningBeforeResetPageResource();
    return (
        <AppContainer>
            <Header
                headerContent={{ id: 'header-fine-tuning', text: headerText }}
            />
            <BodyContainer flexStart>
                <CustomImg
                    id={'img-reset'}
                    src={resetImgSrc}
                    alt={'reset'}
                    imgType={'iconLarge'}
                />
                <CustomTypography
                    className={'subHeading'}
                    id={'txt-reset-instruction1'}
                    type={TypographyType.notoSansSubHeading}
                    text={instruction1}
                />
                <CustomTypography
                    noMargin
                    id={'txt-reset-instruction2'}
                    type={TypographyType.notoSansBody}
                    text={instruction2}
                />
            </BodyContainer>
            <PairButton
                primaryButton={{
                    id: 'btn-yes',
                    onClick: startReset,
                    children: buttonYesText,
                }}
                secondaryButton={{
                    id: 'btn-no',
                    onClick: goBack,
                    children: buttonNoText,
                }}
            />
        </AppContainer>
    );
};

export default FineTuningBeforeResetPage;
