import React from 'react';
import { withTracking } from '../../../../Services/Monitoring/ApplicationInsights';
import PairButton from '../../../../SharedComponents/Button/PairButton';
import AppContainer from '../../../../SharedComponents/Container/AppContainer';
import { WelcomeView } from '../../Welcome/WelcomeView';
import useAppLandingPageHandler from './AppLandingPageHandlerHook';
import useAppLandingPageResource from './AppLandingPageResourceHook';

const AppLandingPage = () => {
    const { onContinue, onReturn } = useAppLandingPageHandler();
    const { header, illustrationImage, subtitle, buttons } =
        useAppLandingPageResource();
    return (
        <AppContainer>
            <WelcomeView
                header={header}
                illustrationImage={illustrationImage}
                subtitle={subtitle}
            />
            <PairButton
                primaryButton={{
                    id: buttons.continue.id,
                    onClick: onContinue,
                    children: buttons.continue.label,
                }}
                secondaryButton={{
                    id: buttons.return.id,
                    onClick: onReturn,
                    children: buttons.return.label,
                }}
            />
        </AppContainer>
    );
};

export default withTracking(AppLandingPage);
