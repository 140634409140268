import { t } from '../../../../../Services/LocalizationService';
type FTFooterWarningPageResource = {
    warningText: {
        id: string;
        text: string;
    };

    warningButtonTexts: {
        primary: string;
        secondary: string;
    };
};

export default function useFTFooterWarningPageResource(): FTFooterWarningPageResource {
    const warningText = {
        id: 'txt-warning',
        text: t('fineTuning:FineTuning_Feature:Solution:Text7'),
    };

    const warningButtonTexts = {
        primary: t('common:Common_Feature:Yes'),
        secondary: t('common:Common_Feature:No'),
    };

    return {
        warningText,
        warningButtonTexts,
    };
}
