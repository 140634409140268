import { useContext } from 'react';
import { MainViewContext } from '../../../../../Pages/MainComponents/MainView/MainViewContext';
import {
    MainViewWorkflow,
    TransitionAction,
} from '../../../../../Pages/MainComponents/MainView/MainViewModel';
import { t } from '../../../../../Services/LocalizationService';
import LocalStorageService from '../../../../../Services/LocalStorageService';
import CompletedWorkflowService, {
    mandatoryWorkflow,
} from '../../../../../Utils/CompletedWorkflow';
import { SubMenuListResource } from '../../../../../Utils/PageUtils';
import { HeaderActionType } from '../../../../Header/HeaderActionType';
import { IDialogContentProps } from '../../../DialogUtils';
import { MenuContext } from '../../MenuContext';
import { MenuDialogType } from '../../MenuDialogType';
import { createSubMenuItem } from '../../MenuUtils';

export default function useHearingAidsMenuResource(
    props: IDialogContentProps
): SubMenuListResource {
    const { pushMenu: renderNextMenu } = useContext(MenuContext);
    const viewContext = useContext(MainViewContext);
    const onClose = props.onClose;

    const hearingAidsMenuLists = [
        createSubMenuItem(
            'item-redoHLAA',
            t('menu:Menu_Feature:SubMenu:HearingAids:Text5'),
            () => renderNextMenu(MenuDialogType.RedoHLAA)
        ),
    ];

    const completedWorkflow =
        LocalStorageService.serviceInstance.getCompletedWorkflow();
    if (
        CompletedWorkflowService.isCompleted(
            completedWorkflow,
            mandatoryWorkflow
        )
    ) {
        hearingAidsMenuLists.unshift(
            createSubMenuItem(
                'item-redoLoudness',
                t('menu:Menu_Feature:SubMenu:HearingAids:Text4'),
                () => {
                    if (onClose) onClose();
                    viewContext.doTransition(TransitionAction.GoRedoLoudness);
                }
            )
        );
        if (viewContext.workflowType === MainViewWorkflow.Web) {
            hearingAidsMenuLists.unshift(
                createSubMenuItem(
                    'item-redoEasyfit',
                    t('menu:Menu_Feature:SubMenu:HearingAids:Text1'),
                    () => renderNextMenu(MenuDialogType.RedoEasyFit)
                )
            );
            hearingAidsMenuLists.unshift(
                createSubMenuItem(
                    'item-repeatPairing',
                    t('menu:Menu_Feature:SubMenu:HearingAids:Text3'),
                    () => renderNextMenu(MenuDialogType.RedoPairing)
                )
            );
        }
    }
    return {
        header: {
            id: 'header-hearingAids-menu',
            text: t('menu:Menu_Feature:Main:Text2'),
            type: HeaderActionType.Close,
        },
        menuItems: hearingAidsMenuLists,
    };
}
