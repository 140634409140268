import { deviceModel, deviceModelId } from '../Models/SupportedDeviceModel';
import LoggingService from './LoggingService';
import LocalStorageService from './LocalStorageService';
import { mapEncodedModelIdToDefault } from '../Utils/BluetoothDeviceUtils';

export function getUserDeviceModel(side = 'left'): deviceModel {
    const modelId = LocalStorageService.serviceInstance.getDeviceModelId(side);
    if (modelId == 6144007) {
        return deviceModel.VibeAir;
    } else if (modelId == 540917767) {
        return deviceModel.VibeGo;
    } else if (modelId == 72024937728770) {
        return deviceModel.VibeComplete;
    }
    return deviceModel.VibeAir; //default
}

export function getUserDeviceModelFromURLParams(): deviceModelId | undefined {
    const params = new URLSearchParams(location.search);
    const platformType = params.get('platformId');
    const leftModelParam = params.get('leftModel');
    const rightModelParam = params.get('rightModel');
    if (
        platformType == null ||
        leftModelParam == null ||
        rightModelParam == null
    )
        return undefined;

    const leftModel = mapEncodedModelIdToDefault(
        leftModelParam as string,
        platformType as string
    );
    const rightModel = mapEncodedModelIdToDefault(
        rightModelParam as string,
        platformType as string
    );

    if (leftModel !== rightModel) {
        LoggingService.error({
            componentName: 'URLParamsValidation',
            args: [`Different model ids, ${location.search}`],
        });
        return undefined;
    }

    return leftModel as deviceModelId;
}

export function checkPairingStatus(side = 'both'): boolean {
    const leftDevice =
        LocalStorageService.serviceInstance.getDeviceModelId('left');
    const rightDevice =
        LocalStorageService.serviceInstance.getDeviceModelId('right');
    switch (side) {
        case 'left':
            return leftDevice !== -1;
        case 'right':
            return rightDevice !== -1;
        default:
            return leftDevice !== -1 && rightDevice !== -1;
    }
}

type ResourceConfig = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
};

class ResourcerService {
    public static getResourceByDevice(resource: ResourceConfig): Object {
        const device = getUserDeviceModel();

        function getArraysIntersection(arr1: string[], arr2: string[]) {
            return arr1.filter(function (n) {
                return arr2.indexOf(n) !== -1;
            });
        }

        if (
            getArraysIntersection(
                Object.keys(resource),
                Object.keys(deviceModel)
            ).length > 0
        ) {
            switch (device) {
                case deviceModel.VibeAir:
                    return resource[deviceModel.VibeAir];
                case deviceModel.VibeGo:
                    return resource[deviceModel.VibeGo];
                case deviceModel.VibeComplete:
                    return resource[deviceModel.VibeComplete];
                default:
                    LoggingService.error({
                        componentName: ResourcerService.name,
                        args: ['Device model is not supported'],
                    });
                    return resource;
            }
        }
        return resource;
    }
}

export default ResourcerService;
