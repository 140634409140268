import React from 'react';
import * as deviceInfo from 'react-device-detect';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import AppContainer from '../../../SharedComponents/Container/AppContainer';

const useStyles = makeStyles({
    table: {
        maxWidth: 650,
    },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function createData(prop: string, type: any, description: string) {
    return { prop, type, description };
}

const rows = [
    createData(
        'isMobile',
        String(deviceInfo.isMobile),
        'returns true if device type is mobile or tablet'
    ),
    createData(
        'isMobileOnly',
        String(deviceInfo.isMobileOnly),
        'returns true if device type is mobile'
    ),
    createData(
        'isTablet',
        String(deviceInfo.isTablet),
        'returns true if device type is tablet'
    ),
    createData(
        'isBrowser',
        String(deviceInfo.isBrowser),
        'returns true if device type is browser'
    ),
    createData(
        'isSmartTV',
        String(deviceInfo.isSmartTV),
        'returns true if device type is smarttv'
    ),
    createData(
        'isWearable',
        String(deviceInfo.isWearable),
        'returns true if device type is wearable'
    ),
    createData(
        'isConsole',
        String(deviceInfo.isConsole),
        'returns true if device type is console'
    ),
    createData(
        'isAndroid',
        String(deviceInfo.isAndroid),
        'returns true if os type is Android'
    ),
    createData(
        'isWinPhone',
        String(deviceInfo.isWinPhone),
        'returns true if os type is Windows Phone'
    ),
    createData(
        'isIOS',
        String(deviceInfo.isIOS),
        'returns true if os type is iOS'
    ),
    createData(
        'isChrome',
        String(deviceInfo.isChrome),
        'returns true if browser is Chrome'
    ),
    createData(
        'isFirefox',
        String(deviceInfo.isFirefox),
        'returns true if browser is Firefox'
    ),
    createData(
        'isSafari',
        String(deviceInfo.isSafari),
        'returns true if browser is Safari'
    ),
    createData(
        'isOpera',
        String(deviceInfo.isOpera),
        'returns true if browser is Opera'
    ),
    createData(
        'isIE',
        String(deviceInfo.isIE),
        'returns true if browser is Internet Explorer'
    ),
    createData(
        'isEdge',
        String(deviceInfo.isEdge),
        'returns true if browser is Edge or Edge Chromium'
    ),
    createData(
        'isYandex',
        String(deviceInfo.isYandex),
        'returns true if browser is Yandex'
    ),
    createData(
        'isChromium',
        String(deviceInfo.isChromium),
        'returns true if browser is Chromium'
    ),
    createData(
        'isMobileSafari',
        String(deviceInfo.isMobileSafari),
        'returns true if browser is Mobile Safari'
    ),
    createData(
        'osVersion',
        String(deviceInfo.osVersion),
        'returns os version (e.g 7 for Windows or 6 for Android)'
    ),
    createData(
        'osName',
        String(deviceInfo.osName),
        'returns os name (e.g Windows, Android)'
    ),
    createData(
        'fullBrowserVersion',
        String(deviceInfo.fullBrowserVersion),
        'returns full browser version (e.g 65.0.3325.181 for Chrome)'
    ),
    createData(
        'browserVersion',
        String(deviceInfo.browserVersion),
        'returns browser major version (e.g 65 in Chrome or 9 in IE)'
    ),
    createData(
        'browserName',
        String(deviceInfo.browserName),
        'returns browser name'
    ),
    createData(
        'mobileVendor',
        String(deviceInfo.mobileVendor),
        'returns mobile device vendor (e.g LG, iPhone etc)'
    ),
    createData(
        'mobileModel',
        String(deviceInfo.mobileModel),
        'returns mobile device model (e.g Nexus 5)'
    ),
    createData(
        'engineName',
        String(deviceInfo.engineName),
        'returns browser engine name (e.g Gecko for FF or WebKit for Chrome)'
    ),
    createData(
        'engineVersion',
        String(deviceInfo.engineVersion),
        'returns engine version'
    ),
    createData('getUA', String(deviceInfo.getUA), 'returns user agent'),
    createData(
        'deviceType',
        String(deviceInfo.deviceType),
        'returns device type (e.g mobile or tablet)'
    ),
    createData(
        'isIOS13',
        String(deviceInfo.isIOS13),
        'returns true/false if device is running on iOS13'
    ),
    createData(
        'isIPhone13',
        String(deviceInfo.isIPhone13),
        'returns true/false if device is iPhone and running on iOS13'
    ),
    createData(
        'isIPad13',
        String(deviceInfo.isIPad13),
        'returns true/false if device is iPad and running on iOS13'
    ),
    createData(
        'isIPod13',
        String(deviceInfo.isIPod13),
        'returns true/false if device is iPod and running on iOS13'
    ),
    createData(
        'isElectron',
        String(deviceInfo.isElectron),
        'returns true/false if running on Electron'
    ),
    createData(
        'isEdgeChromium',
        String(deviceInfo.isEdgeChromium),
        'returns true/false if browser is Edge Chromium'
    ),
    createData(
        'isLegacyEdge',
        String(deviceInfo.isLegacyEdge),
        'returns true if browser is Edge'
    ),
    createData(
        'isWindows',
        String(deviceInfo.isWindows),
        'returns true/false if os is Windows'
    ),
    createData(
        'isMacOs',
        String(deviceInfo.isMacOs),
        'returns true/false if os is Mac OS'
    ),
    createData(
        'deviceDetect',
        JSON.stringify(deviceInfo.deviceDetect()),
        'return data object which includes all data about device (e.g version, engine, os etc.)'
    ),
];

function DeviceInfoPage() {
    const classes = useStyles();
    return (
        <AppContainer>
            <TableContainer component={Paper}>
                <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">prop</TableCell>
                            <TableCell align="center">type</TableCell>
                            <TableCell align="center">description</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.prop}>
                                <TableCell component="th" scope="row">
                                    {row.prop}
                                </TableCell>
                                <TableCell align="left">{row.type}</TableCell>
                                <TableCell align="right">
                                    {row.description}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </AppContainer>
    );
}

export default withTracking(DeviceInfoPage);
