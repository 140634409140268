import { combineReducers } from '@reduxjs/toolkit';
import { setupReducer } from './SetupSlice';
import { notificationReducer } from './NotificationSlice';
import { deviceReducer } from './DeviceSlice';
import { hlaaReducer } from './HlaaSlice';
import { leaveAppReducer } from './LeaveAppSlice';

const rootReducer = combineReducers({
    setup: setupReducer,
    notification: notificationReducer,
    device: deviceReducer,
    hlaa: hlaaReducer,
    leaveApp: leaveAppReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
