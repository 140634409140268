import { SliderProps } from '@material-ui/core';
import React from 'react';
import { SliderStateType, SlideType } from './CustomSlider';
import VolumeSlider, { omitProps } from './Variants/SliderWithControlButton';
import SliderWithControlButtonSideLeft from './Variants/SliderWithControlButtonSideLeft';
import SliderWithControlButtonSideRight from './Variants/SliderWithControlButtonSideRight';

export interface IVolumeSlider extends Omit<SliderProps, omitProps> {
    handleSliderChange: (value: number) => void | Promise<void>;
    handleSliderChangeCommitted: (value: number) => void | Promise<void>;
    onTouchStart: (
        event: React.TouchEvent<HTMLElement>
    ) => void | Promise<void>;
    handleSliderButtonClick?: (value: number) => void | Promise<void>;
    sliderState: SliderStateType;
    stepAdjustValue?: number;
    thumbImgSrc?: string;
    loadingPage?: boolean;
}

export function createSlider(
    props: IVolumeSlider,
    type?: SlideType
): JSX.Element {
    switch (type) {
        case SlideType.sideLeft: {
            return <SliderWithControlButtonSideLeft {...props} />;
        }
        case SlideType.sideRight: {
            return <SliderWithControlButtonSideRight {...props} />;
        }
        default:
            return <VolumeSlider {...props} />;
    }
}
