import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { useEffect, useRef } from 'react';
import AppContainer from '../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../SharedComponents/Container/BodyContainer';
import CustomImg from '../../../SharedComponents/CustomImage/CustomImg';
import Header from '../../../SharedComponents/Header/HeaderRefactor';
import TopProgressBar from '../../../SharedComponents/TopProgressBar/TopProgressBar';
import CustomTypography, {
    TypographyType,
} from '../../../SharedComponents/Typography/CustomTypography';
import {
    HeaderResource,
    ImageResource,
    TextResource,
    VoidReturn,
} from '../../../Utils/PageUtils';
import './OneSideSetUpStyle.scss';

export type OneSideSetUpHandler = {
    onSetUpDevices: () => VoidReturn;
    getTopProgress: () => number;
    progressSetUp: number;
};

export type OneSideSetUpResource = {
    header: HeaderResource;
    image: ImageResource;
    progress: {
        id: string;
    };
    infoText: TextResource;
};

export interface IOneSideSetUp {
    useHandler: () => OneSideSetUpHandler;
    useResource: () => OneSideSetUpResource;
}

export default function OneSideSetUp({
    useHandler,
    useResource,
}: IOneSideSetUp): JSX.Element {
    const { onSetUpDevices, getTopProgress, progressSetUp } = useHandler();
    const { header, image, progress, infoText } = useResource();
    const setUpAction = useRef(onSetUpDevices);

    useEffect(() => {
        const doAction = async () => {
            await setUpAction.current();
        };
        doAction();
    }, []);

    return (
        <AppContainer>
            <TopProgressBar progress={getTopProgress()} />
            <Header headerContent={header} disabled={true} />
            <BodyContainer>
                <div className="OneSideSetUp-runningArea">
                    <CustomImg
                        id={image.id}
                        className={'OneSideSetUp-image'}
                        src={image.src}
                        alt={image.alt}
                    />
                    <CircularProgress
                        id={progress.id}
                        className={'OneSideSetUp-circularProgress'}
                        size="5.3em"
                        thickness={2}
                        variant="determinate"
                        value={progressSetUp}
                    />
                </div>
                <CustomTypography
                    type={TypographyType.notoSansBodyBig}
                    text={infoText.text}
                    id={infoText.id}
                    noMargin
                />
            </BodyContainer>
        </AppContainer>
    );
}
