import React, { useContext } from 'react';
import { ButtonType } from '../../../SharedComponents/Button/ButtonType';
import CustomButton from '../../../SharedComponents/Button/CustomButton';
import AppContainer from '../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../SharedComponents/Container/BodyContainer';
import Header from '../../../SharedComponents/Header/HeaderRefactor';
import BulletList from '../../../SharedComponents/List/BulletList';
import CustomTypography, {
    TypographyType,
} from '../../../SharedComponents/Typography/CustomTypography';
import { HearingAssessmentState, HLAAContext } from '../HLAAContext';
import useHearingAssessmentInfoPageHandler from './HearingAssessmentInfoPageHandler';
import useHearingAssementInfoPageResource from './HearingAssessmentInfoPageResource';

export default function HearingAssessmentInfoPage(): React.ReactElement {
    const { hearingAssessmentState } = useContext(HLAAContext);
    const { header, infoPageButton, instruction1, instruction2 } =
        useHearingAssementInfoPageResource();
    const {
        reinstruct,
        abortMaxPresentedToneReach,
        constant0ToneHeardReach,
        error,
    } = useHearingAssessmentInfoPageHandler();

    const getOnClickAction = (type: HearingAssessmentState) => {
        switch (type) {
            case HearingAssessmentState.Reinstruct: {
                return reinstruct;
            }
            case HearingAssessmentState.AbortMaxPresentedToneReach: {
                return abortMaxPresentedToneReach;
            }
            case HearingAssessmentState.Constant0ToneHeard: {
                return constant0ToneHeardReach;
            }
            default: {
                return reinstruct;
            }
        }
    };

    const renderInstructionText = () => {
        if (instruction2.text.includes('|')) {
            return (
                <BulletList
                    bulletItems={instruction2.text.split('|').filter(Boolean)}
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                />
            );
        } else {
            return (
                <CustomTypography
                    type={TypographyType.notoSansBody}
                    text={instruction2.text}
                    textAlign="center"
                    id={instruction2.id}
                    className={'textInstruction2'}
                    noMargin
                />
            );
        }
    };

    return (
        <AppContainer>
            <Header headerContent={header} />
            <BodyContainer flexStart>
                <CustomTypography
                    type={TypographyType.notoSansBody}
                    noMargin
                    text={instruction1.text}
                    id={instruction1.id}
                    textAlign="left"
                />
                {renderInstructionText()}

                <CustomTypography
                    type={TypographyType.notoSansBody}
                    text={error}
                    id={`error`}
                    hidden={error === ''}
                />
            </BodyContainer>
            <CustomButton
                id={infoPageButton.id}
                buttonType={ButtonType.Primary}
                onClick={getOnClickAction(hearingAssessmentState)}
            >
                {infoPageButton.text}
            </CustomButton>
        </AppContainer>
    );
}
