import React, { useEffect, useState } from 'react';
import AppContainer from '../../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../../SharedComponents/Container/BodyContainer';
import Header from '../../../../SharedComponents/Header/HeaderRefactor';
import CustomizedCircularProgressPercent from '../../../../SharedComponents/Spinner/CustomizedCircularProgressPercent';
import CustomTypography, {
    TypographyType,
} from '../../../../SharedComponents/Typography/CustomTypography';
import useFineTuningDuringResetPageHandler from './FineTuningDuringResetPageHandlerHook';
import useFineTuningDuringResetPageResource from './FineTuningDuringResetPageResourceHook';
import '../FineTuningResetPage.scss';

const FineTuningDuringResetPage = (): JSX.Element => {
    const [percentage, setPercentage] = useState(0);

    const { resetFineTuning } =
        useFineTuningDuringResetPageHandler(setPercentage);
    const { headerText, progressText } = useFineTuningDuringResetPageResource();
    useEffect(() => {
        resetFineTuning();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AppContainer>
            <Header
                headerContent={{ id: 'header-fine-tuning', text: headerText }}
            />
            <BodyContainer>
                <CustomizedCircularProgressPercent
                    loadingPercentage={percentage}
                    loadingText={progressText}
                    spinnerContainerClassName={'resetSpinnerContainer'}
                    loadingTextClassName={'loadingText'}
                />
                <CustomTypography
                    type={TypographyType.notoSansHeading}
                    text={`${percentage}%`}
                    className={'percentageText'}
                    id={'txt-reset-loading'}
                />
            </BodyContainer>
        </AppContainer>
    );
};

export default FineTuningDuringResetPage;
