import AuthService from '../../../Services/Authentication/AuthService';
import { goToUrl } from '../../../Utils/NavigationUtils';

export type AccountDeletedHandler = {
    logout: () => Promise<void>;
};

export default function useAccountDeletedHandler(): AccountDeletedHandler {
    async function logout() {
        AuthService.adB2cInstance.clearSession();
        goToUrl('https://www.vibe-hearing.com');
    }

    return {
        logout,
    };
}
