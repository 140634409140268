import { CircularProgress } from '@material-ui/core';
import React from 'react';
import CustomIconButton from '../../../SharedComponents/Button/CustomIconButton';
import AppContainer from '../../../SharedComponents/Container/AppContainer';
import ButtonStickyContainer from '../../../SharedComponents/Container/ButtonStickyContainer';
import Header from '../../../SharedComponents/Header/HeaderRefactor';
import TopProgressBar from '../../../SharedComponents/TopProgressBar/TopProgressBar';
import '../HearingAssessmentStyle.scss';
import { HLAASharedView } from '../HLAASharedView';
import useHearingAssessmentPlayHandler from './HearingAssessmentPlayHandler';
import useHearingAssessmentPlayResource from './HearingAssessmentPlayResource';
import './HearingAssessmentPlayStyle.scss';

export default function HearingAssessmentPlay(): React.ReactElement {
    const { playTone, getTopProgress } = useHearingAssessmentPlayHandler();
    const {
        header,
        sideText,
        evaluationImage,
        confirmInstruction,
        playImage,
        progress,
        iconButton,
    } = useHearingAssessmentPlayResource();
    return (
        <AppContainer>
            <TopProgressBar progress={getTopProgress()} />
            <Header headerContent={header} />
            <HLAASharedView
                img={evaluationImage}
                title={sideText}
                instruction={confirmInstruction}
            />
            <ButtonStickyContainer>
                <div className={'actionArea'}>
                    <CircularProgress
                        id={progress.id}
                        className={'staticProgress'}
                        variant="determinate"
                        size={'12vh'}
                        value={100}
                        thickness={1}
                    ></CircularProgress>

                    <CustomIconButton
                        id={iconButton.id}
                        onClick={playTone}
                        className={'playImage'}
                        imgSrc={playImage.src}
                        iconSize="iconLarge"
                    />
                </div>
            </ButtonStickyContainer>
        </AppContainer>
    );
}
