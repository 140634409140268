export interface IMaintenanceDialogProps {
    maintenanceStart: number;
}

export type TimerText = {
    hourTimer: string;
    minuteTimer: string;
};

export type MaintenanceDialogHandler = {
    getTimerText: () => TimerText;
};

function useMaintenanceDialogHandler({
    maintenanceStart,
}: IMaintenanceDialogProps): MaintenanceDialogHandler {
    const start: number = maintenanceStart - Date.now() / 1000;
    let h: number;
    let m: number;
    let temp: number;

    const getTimerText = (): TimerText => {
        h = Math.floor(start / 60 / 60);
        // remove the hours
        temp = start - h * 60 * 60;
        m = Math.floor(temp / 60);
        // remove the minuets
        temp = temp - m * 60;

        // add leading zeros for aesthetics
        const hour = h < 10 && h >= 0 ? '0' + h : h;
        const minute = m < 10 && m >= 0 ? '0' + m : m;

        return {
            hourTimer: `${hour.toString()}`,
            minuteTimer: `:${minute.toString()}`,
        };
    };

    return {
        getTimerText,
    };
}

export default useMaintenanceDialogHandler;
