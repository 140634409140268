import React from 'react';
import { withTracking } from '../../../../Services/Monitoring/ApplicationInsights';
import AppContainer from '../../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../../SharedComponents/Container/BodyContainer';
import ButtonStickyContainer from '../../../../SharedComponents/Container/ButtonStickyContainer';
import FlexContainer from '../../../../SharedComponents/Container/FlexContainer';
import CustomImg from '../../../../SharedComponents/CustomImage/CustomImg';
import CustomDialog from '../../../../SharedComponents/Dialog/CustomDialog';
import { IDialogProps } from '../../../../SharedComponents/Dialog/DialogUtils';
import Header from '../../../../SharedComponents/Header/HeaderRefactor';
import CustomLink from '../../../../SharedComponents/Link/CustomLink';
import CustomTypography, {
    TypographyType,
} from '../../../../SharedComponents/Typography/CustomTypography';
import { VoidReturn } from '../../../../Utils/PageUtils';
import './DownloadAppDialog.scss';
import useDownloadDialogResource from './DownloadAppDialogResourceHook';

export interface IDownloadAppDialogProps extends IDialogProps {
    onContinue?: () => VoidReturn;
}

function DownloadAppDialog(props: IDownloadAppDialogProps) {
    const { displayed, onClose, onContinue } = props;
    const {
        header,
        logoImage,
        instructionsText1,
        instructionsText2,
        appleImage,
        appleLink,
        androidImage,
        androidLink,
    } = useDownloadDialogResource();

    return (
        <CustomDialog open={displayed} onClose={onClose}>
            <AppContainer>
                <Header headerContent={header} onClick={onClose} />
                <BodyContainer>
                    <CustomImg
                        id={logoImage.id}
                        imgType={'small'}
                        src={logoImage.src}
                        alt={logoImage.alt}
                        className={'vibeAppIconDownloadAppDialog'}
                    />
                    <CustomTypography
                        id={instructionsText1.id}
                        type={TypographyType.notoSansBodyBig}
                        text={instructionsText1.text}
                        className={'instructionTitleDownloadAppDialog'}
                    />
                    <CustomTypography
                        id={instructionsText2.id}
                        type={TypographyType.notoSansBody}
                        className={'instructionTextDownloadAppDialog'}
                        text={instructionsText2.text}
                    />
                </BodyContainer>
                <ButtonStickyContainer>
                    <FlexContainer
                        flexRow
                        className={'appDownloadImageAreaDownloadAppDialog'}
                    >
                        <CustomLink
                            href={appleLink.text}
                            target="_blank"
                            rel="noopener noreferrer"
                            id={appleLink.id}
                            onClick={onContinue}
                        >
                            <CustomImg
                                id={appleImage.id}
                                imgType={'icon'}
                                src={appleImage.src}
                                alt={appleImage.alt}
                                className={'appDownloadImageDownloadDialog'}
                            />
                        </CustomLink>
                        <CustomLink
                            href={androidLink.text}
                            target="_blank"
                            rel="noopener noreferrer"
                            id={androidLink.id}
                            onClick={onContinue}
                        >
                            <CustomImg
                                id={androidImage.id}
                                imgType={'icon'}
                                src={androidImage.src}
                                alt={androidImage.alt}
                                className={'appDownloadImageDownloadDialog'}
                            />
                        </CustomLink>
                    </FlexContainer>
                </ButtonStickyContainer>
            </AppContainer>
        </CustomDialog>
    );
}

export default withTracking(DownloadAppDialog);
