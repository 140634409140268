import React from 'react';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import AppContainer from '../../Container/AppContainer';
import BodyContainer from '../../Container/BodyContainer';
import Header from '../../Header/HeaderRefactor';
import CustomSwitch from '../../Switch/CustomSwitch';
import CustomTypography, {
    TypographyType,
} from '../../Typography/CustomTypography';
import { IDialogContentProps } from '../DialogUtils';
import useDataCollectionHandler from './DataCollectionHandlerHook';
import useDataCollectionResource from './DataCollectionResourceHook';

const DataCollection = ({ onClose }: IDialogContentProps) => {
    const { header, dataSwitch, info } = useDataCollectionResource();
    const { userAction, onCloseDataCollection, onClickToggle } =
        useDataCollectionHandler(onClose);

    return (
        <AppContainer>
            <Header headerContent={header} onClick={onCloseDataCollection} />
            <BodyContainer flexStart>
                <CustomSwitch
                    id={dataSwitch.id}
                    switchLabel={dataSwitch.text}
                    onClick={onClickToggle}
                    checked={userAction}
                />
                <CustomTypography
                    type={TypographyType.notoSansBody}
                    id={info.id}
                    text={info.text}
                    textAlign="left"
                    noMargin
                />
            </BodyContainer>
        </AppContainer>
    );
};

export default withTracking(DataCollection);
