import { useTranslation } from 'react-i18next';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import {
    ButtonResource,
    HeaderResource,
    ImageResource,
    TextResource,
} from '../../../Utils/PageUtils';

type ConsentPageResource = {
    header: HeaderResource;
    consentImage: ImageResource;
    title: TextResource;
    consentIntruction: TextResource;
    privacyLink: TextResource;
    checkbox: ButtonResource;
    button: ButtonResource;
};

function useConsentPageResource(): ConsentPageResource {
    const { t } = useTranslation();
    const header = {
        text: t(`consent:Consent_Feature:Text8`),
        type: HeaderActionType.Menu,
    };

    const consentImage = {
        id: 'img-consent',
        src: '/resources/consent/consent-graphic.svg',
        alt: t('consent:header'),
    };

    const title = {
        id: 'txt-consent-operation-title',
        text: t('consent:Consent_Feature:Text9'),
    };

    const consentIntruction = {
        id: 'txt-content-operation',
        text: t(`consent:Consent_Feature:Text10`),
    };

    const privacyLink = {
        id: 'link-tap-privacy',
        text: t(`consent:Consent_Feature:Text15`),
    };

    const checkbox = {
        id: 'checkbox-OperationDataConsent',
        label: t(`consent:Consent_Feature:Text2`),
    };

    const button = {
        id: 'btn-continue',
        label: t('consent:Consent_Feature:NextButton'),
    };

    return {
        header,
        consentImage,
        title,
        consentIntruction,
        privacyLink,
        checkbox,
        button,
    };
}

export default useConsentPageResource;
