import React from 'react';
import CustomButton from '../../../../SharedComponents/Button/CustomButton';
import AppContainer from '../../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../../SharedComponents/Container/BodyContainer';
import CustomImg from '../../../../SharedComponents/CustomImage/CustomImg';
import Header from '../../../../SharedComponents/Header/HeaderRefactor';
import CustomTypography, {
    TypographyType,
} from '../../../../SharedComponents/Typography/CustomTypography';
import useFineTuningAfterResetPageHandler from './FineTuningAfterResetPageHandlerHook';
import useFineTuningAfterResetPageResource from './FineTuningAfterResetPageResourceHook';
import '../FineTuningResetPage.scss';
import { ButtonType } from '../../../../SharedComponents/Button/ButtonType';

const FineTuningAfterResetPage = (): JSX.Element => {
    const { onDone } = useFineTuningAfterResetPageHandler();
    const { headerText, tickMarkImgSrc, afterResetText, buttonText } =
        useFineTuningAfterResetPageResource();
    return (
        <AppContainer>
            <Header
                headerContent={{ id: 'header-fine-tuning', text: headerText }}
            />
            <BodyContainer>
                <CustomImg
                    id={'img-tickMark'}
                    imgType={'iconLarge'}
                    src={tickMarkImgSrc}
                    alt={'tickMark'}
                />
                <CustomTypography
                    id={'txt-reset-afterReset'}
                    type={TypographyType.notoSansBody}
                    text={afterResetText}
                    className={'afterResetText'}
                />
            </BodyContainer>
            <CustomButton
                id={'btn-done'}
                buttonType={ButtonType.PrimaryLoading}
                onClick={onDone}
            >
                {buttonText}
            </CustomButton>
        </AppContainer>
    );
};

export default FineTuningAfterResetPage;
