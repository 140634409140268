import { TFunction } from 'i18next';
import { Side } from '../../Models/Side';

export function replaceSideWithText(
    t: TFunction,
    activeSide: Side,
    withColorMarking?: boolean
): { SIDE: string; LorR: string } {
    if (withColorMarking)
        return {
            SIDE: t(
                `pairing:Pairing_Feature:${
                    activeSide === 'left' ? 'Left' : 'Right'
                }Title`
            ).toLowerCase(),
            LorR: '',
        };
    return {
        SIDE: t(
            `common:Common_Feature:${activeSide === 'left' ? 'Left' : 'Right'}s`
        ).toLowerCase(),
        LorR: activeSide === 'left' ? 'L' : 'R',
    };
}
