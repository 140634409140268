import { useContext } from 'react';
import { MainViewContext } from '../../../../Pages/MainComponents/MainView/MainViewContext';
import { TransitionAction } from '../../../../Pages/MainComponents/MainView/MainViewModel';
import { WarningDialogHandler } from '../../Warning/WarningDialog';
import { RedoEasyFitContext, RedoEasyFitState } from '../RedoEasyFitContext';
import AudioService from '../../../../Services/AudioService';
import PatientManagementService from '../../../../Services/PatientManagementService';
import LocalStorageService from '../../../../Services/LocalStorageService';

function useRedoEasyFitDialogVibeGoHandler(): WarningDialogHandler {
    const viewContext = useContext(MainViewContext);
    const redoEasyFitContext = useContext(RedoEasyFitContext);

    const reset = async () => {
        await PatientManagementService.resetFactory();
        LocalStorageService.serviceInstance.clear(false);
        await PatientManagementService.getPatient();
        await AudioService.serviceInstance.initAudioContext();
    };

    const primaryAction = async () => {
        await reset();
        redoEasyFitContext.setRedoEasyFitState(RedoEasyFitState.OneSideSetUp);
    };

    const secondaryAction = () => {
        viewContext.doTransition(TransitionAction.SkipRedoEasyFit);
    };

    return {
        primaryAction,
        secondaryAction,
    };
}

export default useRedoEasyFitDialogVibeGoHandler;
