import { useContext, useEffect, useState } from 'react';
import LocalStorageService from '../../../Services/LocalStorageService';
import TelemetryService from '../../../Services/Monitoring/TelemetryService';
import PatientManagementService from '../../../Services/PatientManagementService';
import { WarningDialogHandler } from '../../../SharedComponents/Dialog/Warning/WarningDialog';
import CompletedWorkflowService, {
    Workflow,
} from '../../../Utils/CompletedWorkflow';
import { MainViewContext } from '../MainView/MainViewContext';
import { TransitionAction } from '../MainView/MainViewModel';
import { useHistory } from 'react-router-dom';

export type UserJourneyHandler = {
    useWarningDialogHandler: () => WarningDialogHandler;
    activeStep: number;
    warningDisplayed: boolean;
    showStartOver: () => void;
    onResume: () => Promise<void>;
    onClose: () => void;
};

function useUserJourneyHandler(): UserJourneyHandler {
    const history = useHistory();
    const [activeStep, setActiveState] = useState(0);
    const [warningDisplayed, setWarningDisplayed] = useState(false);
    const viewContext = useContext(MainViewContext);

    useEffect(() => {
        const updateStepper = () => {
            const workflowStates = [
                Workflow.DevicePairingLeft | Workflow.DevicePairingRight,
                Workflow.HLAATest,
                Workflow.Recommendation,
                Workflow.SpeechComfortTest,
            ];

            const completedState =
                LocalStorageService.serviceInstance.getCompletedWorkflow();

            for (let step = 0; step < workflowStates.length; step++) {
                if (
                    !CompletedWorkflowService.isCompleted(
                        completedState,
                        workflowStates[step]
                    )
                ) {
                    if (step > 0) setActiveState(step);
                    break;
                }

                if (step === workflowStates.length - 1) {
                    setActiveState(workflowStates.length);
                }
            }
        };
        updateStepper();
    }, [history]);

    const onStartOverSelected = async () => {
        let completedWorkflow = undefined;
        await PatientManagementService.resetUserJourney()
            .then(() => {
                return PatientManagementService.getCompletedWorkflow();
            })
            .then((value) => {
                completedWorkflow = value;
            });
        await PatientManagementService.getPatient();

        LocalStorageService.serviceInstance.setCompletedWorkflow(
            completedWorkflow !== undefined ? completedWorkflow : NaN
        );
        await onResume();
    };

    const onResume = async () => {
        viewContext.doTransition(TransitionAction.JourneyResume);
    };

    const showStartOver = () => {
        TelemetryService.serviceInstance.TrackWithIdentity(
            'EasyFit-FeatureUsage',
            {
                Feature: 'StartOver',
            }
        );
        setWarningDisplayed(true);
    };

    const onClose = () => {
        setWarningDisplayed(false);
    };

    const secondaryAction = () => {
        setWarningDisplayed(false);
    };

    const useWarningDialogHandler = () => {
        return {
            primaryAction: onStartOverSelected,
            secondaryAction,
        };
    };

    return {
        useWarningDialogHandler,
        activeStep,
        warningDisplayed,
        showStartOver,
        onResume,
        onClose,
    };
}

export default useUserJourneyHandler;
