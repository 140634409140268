import { CustomerServiceData } from '../../Models/CustomerServiceData/CustomerServiceData';
import { ContactDetails } from '../../Models/CustomerServiceData/ContactDetails';
import LocalStorageService from '../LocalStorageService';
import { getUserDeviceModel } from '../ResourceService';
import { deviceModel } from '../../Models/SupportedDeviceModel';
import { getNonMedicalResource } from '../NonMedicalService';
import { CustomerServiceGlobalData } from '../../Models/CustomerServiceData/CustomerServiceGlobalData';

export enum webSupportAnchorLink {
    VIBEGO = 'vibego',
    VIBECOMPLETE = 'vibecomplete',
    OTHERS = 'others',
}

type CustomerServiceInfoFile = {
    customerServiceCountryData: CustomerServiceData[];
    customerServiceGlobalData: CustomerServiceGlobalData;
};

export class CustomerServiceContactService {
    public static CountrySpecificsService = new CustomerServiceContactService();
    public static countryData: CustomerServiceData | undefined;
    private static customerServiceCountryData: CustomerServiceData[];
    private static customerServiceGlobalData: CustomerServiceGlobalData;

    public static async loadCustomerServiceCountryData(): Promise<void> {
        if (!this.customerServiceCountryData) {
            const resource = await getNonMedicalResource(
                'CustomerServiceContactService',
                'CommonAssets/Configuration/customerServiceInfo.json'
            );
            if (resource && resource.status < 300) {
                const output = resource.data as CustomerServiceInfoFile;
                this.customerServiceCountryData =
                    output.customerServiceCountryData;
                this.customerServiceGlobalData =
                    output.customerServiceGlobalData;
            }
        }
    }

    private static getCountrySpecificContactDetails():
        | CustomerServiceData
        | undefined {
        let countryData;

        const countryCode =
            LocalStorageService.serviceInstance.getPatientCountry();
        for (let i = 0; i < this.customerServiceCountryData.length; i++) {
            if (
                this.customerServiceCountryData[i].countryCode === countryCode
            ) {
                const contactDetails = new Array<ContactDetails>();

                for (
                    let o = 0;
                    o < this.customerServiceCountryData[i].contacts.length;
                    o++
                ) {
                    contactDetails.push(
                        new ContactDetails(
                            this.customerServiceCountryData[i].contacts[o].type,
                            this.customerServiceCountryData[i].contacts[
                                o
                            ].contact
                        )
                    );
                }

                this.countryData = new CustomerServiceData(
                    this.customerServiceCountryData[i].countryCode,
                    this.customerServiceCountryData[i].primaryContactType,
                    contactDetails
                );

                break;
            }
        }

        return countryData;
    }

    private static checkChangeCountryData() {
        if (
            this.countryData === undefined ||
            (this.countryData !== undefined &&
                this.countryData.countryCode !==
                    LocalStorageService.serviceInstance.getPatientCountry())
        ) {
            this.getCountrySpecificContactDetails();
        }
    }

    private static addAddressTag(
        display = true,
        contact: ContactDetails
    ): string {
        let addressTag = '';

        if (display === false) {
            switch (contact.type) {
                case 'phone':
                    addressTag = addressTag + 'tel:';
                    break;
                case 'email':
                    addressTag = addressTag + 'mailto:';
                    break;
                default:
                    break;
            }
        }

        return addressTag;
    }

    private static concatDeviceModel(
        contactDetails: string,
        anchorLink?: webSupportAnchorLink
    ): string {
        // CH only has https://www.vibe-hearing.ch/anleitung currently
        if (LocalStorageService.serviceInstance.getPatientCountry() === 'CH')
            return contactDetails;
        if (anchorLink) return `${contactDetails}#${anchorLink}`;
        switch (getUserDeviceModel()) {
            case deviceModel.VibeGo:
                return `${contactDetails}#${webSupportAnchorLink.VIBEGO}`;
            case deviceModel.VibeComplete:
                return `${contactDetails}#${webSupportAnchorLink.VIBECOMPLETE}`;
            default:
                return contactDetails;
        }
    }

    private static buildAddressString(
        contactType: string | undefined,
        display = true,
        anchorLink?: webSupportAnchorLink
    ): string {
        let contactDetails = '';

        if (contactType === 'globalWebSupport') {
            const primaryContact =
                this.customerServiceGlobalData.contacts.filter(
                    (contact: ContactDetails) => contact.type === 'webSupport'
                )[0].contact;
            if (display) {
                return this.buildMarkdownStyleContactDetails(
                    primaryContact,
                    'globalWebSupport'
                );
            } else {
                return primaryContact;
            }
        }

        if (this.countryData !== undefined) {
            for (const contact of this.countryData.contacts) {
                if (contact.type === contactType) {
                    contactDetails = display
                        ? this.buildMarkdownStyleContactDetails(
                              contact.contact,
                              contact.type,
                              anchorLink
                          )
                        : contact.type === 'webSupport'
                        ? this.concatDeviceModel(contact.contact, anchorLink)
                        : contact.contact;
                }
            }
        }

        return contactDetails;
    }

    private static buildMarkdownStyleContactDetails(
        contactDetails: string,
        contactType: string,
        anchorLink?: webSupportAnchorLink
    ): string {
        let markDownStyleContactDetails = contactDetails;
        switch (contactType) {
            case 'phone':
                markDownStyleContactDetails = `[${contactDetails}](<tel:${contactDetails}>)`;
                break;
            case 'email':
                markDownStyleContactDetails = `[${contactDetails}](mailto:${contactDetails})`;
                break;
            case 'webSupport':
                markDownStyleContactDetails = `[${contactDetails}](${this.concatDeviceModel(
                    contactDetails,
                    anchorLink
                )})`;
                break;
            case 'globalWebSupport':
                markDownStyleContactDetails = `[${contactDetails}](${contactDetails})`;
                break;
            default:
                break;
        }

        return markDownStyleContactDetails;
    }

    public static getCountrySpecificPrimaryContact(display = true): string {
        this.checkChangeCountryData();

        return this.buildAddressString(
            this.countryData?.primaryContactType,
            display
        );
    }

    public static getCountrySpecificPrimaryContactType(): string {
        this.checkChangeCountryData();

        return this.countryData?.primaryContactType ?? 'phone';
    }

    public static getCountrySpecificPhoneContact(display = true): string {
        this.checkChangeCountryData();

        return this.buildAddressString('phone', display);
    }

    public static getCountrySpecificEmailContact(display = true): string {
        this.checkChangeCountryData();

        return this.buildAddressString('email', display);
    }

    public static getCountrySpecificVibeHearingWebSupportAddress(
        display = true,
        anchorLink?: webSupportAnchorLink
    ): string {
        this.checkChangeCountryData();

        return this.buildAddressString('webSupport', display, anchorLink);
    }

    public static getGlobalWebSupportAddress(display = true): string {
        return this.buildAddressString('globalWebSupport', display);
    }

    public static getAllCountrySpecificCustomerServiceData(
        _Ignore = true
    ): CustomerServiceData | undefined {
        this.checkChangeCountryData();

        return this.countryData;
    }
}
