import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CarouselFooterContent } from '../../../SharedComponents/Carousel/CarouselFooter';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import { PairingCarouselResource } from '../PairingCarouselPage';
import { PairingContext } from '../PairingContext';
import { replaceSideWithText } from '../PairingUtils';
import pairingOneSideConfig from './PairingOneSideResource.json';

function usePairingOneSideResource(
    currentStep: number,
    openHyperLink: () => void
): PairingCarouselResource {
    const {
        state: { context },
    } = useContext(PairingContext);
    const { t } = useTranslation();

    const imgSrcList: string[] = [];
    const instructionList: CarouselFooterContent[] = [];
    //vibe air header prefix config
    const getSubHeaderNumber = (step: number) => {
        switch (step) {
            case 0:
                return 1;
            case 1:
                return 2;
            case 2:
            case 3:
                return 3;
            case 4:
            case 5:
                return 4;
            default:
                return 0;
        }
    };

    const header = {
        id: 'header-pairing-one-side',
        prefix: `1.${getSubHeaderNumber(currentStep)}`,
        text: t(
            `vibeAir:VibeAir_Device:Pairing_Feature:OneSide:Header:SubHeader${getSubHeaderNumber(
                currentStep
            )}`,
            replaceSideWithText(t, context.activeSide)
        ),
        type: HeaderActionType.Menu,
    };

    for (
        let index = 1;
        index <= pairingOneSideConfig.VibeAir.totalStep;
        index++
    ) {
        imgSrcList.push(
            `/resources/pairing/${context.activeSide}-paring-${index}.svg`
        );
        instructionList.push({
            instructionContent:
                index < 5
                    ? t(
                          `vibeAir:VibeAir_Device:Pairing_Feature:OneSide:Text${index}`,
                          replaceSideWithText(t, context.activeSide)
                      )
                    : '',
        });
    }

    const pairingButton = {
        id: `btn-pair-${context.activeSide}`,
        label: t('vibeAir:VibeAir_Device:Pairing_Feature:OneSide:NextButton'),
    };

    const help = {
        id: 'help-pairing-troubleshooting',
        text: t('vibeAir:VibeAir_Device:Pairing_Feature:OneSide:Text5'),
    };

    const linkCallBacks = {
        customerSupport: openHyperLink,
    };

    const title = {
        id: 'txt-title',
        text: t(
            `pairing:Pairing_Feature:${
                context.activeSide === 'left' ? 'Left' : 'Right'
            }Title`
        ),
    };

    return {
        header,
        imgSrcList,
        instructionList,
        button: pairingButton,
        help,
        title,
        linkCallBacks,
    };
}

export default usePairingOneSideResource;
