import { useContext } from 'react';
import { VoidReturn } from '../../../Utils/PageUtils';
import { PairingContext } from '../PairingContext';

export type PairingPrepareHandler = {
    onPrepareComplete: () => VoidReturn;
};

export default function usePairingPrepareHandler(): PairingPrepareHandler {
    const { send } = useContext(PairingContext);

    function onPrepareComplete() {
        send('completePrepare');
    }

    return {
        onPrepareComplete,
    };
}
