import React from 'react';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import AppContainer from '../../Container/AppContainer';
import BodyContainer from '../../Container/BodyContainer';
import CustomImg from '../../CustomImage/CustomImg';
import Header from '../../Header/HeaderRefactor';
import CustomTypography, {
    TypographyType,
} from '../../Typography/CustomTypography';
import CustomButton from '../../Button/CustomButton';
import { ButtonType } from '../../Button/ButtonType';
import { ButtonProps } from '@material-ui/core';
import CustomDialog from '../CustomDialog';
import usePageNotFoundDialogResource from './PageNotFoundDialogResourceHook';
import usePageNotFoundDialogHandler from './PageNotFoundDialogHandlerHook';
import './PageNotFoundDialog.scss';

function PageNotFoundDialog() {
    const { header, image, dialogTitle, dialogMessage, backButton } =
        usePageNotFoundDialogResource();

    const { backToVibeNow } = usePageNotFoundDialogHandler();

    const buttonConfig: ButtonProps = {
        id: backButton.id,
        onClick: backToVibeNow,
        children: backButton.label,
    };

    return (
        <CustomDialog fullScreen open={true}>
            <AppContainer>
                <Header headerContent={header} />
                <BodyContainer>
                    <CustomImg
                        imgType={'large'}
                        id={image.id}
                        src={image.src}
                        alt={image.alt}
                        className={'pageNotFoundImageArea'}
                    />
                    <CustomTypography
                        id={dialogTitle.id}
                        type={TypographyType.notoSansBodyBig}
                        text={dialogTitle.text}
                        className={'pageNotFoundTextArea'}
                    />
                    <CustomTypography
                        id={dialogMessage.id}
                        type={TypographyType.notoSansBody}
                        text={dialogMessage.text}
                        className={'pageNotFoundTextArea'}
                    />
                </BodyContainer>
                <CustomButton
                    {...buttonConfig}
                    buttonType={ButtonType.Primary}
                />
            </AppContainer>
        </CustomDialog>
    );
}

export default withTracking(PageNotFoundDialog);
