import React, { useContext, useState } from 'react';
import SpeechComfortTestStartPage from './Start/SpeechComfortTestStartPage';
import SpeechComfortTestDetailPage from './Detail/SpeechComfortTestDetailPage';
import { GainUIMapping } from '../../../Models/HearingProfile/GainUIMapping';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Redux/Reducers/RootReducer';
import IDeviceState from '../../../Redux/Models/Device/IDeviceState';
import {
    ISpeechComfortTestContext,
    ISpeechComfortTestInfo,
    SpeechComfortTestContext,
} from './SpeechComfortTestContext';
import { SpeechComfortTestState } from './SpeechComfortTestModels';
import { MainViewContext } from '../../MainComponents/MainView/MainViewContext';
import { TransitionAction } from '../../MainComponents/MainView/MainViewModel';
import { InitialSettings } from '../../../Models/HearingProfile/InitialSettings';
import DeviceManagementService from '../../../Services/DeviceManagementService';
import LocalStorageService from '../../../Services/LocalStorageService';
import TelemetryService from '../../../Services/Monitoring/TelemetryService';
import PatientManagementService from '../../../Services/PatientManagementService';

function SpeechComfortTestPage() {
    const deviceState = useSelector(
        (state: RootState) => state.device
    ) as IDeviceState;

    const viewContext = useContext(MainViewContext);
    const [info, setInfo] = useState<ISpeechComfortTestInfo>({
        masterGain: new GainUIMapping([]),
        deviceState: deviceState,
        speechComfortTestState: SpeechComfortTestState.Start,
        latestLeftValue: 0,
        latestRightValue: 0,
        loading: false,
    });

    const getMasterGainIdFromObject = (side: string, key: number) => {
        let masterGainIdForSide = 0;

        if (info.masterGain.uiToValueMap.get(side) !== undefined) {
            const masterGainUiLeftObject =
                info.masterGain.uiToValueMap.get(side);

            if (masterGainUiLeftObject !== undefined) {
                const masterGainUiLeftObjectValue =
                    masterGainUiLeftObject.get(key);

                if (masterGainUiLeftObjectValue !== undefined) {
                    masterGainIdForSide = masterGainUiLeftObjectValue;
                }
            }
        }

        return masterGainIdForSide;
    };

    const exitSpeechComfort = async () => {
        const patientId = LocalStorageService.serviceInstance.getPatientId();
        const leftDeviceId =
            LocalStorageService.serviceInstance.getDeviceId('Left');
        const rightDeviceId =
            LocalStorageService.serviceInstance.getDeviceId('Right');

        const speechComfort: InitialSettings = {
            patientId: patientId,
            settings: {
                [leftDeviceId]: getMasterGainIdFromObject(
                    'left',
                    info.latestLeftValue
                ),
                [rightDeviceId]: getMasterGainIdFromObject(
                    'right',
                    info.latestRightValue
                ),
            },
        };
        await DeviceManagementService.confirmInitialSettingsAreApplied(
            speechComfort
        );
        const workflow = await PatientManagementService.getCompletedWorkflow();
        LocalStorageService.serviceInstance.setCompletedWorkflow(
            Number(workflow)
        );

        TelemetryService.serviceInstance.TrackWithIdentity(
            'EasyFit-CompletedWorkflow',
            {
                Workflow: 'SpeechComfortTest',
            }
        );

        TelemetryService.serviceInstance.TrackWithIdentity(
            'EasyFit-Initial-MasterGain',
            {
                Left: getMasterGainIdFromObject(
                    'left',
                    info.latestLeftValue
                ).toString(),
            }
        );

        TelemetryService.serviceInstance.TrackWithIdentity(
            'EasyFit-Initial-MasterGain',
            {
                Right: getMasterGainIdFromObject(
                    'right',
                    info.latestRightValue
                ).toString(),
            }
        );

        viewContext.doTransition(TransitionAction.SpeechComfortContinue);
    };

    const initialValue: ISpeechComfortTestContext = {
        info: info,
        setSpeechComfortTestInfo: setInfo,
        exitSpeechComfort: exitSpeechComfort,
    };

    const renderSwitch = (state: SpeechComfortTestState) => {
        switch (state) {
            case SpeechComfortTestState.Start: {
                return <SpeechComfortTestStartPage />;
            }
            case SpeechComfortTestState.Detail: {
                return <SpeechComfortTestDetailPage />;
            }
            default: {
                return <></>;
            }
        }
    };

    return (
        <SpeechComfortTestContext.Provider value={initialValue}>
            {renderSwitch(info.speechComfortTestState)}
        </SpeechComfortTestContext.Provider>
    );
}

export default withTracking(SpeechComfortTestPage);
