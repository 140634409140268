import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import rootReducer from '../Reducers/RootReducer';

const middleware = [...getDefaultMiddleware()];

const easyFitStore = configureStore({
    reducer: rootReducer,
    middleware,
});

export default easyFitStore;
