import React, { useState } from 'react';
import LoggingService from '../../Services/LoggingService';
import { VoidReturn } from '../../Utils/PageUtils';
import { SliderStateType } from './CustomSlider';

export type SliderHandle = {
    sliderPos: number;
    loading: boolean;
    onClickAdjustButton: (value: number) => VoidReturn;
    handleOnChangeCommittedSlider: (value: number | number[]) => VoidReturn;
    handleOnChangeSlider: (value: number | number[]) => VoidReturn;
    handleSliderStart: (event: React.TouchEvent<HTMLElement>) => VoidReturn;
    handleSliderMove: (event: React.TouchEvent<HTMLElement>) => VoidReturn;
    handleSliderCancel: (event: React.TouchEvent<HTMLElement>) => VoidReturn;
};

function useCustomSliderHandler(
    handleSliderChange: (value: number) => void,
    handleSliderChangeCommitted: (value: number) => void,
    sliderState: SliderStateType,
    onSliderStart?: (event: React.TouchEvent<HTMLElement>) => void,
    handleSliderButtonClick?: (value: number) => void
): SliderHandle {
    const [loading, setLoading] = useState(false);

    const [sliderPos, setSliderPos] = useState(sliderState.initialPosition);

    const handleSliderChangeWithLoading = async (value: number) => {
        setLoading(true);
        await handleSliderChangeCommitted(value);
        setLoading(false);
    };

    const onClickAdjustButton = async (value: number) => {
        setLoading(true);
        let currentSliderPos = sliderPos + value;
        if (
            currentSliderPos >= sliderState.posMin &&
            currentSliderPos <= sliderState.posMax
        ) {
            LoggingService.info({
                args: [`adjust slider ${currentSliderPos}`],
            });
        } else if (
            currentSliderPos < sliderState.posMin &&
            sliderPos !== sliderState.posMin
        ) {
            currentSliderPos = sliderState.posMin;
        } else if (
            currentSliderPos > sliderState.posMax &&
            sliderPos !== sliderState.posMax
        ) {
            currentSliderPos = sliderState.posMax;
        } else {
            return;
        }
        if (handleSliderButtonClick)
            await handleSliderButtonClick(currentSliderPos);
        await handleSliderChangeWithLoading(currentSliderPos);
        setSliderPos(currentSliderPos);
        setLoading(false);
    };

    const handleOnChangeCommittedSlider = async (value: number | number[]) => {
        await handleSliderChangeWithLoading(Number(value));
    };

    const handleOnChangeSlider = async (value: number | number[]) => {
        setLoading(true);
        await handleSliderChange(Number(value));
        setSliderPos(Number(value));
        setLoading(false);
    };

    const handleSliderStart = (event: React.TouchEvent<HTMLElement>): void => {
        if (onSliderStart) {
            onSliderStart(event);
        }
        event.preventDefault();
    };
    const handleSliderMove = (event: React.TouchEvent<HTMLElement>): void => {
        event.preventDefault();
    };
    const handleSliderCancel = (event: React.TouchEvent<HTMLElement>): void => {
        event.preventDefault();
    };

    return {
        sliderPos,
        loading,
        onClickAdjustButton,
        handleOnChangeCommittedSlider,
        handleOnChangeSlider,
        handleSliderStart,
        handleSliderMove,
        handleSliderCancel,
    };
}

export default useCustomSliderHandler;
