import { useState } from 'react';
import HLAAService from '../../Services/HLAAService';
import LocalStorageService from '../../Services/LocalStorageService';
import LoggingService from '../../Services/LoggingService';
import TelemetryService from '../../Services/Monitoring/TelemetryService';
import { getUserDeviceModel } from '../../Services/ResourceService';
import { VoidReturn } from '../../Utils/PageUtils';
import { ScoringCheckPageState } from './AudiogramScoringCheckModels';
import AudiogramScoringCheckPage from './AudiogramScoringCheckPage';

export type AudiogramScoringCheckHandle = {
    initialize: () => VoidReturn;
    leftRightDifference: boolean;
    audiogramResultSevere: boolean;
    pageState: ScoringCheckPageState;
};

export default function useAudiogramScoringCheckHandler(): AudiogramScoringCheckHandle {
    const [pageState, setPageState] = useState(ScoringCheckPageState.Loading);
    const [leftRightDifference, setLeftRightDifference] = useState(false);
    const [audiogramResultSevere, setAudiogramResultSevere] = useState(false);

    const initialize = async () => {
        const hlaaTestId =
            LocalStorageService.serviceInstance.getLastCompletedHlaaTestId();

        const audiogramHlaaResult = await HLAAService.getResult(hlaaTestId);
        if (audiogramHlaaResult !== undefined) {
            setLeftRightDifference(audiogramHlaaResult.leftRightMismatch);
            setAudiogramResultSevere(audiogramHlaaResult.severe);
            audiogramHlaaResult.severe
                ? setPageState(ScoringCheckPageState.Alert)
                : setPageState(ScoringCheckPageState.Results);

            LoggingService.log({
                componentName: AudiogramScoringCheckPage.name,
                args: [
                    `hlaa results: left=${audiogramHlaaResult.left}, right=${audiogramHlaaResult.right}`,
                ],
            });

            LoggingService.info({
                componentName: AudiogramScoringCheckPage.name,
                args: [
                    `Audiogram mismatch for left and right: ${audiogramHlaaResult.leftRightMismatch}`,
                ],
            });

            LoggingService.log({
                componentName: AudiogramScoringCheckPage.name,
                args: [
                    `hlaa audiogram index: left=${JSON.stringify(
                        audiogramHlaaResult.leftAudiogramIndex
                    )}, right=${JSON.stringify(
                        audiogramHlaaResult.rightAudiogramIndex
                    )}`,
                ],
            });

            TelemetryService.serviceInstance.TrackWithIdentity(
                'EasyFit-Audiogram-Left',
                {
                    Model: getUserDeviceModel(),
                    Freq1: audiogramHlaaResult.leftAudiogramIndex[0].toString(),
                    Freq2: audiogramHlaaResult.leftAudiogramIndex[1].toString(),
                    Freq3: audiogramHlaaResult.leftAudiogramIndex[2].toString(),
                    Freq4: audiogramHlaaResult.leftAudiogramIndex[3].toString(),
                }
            );

            TelemetryService.serviceInstance.TrackWithIdentity(
                'EasyFit-Audiogram-Right',
                {
                    Model: getUserDeviceModel(),
                    Freq1: audiogramHlaaResult.rightAudiogramIndex[0].toString(),
                    Freq2: audiogramHlaaResult.rightAudiogramIndex[1].toString(),
                    Freq3: audiogramHlaaResult.rightAudiogramIndex[2].toString(),
                    Freq4: audiogramHlaaResult.rightAudiogramIndex[3].toString(),
                }
            );

            if (audiogramHlaaResult.leftRightMismatch) {
                TelemetryService.serviceInstance.TrackWithIdentity(
                    'EasyFit-UserIssue',
                    {
                        Issue: 'HearingProfileLeftAndRightIsVeryDifferent',
                    }
                );
            }

            if (audiogramHlaaResult.severe) {
                TelemetryService.serviceInstance.TrackWithIdentity(
                    'EasyFit-UserIssue',
                    {
                        Issue: 'HIDoesNotMatchHearingProfile',
                    }
                );
            }
        }
    };

    return {
        initialize: initialize,
        leftRightDifference: leftRightDifference,
        audiogramResultSevere: audiogramResultSevere,
        pageState: pageState,
    };
}
