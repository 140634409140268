import { MutableRefObject, useCallback, useEffect, useRef } from 'react';
import DeviceManagementService from '../../Services/DeviceManagementService';
import FineTuningService from '../../Services/FineTuningService';
import LocalStorageService from '../../Services/LocalStorageService';
import LoggingService from '../../Services/LoggingService';
import PatientManagementService from '../../Services/PatientManagementService';
import useLoading from '../../SharedComponents/Spinner/PageLoadSpinner';
import { Workflow } from '../../Utils/CompletedWorkflow';
import { IFineTuningInfo } from './FineTuningContext';
import { FineTuningSend } from './FineTuningModels';
import { getCouplingOrDefault } from '../../Utils/CouplingUtils';

export interface IFineTuningPageHandlerProps {
    info: MutableRefObject<IFineTuningInfo>;
    send: FineTuningSend;
}

export type FineTuningPageHandler = {
    handleStartFineTuning: () => Promise<void>;
    spinner: JSX.Element;
    loading: boolean;
};

const useFineTuningPageHandler = (
    props: IFineTuningPageHandlerProps
): FineTuningPageHandler => {
    const isComponentMounted = useRef(true);
    const [spinner, setLoading, loading] = useLoading();

    const onStartFineTuning = useCallback(async () => {
        const [workflow] = await Promise.all([
            LocalStorageService.serviceInstance.getCompletedWorkflow(),
            getSupportedCategories(),
        ]);

        if (
            workflow !== undefined &&
            (workflow & Workflow.FineTuningAssistant) ===
                Workflow.FineTuningAssistant
        ) {
            props.info.current.isResetDisabled = false;
        } else {
            props.info.current.isResetDisabled = true;
        }

        setLoading(false);
        props.send('goToStart');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const initialization = async () => {
            await setHearingProfileIdIntoCookies();
            await startFineTuning();
            onStartFineTuning();
        };
        (async () => {
            if (isComponentMounted.current) {
                await initialization();
            }
        })();
        return () => {
            isComponentMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onStartFineTuning]);

    const getSupportedCategories = async () => {
        props.info.current.supportedCategoryList =
            await FineTuningService.getCategoryAndAttributeOption(
                LocalStorageService.serviceInstance.getPatientId(),
                LocalStorageService.serviceInstance.getActiveHearingProfileId()
            );
    };

    const setHearingProfileIdIntoCookies = async () => {
        const patient = await PatientManagementService.getPatient();

        if (patient === undefined) return;

        LocalStorageService.serviceInstance.setActiveHearingProfileId(
            patient.activeHearingProfileId
        );
    };

    const startFineTuning = async () => {
        const leftDeviceId =
            LocalStorageService.serviceInstance.getDeviceId('left');
        const rightDeviceId =
            LocalStorageService.serviceInstance.getDeviceId('right');

        const [leftFineTuningInput, rightFineTuningInput] = await Promise.all([
            await DeviceManagementService.getFineTuningInput(leftDeviceId),
            await DeviceManagementService.getFineTuningInput(rightDeviceId),
        ]);

        LoggingService.log({
            componentName: startFineTuning.name,
            args: [
                `Left device info:\nMaster gain ${leftFineTuningInput.deviceMasterGain}\n
            Band Equalizer Gain 1 ${leftFineTuningInput.band1EqualizerGain}\n
            Band Equalizer Gain 2 ${leftFineTuningInput.band2EqualizerGain}\n
            Band Equalizer Gain 3 ${leftFineTuningInput.band3EqualizerGain}\n
            Band Equalizer Gain 4 ${leftFineTuningInput.band4EqualizerGain}`,
            ],
        });
        LoggingService.log({
            componentName: startFineTuning.name,
            args: [
                `Right device info:\nMaster gain ${rightFineTuningInput.deviceMasterGain}\n
            Band Equalizer Gain 1 ${rightFineTuningInput.band1EqualizerGain}\n
            Band Equalizer Gain 2 ${rightFineTuningInput.band2EqualizerGain}\n
            Band Equalizer Gain 3 ${rightFineTuningInput.band3EqualizerGain}\n
            Band Equalizer Gain 4 ${rightFineTuningInput.band4EqualizerGain} `,
            ],
        });

        props.info.current.deviceState[leftDeviceId] = {
            easyfitMasterGain: leftFineTuningInput.deviceMasterGain,
            band1EqualizerGain: leftFineTuningInput.band1EqualizerGain,
            band2EqualizerGain: leftFineTuningInput.band2EqualizerGain,
            band3EqualizerGain: leftFineTuningInput.band3EqualizerGain,
            band4EqualizerGain: leftFineTuningInput.band4EqualizerGain,
        };
        props.info.current.deviceState[rightDeviceId] = {
            easyfitMasterGain: rightFineTuningInput.deviceMasterGain,
            band1EqualizerGain: rightFineTuningInput.band1EqualizerGain,
            band2EqualizerGain: rightFineTuningInput.band2EqualizerGain,
            band3EqualizerGain: rightFineTuningInput.band3EqualizerGain,
            band4EqualizerGain: rightFineTuningInput.band4EqualizerGain,
        };

        props.info.current.defaultMasterGain = {
            left: leftFineTuningInput.defaultMasterGain,
            right: rightFineTuningInput.defaultMasterGain,
        };

        await Promise.all([
            await FineTuningService.startFineTuning(
                LocalStorageService.serviceInstance.getPatientId(),
                LocalStorageService.serviceInstance.getActiveHearingProfileId(),
                LocalStorageService.serviceInstance.getDeviceModelId('left'),
                props.info.current.deviceState,
                getCouplingOrDefault('left') === -1
                    ? 0
                    : getCouplingOrDefault('left')
            ),
        ]);
    };

    const handleStartFineTuning = async () => {
        props.info.current.selected = {
            category: '-1',
            problem: '-1',
        };
        await startFineTuning();
        onStartFineTuning();
    };

    return {
        handleStartFineTuning,
        spinner,
        loading,
    };
};

export default useFineTuningPageHandler;
