export default class ArcToneService {
    public static getInitialPairing(
        brandId: number,
        pairingAddress: number
    ): string {
        return (
            process.env.REACT_APP_EASYFIT_API_URL +
            `/arc/ArcTone/InitialPairing?brandId=${brandId}&pairingAddress=${pairingAddress}`
        );
    }

    public static getStartAssessment(
        brandId: number,
        pairingAddress: number
    ): string {
        return (
            process.env.REACT_APP_EASYFIT_API_URL +
            `/arc/ArcTone/StartAssessment?brandId=${brandId}&pairingAddress=${pairingAddress}`
        );
    }

    public static hlaaInitialize(
        brandId: number,
        pairingAddress: number,
        freqId: number
    ): string {
        return (
            process.env.REACT_APP_EASYFIT_API_URL +
            `/arc/ArcTone/HLAAInitialize?brandId=${brandId}&pairingAddress=${pairingAddress}&freqId=${freqId}`
        );
    }

    public static setCoupling(
        brandId: number,
        pairingAddress: number,
        couplingId: number
    ): string {
        return (
            process.env.REACT_APP_EASYFIT_API_URL +
            `/arc/ArcTone/SetCoupling?brandId=${brandId}&pairingAddress=${pairingAddress}&couplingId=${couplingId}`
        );
    }

    public static setFrequency(
        brandId: number,
        pairingAddress: number,
        frequencyId: number
    ): string {
        return (
            process.env.REACT_APP_EASYFIT_API_URL +
            `/arc/ArcTone/SetFrequency?brandId=${brandId}&pairingAddress=${pairingAddress}&frequencyId=${frequencyId}`
        );
    }

    public static muteDevice(brandId: number, pairingAddress: number): string {
        return (
            process.env.REACT_APP_EASYFIT_API_URL +
            `/arc/ArcTone/MuteDevice?brandId=${brandId}&pairingAddress=${pairingAddress}`
        );
    }

    public static unmuteDevice(
        brandId: number,
        pairingAddress: number
    ): string {
        return (
            process.env.REACT_APP_EASYFIT_API_URL +
            `/arc/ArcTone/UnMuteDevice?brandId=${brandId}&pairingAddress=${pairingAddress}`
        );
    }

    public static getPlayToneSequence(
        brandId: number,
        pairingAddress: number,
        playToneSequenceId: number
    ): string {
        return (
            process.env.REACT_APP_EASYFIT_API_URL +
            `/arc/ArcTone/PlayToneSequence?brandId=${brandId}&pairingAddress=${pairingAddress}&playToneSequenceId=${playToneSequenceId}`
        );
    }

    public static getExitAssessment(
        brandId: number,
        pairingAddress: number
    ): string {
        return (
            process.env.REACT_APP_EASYFIT_API_URL +
            `/arc/ArcTone/ExitAssessment?brandId=${brandId}&pairingAddress=${pairingAddress}`
        );
    }

    public static getKeepHLAAModeAlive(
        brandId: number,
        pairingAddress: number
    ): string {
        return (
            process.env.REACT_APP_EASYFIT_API_URL +
            `/arc/ArcTone/KeepAlive?brandId=${brandId}&pairingAddress=${pairingAddress}`
        );
    }

    public static getClusterToneSequence(
        brandId: number,
        pairingAddress: number,
        clusterId: number
    ): string {
        return (
            process.env.REACT_APP_EASYFIT_API_URL +
            `/arc/ArcTone/ClusterToneSequence?brandId=${brandId}&pairingAddress=${pairingAddress}&clusterId=${clusterId}`
        );
    }

    public static getVolumeGain(
        brandId: number,
        pairingAddress: number,
        volumeGainId: number
    ): string {
        return (
            process.env.REACT_APP_EASYFIT_API_URL +
            `/arc/ArcTone/VolumeTone?brandId=${brandId}&pairingAddress=${pairingAddress}&volumeId=${volumeGainId}`
        );
    }

    public static getMasterGain(
        brandId: number,
        pairingAddress: number,
        masterGainId: number
    ): string {
        return (
            process.env.REACT_APP_EASYFIT_API_URL +
            `/arc/ArcTone/AbsoluteMasterGain?brandId=${brandId}&pairingAddress=${pairingAddress}&masterGainId=${masterGainId}`
        );
    }

    public static resetEasyFitControls(
        brandId: number,
        pairingAddress: number
    ): string {
        return (
            process.env.REACT_APP_EASYFIT_API_URL +
            `/arc/ArcTone/ResetEasyFitControls?brandId=${brandId}&pairingAddress=${pairingAddress}`
        );
    }

    public static getBandEqualizerGain(
        brandId: number,
        pairingAddress: number,
        band1Gain: number,
        band2Gain: number,
        band3Gain: number,
        band4Gain: number
    ): string {
        return (
            process.env.REACT_APP_EASYFIT_API_URL +
            `/arc/ArcTone/GetBandEqualizerGain?brandId=${brandId}&pairingAddress=${pairingAddress}&band1Gain=${band1Gain}&band2Gain=${band2Gain}&band3Gain=${band3Gain}&band4Gain=${band4Gain}`
        );
    }

    public static getResetFactory(
        brandId: number,
        pairingAddress: number
    ): string {
        return (
            process.env.REACT_APP_EASYFIT_API_URL +
            `/arc/ArcTone/GetResetFactory?brandId=${brandId}&pairingAddress=${pairingAddress}`
        );
    }
}
