import React, { useContext, useEffect } from 'react';
import { MainViewContext } from '../../../Pages/MainComponents/MainView/MainViewContext';
import useGlobalDialogHandler from './GlobalDialogHandlerHook';
import useGlobalDialogRender from './GlobalDialogRenderHook';
import useGlobalDialogResource from './GlobalDialogResourceHook';
import useLeaveAppDialogResource from './LeaveAppDialog/LeaveAppDialogResourceHook';

function GlobalDialog(): React.ReactElement {
    const {
        pairingConnectionLostHandler,
        accountDeletedHandler,
        deviceInfoCheckHandler,
        upversionAnalyticConsentHandler,
        updateDisplay,
        leaveAppHandler,
    } = useGlobalDialogHandler();
    const usePairingConnectionLostResource = useGlobalDialogResource;
    const viewContext = useContext(MainViewContext);
    const {
        renderPairingConnectionLostDialog,
        renderAccountDeletedDialog,
        renderDeviceInfoCheckDialog,
        renderUpversionAnalyticConsentDialog,
        renderLeaveAppDialog,
    } = useGlobalDialogRender({
        pairingConnectionLostHandler,
        usePairingConnectionLostResource,
        accountDeletedHandler,
        deviceInfoCheckHandler,
        upversionAnalyticConsentHandler,
        leaveAppHandler,
        useLeaveAppDialogResource,
    });

    useEffect(() => {
        updateDisplay(viewContext.dialogState);
    }, [updateDisplay, viewContext.dialogState]);

    return (
        <>
            {renderAccountDeletedDialog()}
            {renderPairingConnectionLostDialog()}
            {renderDeviceInfoCheckDialog()}
            {renderUpversionAnalyticConsentDialog()}
            {renderLeaveAppDialog()}
        </>
    );
}

export default GlobalDialog;
