import React, { useContext, useRef, useState } from 'react';
import { MainViewContext } from '../../../Pages/MainComponents/MainView/MainViewContext';
import { TransitionAction } from '../../../Pages/MainComponents/MainView/MainViewModel';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import { MenuDialogType } from '../Menu/MenuDialogType';
import RedoEasyFitBeepConfirmation from './BeepConfirmation/RedoEasyFitBeepConfirmation';
import RedoEasyFitDialog from './Dialog/RedoEasyFitDialog';
import RedoEasyFitOneSideSetUp from './OneSideSetUp/RedoEasyFitOneSideSetUp';
import {
    infoType,
    IRedoEasyFitContext,
    RedoEasyFitContext,
    RedoEasyFitState,
} from './RedoEasyFitContext';
import RedoEasyFitTroubleShooting from './TroubleShooting/RedoEasyFitTroubleShooting';

function RedoEasyFitPage() {
    const [redoEasyFitState, setRedoEasyFitState] = useState<RedoEasyFitState>(
        RedoEasyFitState.Start
    );
    const viewContext = useContext(MainViewContext);

    const createContextInfo = (): infoType => {
        return {
            activeSide: 'left',
            totalPageCount: 0,
            totalPagesForProgress: 4,
        };
    };

    const info = useRef<infoType>(createContextInfo());

    const initialValue: IRedoEasyFitContext = {
        info: info,
        redoEasyFitState: redoEasyFitState,
        setRedoEasyFitState: setRedoEasyFitState,
    };

    const renderSwitch = (state: RedoEasyFitState) => {
        switch (state) {
            case RedoEasyFitState.Start: {
                return (
                    <RedoEasyFitDialog
                        onClose={() =>
                            viewContext.doTransition(
                                TransitionAction.SkipRedoEasyFit
                            )
                        }
                        key={MenuDialogType.RedoEasyFit}
                    />
                );
            }
            case RedoEasyFitState.OneSideSetUp: {
                return <RedoEasyFitOneSideSetUp />;
            }
            case RedoEasyFitState.BeepConfirmation: {
                return <RedoEasyFitBeepConfirmation />;
            }
            case RedoEasyFitState.TroubleShooting: {
                return <RedoEasyFitTroubleShooting />;
            }
        }
    };

    return (
        <RedoEasyFitContext.Provider value={initialValue}>
            {renderSwitch(redoEasyFitState)}
        </RedoEasyFitContext.Provider>
    );
}

export default withTracking(RedoEasyFitPage);
