import { useContext, useState } from 'react';
import { VoidReturn } from '../../../Utils/PageUtils';
import { MainViewContext } from '../../MainComponents/MainView/MainViewContext';
import { TransitionAction } from '../../MainComponents/MainView/MainViewModel';

export type CompleteRecommendationHandler = {
    openDowndloadAppDialog: () => VoidReturn;
    handleCloseDownloadDialog: () => VoidReturn;
    handleCompleteRecommendationContinue: () => void;
    dialogDisplayState: boolean;
};

function useCompleteRecommendationHandler(): CompleteRecommendationHandler {
    const [dialogDisplayState, setDialogDisplayState] = useState(false);
    const { doTransition } = useContext(MainViewContext);

    const openDowndloadAppDialog = () => {
        setDialogDisplayState(true);
    };

    const handleCloseDownloadDialog = () => {
        setDialogDisplayState(false);
    };

    const handleCompleteRecommendationContinue = () => {
        doTransition(TransitionAction.CompleteRecommendationContinue);
    };
    return {
        openDowndloadAppDialog,
        handleCloseDownloadDialog,
        handleCompleteRecommendationContinue,
        dialogDisplayState,
    };
}

export default useCompleteRecommendationHandler;
