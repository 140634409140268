import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './InitialState';

const setupSlice = createSlice({
    name: 'setupSlice',
    initialState: initialState.setupState,
    reducers: {
        updateSetup: (state, action) => {
            return action.payload;
        },
    },
});

export const { updateSetup } = setupSlice.actions;

export const setupReducer = setupSlice.reducer;
