import React from 'react';
import useAudioRoutingPageResource from './AudioRoutingPageResourceHook';
import useSpeechComfortTestStartPageHandler from '../Start/SpeechComfortTestStartPageHandlerHook';
import { ButtonType } from '../../../../SharedComponents/Button/ButtonType';
import CustomButton from '../../../../SharedComponents/Button/CustomButton';
import AppContainer from '../../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../../SharedComponents/Container/BodyContainer';
import CustomImg from '../../../../SharedComponents/CustomImage/CustomImg';
import Header from '../../../../SharedComponents/Header/HeaderRefactor';
import './AudioRoutingPage.scss';
import BulletList from '../../../../SharedComponents/List/BulletList';

export default function AudioRoutingPage(): React.ReactElement {
    const { goToDetailPage } = useSpeechComfortTestStartPageHandler();
    const { header, image, instructionText, button } =
        useAudioRoutingPageResource();
    return (
        <AppContainer>
            <Header headerContent={header} />
            <BodyContainer>
                <CustomImg
                    imgType="small"
                    id={image.id}
                    src={image.src}
                    alt={image.alt}
                    className={'img-audio-routing'}
                />
                <BulletList
                    id={instructionText.id}
                    bulletItems={instructionText.text
                        .split('\n')
                        .filter(Boolean)}
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                />
            </BodyContainer>
            <CustomButton
                buttonType={ButtonType.PrimaryLoading}
                id={button.id}
                onClick={async () => {
                    await goToDetailPage();
                }}
            >
                {button.label}
            </CustomButton>
        </AppContainer>
    );
}
