import { Patient } from '../../../Models/Patient/Patient';
import { SupportedCountries } from '../../../Models/SupportedCountries';
import CountryConsentConfigService from '../../../Services/Configuration/CountryConsentConfigService';
import LocalStorageService from '../../../Services/LocalStorageService';
import LoggingService from '../../../Services/LoggingService';
import PatientManagementService from '../../../Services/PatientManagementService';
import { checkPairingStatus } from '../../../Services/ResourceService';
import CompletedWorkflowService from '../../../Utils/CompletedWorkflow';
import { goToUrl } from '../../../Utils/NavigationUtils';
import {
    DialogState,
    ITransitionHandler,
    PageState,
    TransitionAction,
    ViewState,
} from './MainViewModel';
import {
    handleActionError,
    validatePreviousState,
    checkAndGetNotCompletedWorkflowNextPage,
    supportedPageStateQueryParamsAppFitting,
} from './TransitionHandlerUtils';
import {
    isBluetoothDevice,
    mapEncodedModelIdToDefault,
} from '../../../Utils/BluetoothDeviceUtils';
import {
    getCouplingOrDefault,
    setCouplingIfRequired,
} from '../../../Utils/CouplingUtils';
import { DeviceRepairingState } from '../../../Models/DeviceRepairingState';
import { getActiveConsentVersion } from '../../../Utils/ConsentVersionUtils';

function useAppFittingTransitionHandler(): ITransitionHandler {
    const appExit = (status: string) => {
        const magicUrl = `appexit:fitting/${status}`;
        goToUrl(magicUrl);
    };

    const getDialogOnPatientState = (patient: Patient): DialogState => {
        let dialog = DialogState.None;
        if (patient.deleteOperationDataInProcess) {
            dialog = DialogState.AccountDeleted;
        } else if (patient.privacyNotesVersion !== getActiveConsentVersion()) {
            dialog = DialogState.UpversionAnalyticConsent;
        }

        return dialog;
    };

    const validateParams = async (patient: Patient | undefined) => {
        // Sanitization of URL params
        const params = new URLSearchParams(location.search);
        const platformType = params.get('platformId');
        const leftModel = mapEncodedModelIdToDefault(
            params.get('leftModel') as string,
            platformType as string
        );
        const rightModel = mapEncodedModelIdToDefault(
            params.get('rightModel') as string,
            platformType as string
        );
        const leftArc = params.get('leftArc')
            ? Number(params.get('leftArc'))
            : NaN;
        const rightArc = params.get('rightArc')
            ? Number(params.get('rightArc'))
            : NaN;

        if (
            !isBluetoothDevice(leftModel as string) ||
            !isBluetoothDevice(rightModel as string)
        ) {
            LoggingService.error({
                componentName: 'URLParamsValidation',
                args: [`Not a valid bluetooth device, ${location.search}`],
            });
            return false;
        }

        if (leftModel !== rightModel) {
            LoggingService.error({
                componentName: 'URLParamsValidation',
                args: [`Different model ids, ${location.search}`],
            });
            return false;
        }

        if (
            isNaN(leftArc) ||
            isNaN(rightArc) ||
            leftArc < 0 ||
            leftArc > 15 ||
            rightArc < 0 ||
            rightArc > 15
        ) {
            LoggingService.error({
                componentName: 'URLParamsValidation',
                args: [`Invalid pairing addresses, ${location.search}`],
            });
            return false;
        }

        if (!patient) {
            // set devices called in consent analytic workflow
            LocalStorageService.serviceInstance.setQueryParams(
                params.toString()
            );
        } else {
            // if device models have changed, backend code will reset the devices and workflow
            // if devices models have not changed, backend code will reset pairing addresses

            const devices = [
                {
                    side: 'Left',
                    brandId: 15,
                    modelId: leftModel as string,
                    pairingAddress: leftArc,
                    couplingId: getCouplingOrDefault(
                        'left',
                        leftModel as string
                    ),
                },
                {
                    side: 'Right',
                    brandId: 15,
                    modelId: rightModel as string,
                    pairingAddress: rightArc,
                    couplingId: getCouplingOrDefault(
                        'right',
                        rightModel as string
                    ),
                },
            ];
            await PatientManagementService.SetDevices(devices);
        }

        return true;
    };

    const doTransition = (
        currentView: ViewState,
        action: TransitionAction,
        patient: Patient | undefined
    ): ViewState => {
        const currentPage = currentView.page;
        let nextPage = PageState.None;
        let nextDialog = currentView.dialog;

        // TODO: error handling when patient is undefined
        if (patient) {
            nextDialog = getDialogOnPatientState(patient);
        }

        switch (action) {
            case TransitionAction.CountrySelectionContinue:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.CountrySelection
                );
                nextPage = PageState.NewWelcome;
                break;
            case TransitionAction.WelcomeContinue:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.NewWelcome,
                    PageState.RestartWelcome,
                    PageState.RedoAssessmentOption
                );
                switch (currentPage) {
                    case PageState.NewWelcome: {
                        const country =
                            LocalStorageService.serviceInstance.getSelectedCountry();
                        const enumValue =
                            SupportedCountries[
                                country as keyof typeof SupportedCountries
                            ];
                        const countryConsentConfig =
                            CountryConsentConfigService.getCountryConfig(
                                enumValue
                            );
                        const isOperationDataConsentRequired =
                            countryConsentConfig.operationalDataConsent;
                        const isOperationDataConsented =
                            LocalStorageService.serviceInstance.getOperationConsentState();
                        const analyticConsented =
                            LocalStorageService.serviceInstance.getDataAnalyticConsentState();

                        if (isOperationDataConsentRequired) {
                            if (isOperationDataConsented) {
                                if (!analyticConsented)
                                    nextPage = PageState.ConsentAnalytic;
                                else {
                                    nextPage = PageState.Contraindication;
                                }
                            } else {
                                nextPage = PageState.Consent;
                            }
                        } else {
                            if (
                                !countryConsentConfig.analyticalDataConsent ||
                                analyticConsented
                            ) {
                                nextPage = PageState.Contraindication;
                            } else {
                                nextPage = PageState.ConsentAnalytic;
                            }
                        }
                        break;
                    }
                    case PageState.RestartWelcome:
                    case PageState.RedoAssessmentOption:
                        nextPage = PageState.Contraindication;
                        break;
                    default:
                        // Do nothing. Error logging handled by validatePreviousState
                        break;
                }
                break;
            case TransitionAction.JourneyResume: {
                validatePreviousState(currentPage, action, PageState.Journey);
                const resumeState =
                    LocalStorageService.serviceInstance.getCompletedWorkflow();
                nextPage =
                    CompletedWorkflowService.getNextMandatoryWorkflow(
                        resumeState
                    );
                break;
            }
            case TransitionAction.HiAssembleContinue:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.HiAssemble
                );
                nextPage = PageState.Journey;
                break;
            case TransitionAction.ConsentContinue:
                // validatePreviousState(currentPage, PageState.Journey); TODO: ???
                nextPage = PageState.ConsentAnalytic;
                break;
            case TransitionAction.AnalyticsConsentContinue:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.ConsentAnalytic
                );
                nextPage = PageState.Contraindication;
                break;
            case TransitionAction.AnalyticsConsentDialogContinue: {
                const resumeState =
                    LocalStorageService.serviceInstance.getCompletedWorkflow();
                nextPage = checkAndGetNotCompletedWorkflowNextPage(
                    resumeState,
                    PageState.CompleteRecommendation,
                    PageState.Journey
                );
                nextDialog = DialogState.None;
                break;
            }
            case TransitionAction.ContraindicationContinue:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.Contraindication
                );
                nextPage = PageState.HiAssemble;
                break;
            case TransitionAction.PairingContinue: {
                validatePreviousState(currentPage, action, PageState.Pairing);
                nextPage = CompletedWorkflowService.showSleeveSelection()
                    ? PageState.SleeveSelection
                    : PageState.Assessment;
                break;
            }
            case TransitionAction.AssessmentContinue:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.Assessment
                );
                nextPage = PageState.InitialSettings; // don't move pages.
                break;
            case TransitionAction.AssessmentZeroTone:
                nextPage = handleActionError(currentPage, action);
                break;
            case TransitionAction.InitialSettingsContinue:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.InitialSettings
                );
                nextPage = PageState.SpeechComfort;
                break;
            case TransitionAction.InitialSettingsSevereLoss:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.InitialSettings
                );
                appExit('fail/severe');
                nextPage = currentPage; // don't move pages.
                break;
            case TransitionAction.InitialSettingsMismatchedLoss:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.InitialSettings
                );
                appExit('fail/mismatched');
                nextPage = currentPage; // don't move pages.
                break;
            case TransitionAction.SpeechComfortContinue:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.SpeechComfort
                );
                nextPage = PageState.CompleteRecommendation;
                break;
            case TransitionAction.CompleteRecommendationContinue:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.CompleteRecommendation
                );
                appExit('success/complete');
                nextPage = currentPage;
                break;
            case TransitionAction.DoRedoEasyFit:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.RedoAssessmentOption
                );
                nextPage = PageState.RedoAssessmentOption;
                break;
            case TransitionAction.DoRedoHLAA: {
                const devicePairingState =
                    LocalStorageService.serviceInstance.getDevicePairingRequired();
                nextDialog = DialogState.None;
                if (
                    devicePairingState ===
                    DeviceRepairingState.NoAction.toString()
                ) {
                    nextPage = PageState.Assessment;
                } else {
                    nextPage = PageState.DeviceIncompatibleError;
                }
                break;
            }
            case TransitionAction.GoToAssessment:
                nextDialog = DialogState.None;
                nextPage = PageState.Assessment;
                break;
            case TransitionAction.SkipRedoEasyFit:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.RedoAssessmentOption
                );
                appExit('success/skip');
                nextPage = currentPage;
                break;
            case TransitionAction.SkipRedoHLAA:
                validatePreviousState(currentPage, action, PageState.RedoHLAA);
                appExit('success/skip');
                nextPage = currentPage;
                break;
            case TransitionAction.GoRedoLoudness:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.CompleteRecommendation,
                    PageState.RedoLoudness
                );
                nextPage = PageState.RedoLoudness;
                break;
            case TransitionAction.RedoLoudnessContinue:
                validatePreviousState(
                    currentPage,
                    action,
                    PageState.RedoLoudness
                );
                nextPage = PageState.CompleteRecommendation;
                break;
            case TransitionAction.DeleteAccount:
                LocalStorageService.serviceInstance.clear();
                appExit('fail/no-account');
                break;
            case TransitionAction.DoLeaveApp:
                nextDialog = DialogState.None;
                nextPage = PageState.Journey;
                break;
            case TransitionAction.DoLeaveAppDialogOpen:
                nextDialog = DialogState.LeaveApp;
                nextPage = currentPage;
                break;
            case TransitionAction.DoLeaveAppDialogClose:
                nextDialog = DialogState.None;
                nextPage = currentPage;
                break;
            case TransitionAction.FineTuningComplete: // fall through
            case TransitionAction.GoFineTuning: // fall through
            case TransitionAction.DoRepeatPairing: // fall through
            case TransitionAction.ChangeSleeveCustomerServiceContinue: // fall through
            default:
                nextPage = handleActionError(currentPage, action);
                break;
        }

        LoggingService.info({
            componentName: 'AppFittingTransitionHandlerHook.doTransition',
            args: [
                `Transitioning from ${PageState[currentView.page]} (action: ${
                    TransitionAction[action]
                }) to ${PageState[nextPage]} with dialog ${
                    DialogState[nextDialog]
                }`,
            ],
        });
        return { dialog: nextDialog, page: nextPage } as ViewState;
    };

    const goToInitialState = async (
        patient: Patient | undefined,
        debugRoute?: PageState,
        initial?: PageState
    ): Promise<ViewState> => {
        let nextPage = PageState.None;
        let nextDialog = DialogState.None;

        const paramsAreValid = await validateParams(patient);

        if (debugRoute) {
            nextPage = debugRoute;
            if (patient) nextDialog = getDialogOnPatientState(patient);
        } else if (!paramsAreValid) {
            nextPage = PageState.DeviceIncompatibleError;
        } else {
            if (!patient) {
                // No patient record found
                nextPage =
                    LocalStorageService.serviceInstance.getSelectedCountry()
                        ? PageState.NewWelcome
                        : PageState.CountrySelection;
            } else {
                setCouplingIfRequired();
                nextDialog = getDialogOnPatientState(patient);

                const completedWorkflows = patient.easyFitWorkflows;

                if (
                    initial &&
                    supportedPageStateQueryParamsAppFitting.includes(initial)
                ) {
                    nextPage = checkAndGetNotCompletedWorkflowNextPage(
                        completedWorkflows,
                        initial
                    );

                    if (!checkPairingStatus()) {
                        nextPage = PageState.DeviceIncompatibleError;
                    }
                } else {
                    nextPage = checkAndGetNotCompletedWorkflowNextPage(
                        completedWorkflows,
                        PageState.RedoAssessmentOption
                    );
                }
            }
        }

        LoggingService.info({
            componentName: 'AppFittingTransitionHandlerHook.goToInitialState',
            args: [
                `Initial state: ${PageState[nextPage]} with dialog ${DialogState[nextDialog]}`,
            ],
        });
        //nextDialog
        return { dialog: nextDialog, page: nextPage } as ViewState;
    };

    return {
        doTransition,
        goToInitialState,
    };
}

export default useAppFittingTransitionHandler;
