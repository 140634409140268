import React from 'react';
import { ButtonType } from '../../../SharedComponents/Button/ButtonType';
import CustomButton from '../../../SharedComponents/Button/CustomButton';
import AppContainer from '../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../SharedComponents/Container/BodyContainer';
import CustomImg from '../../../SharedComponents/CustomImage/CustomImg';
import Header from '../../../SharedComponents/Header/HeaderRefactor';
import CustomTypography, {
    TypographyType,
} from '../../../SharedComponents/Typography/CustomTypography';
import usePairingSuccessHandler from './PairingSuccessHandlerHook';
import usePairingSuccessResource from './PairingSuccessResourceHook';
import './PairingSuccessStyle.scss';

export default function PairingSuccessPage(): JSX.Element {
    const { header, instructionImage, instruction, button } =
        usePairingSuccessResource();

    const { handlePrimaryAction } = usePairingSuccessHandler();

    return (
        <AppContainer>
            <Header headerContent={header} />
            <BodyContainer>
                <CustomImg
                    id={instructionImage.id}
                    src={instructionImage.src}
                    alt=""
                    imgType="iconLarge"
                />
                <CustomTypography
                    id={instruction.id}
                    type={TypographyType.notoSansBody}
                    text={instruction.text}
                    className={'pairingSuccessInstruction'}
                />
            </BodyContainer>
            <CustomButton
                id={button.id}
                buttonType={ButtonType.PrimaryLoading}
                onClick={handlePrimaryAction}
            >
                {button.label}
            </CustomButton>
        </AppContainer>
    );
}
