import React, { useState } from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import TelemetryService from '../../Services/Monitoring/TelemetryService';
import CustomIconButton from '../Button/CustomIconButton';
import FlexContainer from '../Container/FlexContainer';
import './VideoPlayerStyle.scss';

export interface IVideoPlayerProps extends ReactPlayerProps {
    id?: string;
    containerClassName?: string;
    videoType: string;
    previewImageSrc?: string;
}

function VideoPlayer({
    containerClassName = 'flexCenterContainer',
    videoType,
    previewImageSrc,
    ...props
}: IVideoPlayerProps): React.ReactElement {
    const [playing, setPlaying] = useState(false);

    return (
        <div className={containerClassName}>
            <FlexContainer id="embeded-video" className={'videoArea'}>
                <ReactPlayer
                    className={'video'}
                    light={previewImageSrc}
                    controls={true}
                    playing={playing}
                    width="100%"
                    height="100%"
                    pip={false}
                    playIcon={
                        <CustomIconButton
                            id="btn-play"
                            imgSrc={`/resources/shared/play-btn.svg`}
                            iconSize="iconLarge"
                            onClick={() => {
                                TelemetryService.serviceInstance.TrackWithIdentity(
                                    'EasyFit-WatchVideo',
                                    {
                                        VideoType: videoType,
                                    }
                                );
                                setPlaying(true);
                            }}
                        ></CustomIconButton>
                    }
                    playsinline={true}
                    muted={true}
                    config={{
                        youtube: {
                            playerVars: {
                                modestbranding: 1,
                                rel: 0,
                                fs: 1,
                                playsinline: 1,
                                iv_load_policy: 3,
                                showinfo: 1,
                            },
                        },
                    }}
                    {...props}
                />
            </FlexContainer>
        </div>
    );
}

export default VideoPlayer;
