import React from 'react';
import CircularProgress, {
    CircularProgressProps,
} from '@material-ui/core/CircularProgress';
import './SpinnerStyle.scss';
import CustomImg from '../CustomImage/CustomImg';
import CustomTypography, {
    TypographyType,
} from '../Typography/CustomTypography';
import FlexContainer from '../Container/FlexContainer';
import { joinMultipleStyle } from '../../Utils/ScssUtils';

export interface ICustomizedCircularProgress extends CircularProgressProps {
    loadingPercentage: number;
    displayPercentage?: boolean;
    loadingText: string;
    spinnerContainerClassName?: string;
    loadingTextClassName?: string;
    displayTickMark?: boolean;
}

export default function CustomizedCircularProgressPercent(
    props: ICustomizedCircularProgress
): React.ReactElement {
    return (
        <div
            className={joinMultipleStyle(
                'spinnerContainerPercent',
                props.spinnerContainerClassName
            )}
        >
            <CustomImg
                imgType={'iconLarge'}
                id={`img-loading-img`}
                className={'displayimage'}
                src={`/resources/shared/wait-asset.svg`}
                alt={props.loadingText}
            />
            <CircularProgress
                id={'progress-loading'}
                className={'movingprogress'}
                size={'12vh'}
                thickness={2}
                variant="determinate"
                value={props.loadingPercentage}
            />
            <div>
                <FlexContainer flexRow>
                    <CustomTypography
                        type={TypographyType.notoSansBody}
                        text={props.loadingText}
                        className={joinMultipleStyle(
                            'displaytextbottom',
                            props.loadingTextClassName
                        )}
                    />
                    {props.displayTickMark ? (
                        <CustomImg
                            id={`img-tickMark`}
                            className={'tickMark'}
                            src={`/resources/fineTuning/Tick-mark.svg`}
                            alt="tickMark"
                        />
                    ) : (
                        <div></div>
                    )}
                </FlexContainer>

                {props.displayPercentage ? (
                    <CustomTypography
                        type={TypographyType.notoSansBody}
                        text={`${props.loadingPercentage}%`}
                        className={'displaytextcaption'}
                    />
                ) : (
                    <div></div>
                )}
            </div>
        </div>
    );
}
