import React from 'react';
import TroubleShooting from '../../../../Pages/MainComponents/TroubleShooting/TroubleShooting';
import { withTracking } from '../../../../Services/Monitoring/ApplicationInsights';
import useRedoHLAATroubleShootingHandler from './RedoHLAATroubleShootingHandlerHook';
import useRedoHLAATroubleShootingResource from './RedoHLAATroubleShootingResourceHook';

function RedoHLAATroubleShooting() {
    return (
        <TroubleShooting
            useHandler={useRedoHLAATroubleShootingHandler}
            useResource={useRedoHLAATroubleShootingResource}
        />
    );
}

export default withTracking(RedoHLAATroubleShooting);
