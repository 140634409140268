import { useTranslation } from 'react-i18next';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import {
    ButtonResource,
    HeaderResource,
    ImageResource,
    TextResource,
} from '../../../Utils/PageUtils';

type LandingReource = {
    header: HeaderResource;
    landingImage: ImageResource;
    instruction: TextResource;
    buttonFineTuning: ButtonResource;
    vibeIcon: ImageResource;
    instructionsAppDownload: TextResource;
};

function useLandingResource(): LandingReource {
    const { t } = useTranslation();
    const header = {
        id: 'header-landing',
        type: HeaderActionType.Menu,
        text: t('completeAndLanding:CompleteAndLanding_Feature:Landing:Header'),
    };
    const landingImage = {
        id: 'img-landing',
        alt: 'img-landing',
        src: `/resources/shared/easyfit-illustration.svg`,
    };
    const instruction = {
        id: 'txt-title',
        text: t('completeAndLanding:CompleteAndLanding_Feature:Landing:Text'),
    };
    const buttonFineTuning = {
        id: 'btn-fine-tuning',
        label: t('common:Common_Feature:FineTuning'),
    };
    const vibeIcon = {
        id: 'img-vibe-icon',
        alt: 'img-vibe-icon',
        src: `/resources/completeAndLanding/Vibe_app_icon.svg`,
    };
    const instructionsAppDownload = {
        id: 'txt-instruction-download',
        text: t(
            'webApp:WebApp_Workflow:CompleteAndLanding_Feature:Shared:Text'
        ),
    };

    return {
        header,
        landingImage,
        instruction,
        buttonFineTuning,
        vibeIcon,
        instructionsAppDownload,
    };
}

export default useLandingResource;
