import { useTranslation } from 'react-i18next';
import { getCurrentLocale } from '../../../Services/LocalizationService';
import {
    ButtonResource,
    ImageResource,
    TextResource,
} from '../../../Utils/PageUtils';
import { createGetPathFunction } from '../../../Utils/ResourcesUtils';
import { deviceModelId } from '../../../Models/SupportedDeviceModel';
import { getUserDeviceModelFromURLParams } from '../../../Services/ResourceService';

type SplashScreenResource = {
    background: ImageResource;
    nextButton: ButtonResource;
    vibeLogo: ImageResource;
    languageSelection: ButtonResource;
    languageSelectionIcon: ImageResource;
    welcomeHeader: TextResource;
    welcomeMessage: TextResource;
    cookiePolicy: TextResource;
};

function useSplashScreenResource(): SplashScreenResource {
    const getTrans = createGetPathFunction('splashScreen', ':');
    const getTransCommon = createGetPathFunction('common', ':');
    const { t } = useTranslation();
    const background = {
        id: 'splashscreen-background',
        src: '/resources/splashScreen/background.svg',
        alt: t(getTransCommon('VibeNow')),
    };

    const nextButton = {
        id: 'btn-next',
        label: t(getTrans('SplashScreen_Feature.NextButton')),
    };

    const vibeLogo = {
        id: 'vibe-logo',
        src: '/resources/shared/vibenow-logo.svg',
        alt: 'vibe-logo',
    };

    const languageSelection = {
        id: 'btn-languageSelection',
        label: t(
            `languageSelection:LanguageSelection_Feature:Selection:${getCurrentLocale()}`
        ),
    };

    const languageSelectionIcon = {
        id: 'img-dropDown',
        src: '/resources/splashScreen/language-icon.svg',
        alt: 'language-selection',
    };

    const welcomeHeader = {
        id: 'splashscreen-header',
        text: t(getTrans('SplashScreen_Feature.TitleText')),
    };

    const welcomeMessage = {
        id: 'splashscreen-message',
        text:
            getUserDeviceModelFromURLParams() == deviceModelId.VibeGo
                ? t('vibeGo:VibeGo_Device.SplashScreen_Feature.Text1')
                : t(getTrans('SplashScreen_Feature.Text1')),
    };

    const cookiePolicy = {
        id: 'txt-cookie-policy',
        text: t(getTrans('SplashScreen_Feature.Text2')),
    };

    return {
        background,
        nextButton,
        vibeLogo,
        languageSelection,
        languageSelectionIcon,
        welcomeHeader,
        welcomeMessage,
        cookiePolicy,
    };
}

export default useSplashScreenResource;
