import { Device } from '../../../Models/Patient/Device';
import { Patient } from '../../../Models/Patient/Patient';
import LoggingService from '../../../Services/LoggingService';
import CompletedWorkflowService, {
    Workflow,
} from '../../../Utils/CompletedWorkflow';
import { PageState, TransitionAction } from './MainViewModel';

export function getAirPairingAddress(patient: Patient, side: string): number {
    if (!patient) return -1;
    const devices = patient.devices;
    const deviceIndex = devices.findIndex(
        (device: Device) => device.side.toString().toLowerCase() === side
    );

    return devices[deviceIndex].pairingAddress;
}

export function isValidAirDevicePairingAddresses(patient: Patient): boolean {
    const leftPairingAddress = getAirPairingAddress(patient, 'left');
    const rightPairingAddress = getAirPairingAddress(patient, 'right');
    const bothSidesUnpaired =
        leftPairingAddress === -1 && rightPairingAddress === -1;
    const bothSidesPaired =
        leftPairingAddress >= 0 &&
        leftPairingAddress <= 15 &&
        rightPairingAddress >= 0 &&
        rightPairingAddress <= 15;
    return bothSidesUnpaired || bothSidesPaired;
}

export function validatePreviousState(
    currentState: PageState,
    action: TransitionAction,
    ...expectedStates: PageState[]
): boolean {
    let isValidState = false;
    for (let i = 0; i < expectedStates.length; i++) {
        if (currentState === expectedStates[i]) {
            isValidState = true;
        }
    }
    if (!isValidState) {
        LoggingService.error({
            componentName: 'TransitionHandlerUtils.validatePreviousState',
            args: [
                `Unexpected transition from ${PageState[currentState]} with action ${TransitionAction[action]}`,
            ],
        });
    }

    return isValidState;
}

export function handleActionError(
    currentState: PageState,
    action: TransitionAction
): PageState {
    LoggingService.error({
        componentName: 'TransitionHandlerUtils.handleActionError',
        args: [
            `Transition ${TransitionAction[action]} not implemented (called on page: ${PageState[currentState]})`,
        ],
    });

    return PageState.None;
}

export function getDebugRoute(): PageState {
    // do fake routing from url params
    const params = new URLSearchParams(location.search);
    const route = params.get('route');
    return PageState[route as keyof typeof PageState];
}

export function getPageStateParam(): PageState {
    const params = new URLSearchParams(location.search);
    const route = params.get('pageState');
    return PageState[route as keyof typeof PageState];
}

export function checkAndGetNotCompletedWorkflowNextPage(
    completedWorkflows: Workflow,
    defaultPageState: PageState,
    pageToShowIfNotComplete?: PageState
): PageState {
    let nextPage = defaultPageState;
    if (
        !CompletedWorkflowService.isCompletedMandatoryWorkflow(
            completedWorkflows
        )
    ) {
        nextPage = CompletedWorkflowService.getNextMandatoryWorkflow(
            completedWorkflows,
            true
        );

        if (pageToShowIfNotComplete) {
            nextPage = pageToShowIfNotComplete;
        }
    }

    return nextPage;
}

export const supportedPageStateQueryParamsAppFitting: PageState[] = [
    PageState.RedoHLAA,
];

export const supportedPageStateQueryParamsFineTuning: PageState[] = [
    PageState.RedoLoudness,
];
