import { useTranslation } from 'react-i18next';
import { HeaderActionType } from '../../../../SharedComponents/Header/HeaderActionType';
import {
    HeaderResource,
    ImageResource,
    TextResource,
} from '../../../../Utils/PageUtils';

export type DownloadDialogResource = {
    header: HeaderResource;
    logoImage: ImageResource;
    instructionsText1: TextResource;
    instructionsText2: TextResource;
    appleImage: ImageResource;
    appleLink: TextResource;
    androidImage: ImageResource;
    androidLink: TextResource;
};

export default function useDownloadDialogResource(): DownloadDialogResource {
    const { t } = useTranslation();

    return {
        header: {
            text: t(
                'webApp:WebApp_Workflow:CompleteAndLanding_Feature:DownloadApp:Header'
            ),
            type: HeaderActionType.Close,
        },
        logoImage: {
            id: 'img-vibe',
            alt: 'img-vibe',
            src: `/resources/completeAndLanding/App-icon_withshadow.svg`,
        },
        instructionsText1: {
            id: 'txt-instructions1',
            text: t(
                'webApp:WebApp_Workflow:CompleteAndLanding_Feature:DownloadApp:Text1'
            ),
        },
        instructionsText2: {
            id: 'txt-instructions2',
            text: t(
                'webApp:WebApp_Workflow:CompleteAndLanding_Feature:DownloadApp:Text2'
            ),
        },
        appleImage: {
            id: 'img-apple-app-store',
            alt: 'Apple App store',
            src: `/resources/completeAndLanding/Apple.svg`,
        },
        appleLink: {
            id: 'apple-app-store-link',
            text: 'https://apps.apple.com/app/vibe-app/id1483761159?itsct=apps_box_link&itscg=30200',
        },
        androidImage: {
            id: 'img-google-play-store',
            alt: 'Google Play store',
            src: `/resources/completeAndLanding/Android.svg`,
        },
        androidLink: {
            id: 'android-app-store-link',
            text: 'https://play.google.com/store/apps/details?id=com.hicontrol.rta',
        },
    };
}
