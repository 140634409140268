import Telemetry from './Telemetry';
import AnonymousTelemetry from './AnonymousTelemetry';
import LocalStorageService from '../LocalStorageService';
import {
    getActiveConsentVersion,
    getResidingCountry,
} from '../../Utils/ConsentVersionUtils';

export default class TelemetryService {
    public static serviceInstance: TelemetryService = new TelemetryService();
    private readonly datacollectionkey: string = process.env
        .REACT_APP_DataCollectionKey as string;
    private readonly anonymous: string = process.env
        .REACT_APP_DataCollectionAnonymous as string;
    private readonly serviceName: string = process.env
        .REACT_APP_DcsServiceName as string;
    private telemetryWithIdentity: Telemetry = Telemetry.getInstance();
    private telemetryAnonymous: AnonymousTelemetry =
        AnonymousTelemetry.getInstance();

    public TrackAnonymous(
        eventName: string,
        payLoad: {
            [name: string]: string;
        }
    ): void {
        const newPayLoad = Object.assign(
            { [this.datacollectionkey]: this.anonymous },
            payLoad
        );
        this.telemetryAnonymous.trackEvent(eventName, newPayLoad);
    }

    public TrackWithIdentity(
        eventName: string,
        payLoad: {
            [name: string]: string;
        }
    ): void {
        if (!getResidingCountry()) {
            // if no country determined, cannot fetch custom consent version from non-medical
            return;
        }
        const patientId = LocalStorageService.serviceInstance.getPatientId();
        const privacyNotesVersion = getActiveConsentVersion();
        let country = LocalStorageService.serviceInstance.getPatientCountry();
        if (country === '')
            country = LocalStorageService.serviceInstance.getSelectedCountry();
        const newPayLoad = Object.assign(
            {
                [this
                    .datacollectionkey]: `${patientId}:${this.serviceName}:${privacyNotesVersion}:${country}`,
            },
            payLoad
        );
        this.telemetryWithIdentity.trackEvent(eventName, newPayLoad);
    }

    public UnTrackPatientId(): void {
        this.telemetryWithIdentity.tracePatientId('');
    }
}
