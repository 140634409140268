import { FineTuningDeviceStates } from '../../../../../Models/FineTuning/FineTuningDeviceStates';
import { FineTuningSolution } from '../../../../../Models/FineTuning/FineTuningSolution';
import { FineTuningSolutionState } from '../../../../../Models/FineTuning/FineTuningSolutionState';
import { SolutionValueChanged } from '../../../../../Models/FineTuning/SolutionValueChanged';
import FineTuningService from '../../../../../Services/FineTuningService';
import { t } from '../../../../../Services/LocalizationService';
import LocalStorageService from '../../../../../Services/LocalStorageService';
import TelemetryService from '../../../../../Services/Monitoring/TelemetryService';
import { getCouplingOrDefault } from '../../../../../Utils/CouplingUtils';
import { useFineTuningContext } from '../../../FineTuningContext';
import { useFineTuningSolutionContext } from '../../FineTuningSolutionContext';
import useFineTuningSolutionPageHandler from '../../FineTuningSolutionPageHandlerHook';
import { isChangeSleeveSolution } from '../../../../../Utils/ChangeSleeveUtils';

type FTFooterAfterApplyPageHandler = {
    handleSolutionSolved: () => Promise<void>;
    handleSolutionNotSolved: () => Promise<void>;
};

const useFTFooterAfterApplyPageHandler = (): FTFooterAfterApplyPageHandler => {
    const { info } = useFineTuningContext();
    const {
        fineTuningSolutionInfo,
        activeSolutionStep,
        setFineTuningSolutionState,
        setActiveSolutionStep,
    } = useFineTuningSolutionContext();
    const { updateMasterGainAndBandEqualizer } =
        useFineTuningSolutionPageHandler();

    const handleSolutionSolved = async () => {
        const solutionName =
            fineTuningSolutionInfo.current.solutionList[activeSolutionStep]
                .name;

        TelemetryService.serviceInstance.TrackWithIdentity(
            'EasyFit-FineTuning',
            {
                CategoryProblem:
                    t(
                        `fineTuning:FineTuning_Feature:Category:${info.current.selected.category}`,
                        {
                            lng: 'en',
                        }
                    ) +
                    '_' +
                    t(
                        `fineTuning:FineTuning_Feature:Problem:${info.current.selected.problem.replace(
                            /_/g,
                            ':'
                        )}`,
                        {
                            lng: 'en',
                        }
                    ),
                Name: solutionName + '_Applied',
            }
        );

        const leftDeviceId =
            LocalStorageService.serviceInstance.getDeviceId('left');
        const rightDeviceId =
            LocalStorageService.serviceInstance.getDeviceId('right');

        const leftDevice = info.current.deviceStateFinal[leftDeviceId];
        const rightDevice = info.current.deviceStateFinal[rightDeviceId];

        TelemetryService.serviceInstance.TrackWithIdentity(
            'EasyFit-FineTuned-MasterGain',
            {
                Left: leftDevice.easyfitMasterGain.toString(),
            }
        );

        TelemetryService.serviceInstance.TrackWithIdentity(
            'EasyFit-FineTuned-MasterGain',
            {
                Right: rightDevice.easyfitMasterGain.toString(),
            }
        );

        TelemetryService.serviceInstance.TrackWithIdentity(
            'EasyFit-Left-FineTuned-BandEqualizer',
            {
                Band1: leftDevice.band1EqualizerGain.toString(),
                Band2: leftDevice.band2EqualizerGain.toString(),
                Band3: leftDevice.band3EqualizerGain.toString(),
                Band4: leftDevice.band4EqualizerGain.toString(),
            }
        );

        TelemetryService.serviceInstance.TrackWithIdentity(
            'EasyFit-Right-FineTuned-BandEqualizer',
            {
                Band1: rightDevice.band1EqualizerGain.toString(),
                Band2: rightDevice.band2EqualizerGain.toString(),
                Band3: rightDevice.band3EqualizerGain.toString(),
                Band4: rightDevice.band4EqualizerGain.toString(),
            }
        );

        await FineTuningService.fineTuningConfirmed(
            LocalStorageService.serviceInstance.getActiveHearingProfileId()
        );
        setFineTuningSolutionState(FineTuningSolutionState.AppliedSuccessfully);
    };

    const resetFineTuningSolution = async () => {
        await updateMasterGainAndBandEqualizer(
            SolutionValueChanged.Both,
            info.current.deviceState
        );

        await FineTuningService.fineTuningReset(
            LocalStorageService.serviceInstance.getActiveHearingProfileId(),
            false,
            new FineTuningDeviceStates(info.current.deviceState)
        );
    };

    const handleSolutionNotSolved = async () => {
        const solutionName =
            fineTuningSolutionInfo.current.solutionList[activeSolutionStep]
                .name;

        TelemetryService.serviceInstance.TrackWithIdentity(
            'EasyFit-FineTuning',
            {
                CategoryProblem:
                    t(
                        `fineTuning:FineTuning_Feature:Category:${info.current.selected.category}`,
                        {
                            lng: 'en',
                        }
                    ) +
                    '_' +
                    t(
                        `fineTuning:FineTuning_Feature:Problem:${info.current.selected.problem.replace(
                            /_/g,
                            ':'
                        )}`,
                        {
                            lng: 'en',
                        }
                    ),
                Name: solutionName + '_Discarded',
            }
        );

        setFineTuningSolutionState(FineTuningSolutionState.Waiting);
        await resetFineTuningSolution();
        if (
            activeSolutionStep ===
            fineTuningSolutionInfo.current.solutionList.length - 1
        ) {
            const nextSolution = await FineTuningService.getFineTuningSolution(
                LocalStorageService.serviceInstance.getActiveHearingProfileId(),
                LocalStorageService.serviceInstance.getDeviceModelId('left'),
                info.current.selected.category,
                info.current.selected.problem,
                activeSolutionStep + 2,
                getCouplingOrDefault('left') === -1
                    ? 0
                    : getCouplingOrDefault('left')
            );
            if (nextSolution !== undefined) {
                setActiveSolutionStep((prevStep) => prevStep + 1);
                fineTuningSolutionInfo.current.solutionList.push(nextSolution);
                if (isChangeSleeveSolution(nextSolution.name)) {
                    setFineTuningSolutionState(
                        FineTuningSolutionState.ChangeSleeveRecommend
                    );
                } else {
                    setFineTuningSolutionState(
                        FineTuningSolutionState.BeforeApply
                    );
                }
            } else {
                fineTuningSolutionInfo.current.solutionList.push(
                    {} as FineTuningSolution
                );
                setActiveSolutionStep((prevStep) => prevStep + 1);
                setFineTuningSolutionState(
                    FineTuningSolutionState.CustomerService
                );
            }
        } else {
            if (
                Object.keys(
                    fineTuningSolutionInfo.current.solutionList[
                        activeSolutionStep + 1
                    ]
                ).length === 0
            ) {
                setActiveSolutionStep((prevStep) => prevStep + 1);
                setFineTuningSolutionState(
                    FineTuningSolutionState.CustomerService
                );
            } else {
                setActiveSolutionStep((prevStep) => prevStep + 1);
                setFineTuningSolutionState(FineTuningSolutionState.BeforeApply);
            }
        }
    };

    return {
        handleSolutionNotSolved,
        handleSolutionSolved,
    };
};

export default useFTFooterAfterApplyPageHandler;
