import { useTranslation } from 'react-i18next';
import { CustomerServiceContactService } from '../../../Services/Configuration/CustomerServiceContactService';
import { getUserDeviceModel } from '../../../Services/ResourceService';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import {
    ButtonResource,
    HeaderResource,
    ImageResource,
    switchBasedOnDevice,
    TextResource,
} from '../../../Utils/PageUtils';
import hiAssemblyPageResource from './HIAssemblyPageResource.json';
import { deviceModel } from '../../../Models/SupportedDeviceModel';

export type HIAssemblyVideoResource = {
    id?: string;
    title?: string;
    url: string;
    videoType: string;
    previewImageSrc: string;
};

export type HIAssemblyPageResource = {
    header: HeaderResource;
    title: TextResource;
    image: ImageResource;
    instructionsText: TextResource;
    video: HIAssemblyVideoResource;
    continueText: TextResource;
    continueButton: ButtonResource;
};

export default function useHIAssemblyPageResource(): HIAssemblyPageResource {
    const { t } = useTranslation();
    const generalKey = switchBasedOnDevice(getUserDeviceModel(), {
        VibeAir: 'vibeAir:VibeAir_Device:HiAssemble_Feature',
        VibeGo: 'vibeGo:VibeGo_Device:HiAssemble_Feature',
        VibeComplete: 'vibeComplete:VibeComplete_Device:HiAssemble_Feature',
    }) as string;

    const header = {
        id: hiAssemblyPageResource.header.id,
        text: t(`${generalKey}${hiAssemblyPageResource.header.text}`),
        type: HeaderActionType.Menu,
    };

    const title = {
        id: hiAssemblyPageResource.title.id,
        text: t(`${generalKey}${hiAssemblyPageResource.title.text}`),
    };

    const instructionsText = {
        id: hiAssemblyPageResource.instructions.id,
        text: t(`${generalKey}${hiAssemblyPageResource.instructions.text}`, {
            supportLink:
                CustomerServiceContactService.getCountrySpecificVibeHearingWebSupportAddress(
                    false
                ),
        }),
    };

    const video = {
        id: hiAssemblyPageResource.video.id,
        title: hiAssemblyPageResource.video.title,
        url: hiAssemblyPageResource.video.url,
        videoType: hiAssemblyPageResource.video.videoType,
        previewImageSrc: hiAssemblyPageResource.video.previewImageSrc,
    };

    const image = {
        id: 'img-hearing-aids',
        src:
            //for vibe air, no hi-assemble image, see HIAssemblyPage for display details
            getUserDeviceModel() === deviceModel.VibeGo
                ? '/resources/hiAssemble/VibeGo/hi-assemble.svg'
                : '/resources/hiAssemble/VibeComplete/hi-assemble.svg',
        alt: 'hearing aids image',
    };

    const continueText = {
        id: hiAssemblyPageResource.continueText.id,
        text: t(`${generalKey}${hiAssemblyPageResource.continueText.text}`),
    };

    const continueButton = {
        id: hiAssemblyPageResource.continueButton.id,
        label: t(hiAssemblyPageResource.continueButton.text),
    };

    return {
        header,
        title,
        instructionsText,
        video,
        image,
        continueText,
        continueButton,
    };
}
