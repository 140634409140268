import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceRepairingState } from '../../../../../Models/DeviceRepairingState';
import { MainViewContext } from '../../../../../Pages/MainComponents/MainView/MainViewContext';
import { MainViewWorkflow } from '../../../../../Pages/MainComponents/MainView/MainViewModel';
import LocalStorageService from '../../../../../Services/LocalStorageService';
import CompletedWorkflowService, {
    mandatoryWorkflow,
    Workflow,
} from '../../../../../Utils/CompletedWorkflow';
import { MenuListResource } from '../../../../../Utils/PageUtils';
import { HeaderActionType } from '../../../../Header/HeaderActionType';
import { createMenuItem } from '../../MenuUtils';
import { MainMenuHandler } from './MainMenuHandlerHook';

export default function useMainMenuResource(
    menuItemHandlers: MainMenuHandler
): MenuListResource {
    const { t } = useTranslation();
    const viewContext = useContext(MainViewContext);

    const translate = (id: string): string => {
        return 'menu:Menu_Feature:Main:' + id;
    };
    const menuLists = [
        createMenuItem(
            '/resources/menu/legal-information.svg',
            t(translate('Text4')),
            menuItemHandlers.legalInformation,
            'menuItem-legalinfo',
            true
        ),
        createMenuItem(
            '/resources/menu/settings.svg',
            t(translate('Text5')),
            menuItemHandlers.settings,
            'menuItem-settings',
            true
        ),
    ];

    const completedWorkflow =
        LocalStorageService.serviceInstance.getCompletedWorkflow();
    if (
        LocalStorageService.serviceInstance.getOperationConsentState() &&
        LocalStorageService.serviceInstance.getDataAnalyticConsentState()
    ) {
        const instruction = createMenuItem(
            '/resources/menu/instructions.svg',
            t(translate('Text3')),
            menuItemHandlers.instructions,
            'menuItem-instructions',
            true
        );
        menuLists.unshift(instruction);
    }

    const devicePairingState =
        LocalStorageService.serviceInstance.getDevicePairingRequired();

    if (
        CompletedWorkflowService.isCompleted(
            completedWorkflow,
            Workflow.Contraindication |
                Workflow.DevicePairingLeft |
                Workflow.DevicePairingRight |
                Workflow.HIAssemble
        ) &&
        devicePairingState === DeviceRepairingState.NoAction.toString()
    ) {
        const hearingAids = createMenuItem(
            '/resources/menu/hearing-aids.svg',
            t(translate('Text2')),
            menuItemHandlers.hearingAids,
            'menuItem-hearingAids',
            true
        );
        (viewContext.workflowType === MainViewWorkflow.Web ||
            viewContext.workflowType === MainViewWorkflow.AppFitting) &&
            menuLists.unshift(hearingAids);
    }
    if (
        CompletedWorkflowService.isCompleted(
            completedWorkflow,
            mandatoryWorkflow
        ) &&
        devicePairingState === DeviceRepairingState.NoAction.toString()
    ) {
        const fineTuning = createMenuItem(
            '/resources/menu/fine-tune.svg',
            t('common:Common_Feature:FineTuning'),
            menuItemHandlers.fineTuning,
            'menuItem-fineTuning',
            false
        );

        viewContext.workflowType !== MainViewWorkflow.AppFitting &&
            menuLists.unshift(fineTuning);
    }

    return {
        header: {
            id: 'header-menu',
            text: t('menu:Menu_Feature:Main:Header'),
            type: HeaderActionType.Close,
        },
        menuItems: menuLists,
    };
}
