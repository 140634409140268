import React from 'react';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import AppContainer from '../../Container/AppContainer';
import BodyContainer from '../../Container/BodyContainer';
import CustomImg from '../../CustomImage/CustomImg';
import Header from '../../Header/HeaderRefactor';
import CustomTypography, {
    TypographyType,
} from '../../Typography/CustomTypography';
import { IDialogContentProps } from '../DialogUtils';
import useLegalHandler, { LegalHandler } from './LegalDialogHandlerHook';
import { LegalInfoType } from './LegalDialogModel';
import useLegalResource, { LegalResource } from './LegalDialogResourceHook';
import './LegalDialogStyle.scss';
export interface ILegalProps extends IDialogContentProps {
    type: LegalInfoType;
    useResource?: (type: LegalInfoType) => LegalResource;
    useHandler?: (type: LegalInfoType) => LegalHandler;
}

function LegalDialog({
    type,
    onClose,
    useResource = useLegalResource,
    useHandler = useLegalHandler,
}: ILegalProps): JSX.Element {
    const { dialogType, linkCallBacks } = useHandler(type);
    const { header, legalContent, ceMark } = useResource(dialogType);

    return (
        <AppContainer>
            <span id={'top-anchor'} />
            <Header headerContent={header} onClick={onClose} />
            <BodyContainer flexStart>
                <CustomTypography
                    id={legalContent.id}
                    data-testid={legalContent.id}
                    type={TypographyType.notoSansBody}
                    text={legalContent.text}
                    linkCallBacks={linkCallBacks}
                    className={'textTopLeft'}
                />
                {dialogType === LegalInfoType.LegalInfo && (
                    <CustomImg
                        id={ceMark.id}
                        src={ceMark.src}
                        alt={ceMark.alt}
                        imgType="iconLarge"
                        className={'legalInfoCEMark'}
                    />
                )}
            </BodyContainer>
        </AppContainer>
    );
}

export default withTracking(LegalDialog);
