import { useContext, useState } from 'react';
import { CustomerServiceContactService } from '../../../Services/Configuration/CustomerServiceContactService';
import TelemetryService from '../../../Services/Monitoring/TelemetryService';
import PatientManagementService from '../../../Services/PatientManagementService';
import { Workflow } from '../../../Utils/CompletedWorkflow';
import { PairingCarouselHandler } from '../PairingCarouselPage';
import { PairingContext } from '../PairingContext';
import { getAvailableSide } from '../PairingStateMachineHandler';
import config from './InsertInstructionConfig.json';
import { getUserDeviceModel } from '../../../Services/ResourceService';
import { deviceModel } from '../../../Models/SupportedDeviceModel';

export default function useInsertInstructionHandler(): PairingCarouselHandler {
    const { send } = useContext(PairingContext);
    const [step, setStep] = useState(0);
    const [disabled, setDisabled] = useState(false);

    const openHyperLink = () => {
        TelemetryService.serviceInstance.TrackWithIdentity(
            'EasyFit-FeatureUsage',
            {
                Feature: 'NeedHelpInPairing',
            }
        );
        window.location.replace(
            CustomerServiceContactService.getCountrySpecificVibeHearingWebSupportAddress(
                false
            )
        );
    };
    const stepPerSide =
        getUserDeviceModel() === deviceModel.VibeGo
            ? config.VibeGo.stepPerSide
            : config.VibeComplete.stepPerSide;

    const totalStep = getAvailableSide().length * stepPerSide;
    const handleChangeStep = (newStep: number) => {
        if (totalStep > stepPerSide) {
            if (newStep < stepPerSide && newStep >= 0) {
                send('switchSide', { data: 'left' });
            } else if (newStep >= stepPerSide && newStep < totalStep) {
                send('switchSide', { data: 'right' });
            }
        }
        setStep(newStep);
    };
    const buttonOnClick = async () => {
        setDisabled(true);
        await PatientManagementService.endWorkflow(
            Workflow.DevicePairingLeft | Workflow.DevicePairingRight
        );
        send('completeBLEParing');
        setDisabled(false);
    };

    const supportLinkDisplayed = step % stepPerSide > 0 && step < totalStep;

    return {
        step,
        setStep: handleChangeStep,
        supportLinkDisplayed,
        buttonOnClick,
        openHyperLink,
        disabled,
    };
}
