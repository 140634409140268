import AuthService from '../../../../Services/Authentication/AuthService';
import PatientManagementService from '../../../../Services/PatientManagementService';
import { useCallback, useContext, useEffect, useState } from 'react';
import { MainViewContext } from '../../../../Pages/MainComponents/MainView/MainViewContext';
import { TransitionAction } from '../../../../Pages/MainComponents/MainView/MainViewModel';
import { ConsentAnalyticHandler } from '../../../../Pages/MainComponents/ConsentAnalytic/ConsentAnalyticHandlerHook';
import { useHistory } from 'react-router-dom';
import LocalStorageService from '../../../../Services/LocalStorageService';
import { ConsentValue } from '../../../../Models/Patient/PatientConsentData';
import TelemetryService from '../../../../Services/Monitoring/TelemetryService';
import {
    getActiveConsentVersion,
    getCustomConsentVersion,
    getResidingCountry,
} from '../../../../Utils/ConsentVersionUtils';
import { addResourceBundle } from '../../../../Services/LocalizationService';

function useAnalyticConsentDialogHandler(
    setLoading: React.Dispatch<boolean>
): ConsentAnalyticHandler {
    const history = useHistory();
    const [showLegalInfo, setShowLegalInfo] = useState(false);
    const viewContext = useContext(MainViewContext);

    const loading = useCallback(async () => {
        setLoading(true);
        if (getCustomConsentVersion()) {
            await addResourceBundle(
                `Legal/${getResidingCountry()}/`,
                'consent'
            );
        }
        const patientId = LocalStorageService.serviceInstance.getPatientId();
        if (patientId === '') {
            const refreshCookies = async () => {
                await AuthService.adB2cInstance.refreshCookies();
            };
            refreshCookies();
        }

        setLoading(false);
    }, [setLoading]);

    useEffect(() => {
        loading();
    }, [history, loading]);

    const onContinue = async (userConsented: boolean) => {
        const activeConsentVersion = getActiveConsentVersion();

        if (userConsented) {
            LocalStorageService.serviceInstance.setDataAnalyticConsentState(
                ConsentValue.Agreed
            );
            await PatientManagementService.ConsentAnalyticData(
                activeConsentVersion
            );
            TelemetryService.serviceInstance.TrackWithIdentity(
                'EasyFit-DataAnalytic',
                {
                    Consent: 'Agreed',
                }
            );
        } else {
            LocalStorageService.serviceInstance.setDataAnalyticConsentState(
                ConsentValue.Declined
            );
            await PatientManagementService.RevokeAnalyticData(
                activeConsentVersion
            );
            TelemetryService.serviceInstance.TrackWithIdentity(
                'EasyFit-DataAnalytic',
                {
                    Consent: 'Declined',
                }
            );
        }

        LocalStorageService.serviceInstance.setConsentVersion(
            activeConsentVersion
        );

        viewContext.doTransition(
            TransitionAction.AnalyticsConsentDialogContinue
        );
    };

    const openHyperLinkPrivacy = () => {
        setShowLegalInfo(true);
    };

    const closeDialog = () => {
        setShowLegalInfo(false);
    };

    return {
        showLegalInfo,
        onContinue,
        openHyperLinkPrivacy,
        closeDialog,
    };
}

export default useAnalyticConsentDialogHandler;
