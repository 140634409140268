import React from 'react';
import PairButton from '../../../SharedComponents/Button/PairButton';
import AppContainer from '../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../SharedComponents/Container/BodyContainer';
import CustomImg from '../../../SharedComponents/CustomImage/CustomImg';
import Header from '../../../SharedComponents/Header/HeaderRefactor';
import CustomTypography, {
    TypographyType,
} from '../../../SharedComponents/Typography/CustomTypography';
import {
    ButtonResource,
    HeaderResource,
    ImageResource,
    TextResource,
    VoidReturn,
} from '../../../Utils/PageUtils';
import './BeepConfirmationStyle.scss';

export type BeepConfirmationHandle = {
    primaryAction: () => VoidReturn;
    secondaryAction: () => VoidReturn;
};

export type BeepConfirmationResource = {
    header: HeaderResource;
    title?: TextResource;
    instructionImage: ImageResource;
    instruction: TextResource;
    buttons: {
        primary: ButtonResource;
        secondary: ButtonResource;
    };
};

export type BeepConfirmationRender = {
    renderTopProgressBar?: () => JSX.Element;
};
export interface IBeepConfirmation {
    useHandler: () => BeepConfirmationHandle;
    useResource: () => BeepConfirmationResource;
    useRender?: () => BeepConfirmationRender;
}
function useDefaultRender() {
    return {
        renderTopProgressBar: undefined,
    };
}

export default function BeepConfirmation({
    useHandler,
    useRender = useDefaultRender,
    useResource,
}: IBeepConfirmation): JSX.Element {
    const { primaryAction, secondaryAction } = useHandler();

    const { header, title, instructionImage, instruction, buttons } =
        useResource();
    const { renderTopProgressBar } = useRender();

    function renderTitle() {
        return (
            title && (
                <CustomTypography
                    className={
                        renderTopProgressBar
                            ? 'beepConfirmationTitle'
                            : 'beepConfirmationWithTopBar'
                    }
                    type={TypographyType.notoSansBodyBig}
                    text={title.text}
                    id={title.id}
                />
            )
        );
    }
    return (
        <AppContainer id="beep-confirmation">
            {renderTopProgressBar ? renderTopProgressBar() : <></>}
            <Header headerContent={header} />
            <BodyContainer>
                {renderTitle()}
                <CustomImg
                    imgType="medium"
                    id={instructionImage.id}
                    src={instructionImage.src}
                    alt={instructionImage.alt ? instructionImage.alt : ''}
                />
                <CustomTypography
                    className={'beepConfirmationInstruction'}
                    type={TypographyType.notoSansBody}
                    text={instruction.text}
                    id={instruction.id}
                />
            </BodyContainer>
            <PairButton
                primaryButton={{
                    children: buttons.primary.label,
                    id: buttons.primary.id,
                    onClick: primaryAction,
                }}
                secondaryButton={{
                    children: buttons.secondary.label,
                    id: buttons.secondary.id,
                    onClick: secondaryAction,
                }}
            />
        </AppContainer>
    );
}
