import React from 'react';
import ButtonStepper from './StepContentVariants/ButtonStepper';
import IconStepper from './StepContentVariants/IconStepper';
import { StepContentType, VerticalStepperProps } from './StepperModel';

export function createStepper(
    type: StepContentType,
    props: VerticalStepperProps
): JSX.Element {
    switch (type) {
        case StepContentType.IconStepper:
            return <IconStepper {...props} />;
        case StepContentType.ButtonStepper:
            return <ButtonStepper {...props} />;
        default:
            return <></>;
    }
}
