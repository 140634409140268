import axios from 'axios';
import { ServiceConfigFactory } from './ServiceConfigFactory';

export class EnvironmentInfoService {
    public static frontEndVersion(): string {
        return 'V' + process.env.REACT_APP_VERSION;
    }

    public static async backEndVersion(): Promise<string> {
        try {
            const res = await axios.get<string>(
                process.env.REACT_APP_EASYFIT_API_URL +
                    '/environmentinfo/version',
                ServiceConfigFactory.CreateAxiosConfigWithoutAuth()
            );
            if (res.status === 200 && res.data !== null) {
                return 'V' + res.data;
            }
            return '(' + res.statusText + ')';
        } catch (ex) {
            return '(' + ex.message + ')';
        }
    }
}
