import {
    AccountInfo,
    AuthenticationResult,
    AuthError,
    BrowserAuthError,
    Configuration,
    InteractionRequiredAuthError,
    PublicClientApplication,
    RedirectRequest,
    SilentRequest,
} from '@azure/msal-browser';
import { Patient } from '../../Models/Patient/Patient';
import CookiesService from '../CookiesService';
import { getCurrentLocale } from '../LocalizationService';
import LocalStorageService from '../LocalStorageService';
import LoggingService from '../LoggingService';
import TelemetryService from '../Monitoring/TelemetryService';
import PatientManagementService from '../PatientManagementService';
import { b2cAuthConfig, throwIfNull } from './AuthenticationUtils';
import axios, { AxiosRequestConfig } from 'axios';
import { ServiceConfigFactory } from '../ServiceConfigFactory';

export default class AuthService {
    public static adB2cInstance: AuthService;
    private readonly msalApp: PublicClientApplication;
    private readonly clientId: string = throwIfNull(
        process.env.REACT_APP_B2C_CLIENT_ID as string,
        'REACT_APP_B2C_CLIENT_ID missing from .env'
    );
    private readonly tenant: string = throwIfNull(
        process.env.REACT_APP_B2C_TENANT as string,
        'REACT_APP_B2C_TENANT missing from .env'
    );
    private readonly tenantId: string = throwIfNull(
        process.env.REACT_APP_B2C_TENANT_ID as string,
        'REACT_APP_B2C_TENANT_ID missing from .env'
    );
    private readonly signInPolicy: string = throwIfNull(
        process.env.REACT_APP_B2C_SIGN_IN_POLICY as string,
        'REACT_APP_B2C_SIGN_IN_POLICY missing from .env'
    );
    private readonly loginScopes: string = throwIfNull(
        process.env.REACT_APP_B2C_LOGIN_SCOPES as string,
        'REACT_APP_B2C_LOGIN_SCOPES missing from .env'
    );
    private readonly authority: string = `https://${this.tenant}.b2clogin.com/${this.tenantId}/${this.signInPolicy}`;
    private readonly apiScope: string = `https://${
        this.tenant
    }.onmicrosoft.com/api-mgt/${this.loginScopes.trim()}`;

    private readonly b2cConfiguration: Configuration = b2cAuthConfig(
        this.clientId,
        this.authority
    );

    private authenticationErrorHandler: (error: unknown) => void;

    private constructor() {
        this.authenticationErrorHandler = (error: unknown) => {
            LoggingService.error({
                componentName: AuthService.name,
                args: ['Error while logging in: ' + error],
            });
            this.logout();
        };

        this.msalApp = new PublicClientApplication(this.b2cConfiguration);
    }

    static async getInstance(): Promise<AuthService> {
        if (!AuthService.adB2cInstance) {
            AuthService.adB2cInstance = new AuthService();
            await AuthService.adB2cInstance.initialize();
        }
        return AuthService.adB2cInstance;
    }

    public async initialize(): Promise<void> {
        await this.msalApp.initialize();
    }

    public async login(force = false): Promise<void> {
        const loginRedirectRequest: RedirectRequest = {
            scopes: ['openid', 'profile', this.apiScope],
            extraQueryParameters: {
                ui_locales: getCurrentLocale(),
            },
        };
        if (force) {
            loginRedirectRequest.prompt = 'login';
        }

        this.setUserAccount(null);
        await this.handleLoginRedirect();
        //session storage variable to check if user logged in during this session
        sessionStorage.setItem('sessionLoggedIn', 'true');
        this.msalApp
            .loginRedirect(loginRedirectRequest)
            .catch((error) => this.authenticationErrorHandler(error));
    }

    public hasUserLoggedInForCurrentSession(): boolean | null {
        return sessionStorage.getItem('sessionLoggedIn') == 'true';
    }

    public async handleLoginRedirect(): Promise<void> {
        await this.msalApp
            .handleRedirectPromise()
            .then((resp: AuthenticationResult | null) => {
                if (resp?.account) {
                    if (this.getUserAccount() === null) {
                        this.setUserAccount(resp?.account);
                        LoggingService.info({
                            componentName: this.handleLoginRedirect.name,
                            args: ['Logging in user'],
                        });
                    } else {
                        // check if expected account is same
                        if (
                            this.getUserAccount()?.localAccountId !==
                            resp?.account.localAccountId
                        ) {
                            LoggingService.info({
                                componentName: this.handleLoginRedirect.name,
                                args: ['Unexpected user account login'],
                            });
                            this.login(true);
                        }
                    }
                }
            })
            .catch((error) => this.authenticationErrorHandler(error));
        return;
    }

    public logout(isHardLogOut = true): void {
        TelemetryService.serviceInstance.TrackWithIdentity(
            'EasyFit-FeatureUsage',
            {
                Feature: 'Logout',
            }
        );
        if (isHardLogOut) {
            this.msalApp.logout();
        }
        LocalStorageService.serviceInstance.clear(true);
        sessionStorage.clear();
        CookiesService.serviceInstance.clearCookies();
        TelemetryService.serviceInstance.UnTrackPatientId();
    }

    public invalidUserLogout(): void {
        LocalStorageService.serviceInstance.clear(true);
        sessionStorage.clear();
        CookiesService.serviceInstance.clearCookies();
        TelemetryService.serviceInstance.UnTrackPatientId();
        this.msalApp.logout();
    }

    public clearSession(): void {
        sessionStorage.clear();
        CookiesService.serviceInstance.clearCookies();
        TelemetryService.serviceInstance.UnTrackPatientId();
    }

    private async getAccessToken(force: boolean) {
        const userAccount = this.getUserAccount();
        if (userAccount != null) {
            const tokenRequest: SilentRequest = {
                scopes: [this.apiScope],
                account: userAccount as AccountInfo,
                extraQueryParameters: {
                    // eslint-disable-next-line camelcase
                    ui_locales: getCurrentLocale(),
                },
                forceRefresh: force,
            };

            return await this.msalApp
                .acquireTokenSilent(tokenRequest)
                .then(async (authResponse) => {
                    if (
                        authResponse.account?.localAccountId ===
                        this.getUserAccount()?.localAccountId
                    ) {
                        return authResponse.accessToken;
                    } else {
                        const acquireRedirectRequest = {
                            scopes: [this.apiScope],
                            loginHint: this.getUserAccount()?.username,
                            prompt: 'login',
                        };

                        return await this.msalApp.acquireTokenRedirect(
                            acquireRedirectRequest
                        );
                    }
                })
                .catch(async (error) => {
                    if (error instanceof InteractionRequiredAuthError) {
                        LoggingService.warn({
                            componentName: this.getAccessToken.name,
                            args: [
                                `InteractionRequiredAuth Error occur with code=${error.errorCode} and message=${error.errorMessage}`,
                            ],
                        });
                        this.setUserAccount(null);
                        window.location.reload();
                    } else if (error instanceof BrowserAuthError) {
                        LoggingService.error({
                            componentName: this.getAccessToken.name,
                            args: [
                                `BrowserAuth Error occur with code=${error.errorCode} and message=${error.errorMessage}`,
                            ],
                        });
                        this.logout();
                    } else if (error instanceof AuthError) {
                        LoggingService.error({
                            componentName: this.getAccessToken.name,
                            args: [
                                `Auth Error occur with code=${error.errorCode} and message=${error.errorMessage}`,
                            ],
                        });
                        this.logout(false);
                        window.location.reload();
                    }
                    return undefined;
                });
        } else {
            window.location.reload();
        }
    }

    public async acquireAccessToken(force = false): Promise<string> {
        const token = await this.getAccessToken(force);
        if (token) {
            return token;
        }
        LoggingService.error({
            componentName: 'AuthService.acquireAccessToken',
            args: [
                `Error occured, could not acquire access token. Logging user out.`,
            ],
        });
        this.logout(false);
        return '';
    }

    public getIsAuthenticated(): boolean {
        if (this.msalApp.getAllAccounts().length > 1) {
            LoggingService.info({
                componentName: this.getIsAuthenticated.name,
                args: ['multiple accounts detected'],
            });
        }
        return this.getUserAccount() ? true : false;
    }

    public getUserAccount(): AccountInfo | null {
        const userId = LocalStorageService.serviceInstance.getPatientLocalId();
        if (userId !== '') {
            return this.msalApp.getAccountByLocalId(userId);
        } else {
            return null;
        }
    }

    private setUserAccount(account: AccountInfo | null) {
        if (account != null) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const idTokenClaims = account.idTokenClaims as any;
            LocalStorageService.serviceInstance.setPatientId(
                idTokenClaims?.extension_vibe_identity_userid
            );
            LocalStorageService.serviceInstance.setPatientLocalId(
                account.localAccountId
            );
        } else {
            LocalStorageService.serviceInstance.setPatientId(null);
            LocalStorageService.serviceInstance.setPatientLocalId(null);
        }
    }

    public async refreshCookies(): Promise<void> {
        const token = await this.acquireAccessToken();
        if (token !== '') {
            let patient = await PatientManagementService.getPatient();
            while (patient === undefined) {
                LoggingService.info({
                    componentName: this.refreshCookies.name,
                    args: [
                        'AuthService -> refreshCookies -> Trying to get patient',
                        patient,
                    ],
                });
                await new Promise((resolve) => setTimeout(resolve, 1000));
                try {
                    patient = await PatientManagementService.getPatient();
                } catch (ex) {
                    LoggingService.error({
                        componentName: this.refreshCookies.name,
                        args: [
                            `Error occur with code=${ex.errorCode} and message=${ex.errorMessage}`,
                        ],
                    });
                }
            }
            LoggingService.info({
                componentName: this.refreshCookies.name,
                args: [
                    'AuthService -> refreshCookies -> patient found',
                    patient,
                ],
            });
            await LocalStorageService.serviceInstance.setPatient(
                patient as Patient
            );
        }
    }

    public async getUserAccountStatus(): Promise<boolean> {
        const axiosConfig: AxiosRequestConfig =
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            );

        const res = await axios.get(
            process.env.REACT_APP_EASYFIT_API_URL + '/IDSAccountStatus/me',
            axiosConfig
        );

        if (res.status === 404) {
            LoggingService.info({
                componentName: this.getUserAccountStatus.name,
                args: [`User account not found`],
            });
            return false;
        }
        return true;
    }
}
