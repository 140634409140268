import {
    ApplicationInsights,
    DistributedTracingModes,
    ITelemetryItem,
} from '@microsoft/applicationinsights-web';
import {
    ReactPlugin,
    withAITracking,
} from '@microsoft/applicationinsights-react-js';
import TelemetryProcessorPlugin from './TelemetryProcessorPlugin';

const reactPlugin = new ReactPlugin();
const telementryProcessorPlugin = new TelemetryProcessorPlugin();
let appInsights: ApplicationInsights;

const createTelemetryService = () => {
    const initialize = (
        instrumentationKey: string,
        browserHistory: unknown
    ) => {
        if (!browserHistory) {
            throw new Error('Could not initialize Telemetry Service');
        }
        if (!instrumentationKey) {
            throw new Error(
                'Instrumentation key not provided in ./src/telemetry-provider.jsx'
            );
        }

        appInsights = new ApplicationInsights({
            config: {
                instrumentationKey,
                maxBatchInterval: 0,
                distributedTracingMode: DistributedTracingModes.W3C,
                autoTrackPageVisitTime: true,
                enableCorsCorrelation: true,
                enableRequestHeaderTracking: true,
                enableResponseHeaderTracking: true,
                enableUnhandledPromiseRejectionTracking: true,
                disableExceptionTracking: false,
                correlationHeaderExcludePatterns: [/arc/],
                extensions: [reactPlugin, telementryProcessorPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: {
                        history: browserHistory,
                    },
                },
            },
        });

        appInsights.loadAppInsights();

        appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
            envelope.tags = envelope.tags || [];
            envelope.tags.push({ 'ai.cloud.role': 'easyfit-frontend' });
        });

        return appInsights;
    };

    return { reactPlugin, appInsights, initialize };
};

const createAnonymousTelemetryService = () => {
    const initialize = (
        instrumentationKey: string,
        browserHistory: unknown
    ) => {
        if (!browserHistory) {
            throw new Error('Could not initialize analytic Telemetry Service');
        }
        if (!instrumentationKey) {
            throw new Error(
                'Instrumentation key not provided in ./src/telemetry-provider.jsx'
            );
        }

        appInsights = new ApplicationInsights({
            config: {
                instrumentationKey,
                maxBatchInterval: 0,
                distributedTracingMode: DistributedTracingModes.W3C,
                autoTrackPageVisitTime: false,
                enableCorsCorrelation: false,
                enableRequestHeaderTracking: false,
                enableResponseHeaderTracking: false,
                extensions: [reactPlugin, telementryProcessorPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: {
                        history: browserHistory,
                    },
                },
            },
        });

        appInsights.loadAppInsights();

        appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
            envelope.tags = envelope.tags || [];
            envelope.tags.push({ 'ai.cloud.role': 'easyfit-frontend' });
            envelope.tags.push({ 'ai.user.id': '' });
            envelope.tags.push({ 'ai.session.id': '' });
        });

        return appInsights;
    };

    return { reactPlugin, appInsights, initialize };
};

export const ai = createTelemetryService();
export const anonymousAI = createAnonymousTelemetryService();
export const getAppInsights = (): ApplicationInsights => appInsights;
export const withTracking = <T>(
    Component: React.ComponentType<T>
): React.ComponentClass<T, unknown> => withAITracking(reactPlugin, Component);
