import React, { useState } from 'react';
import ConsentAnalyticPage from '../../../Pages/MainComponents/ConsentAnalytic/ConsentAnalyticPage';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import CustomDialog from '../CustomDialog';
import {
    AnalyticConsentDialogContext,
    AnalyticConsentDialogState,
    IAnalyticConsentDialogContext,
} from './AnalyticConsentDialogContext';
import useDeviceInfoCheckDialogHandler from './AnalyticConsentDialog/AnalyticConsentDialogHandlerHook';
import AnalyticConsentAlertDialog from './AlertDialog/AnalyticConsentAlertDialog';

export interface IDeviceInfoCheckDialogProps {
    display: boolean;
}

function AnalyticConsentDialog({ display }: IDeviceInfoCheckDialogProps) {
    const [analyticConsentDialogState, setAnalyticConsentDialogState] =
        useState<AnalyticConsentDialogState>(AnalyticConsentDialogState.Start);

    const initialValue: IAnalyticConsentDialogContext = {
        analyticConsentDialogState: analyticConsentDialogState,
        setAnalyticConsentDialogState: setAnalyticConsentDialogState,
    };

    const renderSwitch = (state: AnalyticConsentDialogState) => {
        switch (state) {
            case AnalyticConsentDialogState.Start: {
                return <AnalyticConsentAlertDialog />;
            }
            case AnalyticConsentDialogState.ForceUpversioAnalyticConsent: {
                return (
                    <ConsentAnalyticPage
                        useHandler={useDeviceInfoCheckDialogHandler}
                    />
                );
            }
        }
    };

    return (
        <AnalyticConsentDialogContext.Provider value={initialValue}>
            <CustomDialog fullScreen open={display}>
                {renderSwitch(analyticConsentDialogState)}
            </CustomDialog>
        </AnalyticConsentDialogContext.Provider>
    );
}

export default withTracking(AnalyticConsentDialog);
