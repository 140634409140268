import { useTranslation } from 'react-i18next';
import { HeaderActionType } from '../../Header/HeaderActionType';
import deleteAccountResource from './DeleteAccountResource.json';
import {
    ButtonResource,
    HeaderResource,
    ImageResource,
    TextResource,
} from '../../../Utils/PageUtils';

export type DeleteAccountResource = {
    header: HeaderResource;
    infoImage: ImageResource;
    title: TextResource;
    info: TextResource;
    buttons: {
        primary: ButtonResource;
        secondary: ButtonResource;
    };
};

function useDeleteAccountResource(): DeleteAccountResource {
    const { t } = useTranslation();

    const headerConfig = deleteAccountResource.header;
    const infoImageConfig = deleteAccountResource.infoImage;
    const titleConfig = deleteAccountResource.title;
    const infoConfig = deleteAccountResource.info;
    const buttonsConfig = deleteAccountResource.buttons;

    const header = {
        id: headerConfig.id,
        text: t(`common:Common_Feature${headerConfig.translationKey}`),
        type: HeaderActionType.NoAction,
    };

    const infoImage = {
        id: infoImageConfig.id,
        src: `/resources/consent/delete.svg`,
        alt: t(`consent:Consent_Feature${infoImageConfig.translationKey}`),
    };

    const title = {
        id: titleConfig.id,
        text: t(`consent:Consent_Feature${titleConfig.translationKey}`),
    };

    const info = {
        id: infoConfig.id,
        text: t(`consent:Consent_Feature${infoConfig.translationKey}`),
    };

    const buttons = {
        primary: {
            id: buttonsConfig.primary.id,
            label: t(
                `common:Common_Feature${buttonsConfig.primary.translationKey}`
            ),
        },
        secondary: {
            id: buttonsConfig.secondary.id,
            label: t(
                `common:Common_Feature${buttonsConfig.secondary.translationKey}`
            ),
        },
    };

    return {
        header,
        title,
        infoImage,
        info,
        buttons,
    };
}

export default useDeleteAccountResource;
