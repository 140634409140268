import { useContext } from 'react';
import { CustomerServiceContactService } from '../../../../../Services/Configuration/CustomerServiceContactService';
import { MenuContext } from '../../MenuContext';
import { itemHandler } from '../../MenuUtils';
import { getUserDeviceModel } from '../../../../../Services/ResourceService';
import { isBluetoothDeviceByModel } from '../../../../../Utils/BluetoothDeviceUtils';
import useMenuDialogHandler from '../../MenuDialogHandler';
import {
    goToUrl,
    openNewTabWithUrl,
} from '../../../../../Utils/NavigationUtils';
import { MainViewContext } from '../../../../../Pages/MainComponents/MainView/MainViewContext';
import { MainViewWorkflow } from '../../../../../Pages/MainComponents/MainView/MainViewModel';

export type instructionsHandlers = {
    video: itemHandler;
};

export default function useInstructionsMenuHandler(): instructionsHandlers {
    const { onClose } = useContext(MenuContext);
    const { onClickExternalLinkMenu } = useMenuDialogHandler();
    const viewContext = useContext(MainViewContext);

    return {
        video: () => {
            if (viewContext.workflowType === MainViewWorkflow.Web) {
                isBluetoothDeviceByModel(getUserDeviceModel())
                    ? goToUrl(
                          `${CustomerServiceContactService.getCountrySpecificVibeHearingWebSupportAddress(
                              false
                          )}`
                      )
                    : openNewTabWithUrl(
                          `${CustomerServiceContactService.getCountrySpecificVibeHearingWebSupportAddress(
                              false
                          )}`
                      );
            } else {
                onClickExternalLinkMenu(
                    `${CustomerServiceContactService.getCountrySpecificVibeHearingWebSupportAddress(
                        false
                    )}`
                );
            }
            if (onClose) onClose();
        },
    };
}
