import { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HearingLossAssessmentState } from '../../../Models/HLAA/HearingLossAssessmentState';
import { HLAAState } from '../../../Models/HLAA/HLAAState';
import IHlaaState from '../../../Redux/Models/HLAA/IHlaaState';
import { updateHlaa } from '../../../Redux/Reducers/HlaaSlice';
import { RootState } from '../../../Redux/Reducers/RootReducer';
import ArcToneService from '../../../Services/ArcToneService';
import AudioService from '../../../Services/AudioService';
import HLAAService from '../../../Services/HLAAService';
import LocalStorageService from '../../../Services/LocalStorageService';
import LoggingService from '../../../Services/LoggingService';
import TelemetryService from '../../../Services/Monitoring/TelemetryService';
import PatientManagementService from '../../../Services/PatientManagementService';
import { VoidReturn } from '../../../Utils/PageUtils';
import { ProgressBarCalculateCurrentProgress } from '../../../Utils/ProgressBarService';
import { RunningState, useRunning } from '../../../Utils/UseRunning';
import { MainViewContext } from '../../MainComponents/MainView/MainViewContext';
import { MainViewWorkflow } from '../../MainComponents/MainView/MainViewModel';
import { HearingAssessmentState, HLAAContext } from '../HLAAContext';

export type HearingAssessmentConfirmationeHandler = {
    handleNextState: (numberOfToneHeard: number) => VoidReturn;
    replay: () => VoidReturn;
    getTopProgress: () => number;
    error: string;
    buttonDisplay: boolean;
    setButtonVisibility: (visible: boolean) => VoidReturn;
};

export default function useHearingAssessmentConfirmationHandler(): HearingAssessmentConfirmationeHandler {
    const hlaaReduxState = useSelector(
        (state: RootState) => state.hlaa
    ) as IHlaaState;
    const dispatch = useDispatch();
    const [_Ignore, error, setRunning] = useRunning();
    const { info, setHearingAssessmentState } = useContext(HLAAContext);
    const { workflowType } = useContext(MainViewContext);
    const [buttonDisplay, setButtonDisplay] = useState(false);

    const setButtonVisibility = (visible: boolean) => {
        setButtonDisplay(visible);
    };

    const getTopProgress = (): number => {
        return ProgressBarCalculateCurrentProgress(
            info.current.totalPageCount,
            info.current.totalPagesForProgress
        );
    };

    const handleNextState = async (numberOfToneHeard: number) => {
        LoggingService.info({
            componentName: handleNextState.name,
            args: [`You select hearing ${numberOfToneHeard} tone(s).`],
        });

        const hlaaTestId = info.current.hlaaTestId;

        let ishlaa0ToneFirstRun =
            hlaaReduxState.hlaa0ToneFirstRunState[info.current.activeSide];

        //Frontend check that guarantee 0 tone rule is not applied in Vibe App workflow
        if (workflowType !== MainViewWorkflow.Web) ishlaa0ToneFirstRun = false;

        await HLAAService.identifyThreshold(
            hlaaTestId,
            numberOfToneHeard,
            ishlaa0ToneFirstRun
        );
        onNextState(hlaaTestId);
    };

    const onNextState = async (testId: string) => {
        const hlaaState = await HLAAService.getHLAAState(testId);
        LoggingService.info({
            componentName: onNextState.name,
            args: [
                `Next action is ${hlaaState.action} for ${hlaaState.side} side. Tone SeqId ${hlaaState.seqId}, freqId ${hlaaState.freqId} will be played.`,
            ],
        });
        switch (hlaaState.action) {
            case HearingLossAssessmentState.NotStarted.toString(): {
                info.current.startKeepAlive = true;
                info.current.hlaaState = new HLAAState('', '', -1, -1);
                info.current.left = { hlaaDone: false };
                info.current.right = { hlaaDone: false };

                setHearingAssessmentState(HearingAssessmentState.Start);
                break;
            }
            case HearingLossAssessmentState.Continue.toString(): {
                let totalPageCount = info.current.totalPageCount;

                const brandId =
                    LocalStorageService.serviceInstance.getDeviceBrandId(
                        info.current.activeSide
                    );
                const pairingAddress =
                    LocalStorageService.serviceInstance.getPairingAddress(
                        info.current.activeSide
                    );

                if (info.current.hlaaState.freqId !== hlaaState.freqId) {
                    const duration = Math.round(
                        (new Date().getTime() - info.current.freqStartTime) /
                            1000
                    );
                    info.current.freqStartTime = new Date().getTime();
                    const side =
                        info.current.activeSide === 'left' ? 'Left' : 'Right';
                    TelemetryService.serviceInstance.TrackWithIdentity(
                        `EasyFit-HLAA-${side}-Freq${info.current.hlaaState.freqId}`,
                        {
                            Duration: duration.toString(),
                        }
                    );
                    await AudioService.serviceInstance.prepAudio(
                        ArcToneService.setFrequency(
                            brandId,
                            pairingAddress,
                            hlaaState.freqId
                        ),
                        'SetFrequency'
                    );
                    totalPageCount = totalPageCount + 1;
                }

                info.current.startKeepAlive = true;
                info.current.hlaaState = hlaaState;
                info.current.totalPageCount = totalPageCount;

                setHearingAssessmentState(
                    HearingAssessmentState.AssessmentOneSide
                );
                break;
            }
            case HearingLossAssessmentState.Reinstruct.toString(): {
                TelemetryService.serviceInstance.TrackWithIdentity(
                    'EasyFit-UserIssue',
                    {
                        Issue: 'HLAACannotDetermineHearingProfile',
                    }
                );
                info.current.startKeepAlive = true;
                setHearingAssessmentState(HearingAssessmentState.Reinstruct);
                break;
            }
            case HearingLossAssessmentState.AbortMaxPresentedToneReach.toString(): {
                TelemetryService.serviceInstance.TrackWithIdentity(
                    'EasyFit-UserIssue',
                    {
                        Issue: 'HLAADifficultyHearingBeep',
                    }
                );
                info.current.startKeepAlive = true;
                setHearingAssessmentState(
                    HearingAssessmentState.AbortMaxPresentedToneReach
                );

                break;
            }
            case HearingLossAssessmentState.Constant0ToneHeard.toString(): {
                TelemetryService.serviceInstance.TrackWithIdentity(
                    'EasyFit-UserIssue',
                    {
                        Issue: 'HLAAConnectionIsNotStable',
                    }
                );
                dispatch(
                    updateHlaa({
                        ...hlaaReduxState,
                        hlaa0ToneFirstRunState: {
                            ...hlaaReduxState.hlaa0ToneFirstRunState,
                            [info.current.activeSide]: false,
                        },
                    })
                );

                info.current.startKeepAlive = true;

                const workflow =
                    await PatientManagementService.getCompletedWorkflow();
                LocalStorageService.serviceInstance.setCompletedWorkflow(
                    Number(workflow)
                );

                setHearingAssessmentState(
                    HearingAssessmentState.Constant0ToneHeard
                );

                break;
            }
            case HearingLossAssessmentState.LeftEndRightNext.toString():
            case HearingLossAssessmentState.RightEndLeftNext.toString(): {
                const duration = Math.round(
                    (new Date().getTime() - info.current.freqStartTime) / 1000
                );
                info.current.freqStartTime = new Date().getTime();
                const side =
                    info.current.activeSide === 'left' ? 'Left' : 'Right';
                TelemetryService.serviceInstance.TrackWithIdentity(
                    `EasyFit-HLAA-${side}-Freq${info.current.hlaaState.freqId}`,
                    {
                        Duration: duration.toString(),
                    }
                );

                const totalPageCount = info.current.totalPageCount + 1;

                info.current.startKeepAlive = true;
                info.current.totalPageCount = totalPageCount;
                setHearingAssessmentState(HearingAssessmentState.OneSideDone);
                break;
            }
            case HearingLossAssessmentState.Complete.toString(): {
                const duration = Math.round(
                    (new Date().getTime() - info.current.freqStartTime) / 1000
                );
                const side =
                    info.current.activeSide === 'left' ? 'Left' : 'Right';
                TelemetryService.serviceInstance.TrackWithIdentity(
                    `EasyFit-HLAA-${side}-Freq${info.current.hlaaState.freqId}`,
                    {
                        Duration: duration.toString(),
                    }
                );

                LocalStorageService.serviceInstance.setLastCompletedHlaaTestId(
                    testId
                );
                await onCompletedHLAATest();

                const totalPageCount = info.current.totalPageCount + 1;

                info.current.totalPageCount = totalPageCount;

                info.current.startKeepAlive = false;
                setHearingAssessmentState(HearingAssessmentState.OneSideDone);

                TelemetryService.serviceInstance.TrackWithIdentity(
                    'EasyFit-CompletedWorkflow',
                    {
                        Workflow: 'HLAARight',
                    }
                );

                break;
            }
        }
    };

    const onCompletedHLAATest = async () => {
        await onDoneAssessment();
        setRunning(RunningState.Completed);
    };

    const onDoneAssessment = async () => {
        try {
            setRunning(RunningState.Running);
            const brandId =
                LocalStorageService.serviceInstance.getDeviceBrandId(
                    info.current.activeSide
                );
            const pairingAddressActive =
                LocalStorageService.serviceInstance.getPairingAddress(
                    info.current.activeSide
                );
            const pairingAddressInactive =
                LocalStorageService.serviceInstance.getPairingAddress(
                    info.current.activeSide === 'left' ? 'right' : 'left'
                );
            await exitHLAA(brandId, pairingAddressActive);
            await AudioService.serviceInstance.prepAudio(
                ArcToneService.unmuteDevice(brandId, pairingAddressInactive),
                'UnmuteDevice'
            );
            setRunning(RunningState.Completed);
        } catch (error) {
            setRunning(
                RunningState.Error,
                `Error when exit assessment for ${info.current.activeSide} side: ${error}.`
            );
            throw error;
        }
    };

    const exitHLAA = async (brandId: number, pairingAddress: number) => {
        await AudioService.serviceInstance.prepAudio(
            ArcToneService.getExitAssessment(brandId, pairingAddress),
            'ExitAssessment'
        );
    };

    const replay = () => {
        TelemetryService.serviceInstance.TrackWithIdentity(
            'EasyFit-FeatureUsage',
            { Feature: 'HLAAReplay' }
        );
        info.current.startKeepAlive = false;
        setHearingAssessmentState(HearingAssessmentState.AssessmentOneSide);
    };

    return {
        handleNextState,
        replay,
        getTopProgress,
        error,
        buttonDisplay,
        setButtonVisibility,
    };
}
