import { useContext } from 'react';
import { TroubleShootingHandler } from '../../../../Pages/MainComponents/TroubleShooting/TroubleShooting';
import AudioService from '../../../../Services/AudioService';
import { RedoHLAAContext, RedoHLAAState } from '../RedoHLAAContext';

function useRedoHLAATroubleShootingHandler(): TroubleShootingHandler {
    const { setRedoHLAAState } = useContext(RedoHLAAContext);

    const onStartAudioContext = async () => {
        await AudioService.serviceInstance.createNewAudioContext();
    };

    const onRetry = async () => {
        await onStartAudioContext();
        setRedoHLAAState(RedoHLAAState.OneSideSetUp);
    };

    return {
        onRetry,
    };
}

export default useRedoHLAATroubleShootingHandler;
