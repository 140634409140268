import React from 'react';
import { ButtonType } from '../../../SharedComponents/Button/ButtonType';
import CustomButton from '../../../SharedComponents/Button/CustomButton';
import AppContainer from '../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../SharedComponents/Container/BodyContainer';
import CustomImg from '../../../SharedComponents/CustomImage/CustomImg';
import Header from '../../../SharedComponents/Header/HeaderRefactor';
import CustomTypography, {
    TypographyType,
} from '../../../SharedComponents/Typography/CustomTypography';
import './FineTuningInfoPage.scss';
import useFineTuningInfoPageHandler from './FineTuningInfoPageHandlerHook';
import useFineTuningInfoPageResource from './FineTuningInfoPageResourceHook';

const FineTuningInfoPage = (): JSX.Element => {
    const { headerText, infoImgSrc, instruction1, instruction2, buttonText } =
        useFineTuningInfoPageResource();
    const { goStart } = useFineTuningInfoPageHandler();
    return (
        <AppContainer>
            <Header
                headerContent={{ text: headerText, id: 'header-fine-tuning' }}
            />
            <BodyContainer>
                <CustomImg
                    imgType={'iconLarge'}
                    id={'img-info'}
                    src={infoImgSrc}
                    alt={'img-info'}
                />
                <CustomTypography
                    id={'txt-fine-tuning-info-instruction1'}
                    type={TypographyType.notoSansSubHeading}
                    text={instruction1}
                    className={'finetuninInfoSubHeading'}
                />
                <CustomTypography
                    id={'txt-fine-tuning-info-instruction2'}
                    type={TypographyType.notoSansBody}
                    text={instruction2}
                    className={'noTopMargin'}
                />
            </BodyContainer>
            <CustomButton
                id={'btn-got-it'}
                buttonType={ButtonType.Primary}
                onClick={goStart}
            >
                {buttonText}
            </CustomButton>
        </AppContainer>
    );
};

export default FineTuningInfoPage;
