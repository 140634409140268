import { useTranslation } from 'react-i18next';
import { HeaderResource, TextResource } from '../../../Utils/PageUtils';
import { HeaderActionType } from '../../Header/HeaderActionType';

type DataCollectionResource = {
    header: HeaderResource;
    dataSwitch: TextResource;
    info: TextResource;
};

export default function useDataCollectionResource(): DataCollectionResource {
    const { t } = useTranslation();
    const header = {
        id: 'header-data-collection',
        type: HeaderActionType.Close,
        text: t('common:Common_Feature:UsageStatistics'),
    };

    const dataSwitch = {
        id: 'switch-data-collection',
        text: t(`consent:Consent_Feature:Text3`),
    };

    const info = {
        id: 'txt-revokeDataAnalytic',
        text: t(`consent:Consent_Feature:Text4`),
    };

    return {
        header,
        dataSwitch,
        info,
    };
}
