import { useContext } from 'react';
import { MainViewContext } from '../../../Pages/MainComponents/MainView/MainViewContext';
import {
    MainViewWorkflow,
    TransitionAction,
} from '../../../Pages/MainComponents/MainView/MainViewModel';
import PatientManagementService from '../../../Services/PatientManagementService';
import { goToUrl } from '../../../Utils/NavigationUtils';

export type DeleteAccountHandler = {
    deleteAccount: () => Promise<void>;
    onCancel: (() => void) | undefined;
};

function useDeleteAccountHandler(onClose?: () => void): DeleteAccountHandler {
    const viewContext = useContext(MainViewContext);

    async function deleteAccount() {
        await PatientManagementService.DeleteIDSAccount();
        if (onClose) onClose();
        viewContext.doTransition(TransitionAction.DeleteAccount);
    }

    const cancelAndGoToUrl = () => {
        goToUrl('https://www.vibe-hearing.com');
    };

    // For delete account workflow, if cancel, will redirect to vibe-hearing.com
    // Otherwise, will be web workflow via the menu and cancel will be the same as onClose
    const onCancel =
        viewContext.workflowType == MainViewWorkflow.DeleteAccount
            ? cancelAndGoToUrl
            : onClose;

    return {
        deleteAccount,
        onCancel,
    };
}

export default useDeleteAccountHandler;
