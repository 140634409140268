import { useTranslation } from 'react-i18next';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import { CustomerServiceContactService } from '../../../Services/Configuration/CustomerServiceContactService';
import { TroubleShootingResource } from '../../MainComponents/TroubleShooting/TroubleShooting';
import troubleShootingResource from './HearingAssessmentTroubleShootingResource.json';
import { getUserDeviceModel } from '../../../Services/ResourceService';
import { deviceModel } from '../../../Models/SupportedDeviceModel';

function useHearingAssessmentTroubleShootingResource(): TroubleShootingResource {
    const { t } = useTranslation();

    const getCustomerServiceSupportLink = () => {
        return CustomerServiceContactService.getGlobalWebSupportAddress();
    };

    const {
        headerContent,
        imgContent,
        troubleShootingMessageContent,
        buttonContent,
        customerSupportMessageContent,
        customerSupportLinkContent,
    } = troubleShootingResource;

    const header = {
        id: headerContent.id,
        text: t(headerContent.translationKey),
        type: HeaderActionType.Menu,
    };

    const image = imgContent;

    const troubleShootingMessage = {
        id: troubleShootingMessageContent.id,
        text:
            getUserDeviceModel() == deviceModel.VibeGo
                ? t(troubleShootingMessageContent.translationKey)
                : t(
                      'vibeComplete:VibeComplete_Device:TroubleShooting_Feature:Text1'
                  ),
    };

    const buttonLabel = {
        id: buttonContent.id,
        label: t(buttonContent.translationKey),
    };

    const customerSupportMessage = {
        id: customerSupportMessageContent.id,
        text: t(customerSupportMessageContent.translationKey),
    };

    const customerSupportLink = {
        id: customerSupportLinkContent.id,
        text: getCustomerServiceSupportLink(),
    };

    return {
        header,
        image,
        troubleShootingMessage,
        buttonLabel,
        customerSupportMessage,
        customerSupportLink,
    };
}

export default useHearingAssessmentTroubleShootingResource;
