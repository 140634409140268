import { useCallback, useContext } from 'react';
import { MainViewContext } from '../../../../../Pages/MainComponents/MainView/MainViewContext';
import { MainViewWorkflow } from '../../../../../Pages/MainComponents/MainView/MainViewModel';
import { t } from '../../../../../Services/LocalizationService';
import LocalStorageService from '../../../../../Services/LocalStorageService';
import { SubMenuListResource } from '../../../../../Utils/PageUtils';
import { HeaderActionType } from '../../../../Header/HeaderActionType';
import { MenuContext } from '../../MenuContext';
import { MenuDialogType } from '../../MenuDialogType';
import { createSubMenuItem } from '../../MenuUtils';
import useSettingsMenuHandler from './SettingsMenuHandler';

export default function useSettingsMenuResource(): SubMenuListResource {
    const settingMenuHandlers = useSettingsMenuHandler();
    const { pushMenu: renderNextMenu } = useContext(MenuContext);
    const viewContext = useContext(MainViewContext);
    const createSettingsMenuList = useCallback(
        (isOperationDataConsented: boolean) => {
            const menuList = [];

            const languageSelectionSubMenu = createSubMenuItem(
                'item-languageSelection',
                t('menu:Menu_Feature:SubMenu:Settings:Text1'),
                () => renderNextMenu(MenuDialogType.LanguageSelection)
            );
            menuList.push(languageSelectionSubMenu);

            if (isOperationDataConsented) {
                const accountSettingsSubMenu = createSubMenuItem(
                    'item-accountSettings',
                    t('menu:Menu_Feature:SubMenu:Settings:Text3'),
                    () => renderNextMenu(MenuDialogType.AccountSettings)
                );
                menuList.push(accountSettingsSubMenu);
            }

            if (viewContext.workflowType === MainViewWorkflow.Web) {
                const logOutSubMenu = createSubMenuItem(
                    'item-logout',
                    t('menu:Menu_Feature:SubMenu:Settings:Text2'),
                    settingMenuHandlers.logout
                );
                menuList.push(logOutSubMenu);
            }

            return menuList;
        },
        [renderNextMenu, settingMenuHandlers.logout, viewContext]
    );
    const settingsMenuLists = createSettingsMenuList(
        LocalStorageService.serviceInstance.getOperationConsentState()
    );

    return {
        header: {
            id: 'header-settings-menu',
            text: t('menu:Menu_Feature:Main:Text5'),
            type: HeaderActionType.Close,
        },
        menuItems: settingsMenuLists,
    };
}
