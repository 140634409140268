import { useEffect, useRef, useState } from 'react';
import { sleep } from '../../Utils/ResourcesUtils';
import { IBubblePlayToneIcon } from './BubblePlayToneIcon';

export type PlayToneHandler = {
    displayed: boolean;
    elementRef: React.MutableRefObject<null>;
};

export function usePlayToneHandler(
    props: IBubblePlayToneIcon
): PlayToneHandler {
    const [displayed, setDisplayed] = useState(false);

    const elementRef = useRef(null);

    useEffect(() => {
        const updateBubblePlayToneIcon = async () => {
            if (displayed) {
                await sleep(props.displayDuration);
                if (elementRef.current !== null) setDisplayed(false);
            } else {
                await sleep(props.waitingDuration);
                if (elementRef.current !== null) setDisplayed(true);
            }
        };

        updateBubblePlayToneIcon();
    }, [displayed, props.displayDuration, props.waitingDuration]);

    return {
        displayed,
        elementRef,
    };
}
