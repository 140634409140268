import AuthService from '../../../Services/Authentication/AuthService';
import LoggingService from '../../../Services/LoggingService';
import {
    ITransitionHandler,
    ViewState,
    PageState,
    DialogState,
    TransitionAction,
} from './MainViewModel';

const useDeleteAccountTransitionHandler = (): ITransitionHandler => {
    const doTransition = (
        currentView: ViewState,
        action: TransitionAction
    ): ViewState => {
        let nextDialog = DialogState.None;
        let nextPage = PageState.None;
        switch (action) {
            case TransitionAction.DeleteAccount:
                nextDialog = DialogState.AccountDeleted;
                nextPage = PageState.None;
                break;
            default:
                nextDialog = currentView.dialog;
                nextPage = currentView.page;
        }
        return {
            dialog: nextDialog,
            page: nextPage,
        };
    };

    const goToInitialState = async (): Promise<ViewState> => {
        let nextPage = PageState.None;
        const nextDialog = DialogState.None;

        const userLoggedInForCurrentSession =
            AuthService.adB2cInstance.hasUserLoggedInForCurrentSession() ??
            false;

        const userAuthFromPrevSession =
            !userLoggedInForCurrentSession &&
            AuthService.adB2cInstance.getIsAuthenticated();

        const userAuthFromCurrentSession =
            userLoggedInForCurrentSession &&
            AuthService.adB2cInstance.getIsAuthenticated();

        if (userAuthFromPrevSession) AuthService.adB2cInstance.logout();

        nextPage = userAuthFromCurrentSession
            ? PageState.DeleteAccount
            : PageState.Loading;

        LoggingService.info({
            componentName:
                'DeleteAccountTransitionHandlerHook.goToInitialState',
            args: [
                `Initial state: ${PageState[nextPage]} with dialog ${DialogState[nextDialog]}`,
            ],
        });
        return { dialog: nextDialog, page: nextPage } as ViewState;
    };
    return {
        doTransition,
        goToInitialState,
    };
};

export default useDeleteAccountTransitionHandler;
