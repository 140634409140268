import { useTranslation } from 'react-i18next';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import {
    ButtonResource,
    HeaderResource,
    ImageResource,
    TextResource,
} from '../../../Utils/PageUtils';
import { deviceModelId } from '../../../Models/SupportedDeviceModel';
import { getUserDeviceModelFromURLParams } from '../../../Services/ResourceService';

type WelcomeResource = {
    header: HeaderResource;
    illustrationImage: ImageResource;
    title: TextResource;
    subtitle: TextResource;
    continueButton: ButtonResource;
};

function useWelcomeResource(): WelcomeResource {
    const { t } = useTranslation();
    const header = {
        id: 'header-welcome',
        type: HeaderActionType.Menu,
        iconImgSrc: '/resources/shared/vibe-logo.svg',
    };

    const illustrationImage = {
        id: 'img-easyfitillustration',
        src: '/resources/shared/easyfit-illustration.svg',
        alt: t('welcome:Welcome_Feature:TitleText'),
    };

    const title = {
        id: 'txt-title',
        text: t('welcome:Welcome_Feature:TitleText'),
    };

    const subtitle = {
        id: 'txt-subtitle',
        text:
            getUserDeviceModelFromURLParams() == deviceModelId.VibeGo
                ? t('vibeGo:VibeGo_Device:Welcome_Feature:Text')
                : t('welcome:Welcome_Feature:Text'),
    };

    const continueButton = {
        id: 'btn-continue',
        label: t('welcome:Welcome_Feature:NextButton'),
    };

    return {
        header,
        illustrationImage,
        title,
        subtitle,
        continueButton,
    };
}

export default useWelcomeResource;
