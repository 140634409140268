import { Cookies } from 'react-cookie';

export default class CookiesService {
    public static serviceInstance: CookiesService = new CookiesService();
    private cookies: Cookies;

    constructor() {
        this.cookies = new Cookies();
    }

    public clearCookies(): void {
        const allCookies = ['ai_session', 'ai_user'];

        allCookies.forEach((value) => {
            if (this.cookies.get(value) !== undefined)
                this.cookies.remove(value);
        });
    }
}
