import { PropertiesPluginIdentifier } from '@microsoft/applicationinsights-common';
import {
    IPlugin,
    ITelemetryPluginChain,
} from '@microsoft/applicationinsights-core-js';
import {
    ApplicationInsights as AppInsightsPlugin,
    IAppInsightsCore,
    IConfig,
    IConfiguration,
    ITelemetryItem,
    ITelemetryPlugin,
    PropertiesPlugin,
} from '@microsoft/applicationinsights-web';
import { ObjectWithAnyKey } from '../../Utils/ResourcesUtils';
import LoggingService from '../LoggingService';
import IReactAISettings from './IReactAISettings';

/**
 * Module to include Microsoft Application Insights in React applications.
 *
 * @export
 * @class TelemetryProcessorPlugin
 */
export default class TelemetryProcessorPlugin implements ITelemetryPlugin {
    public extensionId = 'ApplicationInsightsReactUsage';
    public ApplicationInsightsAnalyticsIdentifier =
        'ApplicationInsightsAnalytics';
    public processTelemetry: (env: ITelemetryItem) => void;
    public identifier = this.extensionId;
    public priority = 190;
    public appInsights!: AppInsightsPlugin;
    private propertiesPlugin!: PropertiesPlugin;

    private nextPlugin!: ITelemetryPlugin;
    private contextProps: ObjectWithAnyKey = {};
    private debug = false;
    private obfuscatedHeaders: string[] = ['Authorization'];

    public constructor() {
        this.processTelemetry = this.customDimensionsInitializer.bind(this);
    }

    public setNextPlugin(
        plugin: ITelemetryPlugin | ITelemetryPluginChain
    ): void {
        this.nextPlugin = plugin as ITelemetryPlugin;
    }

    /**
     * Returns the current value of context/custom dimensions.
     *
     * @readonly
     * @type {{ [key: string]: any }}
     * @memberof ReactAI
     */
    public get context(): ObjectWithAnyKey {
        return this.contextProps || {};
    }

    /**
     * Returns if ReactAI is in debug mode.
     *
     * @readonly
     * @type {boolean}
     * @memberof ReactAI
     */
    public get isDebugMode(): boolean {
        return this.debug;
    }

    /**
     * Initializes a singleton instance of ReactAI based on supplied parameters.
     *
     * @param {IReactAISettings} settings
     * @memberof ReactAI
     */
    public initialize(
        settings: IReactAISettings & IConfiguration & IConfig,
        core: IAppInsightsCore,
        extensions: IPlugin[]
    ): void {
        const reactAISettings =
            settings.extensionConfig &&
            settings.extensionConfig[this.identifier]
                ? (settings.extensionConfig[
                      this.identifier
                  ] as IReactAISettings)
                : { debug: false };
        this.debug = reactAISettings.debug || false;
        this.setContext(reactAISettings.initialContext || {}, true);
        extensions.forEach((ext) => {
            const identifier = (ext as ITelemetryPlugin).identifier;
            if (identifier === this.ApplicationInsightsAnalyticsIdentifier) {
                this.appInsights = ext as unknown as AppInsightsPlugin;
            }

            if (identifier === PropertiesPluginIdentifier) {
                this.propertiesPlugin = ext as unknown as PropertiesPlugin;
            }
        });
    }

    /**
     * Set custom context/custom dimensions for Application Insights
     *
     * @param {{ [key: string]: any }} properties - custom properties to add to all outbound Application Insights telemetry
     * @param {boolean} [clearPrevious=false] - if false(default) multiple calls to setContext will append to/overwrite existing custom dimensions, if true the values are reset
     * @memberof ReactAI
     */
    public setContext(
        properties: ObjectWithAnyKey,
        clearPrevious = false
    ): void {
        if (clearPrevious) {
            this.contextProps = {};
            this.debugLog('context is reset.');
        }
        properties = properties || {};
        for (const key in properties) {
            if (Object.prototype.hasOwnProperty.call(properties, key)) {
                this.contextProps[key] = properties[key];
            }
        }
        this.debugLog('context is set to:', this.context);
    }

    private customDimensionsInitializer(
        envelope: ITelemetryItem
    ): boolean | void {
        envelope.baseData = envelope.baseData || {};
        envelope.baseData.properties = envelope.baseData.properties || {};
        const properties = envelope.baseData.properties;

        //loop headers and properties
        this.obfuscatedHeaders.forEach((element) => {
            for (const key in properties) {
                //if this property is one of the excluded headers set the property element to blank
                if (
                    Object.prototype.hasOwnProperty.call(
                        properties[key],
                        element
                    )
                ) {
                    properties[key][element] = '';
                }
            }
        });

        this.debugLog('properties set to:', properties);

        if (this.nextPlugin != null) {
            this.nextPlugin.processTelemetry(envelope);
        }
    }

    private debugLog(message: string, payload?: unknown): void {
        if (this.isDebugMode) {
            LoggingService.log({
                componentName: TelemetryProcessorPlugin.name,
                args: [
                    `TelemetryProcessorPlugin: ${message}`,
                    payload === undefined ? '' : payload,
                ],
            });
        }
    }
}

export const reactAI = new TelemetryProcessorPlugin();
