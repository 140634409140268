import React from 'react';
import PairButton from '../../Button/PairButton';
import AppContainer from '../../Container/AppContainer';
import BodyContainer from '../../Container/BodyContainer';
import CustomImg from '../../CustomImage/CustomImg';
import Header from '../../Header/HeaderRefactor';
import CustomTypography, {
    TypographyType,
} from '../../Typography/CustomTypography';
import { IDialogContentProps } from '../DialogUtils';
import useDeleteAccountHandler from './DeleteAccountHandlerHook';
import useDeleteAccountResource from './DeleteAccountResourceHook';
import './DeleteAccountStyle.scss';

export default function DeleteAccount({
    onClose,
}: IDialogContentProps): JSX.Element {
    const { header, infoImage, title, info, buttons } =
        useDeleteAccountResource();
    const { deleteAccount, onCancel } = useDeleteAccountHandler(onClose);

    return (
        <AppContainer>
            <Header headerContent={header} />
            <BodyContainer flexStart>
                <CustomImg
                    id={infoImage.id}
                    imgType={'small'}
                    src={infoImage.src}
                    alt={infoImage.alt}
                />
                <CustomTypography
                    id={title.id}
                    type={TypographyType.notoSansBodyBig}
                    text={title.text}
                    className={'deleteAccountTitleText'}
                />
                <CustomTypography
                    id={info.id}
                    type={TypographyType.notoSansBody}
                    text={info.text}
                    noMargin
                />
            </BodyContainer>
            <PairButton
                withLoading
                columnAlign
                secondaryButton={{
                    id: buttons.secondary.id,
                    onClick: deleteAccount,
                    children: buttons.secondary.label,
                }}
                primaryButton={{
                    id: buttons.primary.id,
                    onClick: onCancel,
                    children: buttons.primary.label,
                }}
            />
        </AppContainer>
    );
}
