import React from 'react';
import WarningDialog from '../Warning/WarningDialog';
import useFineTuningErrorResource from './FineTuningWorkflowErrorResourceHook';
import useFineTuningErrorHandler from './FineTuningWorkflowErrorHandlerHook';

export default function FineTuningError(): React.ReactElement {
    return (
        <WarningDialog
            useHandler={useFineTuningErrorHandler}
            useResource={useFineTuningErrorResource}
        />
    );
}
