export class MasterGain {
    public side: string;
    public masterGainId: number;
    public masterGainUiMinId: number;
    public masterGainUiMaxId: number;

    constructor(
        side: string,
        masterGainId: number,
        masterGainUiMinId: number,
        masterGainUiMaxId: number
    ) {
        this.side = side;
        this.masterGainId = masterGainId;
        this.masterGainUiMinId = masterGainUiMinId;
        this.masterGainUiMaxId = masterGainUiMaxId;
    }
}
