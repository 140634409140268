import { useTranslation } from 'react-i18next';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import { HeaderResource } from '../../../Utils/PageUtils';

type HearingAssessmentStartResource = {
    header: HeaderResource;

    instructionTitle: {
        id: string;
        text: string;
    };

    instruction: {
        id: string;
        text: string;
    };

    instructionSteps: {
        id: string;
        text: string;
    };

    startButton: {
        id: string;
        text: string;
    };
};

export default function useHearingAssessmentStartResource(): HearingAssessmentStartResource {
    const { t } = useTranslation();

    const header = {
        id: 'header-hlaa-start',
        text: t('common:Common_Feature:HearingProfileCheck'),
        prefix: '2.',
        type: HeaderActionType.Menu,
    };

    const instructionTitle = {
        id: 'txt-title',
        text: t('hlaa:Hlaa_Feature:start:TitleText'),
    };

    const instruction = {
        id: 'txt-instruction',
        text: t('hlaa:Hlaa_Feature:start:Text1'),
    };

    const instructionSteps = {
        id: 'txt-instruction-steps',
        text: t('hlaa:Hlaa_Feature:start:Text2'),
    };

    const startButton = {
        id: 'btn-start-assessment',
        text: t('common:Common_Feature:Continue'),
    };

    return {
        header,
        instructionTitle,
        instruction,
        instructionSteps,
        startButton,
    };
}
