import { useState, useEffect, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ConsentValue } from '../../../Models/Patient/PatientConsentData';
import AuthService from '../../../Services/Authentication/AuthService';
import {
    addResourceBundle,
    getCurrentLocale,
} from '../../../Services/LocalizationService';
import LocalStorageService from '../../../Services/LocalStorageService';
import TelemetryService from '../../../Services/Monitoring/TelemetryService';
import PatientManagementService from '../../../Services/PatientManagementService';
import { getUserDeviceModel } from '../../../Services/ResourceService';
import { VoidReturn } from '../../../Utils/PageUtils';
import { MainViewContext } from '../MainView/MainViewContext';
import * as deviceInfo from 'react-device-detect';
import { TransitionAction } from '../MainView/MainViewModel';
import { DeviceInfo } from '../../../Models/DeviceInfo';
import { mapEncodedModelIdToDefault } from '../../../Utils/BluetoothDeviceUtils';
import { getCouplingOrDefault } from '../../../Utils/CouplingUtils';
import {
    getActiveConsentVersion,
    getCustomConsentVersion,
    getResidingCountry,
} from '../../../Utils/ConsentVersionUtils';

export type ConsentAnalyticHandler = {
    showLegalInfo: boolean;
    onContinue: (userConsented: boolean) => void;
    openHyperLinkPrivacy: () => VoidReturn;
    closeDialog: () => VoidReturn;
};

function useConsentAnalyticHandler(
    setLoading: React.Dispatch<boolean>
): ConsentAnalyticHandler {
    const history = useHistory();
    const [showLegalInfo, setShowLegalInfo] = useState(false);
    const viewContext = useContext(MainViewContext);

    const loading = useCallback(async () => {
        setLoading(true);
        if (getCustomConsentVersion()) {
            await addResourceBundle(
                `Legal/${getResidingCountry()}/`,
                'consent'
            );
        }
        const patientId = LocalStorageService.serviceInstance.getPatientId();
        if (patientId === '') {
            const refreshCookies = async () => {
                await AuthService.adB2cInstance.refreshCookies();
            };
            refreshCookies();
        }

        setLoading(false);
    }, [setLoading]);

    useEffect(() => {
        loading();
    }, [history, loading]);

    const onContinue = async (userConsented: boolean) => {
        const userSelectedLocale = getCurrentLocale();
        const userResidingCountry =
            LocalStorageService.serviceInstance.getSelectedCountry();
        const patientId = LocalStorageService.serviceInstance.getPatientId();
        const activeConsentVersion = getActiveConsentVersion();
        await PatientManagementService.createPatient(patientId);
        await PatientManagementService.updateLanguage(userSelectedLocale);

        await PatientManagementService.updateCountry(userResidingCountry);

        LocalStorageService.serviceInstance.setOperationConsentState(true);
        await PatientManagementService.ConsentOperationData(
            activeConsentVersion
        );

        if (userConsented) {
            LocalStorageService.serviceInstance.setDataAnalyticConsentState(
                ConsentValue.Agreed
            );
            await PatientManagementService.ConsentAnalyticData(
                activeConsentVersion
            );
            TelemetryService.serviceInstance.TrackWithIdentity(
                'EasyFit-DataAnalytic',
                {
                    Consent: 'Agreed',
                }
            );
        } else {
            LocalStorageService.serviceInstance.setDataAnalyticConsentState(
                ConsentValue.Declined
            );
            await PatientManagementService.RevokeAnalyticData(
                activeConsentVersion
            );
            TelemetryService.serviceInstance.TrackWithIdentity(
                'EasyFit-DataAnalytic',
                {
                    Consent: 'Declined',
                }
            );
        }

        // setup devices
        const leftModelId =
            LocalStorageService.serviceInstance.getDeviceModelId('left');
        const rightModelId =
            LocalStorageService.serviceInstance.getDeviceModelId('right');
        // only supporting binaural devices at this time
        if (leftModelId == -1 || rightModelId == -1) {
            const devices = setDeviceListFromParams();
            LocalStorageService.serviceInstance.clearQueryParams();
            if (
                devices.length === 0 ||
                devices[0].modelId == '-1' ||
                devices[1].modelId == '-1'
            ) {
                await PatientManagementService.SetVibeAirDevices();
            } else {
                await PatientManagementService.SetDevices(devices);
                await PatientManagementService.getPatient();
            }
        }

        TelemetryService.serviceInstance.TrackWithIdentity('EasyFit-HiModel', {
            Model: getUserDeviceModel(),
        });

        TelemetryService.serviceInstance.TrackWithIdentity('EasyFit-UserEnv', {
            //All: JSON.stringify(deviceInfo.deviceDetect()),
            Browser: deviceInfo.browserName,
            BrowserVersion: deviceInfo.browserVersion,
            Mobile: deviceInfo.mobileModel,
            OS: deviceInfo.osName,
            OSVersion: deviceInfo.osVersion,
            ResidingCountry: userResidingCountry,
        });

        LocalStorageService.serviceInstance.setSelectedCountry(null);

        viewContext.doTransition(TransitionAction.AnalyticsConsentContinue);
    };

    const setDeviceListFromParams = (): DeviceInfo[] => {
        const deviceList = [];
        const paramsString =
            LocalStorageService.serviceInstance.getQueryParams();
        const params = new URLSearchParams(paramsString);
        const platformType = params.get('platformId');
        const leftModelId = mapEncodedModelIdToDefault(
            params.get('leftModel') as string,
            platformType as string
        );
        const rightModelId = mapEncodedModelIdToDefault(
            params.get('rightModel') as string,
            platformType as string
        );
        const leftPairingAddress = Number(params.get('leftArc'));
        const rightPairingAddress = Number(params.get('rightArc'));
        if (leftModelId && leftPairingAddress) {
            deviceList.push({
                side: 'Left',
                brandId: 15,
                modelId: leftModelId,
                pairingAddress: leftPairingAddress,
                couplingId: getCouplingOrDefault('left', leftModelId),
            });
        }
        if (rightModelId && rightPairingAddress) {
            deviceList.push({
                side: 'Right',
                brandId: 15,
                modelId: rightModelId,
                pairingAddress: rightPairingAddress,
                couplingId: getCouplingOrDefault('right', rightModelId),
            });
        }
        return deviceList;
    };

    const openHyperLinkPrivacy = () => {
        setShowLegalInfo(true);
    };

    const closeDialog = () => {
        setShowLegalInfo(false);
    };

    return {
        showLegalInfo,
        onContinue,
        openHyperLinkPrivacy,
        closeDialog,
    };
}

export default useConsentAnalyticHandler;
