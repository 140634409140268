import React from 'react';
import AppContainer from '../../../../Container/AppContainer';
import BodyContainer from '../../../../Container/BodyContainer';
import Header from '../../../../Header/HeaderRefactor';
import MenuList from '../../../../List/MenuList';
import { IDialogContentProps } from '../../../DialogUtils';
import useMainMenuHandler from './MainMenuHandlerHook';
import useMainMenuResource from './MainMenuResourceHook';

export default function MainMenu({
    onClose,
}: IDialogContentProps): React.ReactElement {
    const menuItemHandlers = useMainMenuHandler(onClose);
    const { header, menuItems } = useMainMenuResource(menuItemHandlers);

    return (
        <AppContainer>
            <Header
                headerContent={header}
                onClick={async () => {
                    if (onClose) onClose();
                }}
            />
            <BodyContainer flexStart>
                <MenuList menuItems={menuItems} />
            </BodyContainer>
        </AppContainer>
    );
}
