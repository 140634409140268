import React from 'react';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import { SubMenuListResource } from '../../../Utils/PageUtils';
import AppContainer from '../../Container/AppContainer';
import BodyContainer from '../../Container/BodyContainer';
import Header from '../../Header/HeaderRefactor';
import SubMenuList from '../../List/SubMenuList';
import { IDialogContentProps } from '../DialogUtils';

export type SubMenuDialogHandler = {
    getSelectedItems: () => string[];
};

export interface ISubMenuDialogProps extends IDialogContentProps {
    useResource: () => SubMenuListResource;
    useHandler?: () => SubMenuDialogHandler;
}

function SubMenuDialog({
    onClose,
    useResource,
    useHandler = () => {
        return {
            getSelectedItems: () => [''],
        };
    },
}: ISubMenuDialogProps) {
    const { getSelectedItems } = useHandler();
    const { header, menuItems } = useResource();

    return (
        <AppContainer>
            <Header headerContent={header} onClick={onClose} />
            <BodyContainer flexStart>
                <SubMenuList
                    menuItems={menuItems}
                    getSelectedItems={getSelectedItems}
                />
            </BodyContainer>
        </AppContainer>
    );
}

export default withTracking(SubMenuDialog);
