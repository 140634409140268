import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MainViewContext } from '../../../../Pages/MainComponents/MainView/MainViewContext';
import {
    switchResource,
    switchWebAppVibeApp,
} from '../../../../Utils/PageUtils';
import { HeaderActionType } from '../../../Header/HeaderActionType';
import { WarningDialogResource } from '../../Warning/WarningDialog';
import redoHLAADialogResource from './RedoHLAADialogResource.json';

function useRedoHLAADialogResource(): WarningDialogResource {
    const { t } = useTranslation();
    const { workflowType } = useContext(MainViewContext);
    const { headerContent, imgContent, warningInfoContent, buttons } =
        redoHLAADialogResource;

    const header = {
        id: headerContent.id,
        text: t(headerContent.translationKey),
        type: switchWebAppVibeApp(
            workflowType,
            HeaderActionType.Close,
            HeaderActionType.NoAction
        ),
    };

    const image = imgContent;

    const warningTitle = {
        id: redoHLAADialogResource[switchResource(workflowType)]
            .warningTitleContent.id,
        text: t(
            redoHLAADialogResource[switchResource(workflowType)]
                .warningTitleContent.translationKey
        ),
    };

    const warningMessage = {
        id: redoHLAADialogResource[switchResource(workflowType)]
            .warningMessageContent.id,
        text: t(
            redoHLAADialogResource[switchResource(workflowType)]
                .warningMessageContent.translationKey
        ),
    };

    const warningInfo = {
        id: warningInfoContent.id,
        text: t(warningInfoContent.translationKey),
    };

    const buttonLabels = {
        primaryLabel: {
            id: buttons.primary.id,
            label: t(buttons.primary.translationKey),
        },
        secondaryLabel: {
            id: buttons.secondary.id,
            label: t(buttons.secondary.translationKey),
        },
    };

    return {
        header,
        image,
        warningTitle,
        warningMessage,
        warningInfo,
        buttonLabels,
    };
}

export default useRedoHLAADialogResource;
