import { useTranslation } from 'react-i18next';
import { HeaderActionType } from '../../Header/HeaderActionType';
import { WarningDialogResource } from '../Warning/WarningDialog';
import deviceCompatibilityCheckDialogResource from './DeviceCompatibilityCheckDialogResource.json';

function useDeviceCompatibilityCheckDialogResource(): WarningDialogResource {
    const { t } = useTranslation();

    const {
        headerContent,
        imgContent,
        warningTitleContent,
        warningMessageContent,
        warningInfoContent,
        exitButton,
    } = deviceCompatibilityCheckDialogResource;

    const header = {
        id: headerContent.id,
        text: t(`common:Common_Feature${headerContent.translationKey}`),
        type: HeaderActionType.NoAction,
    };

    const image = imgContent;

    const warningTitle = {
        id: warningTitleContent.id,
        text: t(`vibeGo:VibeGo_Device${warningTitleContent.translationKey}`),
    };

    const warningMessage = {
        id: warningMessageContent.id,
        text: t(`vibeGo:VibeGo_Device${warningMessageContent.translationKey}`),
    };

    const warningInfo = {
        id: warningInfoContent.id,
        text: '',
    };

    const buttonLabels = {
        primaryLabel: {
            id: exitButton.id,
            label: t(`common:Common_Feature${exitButton.translationKey}`),
        },
    };

    return {
        header,
        image,
        warningTitle,
        warningMessage,
        warningInfo,
        buttonLabels,
    };
}

export default useDeviceCompatibilityCheckDialogResource;
