import AuthService from '../../../Services/Authentication/AuthService';
import { VoidReturn } from '../../../Utils/PageUtils';
import { WarningDialogHandler } from '../Warning/WarningDialog';
import PatientManagementService from '../../../Services/PatientManagementService';
import { useContext } from 'react';
import { MainViewContext } from '../../../Pages/MainComponents/MainView/MainViewContext';
import { TransitionAction } from '../../../Pages/MainComponents/MainView/MainViewModel';

export interface IDeviceInfoCheckDialogProps {
    onClose: () => VoidReturn;
}

function useDeviceInfoCheckDialogHandler(
    props: IDeviceInfoCheckDialogProps
): WarningDialogHandler {
    const viewContext = useContext(MainViewContext);
    const { onClose } = props;

    return {
        primaryAction: async () => {
            await PatientManagementService.SetVibeAirDevices();
            if (onClose) onClose();
            viewContext.doTransition(
                TransitionAction.ResetUserDeviceProfileAndWorkflow
            );
        },
        secondaryAction: async () => {
            AuthService.adB2cInstance.logout();
        },
    };
}

export default useDeviceInfoCheckDialogHandler;
