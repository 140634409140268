import { useContext } from 'react';
import AuthService from '../../../../../Services/Authentication/AuthService';
import { VoidReturn } from '../../../../../Utils/PageUtils';
import { MenuContext } from '../../MenuContext';

export type SettingsMenuHandler = { logout: () => VoidReturn };

export default function useSettingsMenuHandler(): SettingsMenuHandler {
    const { onClose } = useContext(MenuContext);
    return {
        logout: () => {
            AuthService.adB2cInstance.logout();
            if (onClose) onClose();
        },
    };
}
