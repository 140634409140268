import { SupportedCountries } from '../../Models/SupportedCountries';
import { getNonMedicalResource } from '../NonMedicalService';

export type CountryConsentConfigFile = {
    [key: string]: {
        operationalDataConsent: boolean;
        analyticalDataConsent: boolean;
        customVersion?: string;
    };
};

export type ConsentConfig = {
    operationalDataConsent: boolean;
    analyticalDataConsent: boolean;
    customVersion?: string;
};

export default class CountryConsentConfigService {
    private static configs: CountryConsentConfigFile;

    public static async loadCountryConfig(): Promise<void> {
        if (!this.configs) {
            const resource = await getNonMedicalResource(
                'CountryConsentConfigService',
                'CommonAssets/Configuration/CountryConsentConfig.json'
            );
            if (resource && resource.status < 300) {
                this.configs = resource.data as CountryConsentConfigFile;
            }
        }
    }

    public static getCountryConfig(country: SupportedCountries): ConsentConfig {
        return this.configs[country as SupportedCountries];
    }
}
