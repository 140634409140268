import { useContext, useState } from 'react';
import LocalStorageService from '../../../Services/LocalStorageService';
import { VoidReturn } from '../../../Utils/PageUtils';
import { MainViewContext } from '../MainView/MainViewContext';
import { TransitionAction } from '../MainView/MainViewModel';

type CountrySelectionHandler = {
    selectedCountry: string;
    dialogDisplayState: boolean;
    onClickContinueButton: () => VoidReturn;
    onCloseDialog: () => VoidReturn;
    onOpenDialog: () => VoidReturn;
    onSelectCountry: (country: string) => VoidReturn;
    getSelectedItems: () => string[];
};

function useCountrySelectionHandler(): CountrySelectionHandler {
    const viewContext = useContext(MainViewContext);
    const [selectedCountry, setSelectedCountry] = useState(
        LocalStorageService.serviceInstance.getSelectedCountry()
    );
    const [dialogDisplayState, setDialogDisplayState] = useState(false);

    const onClickContinueButton = () => {
        LocalStorageService.serviceInstance.setSelectedCountry(selectedCountry);
        viewContext.doTransition(TransitionAction.CountrySelectionContinue);
    };

    const onCloseDialog = () => {
        setDialogDisplayState(false);
    };

    const onOpenDialog = () => {
        setDialogDisplayState(true);
    };

    const onSelectCountry = (country: string) => {
        setSelectedCountry(country);
        LocalStorageService.serviceInstance.setSelectedCountry(country);
        onCloseDialog();
    };

    const getSelectedItems = () => {
        return [LocalStorageService.serviceInstance.getSelectedCountry()];
    };

    return {
        selectedCountry,
        dialogDisplayState,
        onClickContinueButton,
        onCloseDialog,
        onOpenDialog,
        onSelectCountry,
        getSelectedItems,
    };
}

export default useCountrySelectionHandler;
