import { useContext, useState } from 'react';
import { Side } from '../../../Models/Side';
import ArcToneService from '../../../Services/ArcToneService';
import AudioService from '../../../Services/AudioService';
import { CustomerServiceContactService } from '../../../Services/Configuration/CustomerServiceContactService';
import DeviceManagementService from '../../../Services/DeviceManagementService';
import LocalStorageService from '../../../Services/LocalStorageService';
import TelemetryService from '../../../Services/Monitoring/TelemetryService';
import { PairingCarouselHandler } from '../PairingCarouselPage';
import { PairingContext } from '../PairingContext';

export default function usePairingOneSideHandler(): PairingCarouselHandler {
    const {
        info,
        state: { context },
        send,
    } = useContext(PairingContext);
    const [step, setStep] = useState(0);
    const [disabled, setDisabled] = useState(false);

    const handlePairAction = async () => {
        setDisabled(true);
        await AudioService.serviceInstance.initAudioContext();
        const pairingAddress = DeviceManagementService.generatePairingAddress(
            context.activeSide
        );

        const url = ArcToneService.getInitialPairing(
            LocalStorageService.serviceInstance.getDeviceBrandId(
                context.activeSide
            ),
            pairingAddress
        );
        info.current[context.activeSide as Side] = pairingAddress;
        await AudioService.serviceInstance
            .prepAudio(url, 'PairingDevice')
            .catch((error: Error) => {
                throw error;
            });
        send('completeOneSide');
        setDisabled(false);
    };

    const openHyperLink = async () => {
        TelemetryService.serviceInstance.TrackWithIdentity(
            'EasyFit-FeatureUsage',
            {
                Feature: 'NeedHelpInPairing',
            }
        );
        await AudioService.serviceInstance.closeAudioContext();
        window.open(
            CustomerServiceContactService.getCountrySpecificVibeHearingWebSupportAddress(
                false
            ),
            '_blank'
        );
    };

    const supportLinkDisplayed = step >= 2;

    return {
        disabled,
        supportLinkDisplayed,
        step,
        setStep,
        buttonOnClick: handlePairAction,
        openHyperLink,
    };
}
