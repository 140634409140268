import { useTranslation } from 'react-i18next';
import { HeaderActionType } from '../../../Header/HeaderActionType';
import { WarningDialogResource } from '../../Warning/WarningDialog';
import analyticConsentAlertDialogResource from './AnalyticConsentAlertDialogResource.json';

function useAnalyticConsentAlertDialogResource(): WarningDialogResource {
    const { t } = useTranslation();

    const {
        headerContent,
        imgContent,
        warningTitleContent,
        warningMessageContent,
        warningInfoContent,
        yesButton,
    } = analyticConsentAlertDialogResource;

    const header = {
        id: headerContent.id,
        text: t(`${headerContent.translationKey}`),
        type: HeaderActionType.NoAction,
    };

    const image = imgContent;

    const warningTitle = {
        id: warningTitleContent.id,
        text: t(`${warningTitleContent.translationKey}`),
    };

    const warningMessage = {
        id: warningMessageContent.id,
        text: t(`${warningMessageContent.translationKey}`),
    };

    const warningInfo = {
        id: warningInfoContent.id,
        text: t(`${warningInfoContent.translationKey}`),
    };

    const buttonLabels = {
        primaryLabel: {
            id: yesButton.id,
            label: t(yesButton.translationKey),
        },
    };

    return {
        header,
        image,
        warningTitle,
        warningMessage,
        warningInfo,
        buttonLabels,
    };
}

export default useAnalyticConsentAlertDialogResource;
