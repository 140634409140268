import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './InitialState';

const notificationSlice = createSlice({
    name: 'notificationSlice',
    initialState: initialState.notificationState,
    reducers: {
        updateNotification: (state, action) => {
            return action.payload;
        },
    },
});

export const { updateNotification } = notificationSlice.actions;

export const notificationReducer = notificationSlice.reducer;
