import React, { HTMLAttributes } from 'react';
import ActionStickyContainerDoubleGradient from './ActionStickyContainerDoubleGradient';
import ButtonContainer from './ButtonContainer';
import './ContainerStyle.scss';

function ButtonStickyContainerDoubleGradient(
    props: HTMLAttributes<HTMLDivElement>
): React.ReactElement {
    return (
        <ActionStickyContainerDoubleGradient>
            <ButtonContainer {...props} />
        </ActionStickyContainerDoubleGradient>
    );
}

export default ButtonStickyContainerDoubleGradient;
