import React, { useContext } from 'react';
import Header from '../../../../SharedComponents/Header/HeaderRefactor';
import { InitialSettingContext } from '../InitialSettingContext';
import AppContainer from '../../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../../SharedComponents/Container/BodyContainer';
import CustomTypography, {
    TypographyType,
} from '../../../../SharedComponents/Typography/CustomTypography';
import ButtonStickyContainer from '../../../../SharedComponents/Container/ButtonStickyContainer';
import CustomImg from '../../../../SharedComponents/CustomImage/CustomImg';
import './InitialSettingWritingSide.scss';
import useInitialSettingWritingSideResource from './InitialSettingWritingSideResourceHook';

export default function InitialSettingWritingSide(): JSX.Element {
    const { info } = useContext(InitialSettingContext);

    const {
        header,
        titleWriting,
        instructionWriting,
        instructionImageWriting,
    } = useInitialSettingWritingSideResource();

    return (
        <AppContainer>
            <Header headerContent={header} disabled={info.loading} />
            <BodyContainer flexStart>
                <CustomTypography
                    type={TypographyType.notoSansBodyBig}
                    id={titleWriting.id}
                    text={titleWriting.text}
                    className={'titleInitialSettingApplicationSide'}
                />
                <CustomImg
                    imgType={'medium'}
                    id={instructionImageWriting.id}
                    src={instructionImageWriting.src}
                    alt={instructionImageWriting.alt}
                />
                <CustomTypography
                    type={TypographyType.notoSansBodyBig}
                    id="txt-progress"
                    text={`\`orange{${info.progress}%}\``}
                    className={'progressInitialSettingApplicationSide'}
                />
            </BodyContainer>
            <ButtonStickyContainer>
                <CustomTypography
                    type={TypographyType.notoSansBodyBig}
                    id={instructionWriting.id}
                    text={instructionWriting.text}
                    className={
                        'instructionWritingInitialSettingApplicationSide'
                    }
                />
            </ButtonStickyContainer>
        </AppContainer>
    );
}
