import { useContext } from 'react';
import {
    HearingAssessmentState,
    HLAAContext,
} from '../../../../Pages/HLAA/HLAAContext';
import { MainViewContext } from '../../../../Pages/MainComponents/MainView/MainViewContext';
import { TransitionAction } from '../../../../Pages/MainComponents/MainView/MainViewModel';
import ArcToneService from '../../../../Services/ArcToneService';
import AudioService from '../../../../Services/AudioService';
import LocalStorageService from '../../../../Services/LocalStorageService';
import PatientManagementService from '../../../../Services/PatientManagementService';
import { VoidReturn } from '../../../../Utils/PageUtils';
import { WarningDialogHandler } from '../../Warning/WarningDialog';

function useRedoHLAADialogHandler(
    onClose?: () => VoidReturn,
    onPrimaryAction?: () => VoidReturn
): WarningDialogHandler {
    const viewContext = useContext(MainViewContext);
    const { setHearingAssessmentState } = useContext(HLAAContext);

    const redoHLAAWorkflow = async () => {
        for await (const side of ['left', 'right']) {
            const brandId =
                LocalStorageService.serviceInstance.getDeviceBrandId(side);
            const pairingAddress =
                LocalStorageService.serviceInstance.getPairingAddress(side);
            await AudioService.serviceInstance.prepAudio(
                ArcToneService.resetEasyFitControls(brandId, pairingAddress),
                `${side} side reset factory`
            );
        }
        await redoHLAA();
        //if we are on hearing assessment page must change state to rerender.
        if (setHearingAssessmentState) {
            setHearingAssessmentState(HearingAssessmentState.RedoHLAA);
        }
    };

    const redoHLAA = async () => {
        await PatientManagementService.redoHLAA();
        LocalStorageService.serviceInstance.clear(false);
        await PatientManagementService.getPatient();
    };

    const primaryAction = async () => {
        await AudioService.serviceInstance.initAudioContext();
        for await (const side of ['left', 'right']) {
            const brandId =
                LocalStorageService.serviceInstance.getDeviceBrandId(side);
            const pairingAddress =
                LocalStorageService.serviceInstance.getPairingAddress(side);
            await AudioService.serviceInstance.prepAudio(
                ArcToneService.getExitAssessment(brandId, pairingAddress),
                `${side} exit hlaa`
            );
            await AudioService.serviceInstance.prepAudio(
                ArcToneService.unmuteDevice(brandId, pairingAddress),
                `${side} unmute`
            );
        }
        if (onPrimaryAction) {
            await redoHLAA();
            await onPrimaryAction();
        } else {
            await redoHLAAWorkflow();
            if (onClose) onClose();
            viewContext.doTransition(TransitionAction.DoRedoHLAA);
        }
    };

    const secondaryAction = () => {
        if (onClose) onClose();
    };

    return {
        primaryAction,
        secondaryAction,
    };
}

export default useRedoHLAADialogHandler;
