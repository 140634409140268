import { useContext } from 'react';
import AudioService from '../../../Services/AudioService';
import { TroubleShootingHandler } from '../../MainComponents/TroubleShooting/TroubleShooting';
import { HearingAssessmentState, HLAAContext } from '../HLAAContext';

function useHearingAssessmentTroubleShootingHandler(): TroubleShootingHandler {
    const { info, setHearingAssessmentState } = useContext(HLAAContext);

    const onStartHLAA = async () => {
        await AudioService.serviceInstance.createNewAudioContext();

        info.current.startKeepAlive = false;
        info.current.repeatDeviceSetup = true;
        info.current.freqStartTime = new Date().getTime();
    };

    const onRetry = async () => {
        await onStartHLAA();
        setHearingAssessmentState(HearingAssessmentState.SetUp);
    };

    return {
        onRetry,
    };
}

export default useHearingAssessmentTroubleShootingHandler;
