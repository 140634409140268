import { useContext } from 'react';
import { VoidReturn } from '../../../Utils/PageUtils';
import { PairingContext } from '../PairingContext';

export type TechnicalSetupHandler = {
    handleStartPairingAction: () => VoidReturn;
};

function useTechnicalSetupHandler(): TechnicalSetupHandler {
    const { send } = useContext(PairingContext);

    const handleStartPairingAction = async () => {
        send('completeTechnicalSetup');
    };

    return {
        handleStartPairingAction,
    };
}

export default useTechnicalSetupHandler;
