/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { MutableRefObject, useContext } from 'react';
import { EventData } from 'xstate';
import { PairingEvent, pairingState } from './PairingModels';

export interface IPairingInfo {
    left: number;
    right: number;
}

export interface IPairingContext {
    info: MutableRefObject<IPairingInfo>;
    state: pairingState;
    send: (
        event: PairingEvent,
        payload?: EventData | undefined
    ) => pairingState;
}

export const PairingContext = React.createContext({} as IPairingContext);
export const usePairingContext = (): IPairingContext =>
    useContext(PairingContext);
