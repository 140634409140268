import React from 'react';
import FlexContainer from '../../../../../SharedComponents/Container/FlexContainer';
import CustomImg from '../../../../../SharedComponents/CustomImage/CustomImg';
import CustomTypography, {
    TypographyType,
} from '../../../../../SharedComponents/Typography/CustomTypography';
import '../../FineTuningSolutionPage.scss';
import useFineTuningAppliedSuccessPageResource from './FineTuningAppliedSuccessPageResource';

export default function FineTuningAppliedSuccessPage(): React.ReactElement {
    const { image, activeSolutionText, successText } =
        useFineTuningAppliedSuccessPageResource();
    return (
        <FlexContainer className={'appliedSuccessfullyArea'}>
            <CustomImg
                id={image.id}
                imgType={'iconLarge'}
                src={image.src}
                alt={image.alt}
            />
            <CustomTypography
                id={activeSolutionText.id}
                text={activeSolutionText.text}
                type={TypographyType.notoSansSubHeading}
                className={'appliedSuccessfullyText'}
            />
            <CustomTypography
                id={successText.id}
                text={successText.text}
                type={TypographyType.notoSansBody}
            />
        </FlexContainer>
    );
}
