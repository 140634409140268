import { Divider, List, ListItem } from '@material-ui/core';
import React from 'react';
import CustomImg from '../CustomImage/CustomImg';
import CustomTypography, {
    TypographyType,
} from '../Typography/CustomTypography';
import './ListStyle.scss';

export interface ISubMenuItemListProps {
    menuItems: SubMenuItem[];
    getSelectedItems?: () => string[];
}

export type ListItemType = {
    displayText: string;
    id: string;
};

export type SubMenuItem = {
    id: string;
    displayText: string;
    onClickHandler: (item: ListItemType) => void | Promise<void>;
};

function SubMenuList({
    menuItems,
    getSelectedItems,
}: ISubMenuItemListProps): JSX.Element {
    return (
        <List>
            <Divider></Divider>
            {menuItems.map((item, index) => {
                return (
                    <ListItem
                        button
                        onClick={() => item.onClickHandler(item)}
                        divider={true}
                        key={index}
                        id={item.id ? item.id : `submenu-item-${index}`}
                        className={'listItem'}
                    >
                        <CustomTypography
                            type={TypographyType.notoSansBody}
                            text={item.displayText}
                            textAlign="left"
                        />
                        <CustomImg
                            hidden={
                                !(
                                    getSelectedItems &&
                                    getSelectedItems().includes(item.id)
                                )
                            }
                            imgType="iconSmall"
                            src={`/resources/shared/selection-tick-mark.svg`}
                            alt=""
                        />
                    </ListItem>
                );
            })}
        </List>
    );
}

export default SubMenuList;
