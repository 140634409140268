import { useContext } from 'react';
import { Patient } from '../../../../Models/Patient/Patient';
import { MainViewContext } from '../../../../Pages/MainComponents/MainView/MainViewContext';
import { TransitionAction } from '../../../../Pages/MainComponents/MainView/MainViewModel';
import ArcToneService from '../../../../Services/ArcToneService';
import AudioService from '../../../../Services/AudioService';
import LocalStorageService from '../../../../Services/LocalStorageService';
import PatientManagementService from '../../../../Services/PatientManagementService';
import { VoidReturn } from '../../../../Utils/PageUtils';
import { WarningDialogHandler } from '../../Warning/WarningDialog';

function useRedoEasyFitDialogHandler(
    onClose?: () => VoidReturn
): WarningDialogHandler {
    const viewContext = useContext(MainViewContext);

    const redoEasyFit = async () => {
        const leftBrandId =
            LocalStorageService.serviceInstance.getDeviceBrandId('left');
        const leftPairingAddress =
            LocalStorageService.serviceInstance.getPairingAddress('left');
        const rightBrandId =
            LocalStorageService.serviceInstance.getDeviceBrandId('right');
        const rightPairingAddress =
            LocalStorageService.serviceInstance.getPairingAddress('right');

        await AudioService.serviceInstance.prepAudio(
            ArcToneService.resetEasyFitControls(
                leftBrandId,
                leftPairingAddress
            ),
            'left side reset factory'
        );
        await AudioService.serviceInstance.prepAudio(
            ArcToneService.resetEasyFitControls(
                rightBrandId,
                rightPairingAddress
            ),
            'right side reset factory'
        );

        await PatientManagementService.resetFactory();

        const patient =
            (await PatientManagementService.getPatient()) as Patient;
        await LocalStorageService.serviceInstance.clear(false);
        await LocalStorageService.serviceInstance.setPatient(patient);
    };
    const primaryAction = async () => {
        await AudioService.serviceInstance.initAudioContext();
        await redoEasyFit();
        viewContext.doTransition(TransitionAction.DoRedoEasyFit);
    };

    const secondaryAction = () => {
        if (onClose) onClose();
    };

    return {
        primaryAction,
        secondaryAction,
    };
}

export default useRedoEasyFitDialogHandler;
