import { HeaderActionType } from '../../SharedComponents/Header/HeaderActionType';
import { HeaderResource } from '../../Utils/PageUtils';

export type AudiogramScoringCheckResource = {
    header: HeaderResource;
};

export default function useAudiogramScoringCheckResource(): AudiogramScoringCheckResource {
    return {
        header: {
            id: 'audiogram-check-header',
            text: '',
            type: HeaderActionType.NoAction,
        },
    };
}
