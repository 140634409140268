import { useTranslation } from 'react-i18next';
import {
    ButtonResource,
    HeaderResource,
    ImageResource,
    linkCallBacksResource,
    TextResource,
} from '../../../Utils/PageUtils';
import { HeaderActionType } from '../../Header/HeaderActionType';

type SleeveSelectionResource = {
    header: HeaderResource;
    instructionText: TextResource;
    continueButton: ButtonResource;
    closedSleeveImage: ImageResource;
    ventedSleeveImage: ImageResource;
    closedSleeveName: TextResource;
    ventedSleeveName: TextResource;
    linkCallBacks: linkCallBacksResource;
};

export default function useSleeveSelectionResource(
    openHyperLink: () => void
): SleeveSelectionResource {
    const { t } = useTranslation();

    const header = {
        id: 'header-sleeve-selection',
        text: t('vibeComplete:VibeComplete_Device:ChangeSleeve_Feature:Header'),
        type: HeaderActionType.Menu,
    };

    const instructionText = {
        id: 'txt-instruction',
        text: t('vibeComplete:VibeComplete_Device:ChangeSleeve_Feature:Text'),
    };

    const closedSleeveImage = {
        id: 'img-closed-sleeve',
        src: '/resources/hlaa/closed-sleeve-vibeComplete.svg',
    };

    const ventedSleeveImage = {
        id: 'img-vented-sleeve',
        src: '/resources/hlaa/vented-sleeve-vibeComplete.svg',
    };

    const closedSleeveName = {
        id: 'txt-closed-sleeve',
        text: t(
            'vibeComplete:VibeComplete_Device:ChangeSleeve_Feature:SleeveTypeText1'
        ),
    };

    const ventedSleeveName = {
        id: 'txt-vented-sleeve',
        text: t(
            'vibeComplete:VibeComplete_Device:ChangeSleeve_Feature:SleeveTypeText2'
        ),
    };

    const continueButton = {
        id: 'btn-continue-change-sleeve',
        label: t('common:Common_Feature:Continue'),
    };

    const linkCallBacks = {
        customerSupport: openHyperLink,
    };

    return {
        header,
        instructionText,
        closedSleeveImage,
        ventedSleeveImage,
        continueButton,
        closedSleeveName,
        ventedSleeveName,
        linkCallBacks,
    };
}
