import AuthService from '../../../Services/Authentication/AuthService';
import i18n, { getCurrentLocale } from '../../../Services/LocalizationService';
import PatientManagementService from '../../../Services/PatientManagementService';
import { addCountrySpecificResourceBundles } from '../../../Utils/ConsentVersionUtils';
import { VoidReturn } from '../../../Utils/PageUtils';
import { ListItemType } from '../../List/SubMenuList';

export type LanguageSelectionHandler = {
    getSelectedItems: () => string[];
    onClickItemHandle: (item: ListItemType) => VoidReturn;
};

function useLanguageSelectionHandler(
    onClose?: () => VoidReturn
): LanguageSelectionHandler {
    const onClickItemHandle = async (item: ListItemType) => {
        const instance = await AuthService.getInstance();

        if (instance.getIsAuthenticated()) {
            const patient = await PatientManagementService.getPatient();
            if (patient !== undefined) {
                PatientManagementService.updateLanguage(item.id);
            }
        }
        await i18n.changeLanguage(item.id);
        await addCountrySpecificResourceBundles();
        await i18n.changeLanguage(item.id); //call changeLanguage() again to reload
        if (onClose) onClose();
    };

    function getSelectedItems() {
        return [getCurrentLocale()];
    }

    return {
        getSelectedItems,
        onClickItemHandle,
    };
}

export default useLanguageSelectionHandler;
