import React from 'react';

export type infoType = {
    activeSide: string;
    totalPageCount: number;
    totalPagesForProgress: number;
};

export enum RedoEasyFitState {
    Start,
    OneSideSetUp,
    BeepConfirmation,
    TroubleShooting,
}

export interface IRedoEasyFitContext {
    info: React.MutableRefObject<infoType>;
    redoEasyFitState: RedoEasyFitState;
    setRedoEasyFitState: React.Dispatch<RedoEasyFitState>;
}

export const RedoEasyFitContext = React.createContext(
    {} as IRedoEasyFitContext
);
