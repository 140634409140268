import React, { HTMLAttributes } from 'react';
import { joinMultipleStyle } from '../../Utils/ScssUtils';
import { CustomImgType } from './CustomImg';
import './ImgStyle.scss';

export function createCustomImg(
    { className, ...props }: HTMLAttributes<HTMLImageElement>,
    type?: CustomImgType
): React.ReactElement {
    switch (type) {
        case 'iconSmall':
            return (
                <img
                    className={joinMultipleStyle('imgIconSmall', className)}
                    {...props}
                />
            );
        case 'icon':
            return (
                <img
                    className={joinMultipleStyle('imgIcon', className)}
                    {...props}
                />
            );
        case 'iconMediumLarge':
            return (
                <img
                    className={joinMultipleStyle(
                        'imgIconMediumLarge',
                        className
                    )}
                    {...props}
                />
            );
        case 'iconLarge':
            return (
                <img
                    className={joinMultipleStyle('imgIconLarge', className)}
                    {...props}
                />
            );
        case 'small':
            return (
                <img
                    className={joinMultipleStyle('imgSmall', className)}
                    {...props}
                />
            );
        case 'mediumSmall':
            return (
                <img
                    className={joinMultipleStyle('imgMediumSmall', className)}
                    {...props}
                />
            );
        case 'medium':
            return (
                <img
                    className={joinMultipleStyle('imgMedium', className)}
                    {...props}
                />
            );
        case 'large':
            return (
                <img
                    className={joinMultipleStyle('imgLarge', className)}
                    {...props}
                />
            );
        default: {
            return <img className={className} {...props} />;
        }
    }
}
