import { Patient } from '../../../Models/Patient/Patient';

export type ViewState = {
    dialog: DialogState;
    page: PageState;
};

export type ITransitionHandler = {
    doTransition: (
        currentView: ViewState,
        action: TransitionAction,
        patient: Patient | undefined
    ) => ViewState;
    goToInitialState: (
        patient: Patient | undefined,
        debugRoute?: PageState,
        initial?: PageState
    ) => Promise<ViewState>;
};

export enum MainViewWorkflow {
    Web,
    AppFitting,
    AppFineTuning,
    DeleteAccount,
}

export enum PageState {
    Loading = 'Loading',
    SplashScreen = 'SplashScreen',
    NewWelcome = 'NewWelcome',
    RestartWelcome = 'RestartWelcome',
    CountrySelection = 'CountrySelection',
    Consent = 'Consent',
    ConsentAnalytic = 'ConsentAnalytic',
    HiAssemble = 'HiAssemble',
    Contraindication = 'Contraindication',
    Journey = 'Journey',
    Pairing = 'Pairing',
    RePairing = 'RePairing',
    Assessment = 'Assessment',
    RedoHLAA = 'RedoHLAA',
    InitialSettings = 'InitialSettings',
    CompleteRecommendation = 'CompleteRecommendation',
    SpeechComfort = 'SpeechComfort',
    RedoLoudness = 'RedoLoudness',
    Landing = 'Landing',
    FineTuning = 'FineTuning',
    SmartRemote = 'SmartRemote',
    RedoAssessmentOption = 'RedoAssessmentOption',
    DeleteAccount = 'DeleteAccount',
    FineTuningWorkflowError = 'FineTuningWorkflowError',
    DeviceIncompatibleError = 'DeviceIncompatibleError',
    SleeveSelection = 'SleeveSelection',
    None = 'None',
}

export enum DialogState {
    AccountDeleted = 'AccountDeleted',
    PairingConnectionLost = 'PairingConnectionLost',
    DeviceInfoCheck = 'DeviceInfoCheck',
    UpversionAnalyticConsent = 'UpversionAnalyticConsent',
    LeaveApp = 'LeaveApp',
    None = 'None',
}

export enum TransitionAction {
    CountrySelectionContinue,
    WelcomeContinue,
    JourneyResume,
    HiAssembleContinue,
    ConsentContinue,
    AnalyticsConsentContinue,
    AnalyticsConsentDialogContinue,
    ContraindicationContinue,
    PairingContinue,
    AssessmentContinue,
    AssessmentZeroTone,
    InitialSettingsContinue,
    InitialSettingsSevereLoss,
    InitialSettingsMismatchedLoss,
    SpeechComfortContinue,
    RedoLoudnessContinue,
    CompleteRecommendationContinue,
    FineTuningComplete,
    FineTuningWorkflowError,
    GoFineTuning,
    GoRedoLoudness,
    DoRepeatPairing,
    DoRedoEasyFit,
    DoRedoHLAA,
    GoToAssessment,
    SkipRedoEasyFit,
    SkipRedoHLAA,
    DeleteAccount,
    ResetUserDeviceProfileAndWorkflow,
    ExitInAppBrowser,
    ChangeSleeveCustomerServiceContinue,
    DoLeaveApp,
    DoLeaveAppDialogOpen,
    DoLeaveAppDialogClose,
    GoSplashScreen,
}

export interface IMainViewProps {
    workflowType: MainViewWorkflow;
    useTransitionHandler: () => ITransitionHandler;
}
