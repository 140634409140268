export enum deviceModel {
    VibeAir = 'VibeAir',
    VibeGo = 'VibeGo',
    VibeComplete = 'VibeComplete',
}

export enum deviceModelId {
    VibeAir = '6144007',
    VibeGo = '540917767',
    VibeComplete = '72024937728770',
}

export enum platformType {
    D11 = 'D11',
}

export enum sleeveCoupling {
    Closed = 2,
    Vented = 1,
}
