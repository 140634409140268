export interface AudiogramStateContext {
    leftScore: number;
    rightScore: number;
    leftRightDifference: boolean;
    scorinPageState: ScoringCheckPageState;
    view: () => JSX.Element;
}

export enum ScoringCheckPageState {
    Loading = 'loading',
    Alert = 'alert',
    Results = 'results',
}
