import { useContext } from 'react';
import { HLAAState } from '../../../Models/HLAA/HLAAState';
import LocalStorageService from '../../../Services/LocalStorageService';
import { VoidReturn } from '../../../Utils/PageUtils';
import { HearingAssessmentState, HLAAContext } from '../HLAAContext';

export type HearingAssessmentInfoPageHandler = {
    reinstruct: () => VoidReturn;
    abortMaxPresentedToneReach: () => VoidReturn;
    constant0ToneHeardReach: () => VoidReturn;
    error: string;
};

export default function useHearingAssessmentInfoPageHandler(): HearingAssessmentInfoPageHandler {
    const error = '';
    const { info, setHearingAssessmentState, exitHearingAssessment } =
        useContext(HLAAContext);

    const reinstruct = () => {
        info.current.startKeepAlive = true;
        setHearingAssessmentState(HearingAssessmentState.AssessmentOneSide);
    };

    const abortMaxPresentedToneReach = () => {
        setAssessmentStateToStart();
    };

    const setAssessmentStateToStart = () => {
        info.current.activeSide = 'left';
        info.current.left = {
            hlaaDone: false,
        };
        info.current.right = {
            hlaaDone: false,
        };
        info.current.totalPageCount = 0;
        info.current.hlaaState = new HLAAState('', '', -1, -1);
        info.current.startKeepAlive = false;
        info.current.repeatDeviceSetup = false;

        setHearingAssessmentState(HearingAssessmentState.Start);
    };

    const constant0ToneHeardReach = () => {
        LocalStorageService.serviceInstance.setPairingAddress('left', -1);
        LocalStorageService.serviceInstance.setPairingAddress('right', -1);
        exitHearingAssessment();
    };

    return {
        reinstruct,
        abortMaxPresentedToneReach,
        constant0ToneHeardReach,
        error,
    };
}
