import React, { useContext } from 'react';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import { ButtonType } from '../../../SharedComponents/Button/ButtonType';
import CustomButton from '../../../SharedComponents/Button/CustomButton';
import AppContainer from '../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../SharedComponents/Container/BodyContainer';
import ButtonStickyContainer from '../../../SharedComponents/Container/ButtonStickyContainer';
import CustomImg from '../../../SharedComponents/CustomImage/CustomImg';
import Header from '../../../SharedComponents/Header/HeaderRefactor';
import CustomTypography, {
    TypographyType,
} from '../../../SharedComponents/Typography/CustomTypography';
import { switchWebAppVibeApp } from '../../../Utils/PageUtils';
import DownloadAppDialog from '../../MainComponents/Landing/DownloadDialog/DownloadAppDialog';
import { MainViewContext } from '../../MainComponents/MainView/MainViewContext';
import './CompleteRecommendationPage.scss';
import useCompleteRecommendationHandler from './CompleteRecommendationPageHandlerHook';
import useCompleteRecommendationResource from './CompleteRecommendationPageResourceHook';

function CompleteRecommendationPage(): React.ReactElement {
    const {
        openDowndloadAppDialog,
        handleCloseDownloadDialog,
        handleCompleteRecommendationContinue,
        dialogDisplayState,
    } = useCompleteRecommendationHandler();

    const {
        header,
        instructionsAppDownload1,
        instructionsComplete,
        linkCallBacks,
        finishImage,
        appImage,
        exitButton,
        title,
    } = useCompleteRecommendationResource(openDowndloadAppDialog);

    const viewContext = useContext(MainViewContext);

    const vibeAppFooter = (
        <CustomButton
            buttonType={ButtonType.PrimaryLoading}
            id={exitButton.id}
            onClick={handleCompleteRecommendationContinue}
        >
            {exitButton.label}
        </CustomButton>
    );

    const webAppFooter = (
        <ButtonStickyContainer>
            <CustomImg
                id={appImage.id}
                imgType={'iconLarge'}
                src={appImage.src}
                alt={appImage.alt}
                className={`appIconImageCompleteRecommendation`}
            />
            <CustomTypography
                type={TypographyType.notoSansBody}
                id={instructionsAppDownload1.id}
                text={instructionsAppDownload1.text}
                textAlign="left"
                linkCallBacks={linkCallBacks}
            />
        </ButtonStickyContainer>
    );

    return (
        <>
            <DownloadAppDialog
                displayed={dialogDisplayState}
                onClose={() => handleCloseDownloadDialog()}
                onContinue={handleCompleteRecommendationContinue}
            />
            <AppContainer>
                <Header headerContent={header} />
                <BodyContainer>
                    <CustomImg
                        id={finishImage.id}
                        imgType={'medium'}
                        src={finishImage.src}
                        alt={finishImage.alt}
                        className={'completeImageCompleteRecommendation'}
                    />
                    {switchWebAppVibeApp(
                        viewContext.workflowType,
                        <></>,
                        <CustomTypography
                            id={title.id}
                            type={TypographyType.notoSansBodyBig}
                            text={title.text}
                        />
                    )}
                    <CustomTypography
                        id={instructionsComplete.id}
                        type={TypographyType.notoSansBody}
                        text={instructionsComplete.text}
                    />
                </BodyContainer>
                {switchWebAppVibeApp(
                    viewContext.workflowType,
                    webAppFooter,
                    vibeAppFooter
                )}
            </AppContainer>
        </>
    );
}

export default withTracking(CompleteRecommendationPage);
