import React, { HTMLAttributes } from 'react';
import { joinMultipleStyle } from '../../Utils/ScssUtils';
import './ContainerStyle.scss';

function ActionStickyContainerDoubleGradient({
    className,
    ...others
}: HTMLAttributes<HTMLDivElement>): React.ReactElement {
    return (
        <div
            className={joinMultipleStyle(
                'actionStickyContainerDoubleGradient',
                className
            )}
            {...others}
        />
    );
}

export default ActionStickyContainerDoubleGradient;
