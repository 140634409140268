import { Configuration, LogLevel } from '@azure/msal-browser';
import LoggingService from '../LoggingService';

export const isSecureDomain = (): boolean => {
    const url = new URL(window.location.origin);
    return url.protocol.replace(':', '').trim() === 'https';
};

//Extend to string | number | symbol when there is a need
export const nameof = <T>(name: Extract<keyof T, string>): string => name;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const throwIfNull = <T>(object: T, paramName: string): any => {
    if (object === null || object === undefined)
        throw new Error(`Argument null or undefined exception: ${paramName}`);
    return object;
};

export const b2cAuthConfig = (
    clientId: string,
    authority: string
): Configuration => {
    return {
        auth: {
            clientId,
            authority,
            navigateToLoginRequestUrl: true,
            redirectUri: window.location.origin,
            postLogoutRedirectUri: window.location.href,
            knownAuthorities: [authority],
        },
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: false,
        },
        system: {
            iframeHashTimeout: 10000,
            navigateFrameWait: 0,
            tokenRenewalOffsetSeconds: 500,
            loadFrameTimeout: 9000,
            loggerOptions: {
                loggerCallback: (
                    level: LogLevel,
                    message: string,
                    containsPii: boolean
                ): void => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case LogLevel.Error:
                            LoggingService.error({
                                componentName: 'b2cLogAuto',
                                args: [message],
                            });
                            return;
                        case LogLevel.Info:
                            LoggingService.info({
                                componentName: 'b2cLogAuto',
                                args: [message],
                            });
                            return;
                        case LogLevel.Verbose:
                            LoggingService.log({
                                componentName: 'b2cLogAuto',
                                args: [message],
                            });
                            return;
                        case LogLevel.Warning:
                            LoggingService.warn({
                                componentName: 'b2cLogAuto',
                                args: [message],
                            });
                            return;
                    }
                },
                piiLoggingEnabled: false,
            },
        },
    };
};
