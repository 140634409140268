import { FineTuningSolution } from '../../../../../Models/FineTuning/FineTuningSolution';
import { FineTuningSolutionState } from '../../../../../Models/FineTuning/FineTuningSolutionState';
import FineTuningService from '../../../../../Services/FineTuningService';
import LocalStorageService from '../../../../../Services/LocalStorageService';
import { VoidReturn } from '../../../../../Utils/PageUtils';
import { getCouplingOrDefault } from '../../../../../Utils/CouplingUtils';
import { useFineTuningContext } from '../../../FineTuningContext';
import { useFineTuningSolutionContext } from '../../FineTuningSolutionContext';
import { t } from '../../../../../Services/LocalizationService';
import TelemetryService from '../../../../../Services/Monitoring/TelemetryService';

export type ChangeSleeveRecommendHandler = {
    onContinue: () => VoidReturn;
    onCancel: () => VoidReturn;
};

function useChangeSleeveRecommendPageHandler(): ChangeSleeveRecommendHandler {
    const { info } = useFineTuningContext();

    const {
        fineTuningSolutionInfo,
        activeSolutionStep,
        setFineTuningSolutionState,
        setActiveSolutionStep,
    } = useFineTuningSolutionContext();

    const onContinue = () => {
        const solutionName =
            fineTuningSolutionInfo.current.solutionList[activeSolutionStep]
                .name;
        TelemetryService.serviceInstance.TrackWithIdentity(
            'EasyFit-FineTuning',
            {
                CategoryProblem:
                    t(
                        `fineTuning:FineTuning_Feature:Category:${info.current.selected.category}`,
                        {
                            lng: 'en',
                        }
                    ) +
                    '_' +
                    t(
                        `fineTuning:FineTuning_Feature:Problem:${info.current.selected.problem.replace(
                            /_/g,
                            ':'
                        )}`,
                        {
                            lng: 'en',
                        }
                    ),
                Name: solutionName + '_Applied',
            }
        );
        setFineTuningSolutionState(
            FineTuningSolutionState.ChangeSleeveCustomerService
        );
    };

    const onCancel = async () => {
        if (
            activeSolutionStep ===
            fineTuningSolutionInfo.current.solutionList.length - 1
        ) {
            const nextSolution = await FineTuningService.getFineTuningSolution(
                LocalStorageService.serviceInstance.getActiveHearingProfileId(),
                LocalStorageService.serviceInstance.getDeviceModelId('left'),
                info.current.selected.category,
                info.current.selected.problem,
                activeSolutionStep + 2,
                getCouplingOrDefault('left') === -1
                    ? 0
                    : getCouplingOrDefault('left')
            );
            if (nextSolution !== undefined) {
                fineTuningSolutionInfo.current.solutionList.push(nextSolution);
                setActiveSolutionStep((prevStep) => prevStep + 1);
                setFineTuningSolutionState(FineTuningSolutionState.BeforeApply);
            } else {
                fineTuningSolutionInfo.current.solutionList.push(
                    {} as FineTuningSolution
                );
                setActiveSolutionStep((prevStep) => prevStep + 1);
                setFineTuningSolutionState(
                    FineTuningSolutionState.CustomerService
                );
            }
        } else {
            if (
                Object.keys(
                    fineTuningSolutionInfo.current.solutionList[
                        activeSolutionStep + 1
                    ]
                ).length === 0
            ) {
                setActiveSolutionStep((prevStep) => prevStep + 1);
                setFineTuningSolutionState(
                    FineTuningSolutionState.CustomerService
                );
            } else {
                setActiveSolutionStep((prevStep) => prevStep + 1);
                setFineTuningSolutionState(FineTuningSolutionState.BeforeApply);
            }
        }
    };

    return {
        onContinue,
        onCancel,
    };
}

export default useChangeSleeveRecommendPageHandler;
