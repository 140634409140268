import React from 'react';
import { ButtonType } from '../../../SharedComponents/Button/ButtonType';
import CustomButton from '../../../SharedComponents/Button/CustomButton';
import AppContainer from '../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../SharedComponents/Container/BodyContainer';
import CustomImg from '../../../SharedComponents/CustomImage/CustomImg';
import LanguageSelectionDialog from '../../../SharedComponents/Dialog/LanguageSelection/LanguageSelectionDialog';
import CustomTypography, {
    TypographyType,
} from '../../../SharedComponents/Typography/CustomTypography';
import LegalDialog from './../../../SharedComponents/Dialog/Legal/LegalDialog';
import { Button } from '@material-ui/core';
import FlexContainer from '../../../SharedComponents/Container/FlexContainer';
import useSplashScreenHandler from './SplashScreenHandlerHook';
import useSplashScreenResource from './SplashScreenResourceHook';
import './SplashScreenPageStyle.scss';
import { LegalInfoType } from '../../../SharedComponents/Dialog/Legal/LegalDialogModel';
import CustomDialog from '../../../SharedComponents/Dialog/CustomDialog';

const SplashScreen = (): JSX.Element => {
    const {
        showLanguageSelection,
        showLegalInfo,
        gotoLoginPage,
        closeLanguageSelection,
        openLanguageSelection,
        closeCookiePolicy,
        openCookiePolicy,
        spinner,
        loading,
    } = useSplashScreenHandler();

    const {
        background,
        nextButton,
        vibeLogo,
        languageSelection,
        languageSelectionIcon,
        welcomeHeader,
        welcomeMessage,
        cookiePolicy,
    } = useSplashScreenResource();

    const renderPage = () => {
        return (
            <>
                <CustomDialog open={showLanguageSelection || showLegalInfo}>
                    {showLanguageSelection && (
                        <LanguageSelectionDialog
                            onClose={closeLanguageSelection}
                        />
                    )}

                    {showLegalInfo && (
                        <LegalDialog
                            onClose={closeCookiePolicy}
                            type={LegalInfoType.CookiePolicy}
                        />
                    )}
                </CustomDialog>

                <CustomImg
                    id={background.id}
                    src={background.src}
                    alt={background.alt}
                    className={'splash-screen-background'}
                />

                <Button
                    id={languageSelection.id}
                    onClick={openLanguageSelection}
                    className={'splash-screen-language-selection'}
                >
                    <CustomTypography
                        type={TypographyType.notoSansBodyBig}
                        text={languageSelection.label}
                        textWrap={'no-wrap'}
                    />
                    <CustomImg
                        imgType="icon"
                        id={languageSelectionIcon.id}
                        src={languageSelectionIcon.src}
                        alt={languageSelectionIcon.alt}
                        className={'splash-screen-language-icon'}
                    />
                </Button>
                <BodyContainer>
                    <CustomTypography
                        type={TypographyType.notoSansSubHeading}
                        textAlign="center"
                        id={welcomeHeader.id}
                        text={welcomeHeader.text}
                        className={'splash-screen-welcome-header'}
                    />
                    <CustomImg
                        imgType="icon"
                        id={vibeLogo.id}
                        src={vibeLogo.src}
                        alt={vibeLogo.alt}
                    />
                    <CustomTypography
                        type={TypographyType.notoSansBody}
                        textAlign="center"
                        id={welcomeMessage.id}
                        text={welcomeMessage.text}
                        className={'splash-screen-welcome-message'}
                    />
                </BodyContainer>
                <CustomButton
                    buttonType={ButtonType.Primary}
                    id={nextButton.id}
                    onClick={gotoLoginPage}
                >
                    {nextButton.label}
                </CustomButton>
                <FlexContainer className={'splash-screen-cookie-policy'}>
                    <CustomTypography
                        type={TypographyType.notoSansBody}
                        textAlign="center"
                        id={cookiePolicy.id}
                        text={cookiePolicy.text}
                        linkCallBacks={{
                            cookiePolicyHandler: openCookiePolicy,
                        }}
                    />
                </FlexContainer>
            </>
        );
    };

    return (
        <>
            <AppContainer>{loading ? spinner : renderPage()}</AppContainer>
        </>
    );
};

export default SplashScreen;
