import { useTranslation } from 'react-i18next';
import { getUserDeviceModel } from '../../../../Services/ResourceService';
import { HeaderActionType } from '../../../../SharedComponents/Header/HeaderActionType';
import {
    ButtonResource,
    HeaderResource,
    ImageResource,
    switchBasedOnDevice,
    TextResource,
} from '../../../../Utils/PageUtils';

export type SpeechComfortTestDetailPageResource = {
    header: HeaderResource;
    instructionImage: ImageResource;
    instructionImageText: TextResource;
    instructionText: TextResource;
    button: ButtonResource;
};

export default function useSpeechComfortTestDetailPageResource(): SpeechComfortTestDetailPageResource {
    const { t } = useTranslation();
    return {
        header: {
            id: 'speech-comfort-header',
            text: t('common:Common_Feature:AdjustLoudness'),
            type: HeaderActionType.Menu,
        },
        instructionImage: {
            id: 'result-center-illustration',
            src: switchBasedOnDevice(getUserDeviceModel(), {
                VibeAir:
                    '/resources/speechComfort/result-center-illustration-vibeAir.svg',
                VibeGo: '/resources/speechComfort/result-center-illustration-vibeGo.svg',
                VibeComplete:
                    '/resources/speechComfort/result-center-illustration-vibeComplete.svg',
            }),
            alt: t('speechComfort:detail:label'),
        },
        instructionImageText: {
            id: 'image-label',
            text: `\`orange{${t('common:Common_Feature:Loudness')}}\``,
        },
        instructionText: {
            id: 'instruction-text',
            text: t('speechComfort:SpeechComfort_Feature:Detail:Text'),
        },
        button: {
            id: 'btn-finish-setup',
            label: t('common:Common_Feature:Done'),
        },
    };
}
