import './ContainerStyle.scss';
import React, { HTMLAttributes } from 'react';
import { joinMultipleStyle } from '../../Utils/ScssUtils';

export interface IBodyContainer extends HTMLAttributes<HTMLDivElement> {
    flexStart?: boolean;
    noRowMargin?: boolean;
}

function BodyContainer({
    flexStart,
    noRowMargin,
    className,
    ...others
}: IBodyContainer): React.ReactElement {
    if (flexStart) className = `flexStart ${className}`;
    if (noRowMargin) className = `noRowMargin ${className}`;

    return (
        <div
            className={joinMultipleStyle('bodyContainer', className)}
            {...others}
        />
    );
}

export default BodyContainer;
