import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import {
    ButtonResource,
    HeaderResource,
    ImageResource,
    linkCallBacksResource,
    switchResource,
    TextResource,
} from '../../../Utils/PageUtils';
import { MainViewContext } from '../../MainComponents/MainView/MainViewContext';
import { getUserDeviceModel } from '../../../Services/ResourceService';
import { deviceModel } from '../../../Models/SupportedDeviceModel';

export type CompleteRecommendationResource = {
    header: HeaderResource;
    instructionsAppDownload1: TextResource;
    instructionsComplete: TextResource;
    finishImage: ImageResource;
    appImage: ImageResource;
    linkCallBacks: linkCallBacksResource;
    exitButton: ButtonResource;
    title: TextResource;
};

export default function useCompleteRecommendationResource(
    openDialogLink: () => void
): CompleteRecommendationResource {
    const { t } = useTranslation();
    const { workflowType } = useContext(MainViewContext);
    return {
        header: {
            id: 'complete-page-header',
            text: t(
                'completeAndLanding:CompleteAndLanding_Feature:CompleteRecommendation:Header'
            ),
            type: HeaderActionType.Menu,
        },
        instructionsAppDownload1: {
            id: 'complete-instructions-appDownload1',
            text: t(
                'webApp:WebApp_Workflow:CompleteAndLanding_Feature:Shared:Text'
            ),
        },
        instructionsComplete: {
            id: 'complete-instructions',
            text: t(
                switchResource(workflowType) === 'webApp'
                    ? 'webApp:WebApp_Workflow:CompleteAndLanding_Feature:Text'
                    : getUserDeviceModel() == deviceModel.VibeGo
                    ? 'vibeGo:VibeGo_Device:CompleteAndLanding_Feature:Text'
                    : 'vibeApp:VibeApp_Workflow:CompleteAndLanding_Feature:Text'
            ),
        },
        finishImage: {
            id: 'img-finish',
            src: '/resources/completeAndLanding/finish_illustration.svg',
            alt: 'finished illustration',
        },
        appImage: {
            id: 'img-app-icon',
            src: '/resources/completeAndLanding/Vibe_app_icon.svg',
            alt: 'app icon image',
        },
        exitButton: {
            id: 'btn-exit-vibe-now',
            label: t('common:Common_Feature:ReturnToVibeApp'),
        },
        title: {
            id: 'txt-title-complete-recommendation',
            text:
                getUserDeviceModel() == deviceModel.VibeGo
                    ? t(
                          'vibeGo:VibeGo_Device:CompleteAndLanding_Feature:TitleText'
                      )
                    : t(
                          'vibeApp:VibeApp_Workflow:CompleteAndLanding_Feature:TitleText'
                      ),
        },
        linkCallBacks: {
            openDowndloadAppDialog: openDialogLink,
        },
    };
}
