import { Step, StepConnector, StepLabel, Stepper } from '@material-ui/core';
import React from 'react';
import FlexContainer from '../../Container/FlexContainer';
import CustomImg from '../../CustomImage/CustomImg';
import CustomTypography, {
    TypographyType,
} from '../../Typography/CustomTypography';
import { VerticalStepperProps } from '../StepperModel';
import '../StepperStyle.scss';

function IconStepper({
    activeStep,
    stepContents,
}: VerticalStepperProps): React.ReactElement {
    return (
        <Stepper
            id="stepper-workflow"
            className={'stepperWorkflow'}
            activeStep={activeStep}
            orientation="vertical"
            connector={
                <StepConnector
                    classes={{
                        line: 'connectorLine connectorLineIcon',
                        vertical: 'connectorVertical connectorVerticalIcon',
                        completed: 'connectorCompleted',
                    }}
                />
            }
        >
            {stepContents.map((stepContent, index) => (
                <Step
                    key={index}
                    expanded={true}
                    className={stepContent.disabled ? 'disabled' : ''}
                >
                    <StepLabel
                        icon={
                            <CustomImg
                                id={`img-stepper-${stepContent.stepLabel}`}
                                imgType="iconLarge"
                                src={
                                    stepContent.index < activeStep
                                        ? stepContent.stepIconCompleted
                                        : stepContent.stepIcon
                                }
                                alt={`img-stepper-${stepContent.stepLabel}`}
                            />
                        }
                    >
                        <FlexContainer flexRow className={'stepperLabelArea'}>
                            <CustomTypography
                                id={`txt-stepper-label-${stepContent.stepLabel}`}
                                type={TypographyType.notoSansBodyBig}
                                text={stepContent.stepLabelContent}
                                className={'stepperLabelTextIcon'}
                                textAlign={'left'}
                            />
                            <CustomImg
                                hidden={!(stepContent.index < activeStep)}
                                style={{
                                    height: '4vh',
                                    margin: '0 auto auto 0',
                                }}
                                id={`img-tickMark-${stepContent.stepLabel}`}
                                src={`/resources/shared/tick-mark.svg`}
                                alt={stepContent.stepLabelContent}
                            />
                        </FlexContainer>
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    );
}

export default IconStepper;
