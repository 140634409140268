import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { DeviceState } from '../../../Models/FineTuning/DeviceState';
import { FineTuningSolutionState } from '../../../Models/FineTuning/FineTuningSolutionState';
import { SolutionValueChanged } from '../../../Models/FineTuning/SolutionValueChanged';
import ArcToneService from '../../../Services/ArcToneService';
import AudioService from '../../../Services/AudioService';
import DeviceManagementService from '../../../Services/DeviceManagementService';
import LocalStorageService from '../../../Services/LocalStorageService';
import TelemetryService from '../../../Services/Monitoring/TelemetryService';
import { sleep } from '../../../Utils/ResourcesUtils';
import { useFineTuningContext } from '../FineTuningContext';
import { useFineTuningSolutionContext } from './FineTuningSolutionContext';
import useFineTuningCustomerServicePageResource from './PrimaryComponent/CustomerService/FineTuningCustomerServicePageResource';
import { updateLeaveApp } from '../../../Redux/Reducers/LeaveAppSlice';
import {
    MainViewWorkflow,
    TransitionAction,
} from '../../MainComponents/MainView/MainViewModel';
import { MainViewContext } from '../../MainComponents/MainView/MainViewContext';
import { openNewTabWithUrl } from '../../../Utils/NavigationUtils';

export type FineTuningSolutionPageHandler = {
    isContentEnabled: () => boolean;
    handleHomeAndEditAction: () => void;
    handleWarningYes: () => void;
    showWarningForHome: () => void;
    showWarningForEdit: () => void;
    setStepInCarousel: (step: number) => void;
    updateMasterGainAndBandEqualizer: (
        solutionValueChanged: SolutionValueChanged,
        deviceState: {
            [deviceId: string]: DeviceState;
        }
    ) => Promise<void>;
    handleCustomerService: () => Promise<void>;
    exitToVibeApp: () => void;
};

const useFineTuningSolutionPageHandler = (): FineTuningSolutionPageHandler => {
    const {
        fineTuningSolutionInfo,
        fineTuningSolutionState,
        setFineTuningSolutionState,
        setActiveSolutionStep,
        setPercentage,
    } = useFineTuningSolutionContext();

    const viewContext = useContext(MainViewContext);
    const dispatch = useDispatch();

    const { customerServiceGlobalLink } =
        useFineTuningCustomerServicePageResource();

    const { send, info } = useFineTuningContext();

    const handleCustomerService = async () => {
        TelemetryService.serviceInstance.TrackWithIdentity(
            'EasyFit-UserIssue',
            {
                Issue: 'FineTuningSolutionDoesNotSolveProblem',
            }
        );

        if (viewContext.workflowType === MainViewWorkflow.Web) {
            openNewTabWithUrl(customerServiceGlobalLink.text);
        } else {
            dispatch(
                updateLeaveApp({
                    leaveAppUrl: customerServiceGlobalLink.text,
                })
            );
            viewContext.doTransition(TransitionAction.DoLeaveAppDialogOpen);
        }
    };

    const isContentEnabled = () => {
        switch (fineTuningSolutionState) {
            case FineTuningSolutionState.BeforeApply:
            case FineTuningSolutionState.AppliedSuccessfully:
            case FineTuningSolutionState.ChangeSleeveCustomerService:
            case FineTuningSolutionState.CustomerService: {
                return true;
            }
            case FineTuningSolutionState.Applying:
            case FineTuningSolutionState.AfterApply:
            case FineTuningSolutionState.Waiting:
            case FineTuningSolutionState.Warning:
            case FineTuningSolutionState.WarningCustomerService: {
                return false;
            }
            default: {
                return true;
            }
        }
    };
    const getIncreaseCountPercentage = (
        solutionValueChanged: SolutionValueChanged
    ) => {
        switch (solutionValueChanged) {
            case SolutionValueChanged.MasterGain: {
                return 50;
            }
            case SolutionValueChanged.BandEqualizer: {
                return 50;
            }
            case SolutionValueChanged.Both: {
                return 25;
            }
            default: {
                return 0;
            }
        }
    };

    const updateMasterGainAndBandEqualizer = async (
        solutionValueChanged: SolutionValueChanged,
        deviceState: {
            [deviceId: string]: DeviceState;
        }
    ) => {
        await AudioService.serviceInstance.initAudioContext();
        setPercentage(0);
        const leftDeviceId =
            LocalStorageService.serviceInstance.getDeviceId('left');
        const rightDeviceId =
            LocalStorageService.serviceInstance.getDeviceId('right');

        const leftBrandId =
            LocalStorageService.serviceInstance.getDeviceBrandId('left');
        const leftPairingAddress =
            LocalStorageService.serviceInstance.getPairingAddress('left');
        const rightBrandId =
            LocalStorageService.serviceInstance.getDeviceBrandId('right');
        const rightPairingAddress =
            LocalStorageService.serviceInstance.getPairingAddress('right');

        await updateFineTuning(
            solutionValueChanged,
            leftDeviceId,
            leftBrandId,
            leftPairingAddress,
            'left',
            deviceState[leftDeviceId].easyfitMasterGain,
            deviceState[leftDeviceId].band1EqualizerGain,
            deviceState[leftDeviceId].band2EqualizerGain,
            deviceState[leftDeviceId].band3EqualizerGain,
            deviceState[leftDeviceId].band4EqualizerGain,
            getIncreaseCountPercentage(solutionValueChanged)
        );

        await updateFineTuning(
            solutionValueChanged,
            rightDeviceId,
            rightBrandId,
            rightPairingAddress,
            'right',
            deviceState[rightDeviceId].easyfitMasterGain,
            deviceState[rightDeviceId].band1EqualizerGain,
            deviceState[rightDeviceId].band2EqualizerGain,
            deviceState[rightDeviceId].band3EqualizerGain,
            deviceState[rightDeviceId].band4EqualizerGain,
            getIncreaseCountPercentage(solutionValueChanged)
        );
        await sleep(2000);
        setPercentage(100);
    };

    const getMasterGainAudio = async (
        brandId: number,
        pairingAddress: number,
        side: string,
        masterGain: number
    ) => {
        await AudioService.serviceInstance.prepAudio(
            ArcToneService.getMasterGain(brandId, pairingAddress, masterGain),
            `${side.toUpperCase()} side masterGainId ${masterGain}`
        );
    };

    const updateFineTuning = async (
        type: SolutionValueChanged,
        deviceId: string,
        brandId: number,
        pairingAddress: number,
        side: string,
        masterGain: number,
        band1EqualizerGain: number,
        band2EqualizerGain: number,
        band3EqualizerGain: number,
        band4EqualizerGain: number,
        increaseCount = 0
    ) => {
        if (
            type === SolutionValueChanged.MasterGain ||
            type === SolutionValueChanged.Both
        ) {
            await getMasterGainAudio(brandId, pairingAddress, side, masterGain);
            if (increaseCount > 0)
                setPercentage((prev) => prev + increaseCount);
        }
        if (
            type === SolutionValueChanged.BandEqualizer ||
            type === SolutionValueChanged.Both
        ) {
            await AudioService.serviceInstance.prepAudio(
                ArcToneService.getBandEqualizerGain(
                    brandId,
                    pairingAddress,
                    band1EqualizerGain,
                    band2EqualizerGain,
                    band3EqualizerGain,
                    band4EqualizerGain
                ),
                `bandEqualizerGain ${band1EqualizerGain} ${band2EqualizerGain} ${band3EqualizerGain} ${band4EqualizerGain}`
            );
            if (increaseCount > 0)
                setPercentage((prev) => prev + increaseCount);
        }

        await DeviceManagementService.fineTuningApplied(
            type,
            LocalStorageService.serviceInstance.getPatientId(),
            deviceId,
            masterGain,
            band1EqualizerGain,
            band2EqualizerGain,
            band3EqualizerGain,
            band4EqualizerGain
        );

        info.current.deviceStateFinal[deviceId] = {
            easyfitMasterGain: masterGain,
            band1EqualizerGain: band1EqualizerGain,
            band2EqualizerGain: band2EqualizerGain,
            band3EqualizerGain: band3EqualizerGain,
            band4EqualizerGain: band4EqualizerGain,
        };
    };

    const resetFineTuningContext = () => {
        setFineTuningSolutionState(FineTuningSolutionState.BeforeApply);
        fineTuningSolutionInfo.current.isWarningGoHome = false;
        fineTuningSolutionInfo.current.solutionList = [];
        setActiveSolutionStep(0);
        setPercentage(0);
    };

    const goHome = () => {
        send('completeFineTuning');
    };

    const goStart = () => {
        resetFineTuningContext();
        send('goToStart');
    };

    const exitToVibeApp = () => {
        send('completeChangeSleeve');
    };

    const handleWarningYes = () => {
        fineTuningSolutionInfo.current.isWarningGoHome ? goHome() : goStart();
    };
    const handleHomeAndEditAction = () => {
        fineTuningSolutionInfo.current.previousWarningState =
            fineTuningSolutionState;
        if (fineTuningSolutionState === FineTuningSolutionState.BeforeApply) {
            setFineTuningSolutionState(FineTuningSolutionState.Warning);
        } else if (
            fineTuningSolutionState === FineTuningSolutionState.CustomerService
        ) {
            setFineTuningSolutionState(
                FineTuningSolutionState.WarningCustomerService
            );
        } else {
            handleWarningYes();
        }
    };

    const showWarningForHome = () => {
        fineTuningSolutionInfo.current.isWarningGoHome = true;
        handleHomeAndEditAction();
    };

    const showWarningForEdit = () => {
        fineTuningSolutionInfo.current.isWarningGoHome = false;
        handleHomeAndEditAction();
    };

    const setStepInCarousel = (step: number) => {
        setActiveSolutionStep(step);
        if (
            Object.keys(fineTuningSolutionInfo.current.solutionList[step])
                .length === 0
        ) {
            setFineTuningSolutionState(FineTuningSolutionState.CustomerService);
        } else {
            setFineTuningSolutionState(FineTuningSolutionState.BeforeApply);
        }
    };

    return {
        isContentEnabled,
        handleHomeAndEditAction,
        handleWarningYes,
        showWarningForHome,
        showWarningForEdit,
        setStepInCarousel,
        updateMasterGainAndBandEqualizer,
        handleCustomerService,
        exitToVibeApp,
    };
};

export default useFineTuningSolutionPageHandler;
