import { useContext } from 'react';
import { WarningDialogHandler } from '../Warning/WarningDialog';
import { MainViewContext } from '../../../Pages/MainComponents/MainView/MainViewContext';
import { TransitionAction } from '../../../Pages/MainComponents/MainView/MainViewModel';

export default function useFineTuningWorkflowErrorHandler(): WarningDialogHandler {
    const viewContext = useContext(MainViewContext);
    return {
        primaryAction: () => {
            viewContext.doTransition(TransitionAction.FineTuningWorkflowError);
        },
    };
}
