import { useTranslation } from 'react-i18next';
import { FineTuningSolutionState } from '../../../Models/FineTuning/FineTuningSolutionState';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import { IHeaderProps } from '../../../SharedComponents/Header/HeaderRefactor';
import { ICustomTableProps } from '../../../SharedComponents/Table/CustomTable';
import { TableCellType } from '../../../SharedComponents/Table/TableCellType';
import {
    ICustomTypography,
    TypographyType,
} from '../../../SharedComponents/Typography/CustomTypography';
import { useFineTuningContext } from '../FineTuningContext';
import { useFineTuningSolutionContext } from './FineTuningSolutionContext';
import './FineTuningSolutionPage.scss';
import useFineTuningSolutionPageHandler from './FineTuningSolutionPageHandlerHook';

export type FineTuningSolutionPageResource = {
    headerProps: IHeaderProps;
    tableProps: ICustomTableProps;
    instructionsProps: ICustomTypography;
};

const useFineTuningSolutionPageResource =
    (): FineTuningSolutionPageResource => {
        const { fineTuningSolutionState, activeSolutionStep } =
            useFineTuningSolutionContext();

        const { isContentEnabled, showWarningForHome, showWarningForEdit } =
            useFineTuningSolutionPageHandler();

        const { t } = useTranslation();
        const { info } = useFineTuningContext();

        const headerProps = {
            headerContent: {
                text: t('common:Common_Feature:FineTuning'),
                type: HeaderActionType.Home,
                id: 'header-fine-tuning',
            },
            disabled: !isContentEnabled(),
            onClick: showWarningForHome,
        };

        const categoryHeader = t('fineTuning:FineTuning_Feature:Text1');
        const problemHeader = t('fineTuning:FineTuning_Feature:Text2');
        const selectedCategory = t(
            `fineTuning:FineTuning_Feature:Category:${info.current.selected.category}`
        );
        const selectedProblem = t(
            `fineTuning:FineTuning_Feature:Problem:${info.current.selected.problem.replace(
                /_/g,
                ':'
            )}`
        );

        const getTableProps = () => {
            const tableProps: ICustomTableProps = {
                className: `defaultTable ${
                    isContentEnabled() ? '' : 'disabled'
                }`,
                tableBodyConfig: [
                    {
                        cellsInRow: [
                            {
                                cellType: TableCellType.Text,
                                text: categoryHeader,
                            },
                            {
                                cellType: TableCellType.Text,
                                text: ':',
                                className: 'colonSeparator',
                            },
                            {
                                cellType: TableCellType.Text,
                                text: selectedCategory,
                            },
                            {
                                cellType: TableCellType.Image,
                                imgSrc: '/resources/fineTuning/edit-icon.svg',
                                className: 'editIcon',
                                buttonConfig: {
                                    disabled: !isContentEnabled(),
                                    onClick: showWarningForEdit,
                                },
                            },
                        ],
                    },
                    {
                        cellsInRow: [
                            {
                                cellType: TableCellType.Text,
                                text: problemHeader,
                            },
                            {
                                cellType: TableCellType.Text,
                                text: ':',
                                className: 'colonSeparator',
                            },
                            {
                                cellType: TableCellType.Text,
                                text: selectedProblem,
                            },
                        ],
                    },
                ],
            };

            if (
                fineTuningSolutionState ===
                FineTuningSolutionState.AppliedSuccessfully
            ) {
                tableProps.tableBodyConfig[0].cellsInRow.pop();
                tableProps.className = 'appliedSuccessfullyTable';
            }

            return tableProps;
        };
        const getInstructionsText = () => {
            switch (fineTuningSolutionState) {
                case FineTuningSolutionState.Waiting:
                case FineTuningSolutionState.ChangeSleeveRecommend:
                case FineTuningSolutionState.AppliedSuccessfully:
                    return '';
                case FineTuningSolutionState.CustomerService:
                case FineTuningSolutionState.WarningCustomerService:
                    return t('fineTuning:FineTuning_Feature:Solution:Text10');
                case FineTuningSolutionState.ChangeSleeveCustomerService:
                    return t('fineTuning:FineTuning_Feature:Solution:Text17');
                default:
                    return activeSolutionStep === 0
                        ? t('fineTuning:FineTuning_Feature:Solution:Text8')
                        : t('fineTuning:FineTuning_Feature:Solution:Text9');
            }
        };

        const instructionsProps = {
            type: TypographyType.notoSansBody,
            text: `${getInstructionsText()}`,
            className: `instructionsTextArea ${
                isContentEnabled() ? '' : 'disabled'
            }`,
        };

        return {
            headerProps,
            tableProps: getTableProps(),
            instructionsProps,
        };
    };

export default useFineTuningSolutionPageResource;
