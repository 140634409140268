import { ButtonProps, CircularProgress } from '@material-ui/core';
import React, { useState } from 'react';
import '../ButtonStyle.scss';
import SecondaryButton from './SecondaryButton';

function SecondaryButtonWithLoading({
    disabled,
    onClick,
    ...others
}: Omit<ButtonProps, 'variant'>): React.ReactElement {
    const loadingSpinner = <CircularProgress color="inherit" size="2.05rem" />;
    const [loading, setLoading] = useState(false);

    return (
        <SecondaryButton
            disabled={loading}
            onClick={async (event) => {
                if (onClick) {
                    setLoading(true);
                    await onClick(event);
                    setLoading(false);
                }
            }}
            {...others}
        >
            {loading ? loadingSpinner : <div>{others.children}</div>}
        </SecondaryButton>
    );
}

export default SecondaryButtonWithLoading;
