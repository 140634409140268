import React from 'react';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import { ButtonType } from '../../../SharedComponents/Button/ButtonType';
import CustomButton from '../../../SharedComponents/Button/CustomButton';
import CustomCheckBox from '../../../SharedComponents/Checkbox/CustomCheckBox';
import AppContainer from '../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../SharedComponents/Container/BodyContainer';
import CustomImg from '../../../SharedComponents/CustomImage/CustomImg';
import CustomDialog from '../../../SharedComponents/Dialog/CustomDialog';
import LegalDialog from '../../../SharedComponents/Dialog/Legal/LegalDialog';
import { LegalInfoType } from '../../../SharedComponents/Dialog/Legal/LegalDialogModel';
import Header from '../../../SharedComponents/Header/HeaderRefactor';
import CustomLink from '../../../SharedComponents/Link/CustomLink';
import useLoading from '../../../SharedComponents/Spinner/PageLoadSpinner';
import CustomTypography, {
    TypographyType,
} from '../../../SharedComponents/Typography/CustomTypography';
import useConsentPageHandler from './ConsentPageHandlerHook';
import useConsentPageResource from './ConsentPageResourceHook';
import './ConsentPageStyle.scss';

const ConsentPage = () => {
    const [loadingSpinner, setLoading, loading] = useLoading();

    const {
        header,
        consentImage,
        title,
        consentIntruction,
        privacyLink,
        checkbox,
        button,
    } = useConsentPageResource();
    const {
        showLegalInfo,
        showStartButton,
        handleOperationDataCheckboxChange,
        onContinue,
        openHyperLinkPrivacy,
        dialogClose,
    } = useConsentPageHandler(setLoading);

    return loading ? (
        <>{loadingSpinner}</>
    ) : (
        <>
            <CustomDialog fullScreen open={showLegalInfo}>
                <LegalDialog
                    onClose={dialogClose}
                    type={LegalInfoType.PrivacyPolicy}
                />
            </CustomDialog>
            <AppContainer>
                <Header headerContent={header} />
                <BodyContainer>
                    <CustomImg
                        imgType="small"
                        src={consentImage.src}
                        alt={consentImage.alt}
                        id={consentImage.id}
                    />
                    <CustomTypography
                        textAlign="left"
                        type={TypographyType.notoSansBodyBig}
                        text={title.text}
                        id={title.id}
                        className={'consent-page-title'}
                    />

                    <CustomTypography
                        textAlign="left"
                        type={TypographyType.notoSansBody}
                        text={consentIntruction.text}
                        id={consentIntruction.id}
                    />

                    <CustomLink
                        id={privacyLink.id}
                        onClick={openHyperLinkPrivacy}
                        color="inherit"
                        className={'consent-page-privacy-link'}
                    >
                        {privacyLink.text}
                    </CustomLink>

                    <CustomCheckBox
                        onChange={handleOperationDataCheckboxChange}
                        displayText={checkbox.label}
                        id={checkbox.id}
                        containerClassName={'consent-page-checkbox'}
                        style={{ paddingLeft: 0 }}
                    />
                </BodyContainer>
                <CustomButton
                    buttonType={ButtonType.Primary}
                    id={button.id}
                    onClick={onContinue}
                    disabled={!showStartButton}
                    sticky={false}
                >
                    {button.label}
                </CustomButton>
            </AppContainer>
        </>
    );
};

export default withTracking(ConsentPage);
