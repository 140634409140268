import React from 'react';
import { ButtonType } from '../../../SharedComponents/Button/ButtonType';
import CustomButton from '../../../SharedComponents/Button/CustomButton';
import AppContainer from '../../../SharedComponents/Container/AppContainer';
import BodyContainer from '../../../SharedComponents/Container/BodyContainer';
import ButtonStickyContainer from '../../../SharedComponents/Container/ButtonStickyContainer';
import CustomImg from '../../../SharedComponents/CustomImage/CustomImg';
import Header from '../../../SharedComponents/Header/HeaderRefactor';
import CustomTypography, {
    TypographyType,
} from '../../../SharedComponents/Typography/CustomTypography';
import usePairingTroubleShootingHandler from './PairingTroubleShootingHandlerHook';
import usePairingTroubleShootingResource from './PairingTroubleShootingResourceHook';
import './PairingTroubleShootingStyle.scss';

export default function PairingTroubleshooting(): JSX.Element {
    const { onClickContinue } = usePairingTroubleShootingHandler();

    const { header, title, instruction, instructionImage, button, help } =
        usePairingTroubleShootingResource();

    return (
        <AppContainer>
            <Header headerContent={header} />
            <BodyContainer>
                <CustomTypography
                    type={TypographyType.notoSansSubHeading}
                    text={title.text}
                    id={title.id}
                />
                <CustomImg
                    id={instructionImage.id}
                    src={instructionImage.src}
                    alt=""
                    imgType="medium"
                />
                <CustomTypography
                    id={instruction.id}
                    type={TypographyType.notoSansBody}
                    text={instruction.text}
                    style={{ fontSize: '0.8rem' }}
                />
            </BodyContainer>
            <ButtonStickyContainer style={{ flexDirection: 'column' }}>
                <CustomButton
                    id={button.id}
                    buttonType={ButtonType.Primary}
                    onClick={onClickContinue}
                    withDefaultContainer={false}
                >
                    {button.label}
                </CustomButton>
                <CustomTypography
                    id={help.id}
                    type={TypographyType.notoSansBody}
                    text={help.text}
                />
            </ButtonStickyContainer>
        </AppContainer>
    );
}
