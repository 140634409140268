import { useFineTuningSolutionContext } from '../../FineTuningSolutionContext';
import useFineTuningSolutionPageHandler from '../../FineTuningSolutionPageHandlerHook';

type FTFooterWarningPageHandler = {
    handleWarningYes: () => void;
    handleWarningNo: () => void;
};

const useFTFooterWarningPageHandler = (): FTFooterWarningPageHandler => {
    const { fineTuningSolutionInfo, setFineTuningSolutionState } =
        useFineTuningSolutionContext();
    const { handleWarningYes } = useFineTuningSolutionPageHandler();
    const handleWarningNo = () => {
        setFineTuningSolutionState(
            fineTuningSolutionInfo.current.previousWarningState
        );
    };

    return {
        handleWarningYes,
        handleWarningNo,
    };
};

export default useFTFooterWarningPageHandler;
