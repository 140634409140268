import React from 'react';
import PairButton from '../../../../../SharedComponents/Button/PairButton';
import FlexContainer from '../../../../../SharedComponents/Container/FlexContainer';
import CustomTypography, {
    TypographyType,
} from '../../../../../SharedComponents/Typography/CustomTypography';
import useFTFooterAfterApplyPageHandler from './FTFooterAfterApplyPageHandler';
import useFTFooterAfterApplyPageResource from './FTFooterAfterApplyPageResource';

export default function FTFooterAfterApplyPage(): React.ReactElement {
    const { handleSolutionNotSolved, handleSolutionSolved } =
        useFTFooterAfterApplyPageHandler();
    const { confirmText, buttonTexts } = useFTFooterAfterApplyPageResource();
    return (
        <FlexContainer>
            <CustomTypography
                id={confirmText.id}
                text={confirmText.text}
                type={TypographyType.notoSansBody}
            />
            <PairButton
                primaryButton={{
                    id: 'btn-confirm-yes',
                    onClick: async () => {
                        await handleSolutionSolved();
                    },
                    children: buttonTexts.primary,
                }}
                secondaryButton={{
                    id: 'btn-confirm-no',
                    onClick: async () => {
                        await handleSolutionNotSolved();
                    },
                    children: buttonTexts.secondary,
                }}
            />
        </FlexContainer>
    );
}
