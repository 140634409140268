import { useContext } from 'react';
import TelemetryService from '../../../Services/Monitoring/TelemetryService';
import { VoidReturn } from '../../../Utils/PageUtils';
import { PairingContext } from '../PairingContext';

export type PairingTroubleShootingHandler = {
    onClickContinue: () => VoidReturn;
};

export default function usePairingTroubleShootingHandler(): PairingTroubleShootingHandler {
    const { send } = useContext(PairingContext);

    const onClickContinue = () => {
        TelemetryService.serviceInstance.TrackWithIdentity(
            'EasyFit-FeatureUsage',
            {
                Feature: 'TryPairingAgain',
            }
        );
        send('troubleshot');
    };

    return {
        onClickContinue,
    };
}
