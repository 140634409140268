import axios from 'axios';
import { ServiceConfigFactory } from './ServiceConfigFactory';
import { Patient } from '../Models/Patient/Patient';
import { HLAAAudiogram } from '../Models/HLAA/HLAAAudiogram';
import { SupportedHi } from '../Models/Patient/SupportedHi';
import { Device } from '../Models/Patient/Device';
import { Workflow } from '../Utils/CompletedWorkflow';
import AuthService from './Authentication/AuthService';
import { DeviceRepairingState } from '../Models/DeviceRepairingState';
import LocalStorageService from './LocalStorageService';
import {
    ConsentValue,
    PatientConsentData,
} from '../Models/Patient/PatientConsentData';
import { DeviceInfo } from '../Models/DeviceInfo';
import LoggingService from './LoggingService';
import { getCouplingOrDefault } from '../Utils/CouplingUtils';
import { deviceModelId } from '../Models/SupportedDeviceModel';
import { ErrorMessages } from '../Utils/DeleteAccountUtils';

export default class PatientManagementService {
    public static async createPatient(patientId: string): Promise<void> {
        const dummyRightDeviceId = 'RightDeviceId'; //This id could be anything, cos the device id is auto generated in backend
        const dummyLeftDeviceId = 'LeftDeviceId'; //This id could be anything, cos the device id is auto generated in backend
        const preferredLanguage = 'preferredLanguage';
        const residedCountry = '';
        const devicePairingRequired = DeviceRepairingState.NoAction; // This is dummy
        const lastCompletedHlaaTestId = 'lastCompletedHlaaTestId'; // This is dummy
        const activeHlaaId = 'activeHlaaId'; // This is dummy
        const activeHearingProfileId = 'activeHearingProfileId'; //This is dummy
        const rightSide = 'Right';
        const leftSide = 'Left';
        const dummyPairingAddress = -1;

        const supportedHis = await this.getSupporedHis();
        const patient = new Patient(
            patientId,
            preferredLanguage,
            residedCountry,
            devicePairingRequired,
            0,
            lastCompletedHlaaTestId,
            activeHlaaId,
            activeHearingProfileId,
            [
                new Device(
                    dummyRightDeviceId,
                    rightSide,
                    supportedHis[0].brandId,
                    supportedHis[0].hiUri,
                    dummyPairingAddress,
                    0
                ),
                new Device(
                    dummyLeftDeviceId,
                    leftSide,
                    supportedHis[0].brandId,
                    supportedHis[0].hiUri,
                    dummyPairingAddress,
                    0
                ),
            ],
            false,
            false,
            'DummyVersion',
            ConsentValue.Declined,
            false
        );

        const res = await axios.post(
            process.env.REACT_APP_EASYFIT_API_URL +
                '/PatientManagement/CreatePatient',
            JSON.stringify(patient),
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );
        if (res.status < 300) {
            return;
        }
        return Promise.reject('Failed to create patient ' + res.statusText);
    }

    public static async getPatient(): Promise<Patient | undefined> {
        let res = await axios.get(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/PatientManagement/Patient`,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );
        if (res.status === 401 || res.status === 403) {
            LoggingService.info({
                componentName: this.getPatient.name,
                args: [
                    `Unauthorized ${res.status} - ${res.statusText} occurred when getPatient. Retrying`,
                ],
            });
            await new Promise((resolve) => setTimeout(resolve, 500));
            // forcefully refresh the access token, in case user's token has expired but not detected by client
            // (e.g. client's time is wrong). Hotfix done only on GetPatient, as this is currently
            // the first authenticated API to be hit on page load.
            res = await axios.get(
                process.env.REACT_APP_EASYFIT_API_URL +
                    `/PatientManagement/Patient`,
                ServiceConfigFactory.CreateAxiosConfigWithAuth(
                    await AuthService.adB2cInstance.acquireAccessToken(true)
                )
            );
        }
        if (res.status === 200) {
            if (res.data === ErrorMessages.INVALID_USER) {
                return undefined;
            }
            LocalStorageService.serviceInstance.setPatient(res.data);
            return res.data;
        } else if (res.status === 204) {
            LocalStorageService.serviceInstance.clearPatientData();
            return undefined;
        }
        return Promise.reject(
            'Error occurred when getPatient: ' + res.statusText
        );
    }

    public static async getDeviceRepairingState(): Promise<DeviceRepairingState> {
        const result = await axios
            .get(
                process.env.REACT_APP_EASYFIT_API_URL +
                    `/PatientManagement/DeviceRepairingState`,
                ServiceConfigFactory.CreateAxiosConfigWithAuth(
                    await AuthService.adB2cInstance.acquireAccessToken()
                )
            )
            .then((result) => {
                return result.data;
            });
        LocalStorageService.serviceInstance.setDevicePairingRequired(result);
        return result;
    }

    public static async getCompletedWorkflow(): Promise<Workflow | undefined> {
        return await axios
            .get(
                process.env.REACT_APP_EASYFIT_API_URL +
                    `/PatientManagement/GetCompletedWorkflow`,
                ServiceConfigFactory.CreateAxiosConfigWithAuth(
                    await AuthService.adB2cInstance.acquireAccessToken()
                )
            )
            .then((result) => {
                return result.data;
            });
    }

    public static async applyInitialSettings(
        hlaaTestId: string,
        audiogram: HLAAAudiogram[]
    ): Promise<void> {
        const res = await axios.put<HLAAAudiogram[]>(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/PatientManagement/ApplyInitialSettings?hlaaTestId=${hlaaTestId}`,
            JSON.stringify(audiogram),
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );

        if (res.status < 300) {
            return;
        }
        return Promise.reject('Fail to add audiogram ' + res.statusText);
    }

    public static async getSupporedHis(): Promise<SupportedHi[]> {
        const res = await axios.get(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/PatientManagement/SupportedHi`,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );
        if (res.status === 200 && res.data !== null) {
            return res.data;
        }
        return Promise.reject(
            'Error occurred when getSupporedHis: ' + res.statusText
        );
    }
    public static async updateLanguage(languageCode: string): Promise<void> {
        const res = await axios.put(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/PatientManagement/UpdateLanguage?languageCode=${languageCode}`,
            null,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );

        if (res.status < 300) {
            return;
        }

        return Promise.reject('Fail to update language' + res.statusText);
    }

    public static async updateCountry(country: string): Promise<void> {
        const res = await axios.put(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/PatientManagement/UpdateCountry?country=${country}`,
            null,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );

        if (res.status < 300) {
            return;
        }

        return Promise.reject('Fail to update country' + res.statusText);
    }

    public static async resetUserJourney(): Promise<void> {
        const res = await axios.put(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/PatientManagement/ResetUserJourney`,
            null,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );

        if (res.status < 300) {
            return;
        }
        return Promise.reject(`Fail to reset user workflow` + res.statusText);
    }

    public static async resetFactory(): Promise<void> {
        const res = await axios.put(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/PatientManagement/ResetFactory`,
            null,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );

        if (res.status < 300) {
            return;
        }
        return Promise.reject(`Fail to reset factory` + res.statusText);
    }

    public static async redoHLAA(): Promise<void> {
        const res = await axios.put(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/PatientManagement/RedoHLAA`,
            null,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );

        if (res.status < 300) {
            return;
        }
        return Promise.reject(`Fail to redo HLAA` + res.statusText);
    }

    public static async endWorkflow(workflow: Workflow): Promise<void> {
        const res = await axios.put(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/PatientManagement/EndWorkflow?performedWorkflow=${workflow}`,
            null,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );

        if (res.status < 300) {
            return;
        }
        return Promise.reject(
            `Fail to end ${workflow} workflow` + res.statusText
        );
    }

    public static async ConsentOperationData(version: string): Promise<void> {
        const res = await axios.put(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/PatientManagement/ConsentOperationData?privacyNotesVersion=${version}`,
            null,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );

        if (res.status < 300) {
            return;
        }
        return Promise.reject(
            `Fail to consent operation data` + res.statusText
        );
    }

    public static async ConsentAnalyticData(version: string): Promise<void> {
        const res = await axios.put(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/PatientManagement/ConsentAnalyticData?consentValue=Agreed&privacyNotesVersion=${version}`,
            null,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );

        if (res.status < 300) {
            return;
        }
        return Promise.reject(`Fail to consent analytic data` + res.statusText);
    }

    public static async RevokeAnalyticData(version: string): Promise<void> {
        const res = await axios.put(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/PatientManagement/ConsentAnalyticData?consentValue=Declined&privacyNotesVersion=${version}`,
            null,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );

        if (res.status < 300) {
            return;
        }
        return Promise.reject(
            `Fail to revoke consent analytic data` + res.statusText
        );
    }

    public static async GetAnalyticDataConsentState(): Promise<PatientConsentData> {
        const res = await axios.get(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/PatientManagement/GetAnalyticDataConsentState`,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );
        if (res.status === 200 && res.data !== null) {
            return res.data;
        }
        return Promise.reject(
            'Failed to get analytic data consent state: ' + res.statusText
        );
    }

    public static async GetOperationDataConsentState(): Promise<boolean> {
        const res = await axios.get(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/PatientManagement/GetOperationDataConsentState`,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );
        if (res.status === 200 && res.data !== null) {
            return res.data;
        }
        return Promise.reject(
            'Failed to get operation data consent state: ' + res.statusText
        );
    }

    public static async SetDevices(devices: DeviceInfo[]): Promise<void> {
        const res = await axios.post(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/PatientManagement/UpdateDevices`,
            devices,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );

        if (res.status < 300) {
            return;
        }
        return Promise.reject(`Failed to update devices` + res.statusText);
    }

    public static async SetVibeAirDevices(): Promise<void> {
        const devices = [
            new DeviceInfo(
                'Left',
                15,
                '6144007',
                -1,
                getCouplingOrDefault('left', deviceModelId.VibeAir)
            ),
            new DeviceInfo(
                'Right',
                15,
                '6144007',
                -1,
                getCouplingOrDefault('right', deviceModelId.VibeAir)
            ),
        ];
        const res = await axios.post(
            process.env.REACT_APP_EASYFIT_API_URL +
                `/PatientManagement/UpdateDevices`,
            devices,
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken()
            )
        );

        if (res.status < 300) {
            return;
        }
        return Promise.reject(`Failed to update devices` + res.statusText);
    }

    public static async DeleteIDSAccount(): Promise<void> {
        const res = await axios.delete(
            process.env.REACT_APP_EASYFIT_API_URL +
                '/PatientManagement/DeleteIDSAccount',
            ServiceConfigFactory.CreateAxiosConfigWithAuth(
                await AuthService.adB2cInstance.acquireAccessToken(),
                20000
            )
        );
        if (res.status < 300) {
            return;
        }
        return Promise.reject('Failed to delete IDS account ' + res.statusText);
    }
}
