import { ButtonProps } from '@material-ui/core';
import React from 'react';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import {
    ButtonResource,
    TextResource,
    VoidReturn,
} from '../../../Utils/PageUtils';
import { ButtonType } from '../../Button/ButtonType';
import CustomButton from '../../Button/CustomButton';
import PairButton from '../../Button/PairButton';
import BodyContainer from '../../Container/BodyContainer';
import CustomTypography, {
    TypographyType,
} from '../../Typography/CustomTypography';
import CustomDialog from '../CustomDialog';
import { IDialogContentProps } from '../DialogUtils';
import './PartialDialog.scss';
import ActionContainer from '../../Container/ActionContainer';

export type PartialDialogHandler = {
    primaryAction: () => VoidReturn;
    secondaryAction?: () => VoidReturn;
};

export type PartialDialogResource = {
    dialogTitle: TextResource;
    dialogMessage: TextResource;
    buttonLabels: {
        primaryLabel: ButtonResource;
        secondaryLabel?: ButtonResource;
    };
};

export interface IPartialDialogProps extends IDialogContentProps {
    open: boolean;
    useHandler: () => PartialDialogHandler;
    useResource: () => PartialDialogResource;
}

function PartialDialog(props: IPartialDialogProps) {
    const { useHandler, useResource, open } = props;

    const { primaryAction, secondaryAction } = useHandler();

    const { dialogTitle, dialogMessage, buttonLabels } = useResource();

    const primButtonConfig: ButtonProps = {
        id: buttonLabels.primaryLabel.id,
        onClick: primaryAction,
        children: buttonLabels.primaryLabel.label,
    };

    return (
        <CustomDialog
            open={open}
            fullScreen={false}
            hideBackdrop={false}
            PaperProps={{
                style: {
                    margin: '16px',
                },
            }}
            BackdropProps={{
                style: {
                    background: 'black',
                    opacity: 0.5,
                },
            }}
            style={{
                position: 'fixed',
                zIndex: 1301,
            }}
        >
            <BodyContainer className={'partialDialogContainer'}>
                <CustomTypography
                    id={dialogTitle.id}
                    type={TypographyType.notoSansSubHeading}
                    text={dialogTitle.text}
                    className={'partialDialogTitleText'}
                />
                <CustomTypography
                    id={dialogMessage.id}
                    type={TypographyType.notoSansBody16px}
                    text={dialogMessage.text}
                />
                {buttonLabels.secondaryLabel ? (
                    <ActionContainer className={'partialDialogButtons'}>
                        <PairButton
                            withLoading
                            secondaryButton={{
                                id: buttonLabels.secondaryLabel.id,
                                onClick: secondaryAction,
                                children: buttonLabels.secondaryLabel.label,
                            }}
                            primaryButton={primButtonConfig}
                            withDefaultContainer={false}
                        />
                    </ActionContainer>
                ) : (
                    <CustomButton
                        {...primButtonConfig}
                        buttonType={ButtonType.Primary}
                    />
                )}
            </BodyContainer>
        </CustomDialog>
    );
}

export default withTracking(PartialDialog);
