import React from 'react';
import { withTracking } from '../../../../Services/Monitoring/ApplicationInsights';
import WarningDialog from '../../../../SharedComponents/Dialog/Warning/WarningDialog';
import './LeftRightDifferencePage.scss';
import useLeftRightDifferenceHandler from './LeftRightDifferencePageHandlerHook';
import useLeftRightDifferencePageResource from './LeftRightDifferencePageResourceHook';

function LeftRightDifferencePage() {
    return (
        <WarningDialog
            useHandler={useLeftRightDifferenceHandler}
            useResource={useLeftRightDifferencePageResource}
        />
    );
}

export default withTracking(LeftRightDifferencePage);
