import React from 'react';
import { withTracking } from '../../../Services/Monitoring/ApplicationInsights';
import { IDialogContentProps } from '../DialogUtils';
import SubMenuDialog from '../Menu/SubMenuDialog';
import useLanguageSelectionHandler from './LanguageSelectionDialogHandleHook';
import useLanguageSelectionResource from './LanguageSelectionResourceHook';

function LanguageSelectionDialog({ onClose }: IDialogContentProps) {
    const { getSelectedItems, onClickItemHandle } =
        useLanguageSelectionHandler(onClose);

    const useResource = () => useLanguageSelectionResource(onClickItemHandle);

    return (
        <SubMenuDialog
            onClose={onClose}
            useResource={useResource}
            useHandler={() => ({ getSelectedItems })}
        />
    );
}

export default withTracking(LanguageSelectionDialog);
