import { createContext, MutableRefObject, useContext } from 'react';
import { FineTuningSolution } from '../../../Models/FineTuning/FineTuningSolution';
import { FineTuningSolutionState } from '../../../Models/FineTuning/FineTuningSolutionState';

export interface IFineTuningSolutionInfo {
    solutionList: FineTuningSolution[];
    isWarningGoHome: boolean;
    previousWarningState: FineTuningSolutionState;
}

export interface IFineTuningSolutionContext {
    fineTuningSolutionState: FineTuningSolutionState;
    setFineTuningSolutionState: React.Dispatch<
        React.SetStateAction<FineTuningSolutionState>
    >;
    activeSolutionStep: number;
    setActiveSolutionStep: React.Dispatch<React.SetStateAction<number>>;
    percentage: number;
    setPercentage: React.Dispatch<React.SetStateAction<number>>;
    fineTuningSolutionInfo: MutableRefObject<IFineTuningSolutionInfo>;
}

export const FineTuningSolutionContext = createContext(
    {} as IFineTuningSolutionContext
);

export const useFineTuningSolutionContext = (): IFineTuningSolutionContext =>
    useContext(FineTuningSolutionContext);
