import React from 'react';
import TopProgressBar from '../../../SharedComponents/TopProgressBar/TopProgressBar';
import BeepConfirmation from '../../MainComponents/BeepConfirmation/BeepConfirmation';
import useHearingAssessmentBeepConfirmationHandler from './HearingAssessmentBeepConfirmationHandler';
import { useHLAAConfirmationResource } from './HearingAssessmentBeepConfirmationResource';

export default function HearingAssessmentBeepConfirmation(): React.ReactElement {
    const {
        onBeepConfirmationYesAction,
        onBeepConfirmationNoAction,
        getTopProgress,
    } = useHearingAssessmentBeepConfirmationHandler();
    const useHLAARender = () => {
        return {
            renderTopProgressBar: (): JSX.Element => {
                return <TopProgressBar progress={getTopProgress()} />;
            },
        };
    };

    return (
        <BeepConfirmation
            useHandler={() => {
                return {
                    primaryAction: onBeepConfirmationYesAction,
                    secondaryAction: onBeepConfirmationNoAction,
                };
            }}
            useResource={useHLAAConfirmationResource}
            useRender={useHLAARender}
        />
    );
}
