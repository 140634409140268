import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { OneSideSetUpResource } from '../../../../Pages/MainComponents/OneSideSetUp/OneSideSetUp';
import { HeaderActionType } from '../../../Header/HeaderActionType';
import { RedoHLAAContext } from '../RedoHLAAContext';

export default function useRedoHLAAOneSideSetUpResource(): OneSideSetUpResource {
    const { t } = useTranslation();
    const { info } = useContext(RedoHLAAContext);

    const header = {
        id: 'header-redo-HLAA-oneSide-setup',
        text: t('menu:Menu_Feature:Main:Text2'),
        type: HeaderActionType.Menu,
    };

    const image = {
        id: `img-${info.current.activeSide.toLowerCase()}`,
        src: `/resources/shared/wait-asset.svg`,
        alt: `${info.current.activeSide.toLowerCase()}`,
    };

    const progress = {
        id: 'progress-loading',
    };
    const infoText = {
        id: 'txt-info',
        text: t('hlaa:Hlaa_Feature:Setup:Text'),
    };

    return {
        header,
        image,
        progress,
        infoText,
    };
}
