import React, { HTMLAttributes } from 'react';
import ActionContainer from './ActionContainer';
import ButtonContainer from './ButtonContainer';
import './ContainerStyle.scss';

function ButtonStickyContainer(
    props: HTMLAttributes<HTMLDivElement>
): React.ReactElement {
    return (
        <ActionContainer sticky>
            <ButtonContainer {...props} />
        </ActionContainer>
    );
}

export default ButtonStickyContainer;
