import React from 'react';
import { withTracking } from '../../../../Services/Monitoring/ApplicationInsights';
import WarningDialog from '../../Warning/WarningDialog';
import useAnalyticConsentAlertDialogHandler from './AnalyticConsentAlertDialogHandlerHook';
import useAnalyticConsentAlertDialogResource from './AnalyticConsentAlertDialogResourceHook';

function AnalyticConsentAlertDialog() {
    return (
        <WarningDialog
            useHandler={useAnalyticConsentAlertDialogHandler}
            useResource={useAnalyticConsentAlertDialogResource}
        />
    );
}

export default withTracking(AnalyticConsentAlertDialog);
