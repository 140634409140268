import React from 'react';
import { VoidReturn } from '../../../Utils/PageUtils';
import {
    DialogState,
    PageState,
    MainViewWorkflow,
    TransitionAction,
} from './MainViewModel';

export interface IMainViewContext {
    pageState: PageState;
    dialogState: DialogState;
    workflowType: MainViewWorkflow;
    doTransition: (action: TransitionAction) => VoidReturn;
}

export const MainViewContext = React.createContext({} as IMainViewContext);
